var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { useRef, useState, useCallback, useEffect, useMemo } from "react";
import { useDebounce } from "react-use";
import * as Styled from "./component-data-list.style";
import { CupTooltip } from "ui/components/panels/canvas/overlay-elements/tooltip-components/cup-tooltip";
import { VacuumPumpTooltip } from "ui/components/panels/canvas/overlay-elements/tooltip-components/pump-tooltip";
import { actions, selectors, useAppDispatch, useAppSelector } from "store";
import { NodeComponentType, OverlayData } from "store/types";
import { useStopEventPropagation } from "hooks/stop-event-propagation";
import { useScrollToTopOfFlexReversedContainer } from "hooks/scroll-to-top-of-flex-reversed-container";
import { useGraphDataContext } from "providers/graph-panel";
import { AlphabeticalNodeType } from "hooks/graph-panel/graph";
export var ComponentDataPanel = function () {
    var nodes = useAppSelector(selectors.selectNodes);
    var overlayData = useAppSelector(selectors.selectOverlayData);
    var shouldShowComponentData = overlayData === OverlayData.PERFORMANCE_DATA;
    var getAlphabeticalId = useGraphDataContext().getAlphabeticalId;
    var containerRef = useRef(null);
    useStopEventPropagation(containerRef, "wheel", [shouldShowComponentData]);
    useScrollToTopOfFlexReversedContainer(containerRef, [
        shouldShowComponentData,
    ]);
    var dispatch = useAppDispatch();
    var _a = __read(useState(true), 2), scrolledToTop = _a[0], setScrolledToTop = _a[1];
    var _b = __read(useState(true), 2), scrolledToBottom = _b[0], setScrolledToBottom = _b[1];
    var _c = __read(useState(0), 2), containerWidth = _c[0], setContainerWidth = _c[1];
    var _d = __read(useState(), 2), hoveredItem = _d[0], setHoveredItem = _d[1];
    // We debounce the dispatch to avoid janky camera movements when we quickly hover from item to another item.
    useDebounce(function () {
        dispatch(actions.selectNode(hoveredItem));
    }, 200, [hoveredItem]);
    var handleScroll = useCallback(function () {
        if (!containerRef.current)
            return;
        var _a = containerRef.current, scrollTop = _a.scrollTop, scrollHeight = _a.scrollHeight, clientHeight = _a.clientHeight;
        // The value of scrollTop is sometimes off by 1 pixel on Chrome browsers,
        // so we use this constant to account for that error.
        var errorMargin = 1;
        var maxScrollHeight = scrollHeight - clientHeight;
        var ammountScrolled = Math.ceil(Math.abs(scrollTop));
        var scrollIsAtTop = maxScrollHeight - ammountScrolled <= errorMargin;
        var scrollIsAtBottom = ammountScrolled <= errorMargin;
        setScrolledToTop(scrollIsAtTop);
        setScrolledToBottom(scrollIsAtBottom);
    }, []);
    var scrollUp = useCallback(function () {
        if (!containerRef.current)
            return;
        containerRef.current.scrollBy({
            top: -200,
            left: 0,
            behavior: "smooth",
        });
    }, [containerRef]);
    var scrollDown = useCallback(function () {
        if (!containerRef.current)
            return;
        containerRef.current.scrollBy({
            top: 200,
            left: 0,
            behavior: "smooth",
        });
    }, [containerRef]);
    useEffect(function () {
        if (!containerRef.current)
            return;
        var currentItem = containerRef.current;
        currentItem.addEventListener("scroll", handleScroll);
        setContainerWidth(currentItem.clientWidth);
        return function () { return currentItem.removeEventListener("scroll", handleScroll); };
    }, [containerRef, handleScroll, shouldShowComponentData]);
    var tooltips = useMemo(function () {
        var _a;
        var nodeIsPumpOrCupWithId = function (node) {
            var alphabeticalIdPump = getAlphabeticalId(node.id, AlphabeticalNodeType.PUMP);
            var alphabeticalIdCup = getAlphabeticalId(node.id, AlphabeticalNodeType.CUP);
            var nodeHasAlphabeticalId = (alphabeticalIdPump && alphabeticalIdPump !== "") ||
                (alphabeticalIdCup && alphabeticalIdCup !== "");
            if (!nodeHasAlphabeticalId)
                return false;
            return (node.type === NodeComponentType.GRIP_POSITION ||
                node.type === NodeComponentType.CENTRALIZED_VACUUM_PUMP);
        };
        var alphabeticalTypeByNodeType = (_a = {},
            _a[NodeComponentType.CENTRALIZED_VACUUM_PUMP] = AlphabeticalNodeType.PUMP,
            _a[NodeComponentType.GRIP_POSITION] = AlphabeticalNodeType.CUP,
            _a);
        var sortByAlphabeticalId = function (nodeA, nodeB) {
            var nodeAType = nodeA.type ||
                NodeComponentType.GRIP_POSITION;
            var nodeBType = nodeB.type ||
                NodeComponentType.GRIP_POSITION;
            var alphabeticalIdA = getAlphabeticalId(nodeA.id, alphabeticalTypeByNodeType[nodeAType]);
            var alphabeticalIdB = getAlphabeticalId(nodeB.id, alphabeticalTypeByNodeType[nodeBType]);
            return alphabeticalIdB.localeCompare(alphabeticalIdA);
        };
        return Object.values(nodes)
            .filter(nodeIsPumpOrCupWithId)
            .sort(sortByAlphabeticalId)
            .map(function (node) {
            return (_jsx(ComponentDataListItem, { onItemHover: setHoveredItem, node: node }, node.id));
        });
    }, [getAlphabeticalId, nodes]);
    if (!shouldShowComponentData)
        return null;
    return (_jsxs(Styled.ComponentDataListWrapper, { containerWidth: containerWidth, children: [_jsx(Styled.ComponentDataContainer, { ref: containerRef, scrolledToTop: scrolledToTop, scrolledToBottom: scrolledToBottom, children: tooltips }), !scrolledToTop && (_jsx(Styled.UpButton, { onClick: scrollUp, children: _jsx(Styled.ArrowIcon, { name: "chevron-up", color: "primary", scale: 3 }) })), !scrolledToBottom && (_jsx(Styled.DownButton, { onClick: scrollDown, children: _jsx(Styled.ArrowIcon, { name: "chevron-down", color: "primary", scale: 3 }) }))] }));
};
var ComponentDataListItem = function (_a) {
    var node = _a.node, onItemHover = _a.onItemHover;
    var tooltipComponent = node.type === NodeComponentType.GRIP_POSITION ? (_jsxs(_Fragment, { children: [_jsx(VacuumPumpTooltip, { node: node }), _jsx(CupTooltip, { node: node })] })) : (_jsx(VacuumPumpTooltip, { node: node }));
    return (_jsx(Styled.ComponentDataListItem, { onMouseEnter: function () { return onItemHover(node.id); }, onMouseLeave: function () { return onItemHover(undefined); }, children: tooltipComponent }));
};
