import { useEffect } from "react";
import { usePlaycanvasApplicationContext } from "providers/playcanvas-application";
/**
 * Use this hook in components that should block interaction with the PlayCanvas scene when mounted.
 * The hook can handle being used in multiple components simultaneously, I.E if multiple components
 * are mounted at the same time, the blocking element will only be removed when all components are
 * unmounted.
 *
 * NOTE: The hook can only be used where the PlaycanvasApplicationContext is provided.
 */
export function useBlockingElementEffect() {
    var blockingElementEvents = usePlaycanvasApplicationContext().blockingElementEvents;
    useEffect(function () {
        blockingElementEvents && blockingElementEvents.onBlockingElementEnter();
        return function () {
            blockingElementEvents && blockingElementEvents.onBlockingElementExit();
        };
    }, [blockingElementEvents]);
}
