import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTheme } from "@emotion/react";
export var ErrorIcon = function (_a) {
    var size = _a.size, isDisabled = _a.isDisabled;
    var theme = useTheme();
    var triangleColor = isDisabled
        ? theme.colors.palette.grey[500]
        : theme.colors.components.alert.shades[500].main;
    var contentColor = isDisabled
        ? theme.colors.palette.grey[500]
        : theme.colors.palette.white;
    return (_jsxs("svg", { width: size, height: size, viewBox: "0 0 30 30", children: [_jsx("path", { d: "M12.628 2.28116L1.35666 21.0978C1.12427 21.5003 1.00131 21.9565 1.00001 22.4213C0.998709 22.886 1.11911 23.343 1.34925 23.7467C1.57938 24.1504 1.91122 24.4869 2.31175 24.7226C2.71227 24.9582 3.16753 25.0849 3.63223 25.09H26.175C26.6397 25.0849 27.0949 24.9582 27.4954 24.7226C27.896 24.4869 28.2278 24.1504 28.4579 23.7467C28.6881 23.343 28.8085 22.886 28.8072 22.4213C28.8059 21.9565 28.6829 21.5003 28.4505 21.0978L17.1792 2.28116C16.9419 1.89007 16.6079 1.56671 16.2093 1.3423C15.8107 1.11789 15.361 1 14.9036 1C14.4462 1 13.9965 1.11789 13.5979 1.3423C13.1993 1.56671 12.8653 1.89007 12.628 2.28116V2.28116Z", stroke: triangleColor, fill: "none", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M17.9998 12.6875L11.8075 18.8797", stroke: contentColor, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M11.8076 12.6875L17.9998 18.8797", stroke: contentColor, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })] }));
};
