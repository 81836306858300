var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable camelcase */
import { Material, } from "3d/types";
/** ---------------   **/
/**     OB Family     **/
/** ---------------   **/
var materialTransformTemplate = {
    PU60: {
        materialTransforms: [
            { sourceMaterial: Material.MA_MAIN, targetMaterial: Material.PU60 },
            { sourceMaterial: Material.MA_MAIN2, targetMaterial: Material.PU60 },
        ],
    },
    PU30_60: {
        materialTransforms: [
            { sourceMaterial: Material.MA_MAIN, targetMaterial: Material.PU60 },
            { sourceMaterial: Material.MA_MAIN2, targetMaterial: Material.PU30 },
        ],
    },
    PU60_90: {
        materialTransforms: [
            { sourceMaterial: Material.MA_MAIN, targetMaterial: Material.PU60 },
            { sourceMaterial: Material.MA_MAIN2, targetMaterial: Material.PU30 },
        ],
    },
};
var cupModelTemplate = {
    OB50X140P: {
        cupModelName: "OB50X140P",
        cupWidth: 0.059,
        cupHeight: 0.0345,
        fittingModelName: "G38M01-S6",
        collisionComponentType: "capsule",
    },
};
export var suctionCupsFamilyOB = {
    // OB20x60P PU60
    suction_cup_0115291: __assign(__assign({}, cupModelTemplate.OB50X140P), materialTransformTemplate.PU60),
    // OB35x90P PU60
    suction_cup_0108673: __assign(__assign({}, cupModelTemplate.OB50X140P), materialTransformTemplate.PU60),
    // OB35x90P PU30/60
    suction_cup_0109856: __assign(__assign({}, cupModelTemplate.OB50X140P), materialTransformTemplate.PU30_60),
    // OB50x140P PU60
    suction_cup_0108675: __assign(__assign({}, cupModelTemplate.OB50X140P), materialTransformTemplate.PU60),
    // OB50x140P PU60/90
    suction_cup_0109858: __assign(__assign({}, cupModelTemplate.OB50X140P), materialTransformTemplate.PU60_90),
    // OB65x170P PU60
    suction_cup_0108677: __assign(__assign({}, cupModelTemplate.OB50X140P), materialTransformTemplate.PU60),
    // OB65x170P PU60/90
    suction_cup_0109860: __assign(__assign({}, cupModelTemplate.OB50X140P), materialTransformTemplate.PU60_90),
};
