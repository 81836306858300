var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@emotion/styled";
import { StyledLabel, StyledTextBox, StyledButton } from "@animech/ui";
import { css } from "@emotion/react";
export var prefix = "input-container";
export var InputContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme, locked = _a.locked;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: column;\n\t\t\tjustify-content: space-between;\n\n\t\t\t.", "-content-container {\n\t\t\t\tmax-height: 100%;\n\t\t\t}\n\n\t\t\t.", "-input-container {\n\t\t\t\tdisplay: flex;\n\t\t\t\tposition: relative;\n\t\t\t\theight: 100%;\n\t\t\t\tflex-direction: column;\n\t\t\t\tflex-grow: 1;\n\n\t\t\t\t.", "-button-container {\n\t\t\t\t\tposition: absolute;\n\t\t\t\t\ttop: -15px;\n\t\t\t\t\tright: -10px;\n\n\t\t\t\t\t", " {\n\t\t\t\t\t\t", "\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\ttext-overflow: ellipsis;\n\t\t\t\toverflow: hidden;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tmax-height: 100%;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\talign-items: center;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tdisplay: flex;\n\t\t\t}\n\t\t"], ["\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: column;\n\t\t\tjustify-content: space-between;\n\n\t\t\t.", "-content-container {\n\t\t\t\tmax-height: 100%;\n\t\t\t}\n\n\t\t\t.", "-input-container {\n\t\t\t\tdisplay: flex;\n\t\t\t\tposition: relative;\n\t\t\t\theight: 100%;\n\t\t\t\tflex-direction: column;\n\t\t\t\tflex-grow: 1;\n\n\t\t\t\t.", "-button-container {\n\t\t\t\t\tposition: absolute;\n\t\t\t\t\ttop: -15px;\n\t\t\t\t\tright: -10px;\n\n\t\t\t\t\t", " {\n\t\t\t\t\t\t", "\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\ttext-overflow: ellipsis;\n\t\t\t\toverflow: hidden;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tmax-height: 100%;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\talign-items: center;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tdisplay: flex;\n\t\t\t}\n\t\t"])), prefix, prefix, prefix, StyledButton.Root, !locked &&
        "\n\t\t\t\t\t\t\tpadding: 3px;\n\t\t\t\t\t\t\tborder: 2px ".concat(theme.colors.palette.green[500], " dashed;\n\t\t\t\t\t\t"), StyledLabel.Root, StyledTextBox.Root, StyledTextBox.Container, StyledTextBox.InputContainer);
});
var templateObject_1, templateObject_2;
