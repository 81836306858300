var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { ActionCreators as UndoActionCreators } from "redux-undo";
import * as Styled from "ui/components/elements/undo-redo/undo-redo.style";
import { RedoIcon } from "ui/components/elements/icons/redo";
import { UndoIcon } from "ui/components/elements/icons/undo";
import { IconButton, } from "ui/components/elements/icons/icon-button";
import { selectors, useAppDispatch, useAppSelector } from "store";
export var UndoRedo = function () {
    var dispatch = useAppDispatch();
    var canUndo = useAppSelector(selectors.selectCanUndo);
    var canRedo = useAppSelector(selectors.selectCanRedo);
    var handleUndoClick = function () {
        dispatch(UndoActionCreators.undo());
    };
    var handleRedoClick = function () {
        dispatch(UndoActionCreators.redo());
    };
    var iconButtonProps = {
        color: "inverted",
        variant: "rectangle",
        iconSize: 20,
    };
    return (_jsxs(Styled.Root, { children: [_jsx(IconButton, __assign({ disabled: !canUndo, onClick: handleUndoClick, rootAttributes: { style: { opacity: canUndo ? 1 : 0.4 } } }, iconButtonProps, { children: _jsx(UndoIcon, {}) })), _jsx(IconButton, __assign({ disabled: !canRedo, onClick: handleRedoClick, rootAttributes: { style: { opacity: canRedo ? 1 : 0.4 } } }, iconButtonProps, { children: _jsx(RedoIcon, {}) }))] }));
};
