var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { StyledWizardNav, StyledWizard } from "@animech/ui";
export var prefix = "wizard-panel";
export var WizardPanel = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme, activeStepIsCardboard = _a.activeStepIsCardboard, hidePreviousButton = _a.hidePreviousButton;
    var largePadding = theme.dimensions.margin.large;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t\t\t.", "-container {\n\t\t\t\tpadding: ", ";\n\t\t\t\tmax-height: 90vh;\n\n\t\t\t\th5 {\n\t\t\t\t\tmargin-bottom: 0;\n\t\t\t\t\ttext-align: center;\n\t\t\t\t}\n\n\t\t\t\t.", "-error {\n\t\t\t\t\tmargin-top: ", ";\n\t\t\t\t}\n\n\t\t\t\t.", "-input-container {\n\t\t\t\t\tdisplay: grid;\n\t\t\t\t\toverflow: hidden;\n\t\t\t\t\tgrid-template-columns: repeat(2, minmax(0, 1fr));\n\t\t\t\t\tgrid-gap: ", "\n\t\t\t\t\t\t", ";\n\t\t\t\t\tmargin: ", " 0;\n\t\t\t\t\tpadding: 0 8px;\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\twidth: 200px;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tpadding: ", ";\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tmargin-top: ", ";\n\t\t\t\tmargin-bottom: ", ";\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\t", ";\n\t\t\t}\n\t\t"], ["\n\t\t\t.", "-container {\n\t\t\t\tpadding: ", ";\n\t\t\t\tmax-height: 90vh;\n\n\t\t\t\th5 {\n\t\t\t\t\tmargin-bottom: 0;\n\t\t\t\t\ttext-align: center;\n\t\t\t\t}\n\n\t\t\t\t.", "-error {\n\t\t\t\t\tmargin-top: ", ";\n\t\t\t\t}\n\n\t\t\t\t.", "-input-container {\n\t\t\t\t\tdisplay: grid;\n\t\t\t\t\toverflow: hidden;\n\t\t\t\t\tgrid-template-columns: repeat(2, minmax(0, 1fr));\n\t\t\t\t\tgrid-gap: ", "\n\t\t\t\t\t\t", ";\n\t\t\t\t\tmargin: ", " 0;\n\t\t\t\t\tpadding: 0 8px;\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\twidth: 200px;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tpadding: ", ";\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tmargin-top: ", ";\n\t\t\t\tmargin-bottom: ", ";\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\t", ";\n\t\t\t}\n\t\t"])), prefix, activeStepIsCardboard ? "0" : theme.dimensions.margin.large, prefix, theme.dimensions.margin.base, prefix, theme.dimensions.margin.small, theme.dimensions.margin.large, theme.dimensions.margin.base, StyledWizardNav.Stepper, StyledWizard.Footer, largePadding, StyledWizardNav.Root, theme.dimensions.margin.xLarge, theme.dimensions.margin.base, StyledWizardNav.Previous, hidePreviousButton && "display: none");
});
var templateObject_1, templateObject_2;
