var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Label } from "@animech/ui";
export var prefix = "dashboard-value";
export var DashboardValueLabel = styled(Label)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var DashboardValue = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme, flexDirection = _a.flexDirection;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: ", ";\n\n\t\t\t", " {\n\t\t\t\tfont-style: italic;\n\t\t\t\tpadding: 0 ", ";\n\n\t\t\t\t&[data-size=\"small\"] {\n\t\t\t\t\tmargin: 0;\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t", "\n\t\t"], ["\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: ", ";\n\n\t\t\t", " {\n\t\t\t\tfont-style: italic;\n\t\t\t\tpadding: 0 ", ";\n\n\t\t\t\t&[data-size=\"small\"] {\n\t\t\t\t\tmargin: 0;\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t", "\n\t\t"])), flexDirection, DashboardValueLabel, theme.dimensions.margin.base, flexDirection === "row-reverse" &&
        "\n\t\t\t\talign-items: center;\n\t\t\t\tjustify-content: flex-end;\n\t\t\t\tfont-size: ".concat(theme.typography.fontSize.small, ";\n\n\t\t\t\t").concat(DashboardValueLabel, " {\n\t\t\t\t\t&[data-size=\"small\"] {\n\t\t\t\t\t\tpadding: 0 0 0 ").concat(theme.dimensions.margin.base, ";\n\t\t\t\t\t\tmargin: 0 ").concat(theme.dimensions.margin.xSmall, " 0 0;\n\t\t\t\t\t}\n\t\t\t\n\t\t\t\t}\n\t\t\t"));
});
var templateObject_1, templateObject_2, templateObject_3;
