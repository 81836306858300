var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable camelcase */
import { Material } from "3d/types";
/** ---------------   **/
/**      B Family     **/
/** ---------------   **/
var baseCupModelTemplate = {
    B10: {
        cupModelName: "B10-2",
        cupWidth: 0.011,
        cupHeight: 0.0214,
        fittingModelName: "MM5M-S1",
    },
    B15: {
        cupModelName: "B15-2",
        cupWidth: 0.0157,
        cupHeight: 0.0248,
        fittingModelName: "G18M-S1",
    },
    B20: {
        cupModelName: "B20",
        cupWidth: 0.022,
        cupHeight: 0.024,
        fittingModelName: "G18M-S1",
    },
    B40: {
        cupModelName: "B40",
        cupWidth: 0.043,
        cupHeight: 0.034,
        fittingModelName: "G38M01-S5",
    },
    B75P: {
        cupModelName: "B75P",
        cupWidth: 0.081,
        cupHeight: 0.0388,
        fittingModelName: "G38M01-S6",
    },
    B110P: {
        cupModelName: "B110",
        cupWidth: 0.078,
        cupHeight: 0.0503,
        fittingModelName: "G12F01-S1",
    },
    B150P: {
        cupModelName: "B150P",
        cupWidth: 0.149,
        cupHeight: 0.052,
        fittingModelName: "G38M01-S6",
    },
};
var materialTransformTemplate = {
    SIL_RED: {
        materialTransforms: [
            { sourceMaterial: Material.MA_MAIN, targetMaterial: Material.S },
            {
                sourceMaterial: Material.MA_SECONDARY,
                targetMaterial: Material.S,
            },
        ],
    },
    HNBR: {
        materialTransforms: [
            { sourceMaterial: Material.MA_MAIN, targetMaterial: Material.HN },
            {
                sourceMaterial: Material.MA_SECONDARY,
                targetMaterial: Material.HN,
            },
        ],
    },
    MF: {
        materialTransforms: [
            { sourceMaterial: Material.MA_MAIN, targetMaterial: Material.SF },
            {
                sourceMaterial: Material.MA_SECONDARY,
                targetMaterial: Material.SF,
            },
        ],
    },
    PU60: {
        materialTransforms: [
            { sourceMaterial: Material.MA_MAIN, targetMaterial: Material.PU60 },
            {
                sourceMaterial: Material.MA_SECONDARY,
                targetMaterial: Material.PU60,
            },
        ],
    },
    PU60_PU30: {
        materialTransforms: [
            { sourceMaterial: Material.MA_MAIN, targetMaterial: Material.PU60 },
            {
                sourceMaterial: Material.MA_MAIN2,
                targetMaterial: Material.PU30,
            },
        ],
    },
    NP: {
        materialTransforms: [
            { sourceMaterial: Material.MA_MAIN, targetMaterial: Material.NP },
        ],
    },
};
export var suctionCupsFamilyB = {
    // B5 Sil red
    suction_cup_3250082S: __assign(__assign({}, baseCupModelTemplate.B10), materialTransformTemplate.SIL_RED),
    // B5 HNBR
    suction_cup_9912093: __assign(__assign({}, baseCupModelTemplate.B10), materialTransformTemplate.HNBR),
    // B8 Sil Red
    suction_cup_3250083S: __assign(__assign({}, baseCupModelTemplate.B10), materialTransformTemplate.SIL_RED),
    // B8 HNBR
    suction_cup_9912094: __assign(__assign({}, baseCupModelTemplate.B10), materialTransformTemplate.HNBR),
    // B10-2 Sil red
    suction_cup_3150023S: __assign(__assign({}, baseCupModelTemplate.B10), materialTransformTemplate.SIL_RED),
    // B10-2 HNBR
    suction_cup_9906865: __assign(__assign({}, baseCupModelTemplate.B10), materialTransformTemplate.HNBR),
    // B13-2 Sil red
    suction_cup_9942710: __assign(__assign({}, baseCupModelTemplate.B10), materialTransformTemplate.SIL_RED),
    // B13-2 HNBR
    suction_cup_9944015: __assign(__assign({}, baseCupModelTemplate.B10), materialTransformTemplate.HNBR),
    // B15-2 Sil red
    suction_cup_9943097: __assign(__assign({}, baseCupModelTemplate.B15), materialTransformTemplate.SIL_RED),
    // B15-2 HNBR
    suction_cup_9948818: __assign(__assign({}, baseCupModelTemplate.B15), materialTransformTemplate.HNBR),
    // B15MF
    suction_cup_3250069: __assign(__assign({}, baseCupModelTemplate.B15), materialTransformTemplate.MF),
    // B20 Red Sil
    suction_cup_0101172: __assign(__assign({}, baseCupModelTemplate.B20), materialTransformTemplate.SIL_RED),
    // B20 HNBR
    suction_cup_9906877: __assign(__assign({}, baseCupModelTemplate.B20), materialTransformTemplate.HNBR),
    // B20MF
    suction_cup_0101183: __assign(__assign({}, baseCupModelTemplate.B20), materialTransformTemplate.MF),
    // B26 Red Sil
    suction_cup_9943305: __assign(__assign({}, baseCupModelTemplate.B15), materialTransformTemplate.SIL_RED),
    // B26 HNBR
    suction_cup_9943780: __assign(__assign({}, baseCupModelTemplate.B15), materialTransformTemplate.HNBR),
    // B30-2 Red Sil
    suction_cup_0101433: __assign(__assign({}, baseCupModelTemplate.B40), materialTransformTemplate.SIL_RED),
    // B30-2 HNBR
    suction_cup_0108168: __assign(__assign({}, baseCupModelTemplate.B40), materialTransformTemplate.HNBR),
    // B30MF
    suction_cup_0101446: __assign(__assign({}, baseCupModelTemplate.B40), materialTransformTemplate.MF),
    // B40 Red Sil
    suction_cup_0101472: __assign(__assign({}, baseCupModelTemplate.B40), materialTransformTemplate.SIL_RED),
    // B40 HNBR
    suction_cup_0108170: __assign(__assign({}, baseCupModelTemplate.B40), materialTransformTemplate.HNBR),
    // B40MF
    suction_cup_0101485: __assign(__assign({}, baseCupModelTemplate.B40), materialTransformTemplate.MF),
    // B50 Red Sil
    suction_cup_0101633: __assign(__assign({}, baseCupModelTemplate.B40), materialTransformTemplate.SIL_RED),
    // B50 HNBR
    suction_cup_0108173: __assign(__assign({}, baseCupModelTemplate.B40), materialTransformTemplate.HNBR),
    // B50MF
    suction_cup_0101685: __assign(__assign({}, baseCupModelTemplate.B40), materialTransformTemplate.MF),
    // B75P PU60
    suction_cup_0107319: __assign(__assign({}, baseCupModelTemplate.B75P), materialTransformTemplate.PU60),
    // B30-2 CR
    suction_cup_0101420: __assign(__assign({}, baseCupModelTemplate.B40), materialTransformTemplate.HNBR),
    // B40 Chloroprene
    suction_cup_0101459: __assign(__assign({}, baseCupModelTemplate.B40), materialTransformTemplate.HNBR),
    // B50 Nitrile-PVC
    suction_cup_0101646: __assign(__assign({}, baseCupModelTemplate.B40), materialTransformTemplate.HNBR),
    // B150P
    suction_cup_0241409: __assign(__assign({}, baseCupModelTemplate.B150P), materialTransformTemplate.PU60_PU30),
    // B150P
    suction_cup_0241406: __assign(__assign({}, baseCupModelTemplate.B150P), materialTransformTemplate.PU60_PU30),
    // B75 Nitrile-PVC
    suction_cup_0101855: __assign(__assign({}, baseCupModelTemplate.B110P), materialTransformTemplate.NP),
    // B75 Red Silicone
    suction_cup_0101851: __assign(__assign({}, baseCupModelTemplate.B110P), materialTransformTemplate.SIL_RED),
    // B110 Nitrile-PVC
    suction_cup_0101884: __assign(__assign({}, baseCupModelTemplate.B110P), materialTransformTemplate.NP),
    // B110 Red Silicone
    suction_cup_0101890: __assign(__assign({}, baseCupModelTemplate.B110P), materialTransformTemplate.SIL_RED),
    // B150 Nitrile-PVC
    suction_cup_0101931: __assign(__assign({}, baseCupModelTemplate.B110P), materialTransformTemplate.NP),
    // B150 Red Silicone
    suction_cup_0101928: __assign(__assign({}, baseCupModelTemplate.B110P), materialTransformTemplate.SIL_RED),
};
