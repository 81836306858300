import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import * as Styled from "./logo.style";
import { PageRoute } from "utility/url";
import { SelectApplicationDropdown } from "ui/components/elements/select-application-dropdown";
export var Logo = function (props) {
    var isInverted = props.isInverted, onNavigate = props.onNavigate, applicationSelectionIsDisabled = props.applicationSelectionIsDisabled;
    var t = useTranslation().t;
    var handleSelectedApplication = useCallback(function () {
        onNavigate === null || onNavigate === void 0 ? void 0 : onNavigate(PageRoute.DEFINE_PARAMETERS);
    }, [onNavigate]);
    return (_jsxs(Styled.Logo, { "data-testid": "logo-root", isInverted: isInverted, children: [_jsx(Styled.Image, { alt: "logo", src: isInverted ? "img/logo-black.webp" : "img/logo.webp" }), _jsxs(Styled.Title, { children: [_jsx(Styled.Half, { children: _jsx(Styled.ApplicationName, { children: t("application_title") }) }), _jsx(Styled.Half, { children: _jsx(SelectApplicationDropdown, { onSelected: handleSelectedApplication, disabled: applicationSelectionIsDisabled }) })] })] }));
};
