var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { v4 as uuidv4 } from "uuid";
import { getStoredState, REHYDRATE } from "redux-persist";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getInitialTubeDiameterIndex } from "./scene/helpers";
import { isCentralizedSystem } from "./system-data/helpers";
import { persistTemplate } from "./application-template";
import { NodeComponentType, tubeDiameterOptions, UnitSystem, } from "store/types";
import * as sceneReducer from "store/scene/reducer";
import * as systemDataReducerDefineParameters from "store/system-data/define-parameters/reducer";
import * as systemDataReducerSelectApplication from "store/system-data/select-application/reducer";
import * as uiReducer from "store/ui/reducer";
import * as systemPerformanceReducer from "store/system-performance/reducer";
import { persistKey as uiPersistKey } from "store/ui/persist-config";
import { ComponentList } from "api/tacton/types";
import { persistConfig as defineParametersPersistConfig, persistKey as defineParametersPersistKey, } from "store/system-data/define-parameters/persist-config";
import { persistConfig as selectApplicationPersistConfig, persistKey as selectApplicationPersistKey, } from "store/system-data/select-application/persist-config";
import { persistConfig as scenePersistConfig, persistKey as scenePersistKey, } from "store/scene/persist-config";
var checklistActionAddPump = function () { return function (dispatch, getState) {
    var state = getState();
    var systemDataDefineParameters = state.systemDataDefineParameters;
    var systemData = systemDataDefineParameters.dataState.data;
    if (!systemData)
        return;
    var isSystemCentralized = isCentralizedSystem(systemData);
    if (isSystemCentralized) {
        dispatch(addCentralizedPump());
    }
    else {
        dispatch(_selectGripPositionWithoutPumpSelection());
    }
}; };
var checklistActionSelectCup = function () { return function (dispatch) {
    dispatch(_selectGripPositionWithoutCupSelection());
}; };
var _selectGripPositionWithoutPumpSelection = function () { return function (dispatch) {
    dispatch(_selectGripPositionWithoutSelectedComponent("vacuumPumpSelectionsDecentralized", "pumpSelectionDecentralMap"));
}; };
var _selectGripPositionWithoutCupSelection = function () { return function (dispatch) {
    dispatch(_selectGripPositionWithoutSelectedComponent("suctionCupSelections", "cupSelectionMap"));
}; };
var _selectGripPositionWithoutSelectedComponent = function (systemDataKey, defineParametersKey) {
    return function (dispatch, getState) {
        var e_1, _a;
        var state = getState();
        var systemDataDefineParameters = state.systemDataDefineParameters;
        var systemData = systemDataDefineParameters.dataState.data;
        if (!systemData)
            return;
        var sceneState = state.scene.present;
        var nodes = sceneState.nodes;
        var selections = systemData[systemDataKey];
        var selectionMap = systemDataDefineParameters[defineParametersKey];
        var gripPositionWithNoSelectionId;
        try {
            for (var _b = __values(Object.values(nodes)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var node = _c.value;
                var isGripPosition = node.type === NodeComponentType.GRIP_POSITION;
                if (!isGripPosition)
                    continue;
                var nodeId = node.id;
                var selectionId = selectionMap[nodeId];
                var selectedComponent = selectionId && selections[selectionId];
                if (selectedComponent)
                    continue;
                gripPositionWithNoSelectionId = nodeId;
                break;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (gripPositionWithNoSelectionId) {
            dispatch(uiReducer.callableActions.selectEntity(gripPositionWithNoSelectionId));
        }
    };
};
var addGripPosition = function () { return function (dispatch, getState) {
    var nodeId = "grip-position-".concat(uuidv4());
    dispatch(systemDataReducerDefineParameters.internalActions.assignCupSelection(nodeId));
    dispatch(systemDataReducerDefineParameters.internalActions.assignCupMountingSelection(nodeId));
    dispatch(systemDataReducerDefineParameters.internalActions.assignDecentralPumpSelection(nodeId));
    if (!getState().systemDataDefineParameters.cupSelectionMap[nodeId] ||
        !getState().systemDataDefineParameters.cupMountingSelectionMap[nodeId] ||
        !getState().systemDataDefineParameters.pumpSelectionDecentralMap[nodeId]) {
        dispatch(systemDataReducerDefineParameters.internalActions.unassignAllSelections([
            nodeId,
        ]));
        return;
    }
    dispatch(sceneReducer.internalActions.addGripPosition(nodeId));
    dispatch(uiReducer.callableActions.selectEntity(nodeId));
}; };
var addCentralizedPump = function () { return function (dispatch, getState) {
    if (getState().systemDataDefineParameters.dataState.isLoading)
        return;
    var nodeId = "central-pump-".concat(uuidv4());
    dispatch(systemDataReducerDefineParameters.internalActions.assignCentralPumpSelection(nodeId));
    if (!getState().systemDataDefineParameters.pumpSelectionCentralMap[nodeId]) {
        return;
    }
    dispatch(sceneReducer.internalActions.addCentralizedPump(nodeId));
    dispatch(systemDataReducerDefineParameters.internalActions.selectDefaultCentralPump(nodeId));
    dispatch(uiReducer.callableActions.selectEntity(nodeId));
}; };
var addTubeConnection = function () { return function (dispatch) {
    var nodeId = "tube-connection-".concat(uuidv4());
    dispatch(sceneReducer.internalActions.addTubeConnection(nodeId));
    dispatch(uiReducer.callableActions.selectEntity(nodeId));
}; };
var addEdge = function (fromAttachmentId, toAttachmentId) {
    return function (dispatch, getState) {
        if (getState().systemDataDefineParameters.dataState.isLoading)
            return;
        var systemDataDefineParameters = getState().systemDataDefineParameters.dataState.data;
        if (!systemDataDefineParameters)
            return;
        var edgeId = "edge-".concat(uuidv4());
        var unitSystem = getState().ui.unitSystem;
        dispatch(sceneReducer.internalActions.addEdge({
            fromAttachmentId: fromAttachmentId,
            toAttachmentId: toAttachmentId,
            edgeId: edgeId,
            unitSystem: unitSystem,
            initialTubeDiamaterIndex: getInitialTubeDiameterIndex(unitSystem, systemDataDefineParameters),
        }));
        dispatch(uiReducer.callableActions.selectEntity(edgeId));
    };
};
var setUnitSystem = function (unitSystem) {
    return function (dispatch, getState) {
        if (getState().systemDataDefineParameters.dataState.isLoading)
            return;
        var systemDataDefineParameters = getState().systemDataDefineParameters.dataState.data;
        if (!systemDataDefineParameters)
            return;
        var edges = getState().scene.present.edges;
        var edgeIds = Object.keys(edges);
        var initialTubeDiamaterIndex = getInitialTubeDiameterIndex(unitSystem, systemDataDefineParameters);
        var defaultDiameter = tubeDiameterOptions[unitSystem][initialTubeDiamaterIndex].value;
        dispatch(sceneReducer.callableActions.setEdgeDiameterDefaultTube({
            edgeIds: edgeIds,
            diameter: defaultDiameter,
            index: initialTubeDiamaterIndex,
        }));
        dispatch(sceneReducer.callableActions.setEdgeDiameterBlowOffTube({
            edgeIds: edgeIds,
            diameter: defaultDiameter,
            index: initialTubeDiamaterIndex,
        }));
        dispatch(uiReducer.callableActions.setUnitSystem(unitSystem));
    };
};
var duplicateNode = function (nodeId) {
    return function (dispatch, getState) {
        if (getState().systemDataDefineParameters.dataState.isLoading)
            return;
        var newNodeId = "tube-connection-".concat(uuidv4());
        var sceneState = getState().scene.present;
        if (sceneState.nodes[nodeId].type === NodeComponentType.GRIP_POSITION) {
            newNodeId = "grip-position-".concat(uuidv4());
            dispatch(systemDataReducerDefineParameters.internalActions.assignCupSelection(newNodeId));
            dispatch(systemDataReducerDefineParameters.internalActions.assignCupMountingSelection(newNodeId));
            dispatch(systemDataReducerDefineParameters.internalActions.assignDecentralPumpSelection(newNodeId));
            if (!getState().systemDataDefineParameters.cupSelectionMap[newNodeId] ||
                !getState().systemDataDefineParameters.cupMountingSelectionMap[newNodeId] ||
                !getState().systemDataDefineParameters.pumpSelectionDecentralMap[newNodeId]) {
                dispatch(systemDataReducerDefineParameters.internalActions.unassignAllSelections([newNodeId]));
                return;
            }
            dispatch(systemDataReducerDefineParameters.internalActions.duplicateSelections({
                fromNodeId: nodeId,
                toNodeId: newNodeId,
            }));
        }
        else if (sceneState.nodes[nodeId].type ===
            NodeComponentType.CENTRALIZED_VACUUM_PUMP) {
            newNodeId = "central-pump-".concat(uuidv4());
            dispatch(systemDataReducerDefineParameters.internalActions.assignCentralPumpSelection(newNodeId));
            if (!getState().systemDataDefineParameters.pumpSelectionCentralMap[newNodeId]) {
                return;
            }
            dispatch(systemDataReducerDefineParameters.internalActions.duplicateSelections({
                fromNodeId: nodeId,
                toNodeId: newNodeId,
            }));
        }
        dispatch(sceneReducer.internalActions.duplicateNode({
            fromNodeId: nodeId,
            toNodeId: newNodeId,
        }));
        dispatch(uiReducer.callableActions.selectEntity(newNodeId));
    };
};
var deleteNode = function (nodeId) {
    return function (dispatch, getState) {
        if (getState().systemDataDefineParameters.dataState.isLoading)
            return;
        dispatch(systemDataReducerDefineParameters.internalActions.deselectAllComponents([
            nodeId,
        ]));
        dispatch(systemDataReducerDefineParameters.internalActions.unassignAllSelections([
            nodeId,
        ]));
        dispatch(sceneReducer.internalActions.deleteNode(nodeId));
        dispatch(uiReducer.callableActions.deselectEntities());
    };
};
var resetScene = function () { return function (dispatch, getState) {
    var nodeIds = Object.keys(getState().scene.present.nodes);
    if (nodeIds.length > 1) {
        dispatch(systemDataReducerDefineParameters.internalActions.unassignAllSelections(nodeIds));
        dispatch(sceneReducer.callableActions.clearScene());
        dispatch(uiReducer.callableActions.deselectEntities());
    }
}; };
var loadTemplate = createAsyncThunk("load-template", function (template, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, _b, _c, _d, _e, _f;
    var _g, _h, _j;
    return __generator(this, function (_k) {
        switch (_k.label) {
            case 0:
                thunkAPI.dispatch(resetScene);
                persistTemplate(template);
                /*
                There is a problem for templates that do not define which metric system they are defined in.
                
                If we are using imperial units locally when loading a template defined in metric units,
                then the tube diameters indices in the template code will point to the wrong
                places in our tube diameter options array.
                
                The below will default these templates to metric so that we at least don't get any problems for templates defined in metric.
            */
                if (!template.includes("".concat(uiPersistKey, "="))) {
                    thunkAPI.dispatch(uiReducer.callableActions.setUnitSystem(UnitSystem.METRIC));
                }
                _b = (_a = thunkAPI).dispatch;
                _g = {
                    type: REHYDRATE,
                    key: defineParametersPersistKey
                };
                return [4 /*yield*/, getStoredState(defineParametersPersistConfig)];
            case 1:
                _b.apply(_a, [(_g.payload = _k.sent(),
                        _g)]);
                _d = (_c = thunkAPI).dispatch;
                _h = {
                    type: REHYDRATE,
                    key: selectApplicationPersistKey
                };
                return [4 /*yield*/, getStoredState(selectApplicationPersistConfig)];
            case 2:
                _d.apply(_c, [(_h.payload = _k.sent(),
                        _h)]);
                _f = (_e = thunkAPI).dispatch;
                _j = {
                    type: REHYDRATE,
                    key: scenePersistKey
                };
                return [4 /*yield*/, getStoredState(scenePersistConfig)];
            case 3:
                _f.apply(_e, [(_j.payload = _k.sent(),
                        _j)]);
                return [2 /*return*/];
        }
    });
}); });
var deleteEdge = function (edgeId) {
    return function (dispatch) {
        dispatch(sceneReducer.internalActions.deleteEdge(edgeId));
        dispatch(uiReducer.callableActions.deselectEntities());
    };
};
var selectCup = function (_a) {
    var nodeIds = _a.nodeIds, value = _a.value;
    return function (dispatch, getState) {
        var e_2, _a;
        var _b;
        dispatch(systemDataReducerDefineParameters.internalActions.selectCup({
            nodeIds: nodeIds,
            value: value,
        }));
        var cupIsSelected = value !== "".concat(ComponentList.SUCTION_CUP, "_X");
        var scene = getState().scene.present;
        var attachmentIds = nodeIds.flatMap(function (nodeId) { var _a; return (_a = scene.nodes[nodeId]) === null || _a === void 0 ? void 0 : _a.attachmentIds; });
        if (cupIsSelected || !attachmentIds)
            return;
        try {
            for (var attachmentIds_1 = __values(attachmentIds), attachmentIds_1_1 = attachmentIds_1.next(); !attachmentIds_1_1.done; attachmentIds_1_1 = attachmentIds_1.next()) {
                var attachmentId = attachmentIds_1_1.value;
                var edgeId = (_b = scene.attachments[attachmentId]) === null || _b === void 0 ? void 0 : _b.edgeId;
                if (!edgeId)
                    continue;
                dispatch(sceneReducer.internalActions.deleteEdge(edgeId));
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (attachmentIds_1_1 && !attachmentIds_1_1.done && (_a = attachmentIds_1.return)) _a.call(attachmentIds_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
};
export var actions = __assign(__assign(__assign(__assign(__assign(__assign({}, sceneReducer.callableActions), systemDataReducerDefineParameters.callableActions), systemDataReducerSelectApplication.callableActions), systemPerformanceReducer.callableActions), uiReducer.callableActions), { addGripPosition: addGripPosition, addCentralizedPump: addCentralizedPump, addTubeConnection: addTubeConnection, duplicateNode: duplicateNode, deleteNode: deleteNode, addEdge: addEdge, setUnitSystem: setUnitSystem, deleteEdge: deleteEdge, resetScene: resetScene, selectCup: selectCup, checklistActionSelectCup: checklistActionSelectCup, checklistActionAddPump: checklistActionAddPump, loadTemplate: loadTemplate });
