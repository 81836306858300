var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Button, Container, Grid, GridItem, } from "@animech/ui";
import { useTranslation } from "react-i18next";
import { CustomBaseModal } from "ui/components/modals/custom-base-modal/custom-base-modal";
export var ConfirmationResult;
(function (ConfirmationResult) {
    ConfirmationResult["KEEP_CHANGES"] = "keep_changes";
    ConfirmationResult["RESET_CHANGES"] = "reset_changes";
})(ConfirmationResult || (ConfirmationResult = {}));
export var ResetConfirmationModal = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    var t = useTranslation().t;
    var onShouldClose = props.onShouldClose, footerPadding = props.footerPadding;
    var prefixedTranslate = function (key) { return t("reset_modal.".concat(key)); };
    return (_jsx(CustomBaseModal, __assign({}, props, { title: prefixedTranslate("title"), color: "inverted", onShouldClose: function () { return onShouldClose === null || onShouldClose === void 0 ? void 0 : onShouldClose(false); }, hideCloseButton: true, preventCloseOnDefocus: true, maxWidth: "700px", footer: _jsx(Container, { padding: footerPadding, children: _jsxs(Grid, { spacing: "medium", children: [_jsx(GridItem, { rootAttributes: { style: { textAlign: "left" } }, children: _jsx(Button, { appearance: "ghost", onClick: function () { return onShouldClose === null || onShouldClose === void 0 ? void 0 : onShouldClose(false); }, children: prefixedTranslate("button_dismiss") }) }), _jsx(GridItem, { rootAttributes: { style: { textAlign: "right" } }, children: _jsx(Button, { onClick: function () { return onShouldClose === null || onShouldClose === void 0 ? void 0 : onShouldClose(ConfirmationResult.KEEP_CHANGES); }, children: prefixedTranslate("button_keep") }) }), _jsx(GridItem, { rootAttributes: { style: { textAlign: "right" } }, children: _jsx(Button, { onClick: function () {
                                return onShouldClose === null || onShouldClose === void 0 ? void 0 : onShouldClose(ConfirmationResult.RESET_CHANGES);
                            }, children: prefixedTranslate("button_reset") }) })] }) }), children: children })));
};
