var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { selectors, useAppSelector } from "store";
import { defaultXAxisProps } from "ui/components/panels/graphs/constants";
var CustomLine = function (props) {
    return (_jsx("line", __assign({ orientation: "bottom", stroke: "white", fill: "none", x1: "0", y1: "0", x2: "0", y2: "0" }, props)));
};
var BaseTick = function (props) {
    var x = props.x, y = props.y, dx = props.dx, dy = props.dy, label = props.label, textAnchor = props.textAnchor, verticalLineFromProps = props.verticalLine, horizontalLineFromProps = props.horizontalLine;
    var yStart = defaultXAxisProps.tickMargin;
    var xLineWidth = 15;
    var yLineLength = 16;
    var verticalLine = _jsx(CustomLine, { y1: yStart, y2: -yLineLength });
    var horizontalLine = (_jsx(CustomLine, { y1: yStart, x2: -xLineWidth, y2: yStart }));
    return (_jsxs("g", { transform: "translate(".concat(x, ",").concat(y, ")"), children: [verticalLineFromProps !== null && verticalLineFromProps !== void 0 ? verticalLineFromProps : verticalLine, horizontalLineFromProps !== null && horizontalLineFromProps !== void 0 ? horizontalLineFromProps : horizontalLine, _jsx("text", { x: 0, y: 0, dy: dy !== null && dy !== void 0 ? dy : 0, dx: dx !== null && dx !== void 0 ? dx : 0, textAnchor: textAnchor, fill: "white", children: label !== null && label !== void 0 ? label : "" })] }));
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export var CustomXAxisTick = function (props) {
    var _a;
    var t = useTranslation().t;
    var payload = props.payload;
    var graphData = useAppSelector(selectors.selectGraphData);
    var _b = graphData.systemProperty, esIndex = _b.esIndex, atmIndex = _b.atmIndex, psIndex = _b.psIndex, timeData = _b.timeData;
    var isPSValue = payload.value === timeData[psIndex];
    var isESValue = payload.value === timeData[esIndex];
    var isAtmValue = payload.value === timeData[atmIndex];
    var yStart = (_a = defaultXAxisProps.tickMargin) !== null && _a !== void 0 ? _a : 0;
    var textLinePadding = 5;
    var xLineWidth = 15;
    var yLineLength = 16;
    var xTextPosition = textLinePadding + xLineWidth;
    var yTextPosition = yLineLength;
    var dx = isESValue ? xTextPosition : -xTextPosition;
    var horizontalLine = isESValue ? (_jsx(CustomLine, { y1: yStart, x2: xLineWidth, y2: yStart })) : undefined;
    var label = isPSValue
        ? t("graph_panel.pump_x_label_PS")
        : isESValue
            ? t("graph_panel.pump_x_label_ES")
            : isAtmValue
                ? t("graph_panel.pump_x_label_ATM")
                : t("graph_panel.pump_x_label_time");
    return (_jsx(BaseTick, __assign({}, props, { horizontalLine: horizontalLine, dy: yTextPosition, dx: dx, textAnchor: isESValue ? "start" : "end", label: label })));
};
