import { useCallback } from "react";
import { useTranslation } from "react-i18next";
/**
 * Hook to get formatted labels that are used in the graph panel.
 */
export var useGraphLabels = function () {
    var t = useTranslation().t;
    /**
     * Returns a label with following format: <Channel {identifier} - {pumpName}>
     * Example: Channel A - PCS23 x1
     */
    var getVacuumPumpLabelBase = useCallback(function (alphabeticalId, name) {
        return t("graph_panel.node_list_pump_name", {
            identifier: alphabeticalId,
            name: name,
        });
    }, [t]);
    var getVacuumPumpLabel = useCallback(function (_a) {
        var isGoverningPump = _a.isGoverningPump, alphabeticalId = _a.alphabeticalId, name = _a.name;
        var pumpLabelBase = getVacuumPumpLabelBase(alphabeticalId, name);
        return isGoverningPump
            ? "".concat(pumpLabelBase, " (").concat(t("graph_panel.governing"), ")")
            : pumpLabelBase;
    }, [getVacuumPumpLabelBase, t]);
    var getSuctionCupLabel = useCallback(function (_a) {
        var alphabeticalId = _a.alphabeticalId, name = _a.name;
        return t("graph_panel.node_list_suction_cup_name", {
            identifier: alphabeticalId,
            name: name,
        });
    }, [t]);
    return {
        /**
         * Returns a label with same format as getVacuumPumpLabelBase, but adds
         * "(limiting)" if it's a governing pump, with the following format:
         * <Channel {identifier} - {pumpName} (limiting)>
         * Example 1: Channel A - PCS23 x1
         * Example 2: Channel A - PCS23 x1 (limiting)
         */
        getVacuumPumpLabel: getVacuumPumpLabel,
        /**
         * Returns a label with following format: <{identifier} {cupName}>
         * Example: a1 BXF75P
         */
        getSuctionCupLabel: getSuctionCupLabel,
    };
};
