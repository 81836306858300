var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
export var ApiErrorType;
(function (ApiErrorType) {
    /* Generic errors */
    ApiErrorType["REQUEST_ERROR"] = "request_error";
    ApiErrorType["SERIALIZER_ERROR"] = "serializer_error";
    /* System data errors */
    ApiErrorType["SUBMIT_NOT_OK"] = "submit_not_ok";
    ApiErrorType["CLEAR_NOT_OK"] = "clear_not_ok";
    ApiErrorType["GET_NOT_OK"] = "get_not_ok";
    /* Restoring saved values */
    ApiErrorType["RESTORE_SAVED_ERROR"] = "restore_saved_error";
    /* System performance errors */
    ApiErrorType["TARGET_PARTS_PER_MINUTE_NOT_ACHIEVED"] = "target_parts_per_minute_not_achieved";
    ApiErrorType["FEED_PRESSURE_NOT_SUITABLE_FOR_PUMP"] = "feed_pressure_not_suitable_for_pump";
})(ApiErrorType || (ApiErrorType = {}));
var ApiError = /** @class */ (function (_super) {
    __extends(ApiError, _super);
    function ApiError(message, type, extra, systemData) {
        var _this = _super.call(this, message) || this;
        _this.type = type;
        _this.extra = extra;
        _this.systemData = systemData;
        return _this;
    }
    return ApiError;
}(Error));
export { ApiError };
