var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as Sentry from "@sentry/react";
import { TactonResponseStatus } from "./types";
import { deserializeSystemDataParameters } from "./serializers";
import { parameterNames, parameterValues } from "./constants";
import { step, commit, uncommit } from "./common-api";
import { ApiError, ApiErrorType } from "api/errors";
var apiErrorByEndpoint = {
    commit: ApiErrorType.SUBMIT_NOT_OK,
    uncommit: ApiErrorType.CLEAR_NOT_OK,
    step: ApiErrorType.GET_NOT_OK,
};
var getResponse = function (endpointFn, endpoint, ticket) { return __awaiter(void 0, void 0, Promise, function () {
    var response, err_1, err, systemData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, endpointFn()];
            case 1:
                response = _a.sent();
                return [3 /*break*/, 3];
            case 2:
                err_1 = _a.sent();
                Sentry.captureException(err_1);
                console.error(err_1);
                throw new ApiError("Tacton ".concat(endpoint, " request error"), ApiErrorType.REQUEST_ERROR);
            case 3:
                if (response.response.status === TactonResponseStatus.NOT_OK) {
                    err = void 0;
                    try {
                        systemData = deserializeSystemDataParameters(response, ticket);
                        err = new ApiError("not OK", apiErrorByEndpoint[endpoint], undefined, systemData);
                    }
                    catch (_serializerErr) {
                        err = new ApiError("not OK", apiErrorByEndpoint[endpoint]);
                    }
                    console.error(err);
                    Sentry.captureException(err);
                    throw err;
                }
                try {
                    return [2 /*return*/, deserializeSystemDataParameters(response, ticket)];
                }
                catch (err) {
                    Sentry.captureException(err);
                    throw new ApiError("Error deserializing define parameters", ApiErrorType.SERIALIZER_ERROR);
                }
                return [2 /*return*/];
        }
    });
}); };
export var tactonSystemDataAPI = {
    getData: function (configId, tactonStep, ticket) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, getResponse(function () { return step(configId, tactonStep, ticket); }, "step", ticket)];
    }); }); },
    submitParameters: function (configId, parameterIds, values, ticket) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, getResponse(function () { return commit(configId, parameterIds, values, ticket); }, "commit", ticket)];
        });
    }); },
    clearParameter: function (configId, parameterId, ticket) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, getResponse(function () { return uncommit(configId, [parameterId], ticket); }, "uncommit", ticket)];
        });
    }); },
    clearParameters: function (configId, parameterIds, ticket) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, getResponse(function () { return uncommit(configId, parameterIds, ticket); }, "uncommit", ticket)];
        });
    }); },
};
export var tactonSystemDataConstants = {
    parameterNames: parameterNames,
    parameterValues: parameterValues,
};
