var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback, useMemo, useState } from "react";
import { selectors, useAppSelector } from "store";
export var useZoom = function () {
    var _a = __read(useState(), 2), zoomAreaLeftX = _a[0], setZoomAreaLeftX = _a[1];
    var _b = __read(useState(), 2), zoomAreaRightX = _b[0], setZoomAreaRightX = _b[1];
    var _c = __read(useState(), 2), dataIndexLeft = _c[0], setDataIndexLeft = _c[1];
    var _d = __read(useState(), 2), dataIndexRight = _d[0], setDataIndexRight = _d[1];
    var graphData = useAppSelector(selectors.selectGraphData);
    var timeData = graphData.systemProperty.timeData;
    var _e = __read(useState(), 2), zoomedVacuumLevelDomain = _e[0], setZoomedVacuumLevelDomain = _e[1];
    var _f = __read(useState(), 2), zoomedRobotMotionDomain = _f[0], setZoomedRobotMotionDomain = _f[1];
    var _g = __read(useState(), 2), zoomedAirConsumptionDomain = _g[0], setZoomedAirConsumptionDomain = _g[1];
    var _h = __read(useState(), 2), zoomedLiftForceDomain = _h[0], setZoomedLiftForceDomain = _h[1];
    var _j = __read(useState(), 2), zoomedTimeDomain = _j[0], setZoomedTimeDomain = _j[1];
    var parsedGraphData = useAppSelector(selectors.selectParsedGraphData);
    var vacuumPumpData = useMemo(function () { return (parsedGraphData ? parsedGraphData.nodeData : []); }, [parsedGraphData]);
    var robotMotionData = useMemo(function () { return (parsedGraphData ? parsedGraphData.robotMotionData : []); }, [parsedGraphData]);
    var airConsumptionData = useMemo(function () { return (parsedGraphData ? parsedGraphData.airConsumptionData : []); }, [parsedGraphData]);
    var liftForceData = useMemo(function () { return (parsedGraphData ? parsedGraphData.liftingForcesData : []); }, [parsedGraphData]);
    var resetZoom = function () {
        setZoomAreaLeftX(undefined);
        setZoomAreaRightX(undefined);
        setZoomedVacuumLevelDomain(undefined);
        setZoomedRobotMotionDomain(undefined);
        setZoomedAirConsumptionDomain(undefined);
        setZoomedLiftForceDomain(undefined);
        setZoomedTimeDomain(undefined);
        setDataIndexLeft(undefined);
    };
    var getZoomedDomain = function (data) {
        var offset = 5;
        var _a = __read(data.reduce(function (acc, dataPoint) {
            Object.entries(dataPoint).forEach(function (_a) {
                var _b = __read(_a, 2), id = _b[0], dataValue = _b[1];
                if (id !== "time") {
                    acc[0] = Math.min(dataValue, acc[0]);
                    acc[1] = Math.max(dataValue, acc[1]);
                }
            });
            return acc;
        }, [999, -999]), 2), min = _a[0], max = _a[1];
        return [min - offset, max + offset];
    };
    var updateDomains = useCallback(function (leftIndex, rightIndex) {
        var domains = [
            {
                graphData: vacuumPumpData,
                setDomain: setZoomedVacuumLevelDomain,
            },
            {
                graphData: robotMotionData,
                setDomain: setZoomedRobotMotionDomain,
            },
            {
                graphData: airConsumptionData,
                setDomain: setZoomedAirConsumptionDomain,
            },
            {
                graphData: liftForceData,
                setDomain: setZoomedLiftForceDomain,
            },
        ];
        domains.forEach(function (domain) {
            var graphData = domain.graphData, setDomain = domain.setDomain;
            var refData = graphData.slice(Math.max(leftIndex - 1, 0), rightIndex);
            var newDomain = getZoomedDomain(refData);
            setDomain(newDomain);
        });
    }, [airConsumptionData, liftForceData, robotMotionData, vacuumPumpData]);
    var zoom = function (dataIndexRightProp) {
        var _a, _b;
        if (dataIndexLeft === undefined ||
            dataIndexRightProp === undefined ||
            zoomAreaLeftX === undefined ||
            zoomAreaRightX === undefined) {
            console.warn("both index undefined");
            return;
        }
        if (dataIndexLeft === dataIndexRightProp) {
            console.warn("indexes are the same");
            setZoomAreaLeftX(undefined);
            setZoomAreaRightX(undefined);
            return;
        }
        var newDataIndexLeft = dataIndexLeft;
        var newDataIndexRight = dataIndexRightProp;
        var newRefAreaLeft = zoomAreaLeftX;
        var newRefArearight = zoomAreaRightX;
        // If user drags the mouse to the left instead of right, we swap left and right on X-Axis.
        if (newDataIndexLeft > newDataIndexRight) {
            _a = __read([
                newDataIndexRight,
                newDataIndexLeft,
            ], 2), newDataIndexLeft = _a[0], newDataIndexRight = _a[1];
            _b = __read([newRefArearight, newRefAreaLeft], 2), newRefAreaLeft = _b[0], newRefArearight = _b[1];
        }
        updateDomains(newDataIndexLeft, newDataIndexRight);
        setZoomedTimeDomain([newRefAreaLeft, newRefArearight]);
        setDataIndexLeft(newDataIndexLeft);
        setDataIndexRight(newDataIndexRight);
        setZoomAreaLeftX(undefined);
        setZoomAreaRightX(undefined);
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var isMouseEventOutsideGraph = function (e) {
        return !e || e.activeTooltipIndex === undefined;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var onMouseDown = function (e) {
        if (isMouseEventOutsideGraph(e))
            return;
        // this.setState({ refAreaLeft: e.activeLabel }
        setZoomAreaLeftX(e.activeLabel);
        setDataIndexLeft(e.activeTooltipIndex);
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var onMouseMove = function (e) {
        if (isMouseEventOutsideGraph(e))
            return;
        if (zoomAreaLeftX === undefined)
            return;
        setZoomAreaRightX(e.activeLabel);
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var onMouseUp = function (e) {
        if (zoomAreaLeftX === undefined || zoomAreaRightX === undefined)
            return;
        // If the user lets go of the mouse outside of the graph area, we won't have a value for the x-index.
        // So we set it to either 0 or the last index in the vacuum level data depending on if user moved mouse left or right.
        var mouseUpOccuredOutsideGraphArea = isMouseEventOutsideGraph(e);
        var xIndex;
        if (mouseUpOccuredOutsideGraphArea) {
            xIndex = zoomAreaLeftX > zoomAreaRightX ? 0 : vacuumPumpData.length - 1;
        }
        zoom(xIndex !== null && xIndex !== void 0 ? xIndex : e.activeTooltipIndex);
    };
    var horizontalZoom = function (newIndexLeft, newIndexRight) {
        var newRefAreaLeft = timeData[newIndexLeft];
        var newRefAreaRight = timeData[newIndexRight];
        setDataIndexLeft(newIndexLeft);
        setDataIndexRight(newIndexRight);
        updateDomains(newIndexLeft, newIndexRight);
        setZoomedTimeDomain([newRefAreaLeft, newRefAreaRight]);
    };
    return {
        onMouseDown: onMouseDown,
        onMouseMove: onMouseMove,
        onMouseUp: onMouseUp,
        zoomOut: resetZoom,
        zoomAreaLeftX: zoomAreaLeftX,
        zoomAreaRightX: zoomAreaRightX,
        zoomedVacuumLevelDomain: zoomedVacuumLevelDomain,
        zoomedRobotMotionDomain: zoomedRobotMotionDomain,
        zoomedAirConsumptionDomain: zoomedAirConsumptionDomain,
        zoomedLiftForceDomain: zoomedLiftForceDomain,
        zoomedTimeDomain: zoomedTimeDomain,
        horizontalZoom: horizontalZoom,
        dataIndexLeft: dataIndexLeft,
        dataIndexRight: dataIndexRight,
    };
};
