import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTheme } from "@emotion/react";
export var InfoIcon = function (_a) {
    var size = _a.size, isDisabled = _a.isDisabled;
    var theme = useTheme();
    var circleColor = isDisabled
        ? theme.colors.palette.grey[500]
        : theme.colors.components.primary.shades[500].main;
    var contentColor = isDisabled
        ? theme.colors.palette.grey[500]
        : theme.colors.palette.white;
    return (_jsxs("svg", { width: size, height: size, viewBox: "0 0 28 28", children: [_jsx("path", { d: "M14 0.999999C6.8203 0.999998 1 6.8203 1 14C1 21.1797 6.8203 27 14 27C21.1797 27 27 21.1797 27 14C27 6.8203 21.1797 0.999999 14 0.999999Z", stroke: circleColor, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round", fill: "none" }), _jsx("path", { d: "M15.0818 10.9822L15.0818 9.07348L12.9176 9.07348L12.9176 10.9822L15.0818 10.9822ZM15.0518 12.2748L12.9476 12.2748L12.9476 20L15.0518 20L15.0518 12.2748Z", fill: contentColor })] }));
};
