var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useState, useEffect, useMemo, useRef } from "react";
import * as pc from "playcanvas";
import { TubeConnection } from "./node-components/tube-connection";
import { GripPosition } from "./node-components/grip-position";
import { CentralizedVacuumPump } from "./node-components/centralized-vacuum-pump";
import { Attachment } from "./node-components/attachment";
import { FeedPressurePoint } from "./node-components/feed-pressure-point";
import { NodeButtons } from "./overlay-elements/node-buttons";
import { NodeWarning } from "./overlay-elements/node-warning";
import { NodeTooltip } from "./overlay-elements/tooltip-components/node-tooltip";
import { NodeVacuumLevel } from "./overlay-elements/node-vacuum-level";
import { NodeAlphabeticalId } from "./overlay-elements/node-alphabetical-id";
import { useAppSelector, selectors } from "store";
import { useInteractiveAppearance } from "hooks/playcanvas/interactive-appearance";
import { NodeComponentType, OverlayData, } from "store/types";
import { getBoundingBox } from "3d/helpers";
import { useNodeWarnings } from "hooks/node-warnings";
var timeBetweenUpdates = 0.2; // 200ms
export var Node = function (_a) {
    var _b;
    var app = _a.app, modelAssets = _a.modelAssets, materialAssets = _a.materialAssets, node = _a.node, movingNodeIds = _a.movingNodeIds, hoverId = _a.hoverId, hoveredNodeTooltipId = _a.hoveredNodeTooltipId, onTooltipHover = _a.onTooltipHover;
    var _c = __read(useState(null), 2), nodeEntity = _c[0], setNodeEntity = _c[1];
    var _d = __read(useState(null), 2), boundingBox = _d[0], setBoundingBox = _d[1];
    var _e = __read(useState(null), 2), position = _e[0], setPosition = _e[1];
    var timeSinceLastUpdate = useRef(0);
    var attachments = useAppSelector(selectors.selectAttachments);
    var overlayData = useAppSelector(selectors.selectOverlayData);
    var warnings = useNodeWarnings(node);
    var isZoomedIn = useAppSelector(selectors.selectIsZoomedIn(node.id));
    var selectedIds = useAppSelector(selectors.selectSelectedIds);
    var isMultiSelecting = useAppSelector(selectors.selectIsMultiSelecting);
    var isPerformanceOverlay = useAppSelector(selectors.selectOverlayData) ===
        OverlayData.PERFORMANCE_DATA;
    var isSelected = selectedIds.includes(node.id) && !isPerformanceOverlay;
    var isHovered = hoverId === node.id;
    var isMoving = (_b = movingNodeIds === null || movingNodeIds === void 0 ? void 0 : movingNodeIds.includes(node.id)) !== null && _b !== void 0 ? _b : false;
    var isNoneSelected = selectedIds.length === 0;
    var hasWarnings = warnings.length > 0;
    useEffect(function () {
        var entity = new pc.Entity(node.id.toString());
        app.root.addChild(entity);
        setNodeEntity(entity);
        return function () {
            entity.destroy();
            setNodeEntity(null);
        };
    }, [app.root, node.id]);
    useEffect(function () {
        if (!nodeEntity)
            return;
        nodeEntity.setPosition(new pc.Vec3(node.position.x, node.position.y, node.position.z));
    }, [nodeEntity, node.position]);
    /* Grip position rotation changed */
    useEffect(function () {
        if (!nodeEntity)
            return;
        nodeEntity.setLocalEulerAngles(0, node.rotationY, 0);
    }, [node.rotationY, nodeEntity]);
    useEffect(function () {
        /* We only care about position when the node is hovered or selected or have warnings,
         * or if overlay data should be shown */
        if (isMultiSelecting ||
            !nodeEntity ||
            (!isHovered && !isSelected && !hasWarnings && !overlayData)) {
            return;
        }
        var updateBoundingBox = function (dt) {
            timeSinceLastUpdate.current += dt;
            if (timeSinceLastUpdate.current < timeBetweenUpdates) {
                return;
            }
            else {
                timeSinceLastUpdate.current = 0;
            }
            var cameraEntity = app.root.findByName("Camera");
            var camera = cameraEntity === null || cameraEntity === void 0 ? void 0 : cameraEntity.camera;
            if (!camera || !nodeEntity)
                return;
            var bbox = getBoundingBox(nodeEntity);
            if (bbox) {
                var nodeEntityPosition = camera.worldToScreen(nodeEntity.getPosition());
                var bboxBottomLeft = camera.worldToScreen(new pc.Vec3(bbox.center.x - bbox.halfExtents.x, bbox.center.y, bbox.center.z + bbox.halfExtents.z));
                var bboxTopRight = camera.worldToScreen(new pc.Vec3(bbox.center.x + bbox.halfExtents.x, bbox.center.y, bbox.center.z - bbox.halfExtents.z));
                var boundingBoxWidth = Math.round(bboxBottomLeft.x - bboxTopRight.x);
                var boundingBoxHeight = Math.round(bboxBottomLeft.y - bboxTopRight.y);
                setBoundingBox({
                    height: boundingBoxHeight,
                    width: boundingBoxWidth,
                    center: {
                        x: Math.round(bboxBottomLeft.x - boundingBoxWidth / 2),
                        y: Math.round(bboxBottomLeft.y - boundingBoxHeight / 2),
                    },
                });
                setPosition({ x: nodeEntityPosition.x, y: nodeEntityPosition.y });
            }
        };
        app.on("update", updateBoundingBox);
        return function () {
            app.off("update", updateBoundingBox);
        };
    }, [
        nodeEntity,
        isSelected,
        isHovered,
        node.id,
        node.type,
        app,
        isZoomedIn,
        hasWarnings,
        overlayData,
        isMultiSelecting,
    ]);
    var component = useMemo(function () {
        if (nodeEntity && node.type === NodeComponentType.GRIP_POSITION) {
            return (_jsx(GripPosition, { app: app, nodeEntity: nodeEntity, modelAssets: modelAssets, materialAssets: materialAssets, gripPosition: node.component }));
        }
        else if (nodeEntity && node.type === NodeComponentType.TUBE_CONNECTION) {
            return (_jsx(TubeConnection, { app: app, nodeEntity: nodeEntity, materialAssets: materialAssets, modelAssets: modelAssets, tubeConnection: node.component }));
        }
        else if (nodeEntity &&
            node.type === NodeComponentType.CENTRALIZED_VACUUM_PUMP) {
            return (_jsx(CentralizedVacuumPump, { app: app, nodeEntity: nodeEntity, modelAssets: modelAssets, node: node, isMoving: isMoving }));
        }
        else if (nodeEntity &&
            node.type === NodeComponentType.FEED_PRESSURE_POINT) {
            return (_jsx(FeedPressurePoint, { nodeEntity: nodeEntity, materialAssets: materialAssets, modelAssets: modelAssets, feedPressurePoint: node.component, node: node }));
        }
        else {
            return null;
        }
    }, [app, modelAssets, materialAssets, nodeEntity, node, isMoving]);
    useInteractiveAppearance(node.id, nodeEntity, materialAssets, hoverId);
    var showSizeDescription = isSelected && node.type === NodeComponentType.GRIP_POSITION;
    var buttons = useMemo(function () {
        if (!(boundingBox === null || boundingBox === void 0 ? void 0 : boundingBox.height) || !(position === null || position === void 0 ? void 0 : position.x) || isMoving)
            return null;
        var buttonsCenterX = position.x;
        var buttonsTopY = boundingBox.center.y - boundingBox.height / 2;
        if (isZoomedIn) {
            buttonsCenterX = app.graphicsDevice.canvas.clientWidth / 2;
            buttonsTopY = app.graphicsDevice.canvas.clientHeight - 250;
        }
        return (_jsx(NodeButtons, { isZoomedIn: isZoomedIn, nodeBoundingBoxHeight: boundingBox.height, nodeTopY: buttonsTopY, nodeCenterX: buttonsCenterX, node: node, showSizeDescription: showSizeDescription }));
    }, [
        showSizeDescription,
        boundingBox === null || boundingBox === void 0 ? void 0 : boundingBox.height,
        boundingBox === null || boundingBox === void 0 ? void 0 : boundingBox.center.y,
        position === null || position === void 0 ? void 0 : position.x,
        isMoving,
        isZoomedIn,
        node,
        app.graphicsDevice.canvas.clientWidth,
        app.graphicsDevice.canvas.clientHeight,
    ]);
    var warning = useMemo(function () {
        if (!hasWarnings || !(position === null || position === void 0 ? void 0 : position.x))
            return null;
        return _jsx(NodeWarning, { nodeCenterX: position.x, nodeCenterY: position.y });
    }, [hasWarnings, position === null || position === void 0 ? void 0 : position.x, position === null || position === void 0 ? void 0 : position.y]);
    var performanceTooltip = useMemo(function () {
        if (!(boundingBox === null || boundingBox === void 0 ? void 0 : boundingBox.width) || !(position === null || position === void 0 ? void 0 : position.y))
            return null;
        var tooltipCenterY = position.y;
        var tooltipRightX = boundingBox.center.x - boundingBox.width / 2;
        var tooltipLeftX = boundingBox.center.x + boundingBox.width / 2;
        if (isZoomedIn) {
            tooltipRightX = 10;
            tooltipLeftX = app.graphicsDevice.canvas.clientWidth - 10;
            tooltipCenterY = 120;
        }
        return (_jsx(NodeTooltip, { node: node, nodeCenterY: tooltipCenterY, nodeRightX: tooltipRightX, nodeLeftX: tooltipLeftX, isSelected: isSelected, isMoving: isMoving, onTooltipHover: onTooltipHover, hoveredNodeTooltipId: hoveredNodeTooltipId }));
    }, [
        boundingBox === null || boundingBox === void 0 ? void 0 : boundingBox.width,
        boundingBox === null || boundingBox === void 0 ? void 0 : boundingBox.center.x,
        position === null || position === void 0 ? void 0 : position.y,
        isZoomedIn,
        node,
        isSelected,
        isMoving,
        onTooltipHover,
        hoveredNodeTooltipId,
        app.graphicsDevice.canvas.clientWidth,
    ]);
    var vacuumLevel = useMemo(function () {
        if (!(position === null || position === void 0 ? void 0 : position.x) || !(position === null || position === void 0 ? void 0 : position.y))
            return null;
        return (_jsx(NodeVacuumLevel, { nodeId: node.id, nodeCenterX: position.x, nodeCenterY: position.y }));
    }, [position === null || position === void 0 ? void 0 : position.x, position === null || position === void 0 ? void 0 : position.y, node.id]);
    var alphabeticalIdOverlay = useMemo(function () {
        if (!(position === null || position === void 0 ? void 0 : position.x) || !(position === null || position === void 0 ? void 0 : position.y))
            return null;
        return (_jsx(NodeAlphabeticalId, { node: node, nodeCenterX: position.x, nodeCenterY: position.y }));
    }, [position === null || position === void 0 ? void 0 : position.x, position === null || position === void 0 ? void 0 : position.y, node]);
    return (_jsxs(_Fragment, { children: [performanceTooltip, isSelected && !isMultiSelecting && buttons, isNoneSelected && warning, vacuumLevel, alphabeticalIdOverlay, component, nodeEntity &&
                !isZoomedIn &&
                !isMultiSelecting &&
                node.attachmentIds.map(function (attachmentId) { return (_jsx(Attachment, { app: app, nodeEntity: nodeEntity, node: node, attachment: attachments[attachmentId], materialAssets: materialAssets, modelAssets: modelAssets, hoverId: hoverId }, attachmentId)); })] }));
};
