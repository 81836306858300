var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from "@emotion/react";
import styled from "@emotion/styled";
export var prefix = "node-buttons";
var defaultBoundingBoxMargin = 10;
export var NodeButtons = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var nodeBoundingBoxHeight = _a.nodeBoundingBoxHeight, nodeBoundingBoxMargin = _a.nodeBoundingBoxMargin;
    var boundingBoxMargin = nodeBoundingBoxMargin !== undefined
        ? nodeBoundingBoxMargin
        : defaultBoundingBoxMargin;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t\t\ttransform: translate(-50%, -", "px);\n\t\t\tposition: relative;\n\t\t\tpointer-events: all;\n\n\t\t\t.", "-bbox {\n\t\t\t\theight: ", "px;\n\t\t\t\tmargin: ", "px;\n\t\t\t}\n\n\t\t\t.", "-top-buttons {\n\t\t\t\tposition: absolute;\n\t\t\t\twidth: 100%;\n\t\t\t\ttext-align: center;\n\t\t\t\ttransform: translateY(-100%) translateY(-", "px);\n\t\t\t}\n\t\t"], ["\n\t\t\ttransform: translate(-50%, -", "px);\n\t\t\tposition: relative;\n\t\t\tpointer-events: all;\n\n\t\t\t.", "-bbox {\n\t\t\t\theight: ", "px;\n\t\t\t\tmargin: ", "px;\n\t\t\t}\n\n\t\t\t.", "-top-buttons {\n\t\t\t\tposition: absolute;\n\t\t\t\twidth: 100%;\n\t\t\t\ttext-align: center;\n\t\t\t\ttransform: translateY(-100%) translateY(-", "px);\n\t\t\t}\n\t\t"])), boundingBoxMargin, prefix, nodeBoundingBoxHeight, boundingBoxMargin, prefix, boundingBoxMargin);
});
var templateObject_1, templateObject_2;
