/*
 * Before updating the version, make sure you have
 * read and followed the "Guidelines for updating versioned
 * state types" section in the README
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var GlassObjectShapeId = {
    ADJUSTABLE_GLASS_BOX: "adjustable_glass_box",
    ADJUSTABLE_GLASS_CYLINDER: "adjustable_glass_cylinder",
    SCREEN_PHONE: "glass_screen_phone",
    SHEET_63X120: "glass_sheet_63x120",
    SHEET_100X10: "glass_sheet_100x10",
    WINDSHIELD: "glass_windshield",
};
export var PlasticObjectShapeId = {
    ADJUSTABLE_PLASTIC_BOX: "adjustable_plastic_box",
    ADJUSTABLE_PLASTIC_CYLINDER: "adjustable_plastic_cylinder",
    BUMPER_FRONT: "plastic_bumper_front",
    BUMPER_REAR: "plastic_bumper_rear",
    CYLINDER_10X10: "plastic_cylinder_10x10",
    CYLINDER_HALF_10X10_A: "plastic_cylinder_half_10x10_a",
    CYLINDER_HALF_10X10_B: "plastic_cylinder_half_10x10_b",
    DASHBOARD: "plastic_dashboard",
    DOOR_PANEL_INNER: "plastic_door_panel_inner",
    SHEET_20X20: "plastic_sheet_20x20",
    TOTE_1: "plastic_tote_1",
    TOTE_2: "plastic_tote_2",
    TOTE_3: "plastic_tote_3",
};
export var MetalObjectShapeId = {
    ADJUSTABLE_METAL_BOX: "adjustable_metal_box",
    ADJUSTABLE_METAL_CYLINDER: "adjustable_metal_cylinder",
    CAR_HOOD: "car_hood",
    CAR_DOOR_OUTER: "car_door_outer",
    CAR_ROOF: "car_roof",
    CAR_ROOF_2: "car_roof_2",
    CAR_ROOF_3: "car_roof_3",
    CAR_ROOF_4: "car_roof_4",
    CAR_BODY_SIDE: "car_body_side",
    CAR_DOOR_INNER: "car_door_inner",
    CAR_FENDER_FRONT: "car_fender_front",
    CAR_FLOOR_PANEL: "car_floor_panel",
    CAR_TRUNK_COVER: "car_trunk_cover",
};
export var ObjectShapeId = __assign(__assign(__assign({}, MetalObjectShapeId), GlassObjectShapeId), PlasticObjectShapeId);
export { TubeConnectionId, TubeType, GripPositionMarkerId, NodeComponentType, } from "./types.v1";
