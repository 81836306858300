var _a;
import { createSlice } from "@reduxjs/toolkit";
import { MenuItem, UnitSystem, ChecklistItemName, } from "./types";
import { CameraViewId } from "3d/types";
import { USE_LOCAL_GRAPH_DATA } from "ui/components/panels/graphs/constants";
export var initialState = {
    activeContextMenuNodeId: null,
    cameraViewId: CameraViewId.EDIT_VIEW,
    unitSystem: UnitSystem.METRIC,
    activeMenuItem: null,
    is3DLoading: false,
    isGraphPanelOpen: USE_LOCAL_GRAPH_DATA ? true : false,
    checklist: (_a = {},
        _a[ChecklistItemName.ADD_GRIP_POSITION] = {
            translationKey: "checklist.add_grip_position",
            completed: false,
        },
        _a[ChecklistItemName.SELECT_CUP] = {
            translationKey: "checklist.select_cup",
            completed: false,
        },
        _a[ChecklistItemName.ADD_PUMP] = {
            translationKey: "checklist.add_pump",
            completed: false,
        },
        _a[ChecklistItemName.ADD_TUBE_CONNECTION] = {
            translationKey: "checklist.add_tube_connection",
            completed: false,
        },
        _a[ChecklistItemName.CONNECT_PARTS] = {
            translationKey: "checklist.connect_parts",
            completed: false,
        },
        _a[ChecklistItemName.CALCULATE] = {
            translationKey: "checklist.calculate",
            completed: false,
        },
        _a),
    selectedIds: [],
};
var setIs3DLoading = function (state, action) {
    state.is3DLoading = action.payload;
};
var selectEntity = function (state, action) {
    if (!action.payload) {
        return;
    }
    state.selectedIds = [action.payload];
    state.cameraViewId = CameraViewId.EDIT_VIEW;
    state.overlayData = undefined;
    state.activeMenuItem = MenuItem.SELECTED_ENTITY;
};
var setActiveContextMenuNodeId = function (state, action) {
    state.activeContextMenuNodeId = action.payload;
};
var deselectEntity = function (state, action) {
    state.selectedIds = state.selectedIds.filter(function (id) { return id !== action.payload; });
    state.cameraViewId = CameraViewId.EDIT_VIEW;
    state.activeMenuItem = null;
};
var selectNode = function (state, action) {
    if (!action.payload) {
        state.cameraViewId = CameraViewId.EDIT_VIEW;
        return;
    }
    state.selectedIds = [action.payload];
    state.cameraViewId = CameraViewId.NODE_OVERLAY_VIEW;
};
var setCameraView = function (state, action) {
    state.cameraViewId = action.payload;
};
var setUnitSystem = function (state, action) {
    state.unitSystem = action.payload;
};
var setOverlayData = function (state, action) {
    state.overlayData = action.payload;
};
var setActiveSidebarItemID = function (state, action) {
    state.activeSidebarItemID = action.payload;
};
var openMenuItem = function (state, action) {
    state.activeMenuItem = action.payload;
    /* Deselect entities if user presses "back" button in menu*/
    if (!action.payload) {
        state.selectedIds = [];
        state.cameraViewId = CameraViewId.EDIT_VIEW;
    }
};
var toggleGraphPanel = function (state, action) {
    state.isGraphPanelOpen = action.payload;
};
var setChecklistItemCompleted = function (state, action) {
    var _a = action.payload, itemName = _a.itemName, completed = _a.completed;
    var itemToComplete = state.checklist[itemName];
    itemToComplete.completed = completed;
};
var setChecklistItemHidden = function (state, action) {
    var _a = action.payload, itemName = _a.itemName, hidden = _a.hidden;
    var itemToHide = state.checklist[itemName];
    itemToHide.hidden = hidden;
};
var selectEntities = function (state, action) {
    var unique = new Set();
    action.payload.forEach(function (id) { return unique.add(id); });
    state.selectedIds = Array.from(unique);
    state.cameraViewId = CameraViewId.EDIT_VIEW;
    state.activeMenuItem = MenuItem.SELECTED_ENTITY;
};
var deselectEntities = function (state) {
    state.selectedIds = [];
    state.cameraViewId = CameraViewId.EDIT_VIEW;
    state.activeMenuItem = null;
};
var uiSlice = createSlice({
    name: "ui",
    initialState: initialState,
    reducers: {
        setActiveContextMenuNodeId: setActiveContextMenuNodeId,
        selectEntity: selectEntity,
        deselectEntity: deselectEntity,
        selectNode: selectNode,
        setCameraView: setCameraView,
        setOverlayData: setOverlayData,
        openMenuItem: openMenuItem,
        toggleGraphPanel: toggleGraphPanel,
        setIs3DLoading: setIs3DLoading,
        setUnitSystem: setUnitSystem,
        setChecklistItemCompleted: setChecklistItemCompleted,
        setChecklistItemHidden: setChecklistItemHidden,
        selectEntities: selectEntities,
        deselectEntities: deselectEntities,
        setActiveSidebarItemID: setActiveSidebarItemID,
    },
});
export var allActions = uiSlice.actions, reducer = uiSlice.reducer;
export var callableActions = {
    setActiveContextMenuNodeId: allActions.setActiveContextMenuNodeId,
    selectEntity: allActions.selectEntity,
    deselectEntity: allActions.deselectEntity,
    selectNode: allActions.selectNode,
    setCameraView: allActions.setCameraView,
    setOverlayData: allActions.setOverlayData,
    openMenuItem: allActions.openMenuItem,
    toggleGraphPanel: allActions.toggleGraphPanel,
    setIs3DLoading: allActions.setIs3DLoading,
    setUnitSystem: allActions.setUnitSystem,
    setChecklistItemCompleted: allActions.setChecklistItemCompleted,
    setChecklistItemHidden: allActions.setChecklistItemHidden,
    selectEntities: allActions.selectEntities,
    deselectEntities: allActions.deselectEntities,
    setActiveSidebarItemID: allActions.setActiveSidebarItemID,
};
export var internalActions = {};
export default reducer;
