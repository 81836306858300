var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState, } from "react";
import { TreeNavDivider } from "@animech/ui";
import { ParameterInput } from "../parameter-input";
import * as Styled from "./custom-drawer.style";
import { drawerTransitionDelay } from "./custom-drawer.style";
import { useBlockingElementEffect } from "hooks/playcanvas/blocking-element-effect";
import { actions, selectors, useAppDispatch, useAppSelector } from "store";
export var SidebarDrawerButton = function (props) {
    var button = props.button, id = props.id, sidebarWidth = props.sidebarWidth;
    var _a = __read(useState(false), 2), isVisible = _a[0], setIsVisible = _a[1];
    var dispatch = useAppDispatch();
    var activeDrawerItem = useAppSelector(selectors.selectActiveSidebarItemID);
    var sidebarContentContainerRef = useRef(null);
    var isDrawerActive = useMemo(function () { return activeDrawerItem !== undefined && activeDrawerItem === id; }, [activeDrawerItem, id]);
    // Close the drawer if the user clicks anywhere outside of its bounding box.
    var handleClickOutsideDrawer = useCallback(function (event) {
        var sidebarContentContainerElement = sidebarContentContainerRef.current;
        if (!sidebarContentContainerElement)
            return;
        var boundingRect = sidebarContentContainerElement.getBoundingClientRect();
        var clickOccuredWithinDrawer = event.clientX >= boundingRect.left &&
            event.clientX <= boundingRect.right &&
            event.clientY >= boundingRect.top &&
            event.clientY <= boundingRect.bottom;
        if (!clickOccuredWithinDrawer) {
            dispatch(actions.setActiveSidebarItemID());
        }
    }, [dispatch]);
    // Adds a click event listener to the currently opened drawer.
    useEffect(function () {
        if (isDrawerActive) {
            document.addEventListener("click", handleClickOutsideDrawer, true);
        }
        else {
            document.removeEventListener("click", handleClickOutsideDrawer, true);
        }
        return function () {
            document.removeEventListener("click", handleClickOutsideDrawer, true);
        };
    }, [handleClickOutsideDrawer, isDrawerActive, dispatch]);
    // This useEffect will make sure that the modal has time to fade out entirely before it's disabled.
    useEffect(function () {
        if (isDrawerActive) {
            setIsVisible(true);
            return;
        }
        else {
            var timeout_1 = setTimeout(function () {
                setIsVisible(false);
            }, drawerTransitionDelay);
            return function () { return clearTimeout(timeout_1); };
        }
    }, [isDrawerActive]);
    return (_jsxs(Styled.SidebarDrawerRoot, { isVisible: isDrawerActive, sidebarWidth: sidebarWidth, children: [_jsx(Styled.SidebarButtonContainer, { onClick: function () { return dispatch(actions.setActiveSidebarItemID(id)); }, children: button }), _jsx(Styled.SidebarDrawerContentContainer, { ref: sidebarContentContainerRef, children: isVisible && (_jsx(SidebarDrawerContent, __assign({}, props, { isOpen: isDrawerActive }))) })] }));
};
var SidebarDrawerContent = function (props) {
    var id = props.id, customRenderElement = props.customRenderElement, additionalRenderElement = props.additionalRenderElement, isOpen = props.isOpen, title = props.title;
    var parameterGroups = useAppSelector(selectors.selectGroupedParametersDefineParameters);
    var _a = __read(useState([]), 2), parameters = _a[0], setParameters = _a[1];
    useBlockingElementEffect();
    /* It's important that we save the parameters in state instead of using, for example, a useMemo here. This is to ensure that all the content within the drawer isn't cleared
     * instantly after the user closes the modal. Since we want the content to remain inside the drawer while it's fading out.
     */
    useEffect(function () {
        if (!isOpen || !parameterGroups.length || !id)
            return;
        var group = parameterGroups.find(function (group) { return group.id === id; });
        if (group === null || group === void 0 ? void 0 : group.parameters) {
            setParameters(group.parameters);
        }
    }, [isOpen, parameterGroups, id]);
    return (_jsxs(Styled.SidebarDrawerContentRoot, { children: [title && _jsx("h6", { children: title }), customRenderElement ? (customRenderElement) : (_jsxs(Fragment, { children: [parameters.map(function (parameter, i) {
                        var showDivider = i !== parameters.length - 1;
                        return (_jsxs("div", { children: [_jsx("div", { className: "parameter-input-container", children: _jsx(ParameterInput, { parameter: parameter }) }), showDivider && _jsx(TreeNavDivider, {})] }, parameter.id));
                    }), additionalRenderElement ? additionalRenderElement : null] }, id))] }));
};
