var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
import * as typesVMinus1 from "./types-versions/types.v-1";
import * as typesV0 from "./types-versions/types.v0";
import * as typesV1 from "./types-versions/types.v1";
/*
 * Each migration step will take one version as input and return the next version as output.
 * (The key `2` means that it is the step which migrates from V1 to V2.)
 */
var migrations = (_a = {},
    _a[0] = function (state) {
        /* Example: add a new (required) key, and change the type of an existing key for all nodes in an old state */
        var newNodes = Object.values(state.nodes).reduce(function (nodes, currentNode) {
            var nodeV0 = __assign(__assign({}, currentNode), { rotationY: Number(currentNode.rotationY), position: { x: 0, y: 5.5, z: 0 } });
            nodes[currentNode.id] = nodeV0;
            return nodes;
        }, {});
        return __assign(__assign({}, state), { nodes: newNodes });
    },
    // v1: add tube type to attachment and tube length/diameter to grip position
    _a[1] = function (state) {
        var newAttachments = Object.values(state.attachments).reduce(function (attachments, currentAtt) {
            attachments[currentAtt.id] = __assign(__assign({}, currentAtt), { tubeType: typesV1.TubeType.UNKNOWN });
            return attachments;
        }, {});
        var newNodes = Object.values(state.nodes).reduce(function (nodes, currentNode) {
            if (currentNode.type === typesV0.NodeComponentType.GRIP_POSITION) {
                var gp = currentNode.component;
                var v1Gp = __assign(__assign({}, gp), { inlineTubeLength: 0.1, inlineTubeDiameter: 0.004 });
                nodes[currentNode.id] = __assign(__assign({}, currentNode), { component: v1Gp });
            }
            else {
                nodes[currentNode.id] = currentNode;
            }
            return nodes;
        }, {});
        return __assign(__assign({}, state), { attachments: newAttachments, nodes: newNodes });
    },
    _a[2] = function (state) {
        var newEdges = Object.values(state.edges).reduce(function (edges, currentEdge) {
            edges[currentEdge.id] = __assign(__assign({}, currentEdge), { defaultDiameterIndex: 2, blowOffDiameterIndex: 2 });
            return edges;
        }, {});
        var newObjectShape = state.objectShape;
        return __assign(__assign({}, state), { edges: newEdges, objectShape: newObjectShape });
    },
    _a[3] = function (state) {
        var newEdges = Object.values(state.edges).reduce(function (edges, currentEdge) {
            // Removes lengthLocked property and copies remaining properties into newEdge
            var lengthLocked = currentEdge.lengthLocked, newEdge = __rest(currentEdge, ["lengthLocked"]);
            edges[currentEdge.id] = newEdge;
            return edges;
        }, {});
        var newNodes = Object.values(state.nodes).reduce(function (nodes, currentNode) {
            // Looks for nodes that are tube connections
            if (currentNode.type === typesVMinus1.NodeComponentType.TUBE_CONNECTION) {
                // Removes diameter property and copies remaining properties into newTubeConnection
                var _a = currentNode.component, diameter = _a.diameter, newTubeConnection = __rest(_a, ["diameter"]);
                // Save the old diameter property in the new diameters property
                newTubeConnection.diameters = [diameter];
                // Save the new component on current node
                currentNode.component = __assign({}, newTubeConnection);
            }
            nodes[currentNode.id] = currentNode;
            return nodes;
        }, {});
        return __assign(__assign({}, state), { edges: newEdges, nodes: newNodes, hasDisconnectedEdges: true });
    },
    _a[4] = function (state) {
        var newEdges = Object.values(state.edges).reduce(function (edges, currentEdge) {
            edges[currentEdge.id] = __assign(__assign({}, currentEdge), { lengthLocked: false });
            return edges;
        }, {});
        return __assign(__assign({}, state), { edges: newEdges });
    },
    _a[5] = function (state) {
        return {
            past: [],
            present: __assign({}, state),
            future: [],
        };
    },
    _a[6] = function (state) {
        var presentState = state.present;
        var newObjectShape = presentState.objectShape;
        return __assign(__assign({}, state), { present: __assign(__assign({}, presentState), { objectShape: newObjectShape }) });
    },
    _a);
export { migrations };
