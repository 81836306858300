var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Button } from "@animech/ui";
import { useTranslation } from "react-i18next";
import * as Styled from "./newsflash.style";
import { selectors, useAppSelector } from "store";
export var NewsflashModal = function (props) {
    var t = useTranslation().t;
    var releaseNotes = useAppSelector(selectors.selectReleaseNotes);
    if (!releaseNotes)
        return null;
    var closeModal = function () {
        localStorage.setItem("lastViewedNewsflashId", releaseNotes[0].id);
        props.onShouldClose && props.onShouldClose();
    };
    return (_jsxs(Styled.NewsflashRoot, __assign({}, props, { title: t("newsflash.title"), color: "inverted", onShouldClose: closeModal, children: [_jsx(Styled.ReleaseNotesContainer, { children: releaseNotes.map(function (note) { return (_jsxs(Styled.ReleaseNote, { children: [_jsx(Styled.Date, { children: note.date }), note.changes.map(function (change, i) { return (_jsx("p", { children: change }, note.id + i)); })] }, note.id)); }) }), _jsx(Styled.Footer, { children: _jsx(Button, { onClick: function () { return closeModal(); }, children: t("newsflash.button_text") }) })] })));
};
