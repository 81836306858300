var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Styled from "ui/components/panels/sidebar/sidebar.style";
import { EditButtons } from "ui/components/elements/edit-buttons/edit-buttons";
import { TubeConnectionId } from "store/types";
import { tubeConnectionModels } from "3d/constants/common";
import { useAppDispatch, actions, selectors, useAppSelector } from "store";
import { KeyBindings, useKeyBindings } from "hooks/keybindings";
import { useNodeActions } from "hooks/node-actions";
import { useNumberConversionWithMillimeters } from "hooks/number-conversion";
import { Unit } from "utility/number";
import { MultipleValuesDropdown } from "ui/components/elements/input/multiple-values-dropdown";
import { MultipleValuesCustomSlider } from "ui/components/elements/custom-slider/multiple-values-custom-slider";
var TubeDiameters = function (_a) {
    var tubeConnection = _a.tubeConnection;
    var convertNumberWithMillimeters = useNumberConversionWithMillimeters();
    var tubeDiameters = useMemo(function () {
        var tubeDiameters = __spreadArray([], __read(tubeConnection.diameters), false);
        // Sort tubeDiameters array so we get the largest diameter first
        tubeDiameters.sort(function (a, b) { return Number(b) - Number(a); });
        return tubeDiameters.map(function (diameter) { return convertNumberWithMillimeters(diameter, Unit.M).formatted; });
    }, [convertNumberWithMillimeters, tubeConnection.diameters]);
    return (_jsx(Styled.TubeDiameterContainer, { children: tubeDiameters.map(function (diameter, i) { return (_jsx(Styled.Diameter, { children: diameter }, i)); }) }));
};
export var TubeConnectionMenu = function (_a) {
    var nodes = _a.nodes;
    var t = useTranslation().t;
    var dispatch = useAppDispatch();
    var convertNumberWithMillimeters = useNumberConversionWithMillimeters();
    var isMultiSelecting = useAppSelector(selectors.selectIsMultiSelecting);
    var tubeConnections = nodes.map(function (node) { return node.component; });
    var toolchangerDiameters = tubeConnections.map(function (tubeConnection) { return tubeConnection.diameters[0]; });
    var models = tubeConnections.map(function (tubeConnection) { return tubeConnectionModels[tubeConnection.type]; });
    useKeyBindings(nodes[0], [KeyBindings.DELETE, KeyBindings.ESCAPE]);
    var nodeActions = useNodeActions(nodes[0]);
    var onSelectType = function (type) {
        var typeId = type;
        dispatch(actions.setTubeConnectionType({
            nodeIds: nodes.map(function (node) { return node.id; }),
            type: typeId,
        }));
    };
    var onChangeDiameter = function (diameter) {
        dispatch(actions.setTubeConnectionDiameters({
            nodeIds: nodes.map(function (node) { return node.id; }),
            diameters: [diameter / 1000],
        }));
    };
    var tubeConnectionOptions = useMemo(function () {
        var items = Object.keys(tubeConnectionModels);
        var labels = items.map(function (itemKey) {
            return t(tubeConnectionModels[itemKey].translationKey);
        });
        return { items: items, labels: labels };
    }, [t]);
    var isToolchanger = tubeConnections.every(function (tubeConnection) { return tubeConnection.type === TubeConnectionId.TOOL_CHANGER; });
    var diameterLabel = useMemo(function () {
        if (isToolchanger) {
            return t("sidebar.edit_tube_connection.connection_diameter_toolchanger");
        }
        var hasMultipleDiameters = tubeConnections.some(function (tubeConnection) { return tubeConnection.diameters.length > 1; });
        if (hasMultipleDiameters) {
            return t("sidebar.edit_tube_connection.connection_diameters");
        }
        return t("sidebar.edit_tube_connection.connection_diameter");
    }, [isToolchanger, t, tubeConnections]);
    // the largest minimum diameter of all models
    var diameterMin = models.reduce(function (min, model) { return Math.max(min, model.diameterRange[0]); }, models[0].diameterRange[0]);
    // the smallest maximum diameter of all models
    var diameterMax = models.reduce(function (max, model) { return Math.min(max, model.diameterRange[1]); }, models[0].diameterRange[1]);
    var toolChangerLabel = __spreadArray([], __read(new Set(toolchangerDiameters)), false).sort(function (a, b) { return a - b; })
        .map(function (diameter) { return convertNumberWithMillimeters(diameter, Unit.M).formatted; })
        .join(", ");
    return (_jsxs(_Fragment, { children: [!isMultiSelecting && (_jsx(EditButtons, { className: "".concat(Styled.prefix, "-edit-buttons"), actions: nodeActions, buttonSize: "large" })), _jsx(Styled.SidebarSection, { children: _jsx(MultipleValuesDropdown, { label: t("sidebar.edit_tube_connection.connection_type_label"), items: tubeConnectionOptions.items, itemLabels: tubeConnectionOptions.labels, values: tubeConnections.map(function (tubeConnection) { return tubeConnection.type; }), onValueChange: onSelectType }) }), isToolchanger ? (_jsx(Styled.SidebarSection, { children: _jsx(MultipleValuesCustomSlider, { header: diameterLabel, className: "".concat(Styled.prefix, "-slider"), min: diameterMin * 1000, max: diameterMax * 1000, stepSize: 0.5, showSteps: false, showValue: true, values: toolchangerDiameters.map(function (diameter) { return diameter * 1000; }), onValueChange: onChangeDiameter, labelLeft: toolChangerLabel }) })) : (!isMultiSelecting && (_jsx(Styled.SidebarSection, { children: _jsx(TubeDiameters, { tubeConnection: tubeConnections[0] }) })))] }));
};
