import { useTranslation } from "react-i18next";
export var useNumberValidation = function (type, min, max) {
    var t = useTranslation().t;
    var validate = function (value) {
        var valueAsNumber = Number(value);
        if (isNaN(valueAsNumber)) {
            return t("input_errors.value_not_a_number");
        }
        if (type === "int" && !Number.isInteger(valueAsNumber)) {
            return t("input_errors.value_not_an_integer");
        }
        if (min !== undefined && valueAsNumber < min) {
            return t("input_errors.value_too_small", { minValue: min });
        }
        if (max !== undefined && valueAsNumber > max) {
            return t("input_errors.value_too_large", { maxValue: max });
        }
        return "";
    };
    return { validate: validate };
};
