var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { StyledDropDown } from "@animech/ui";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
export var ResetModalText = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var Root = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function () {
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t\t\t", " {\n\t\t\t\tmargin: 0;\n\t\t\t\tpadding-right: 16px;\n\t\t\t\tpadding-left: 16px;\n\n\t\t\t\t/* using !important is a simpler way to override the default box-shadow\n\t\t\t\t* and background styles compared to targeting various data attributes\n\t\t\t\t*/\n\t\t\t\tbox-shadow: none !important;\n\t\t\t\tbackground: none !important;\n\n\t\t\t\t", " {\n\t\t\t\t\tpadding: 0;\n\t\t\t\t}\n\n\t\t\t\t&[data-disabled=\"true\"] {\n\t\t\t\t\t", " {\n\t\t\t\t\t\tdisplay: none;\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t"], ["\n\t\t\t", " {\n\t\t\t\tmargin: 0;\n\t\t\t\tpadding-right: 16px;\n\t\t\t\tpadding-left: 16px;\n\n\t\t\t\t/* using !important is a simpler way to override the default box-shadow\n\t\t\t\t* and background styles compared to targeting various data attributes\n\t\t\t\t*/\n\t\t\t\tbox-shadow: none !important;\n\t\t\t\tbackground: none !important;\n\n\t\t\t\t", " {\n\t\t\t\t\tpadding: 0;\n\t\t\t\t}\n\n\t\t\t\t&[data-disabled=\"true\"] {\n\t\t\t\t\t", " {\n\t\t\t\t\t\tdisplay: none;\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t"])), StyledDropDown.Root, StyledDropDown.Container, StyledDropDown.ArrowIcon);
});
var templateObject_1, templateObject_2, templateObject_3;
