var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as Sentry from "@sentry/react";
import { serializeStateToRequest, deserializeResponseToSystemPerformance, deserializeResponseToSystemSanityCheck, } from "./serializers";
import { round } from "utility/number";
import { systemDataConstants } from "api/system-data";
import { ApiError, ApiErrorType } from "api/errors";
import { selectors } from "store/selectors";
import { performRequest } from "api/tacton/common-api";
var defaultConfig = {
    method: "POST",
    "Content-Type": "application/json",
};
var DEFAULT_BASE_URL = process.env.CALCULATION_API_BASE_URL;
var getData = function (baseUrl, serializedState, energySaveOn) {
    if (baseUrl === void 0) { baseUrl = DEFAULT_BASE_URL; }
    return __awaiter(void 0, void 0, void 0, function () {
        var idMap, response, err_1, error, message, extra, deserializedResponse, deserializedSanityCheckResponse;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    idMap = serializedState.apiIdToNodeId;
                    return [4 /*yield*/, performRequest("".concat(baseUrl, "/calculate"), __assign(__assign({}, defaultConfig), { body: JSON.stringify(serializedState.request) }))];
                case 1:
                    response = _b.sent();
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _b.sent();
                    Sentry.captureException(err_1);
                    console.error(err_1);
                    error = err_1;
                    message = void 0;
                    try {
                        message = JSON.parse(error.message);
                    }
                    catch (err) {
                        throw new ApiError("Calculation request error", ApiErrorType.REQUEST_ERROR);
                    }
                    switch (message.code) {
                        case 7: {
                            extra = ((_a = message.extra) === null || _a === void 0 ? void 0 : _a.feed_pressure)
                                ? {
                                    feedPressure: round(message.extra.feed_pressure, 3),
                                }
                                : undefined;
                            throw new ApiError(message.message, ApiErrorType.FEED_PRESSURE_NOT_SUITABLE_FOR_PUMP, extra);
                        }
                        case 6:
                            throw new ApiError(message.message, ApiErrorType.TARGET_PARTS_PER_MINUTE_NOT_ACHIEVED);
                        default:
                            throw new ApiError("Calculation request error", ApiErrorType.REQUEST_ERROR);
                    }
                    return [3 /*break*/, 3];
                case 3:
                    try {
                        deserializedResponse = deserializeResponseToSystemPerformance(response, idMap || {});
                        deserializedSanityCheckResponse = deserializeResponseToSystemSanityCheck(response, energySaveOn);
                    }
                    catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                        throw new ApiError("Error deserializing calculation response", ApiErrorType.SERIALIZER_ERROR);
                    }
                    return [2 /*return*/, {
                            response: deserializedResponse,
                            sanityCheck: deserializedSanityCheckResponse,
                        }];
            }
        });
    });
};
var getSerializedState = function (state, isValidation) {
    try {
        var isCentralizedSystem = selectors.selectIsCentralizedSystem(state);
        var systemDataDefineParameters_1 = selectors.selectSystemDataDefineParameters(state);
        if (!systemDataDefineParameters_1) {
            throw Error("System data undefined");
        }
        var pumpSelections = isCentralizedSystem
            ? systemDataDefineParameters_1.vacuumPumpSelectionsCentralized
            : systemDataDefineParameters_1.vacuumPumpSelectionsDecentralized;
        var isEnergySavingSelected = systemDataDefineParameters_1.parameters[systemDataConstants.parameterNames.energySavingToggle].value === systemDataConstants.parameterValues.energySavingOn;
        var isEnergySavingAvailable = Object.values(pumpSelections).every(function (value) {
            return !value ||
                systemDataDefineParameters_1.vacuumPumpComponents[value]
                    .isEnergySavingAvailable;
        });
        return {
            esOff: serializeStateToRequest(state, false, isValidation),
            esOn: isEnergySavingSelected && isEnergySavingAvailable
                ? serializeStateToRequest(state, true, isValidation)
                : undefined,
        };
    }
    catch (err) {
        Sentry.captureException(err);
        console.error(err);
        throw new ApiError("Error serializing state", ApiErrorType.SERIALIZER_ERROR);
    }
};
export var computationSystemPerformanceAPI = {
    getSanityCheck: function (state, baseUrl) {
        if (baseUrl === void 0) { baseUrl = DEFAULT_BASE_URL; }
        return __awaiter(void 0, void 0, void 0, function () {
            var serializedState, computationResponse, request, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        serializedState = getSerializedState(state, true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        request = serializedState.esOn
                            ? serializedState.esOn.request
                            : serializedState.esOff.request;
                        return [4 /*yield*/, performRequest("".concat(baseUrl, "/calculate"), __assign(__assign({}, defaultConfig), { body: JSON.stringify(request) }))];
                    case 2:
                        computationResponse = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _a.sent();
                        /* Don't report sanity check request errors to Sentry since we
                         * expect a lot of them */
                        throw new ApiError("Sanity check request error", ApiErrorType.REQUEST_ERROR);
                    case 4:
                        try {
                            return [2 /*return*/, deserializeResponseToSystemSanityCheck(computationResponse, serializedState.esOn !== undefined)];
                        }
                        catch (err) {
                            Sentry.captureException(err);
                            console.error(err);
                            throw new ApiError("Error deserializing sanity check response", ApiErrorType.SERIALIZER_ERROR);
                        }
                        return [2 /*return*/];
                }
            });
        });
    },
    getDataWithoutEs: function (state, baseUrl) { return __awaiter(void 0, void 0, void 0, function () {
        var serializedState, _a, response, sanityCheck;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    serializedState = getSerializedState(state, false);
                    return [4 /*yield*/, getData(baseUrl, serializedState.esOff, false)];
                case 1:
                    _a = _b.sent(), response = _a.response, sanityCheck = _a.sanityCheck;
                    return [2 /*return*/, {
                            energySavingOff: response,
                            sanityCheck: sanityCheck,
                        }];
            }
        });
    }); },
    getDataWithEs: function (state, baseUrl) { return __awaiter(void 0, void 0, void 0, function () {
        var serializedState, _a, response, sanityCheck;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    serializedState = getSerializedState(state, false);
                    if (!serializedState.esOn)
                        return [2 /*return*/, undefined];
                    return [4 /*yield*/, getData(baseUrl, serializedState.esOn, false)];
                case 1:
                    _a = _b.sent(), response = _a.response, sanityCheck = _a.sanityCheck;
                    return [2 /*return*/, {
                            energySavingOn: response,
                            sanityCheck: sanityCheck,
                        }];
            }
        });
    }); },
};
