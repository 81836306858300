import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { PerformanceTooltip, } from "./performance-tooltip";
import { Unit, round } from "utility/number";
import { useNumberConversion } from "hooks/number-conversion";
import { selectors, useAppSelector } from "store";
import { useGraphDataContext } from "providers/graph-panel";
import { AlphabeticalNodeType } from "hooks/graph-panel/graph";
import { systemDataConstants } from "api/system-data";
export var VacuumPumpTooltip = function (_a) {
    var _b;
    var node = _a.node, x = _a.x, y = _a.y, align = _a.align, onTooltipHover = _a.onTooltipHover, shouldFadeTooltip = _a.shouldFadeTooltip;
    var convertNumber = useNumberConversion();
    var getAlphabeticalId = useGraphDataContext().getAlphabeticalId;
    var pumpPerformance = useAppSelector(selectors.selectVacuumPumpPerformance(node.id));
    var isSystemPerformanceLoading = useAppSelector(selectors.selectIsPerformanceLoading);
    var vacuumPumpComponent = useAppSelector(selectors.selectVacuumPumpComponent(node.id));
    var t = useTranslation().t;
    var pumpLabel = (_b = vacuumPumpComponent === null || vacuumPumpComponent === void 0 ? void 0 : vacuumPumpComponent.label) !== null && _b !== void 0 ? _b : "";
    var alphabeticalId = getAlphabeticalId(node.id, AlphabeticalNodeType.PUMP);
    var systemDataSnapshot = useAppSelector(selectors.selectPerformanceSystemDataSnapshot);
    var airConsumptionPerMinute = useMemo(function () {
        if (!pumpPerformance || !systemDataSnapshot)
            return "";
        var parameterNames = systemDataConstants.parameterNames;
        var getParameterValue = function (parameterName) { var _a; return Number((_a = systemDataSnapshot.parameters[parameterName]) === null || _a === void 0 ? void 0 : _a.value) || 0; };
        var targetPartsPerMinute = {
            value: getParameterValue(parameterNames.targetPartsPerMinute) || 1,
            translationKey: "dashboard.parts_per_minute",
        };
        return convertNumber(pumpPerformance.airConsumptionTotal * targetPartsPerMinute.value, Unit.NL_MIN).formatted;
    }, [convertNumber, pumpPerformance, systemDataSnapshot]);
    if (!pumpPerformance || isSystemPerformanceLoading)
        return null;
    return (_jsx(PerformanceTooltip, { x: x, y: y, align: align, title: t("canvas.vacuum_pump_title", {
            alphabeticalId: alphabeticalId,
        }), name: pumpLabel, onTooltipHover: onTooltipHover, shouldFadeTooltip: shouldFadeTooltip, children: _jsxs(_Fragment, { children: [_jsx("br", {}), "".concat(t("canvas.compressed_air_pressure_pump"), ": ").concat(convertNumber(pumpPerformance.compressedAirPressure, Unit.MPA)
                    .formatted, "\n\t\t\t\t\t \t"), _jsx("br", {}), t("canvas.time_to_part_secured", {
                    value: round(pumpPerformance.timeToPartSecured * 1000, 0),
                    context: pumpPerformance.timeToPartSecured === 0 ? "not_reached" : "",
                }), _jsx("br", {}), pumpPerformance.timeToEnergySave ? (_jsxs(_Fragment, { children: [t("canvas.time_to_energy_save", {
                            value: round(pumpPerformance.timeToEnergySave * 1000, 0),
                        }), _jsx("br", {})] })) : null, "".concat(t("canvas.final_vacuum_level"), ": ").concat(convertNumber(pumpPerformance.finalVacuumLevel, Unit.KPA).formatted, "\n\t\t\t\t\t \t\t"), _jsx("br", {}), "".concat(t("canvas.free_running_vacuum_pump"), ": ").concat(convertNumber(pumpPerformance.freeRunningVacuumLevel, Unit.KPA)
                    .formatted, "\n\t\t\t\t\t \t\t"), _jsx("br", {}), "".concat(t("canvas.air_consumption_pump"), ": ").concat(airConsumptionPerMinute, "\n\t\t\t\t\t \t\t")] }) }));
};
