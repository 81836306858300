var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { persistKey as scenePersistKey } from "store/scene/persist-config";
import { persistKey as uiPersistKey } from "store/ui/persist-config";
import { persistKey as persistKeyDefineParameters } from "store/system-data/define-parameters/persist-config";
import { persistKey as persistKeySelectApplication } from "store/system-data/select-application/persist-config";
var setParamFromStorage = function (persistKey, params) {
    var storedState = window.sessionStorage.getItem("persist:".concat(persistKey));
    if (storedState) {
        params.set(persistKey, storedState);
    }
};
export var getSharableLink = function () {
    var _a = __read(window.location.hash.split("?"), 2), path = _a[0], search = _a[1];
    var pathName = window.location.pathname;
    var params = new URLSearchParams(search);
    setParamFromStorage(scenePersistKey, params);
    setParamFromStorage(uiPersistKey, params);
    setParamFromStorage(persistKeyDefineParameters, params);
    setParamFromStorage(persistKeySelectApplication, params);
    return window.location.origin + pathName + path + "?" + params.toString();
};
