import { useMemo } from "react";
import { checkGripPositionFit, isMountingCompatibleWithSuctionCup, } from "3d/helpers";
import { useAppSelector, selectors } from "store";
import { useCollisionsContext } from "providers/collisions";
export var Warning;
(function (Warning) {
    Warning["INVALID_CUP"] = "invalid_cup";
    Warning["INVALID_PUMP"] = "invalid_pump";
    Warning["INVALID_CUP_MOUNTING"] = "invalid_cup_mounting";
    Warning["GRIP_POSITION_SIZE"] = "grip_position_size";
    Warning["TAPE_COLLISION"] = "tape_collision";
})(Warning || (Warning = {}));
export var useNodeWarnings = function (node) {
    var nodeId = (node === null || node === void 0 ? void 0 : node.id) || "";
    var gripPositionHasCollision = useCollisionsContext().gripPositionHasCollision;
    var selectedCup = useAppSelector(selectors.selectSelectedCup(nodeId));
    var selectedCupMounting = useAppSelector(selectors.selectSelectedCupMounting(nodeId));
    var selectedPumpCentral = useAppSelector(selectors.selectSelectedCentralPump(nodeId));
    var selectedPumpDecentral = useAppSelector(selectors.selectSelectedDecentralPump(nodeId));
    var isCentralizedSystem = useAppSelector(selectors.selectIsCentralizedSystem);
    var nodeIsCollidingWithTape = selectedCup && gripPositionHasCollision(nodeId);
    var nodeComponent = node === null || node === void 0 ? void 0 : node.component;
    var warnings = useMemo(function () {
        var allWarnings = [];
        if (selectedCup && nodeComponent) {
            var gripPosition = nodeComponent;
            if (!selectedCup.valid) {
                allWarnings.push(Warning.INVALID_CUP);
            }
            if (!checkGripPositionFit(gripPosition, selectedCup.width, selectedCup.height)) {
                allWarnings.push(Warning.GRIP_POSITION_SIZE);
            }
        }
        if (!isCentralizedSystem &&
            selectedPumpDecentral &&
            !selectedPumpDecentral.valid) {
            allWarnings.push(Warning.INVALID_PUMP);
        }
        if (isCentralizedSystem &&
            selectedPumpCentral &&
            !selectedPumpCentral.valid) {
            allWarnings.push(Warning.INVALID_PUMP);
        }
        if ((selectedCupMounting && !selectedCupMounting.valid) ||
            !isMountingCompatibleWithSuctionCup(selectedCup, selectedCupMounting)) {
            allWarnings.push(Warning.INVALID_CUP_MOUNTING);
        }
        if (nodeIsCollidingWithTape) {
            allWarnings.push(Warning.TAPE_COLLISION);
        }
        return allWarnings;
    }, [
        nodeComponent,
        nodeIsCollidingWithTape,
        selectedCupMounting,
        selectedCup,
        selectedPumpCentral,
        selectedPumpDecentral,
        isCentralizedSystem,
    ]);
    return warnings;
};
