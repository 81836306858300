var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defaultMetalCupMaterialTransform, } from "3d/types";
/** ---------------   **/
/**    PAXB Family    **/
/** ---------------   **/
export var suctionCupsFamilyPAXB = {
    PAXB: __assign(__assign({ cupModelName: "PAXB52", cupWidth: 0.052, cupHeight: 0.052 }, defaultMetalCupMaterialTransform.NP), { fittingModelName: "G38MNS18F01" }),
    // PAXB32
    "suction_cup_S.PAXB32NP60.G38M.01": __assign(__assign({ cupModelName: "PAXB32", cupWidth: 0.032, cupHeight: 0.032 }, defaultMetalCupMaterialTransform.NP), { fittingModelName: "G38MNS18F01" }),
};
