var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Container } from "@animech/ui";
import { useTranslation } from "react-i18next";
import * as Styled from "./conflict-confirmation.style";
import { NumberType } from "api/types";
import { useAppSelector, selectors } from "store";
import { useNumberConversion } from "hooks/number-conversion";
import { AlertIcon } from "ui/components/elements/icons/alert";
import { CustomConfirmModal } from "ui/components/modals/custom-confirm-modal/custom-confirm-modal";
export var ConflictConfirmationModal = function (_a) {
    var props = __rest(_a, []);
    var t = useTranslation().t;
    return (_jsx(CustomConfirmModal, __assign({}, props, { title: t("conflict_modal.title"), cancelActionLabel: t("conflict_modal.button_dismiss"), okActionLabel: t("conflict_modal.button_accept"), children: _jsx(ConflictConfirmationModalContent, {}) })));
};
var ConflictConfirmationModalContent = function () {
    var t = useTranslation().t;
    var conflict = useAppSelector(selectors.selectConflict);
    var convertNumber = useNumberConversion();
    var getParameterValue = function (parameter) {
        var _a;
        if (parameter.type === NumberType.INT ||
            parameter.type === NumberType.FLOAT) {
            if (parameter.unit) {
                return convertNumber(Number(parameter.value), parameter.unit).formatted;
            }
            else {
                return parameter.value;
            }
        }
        else {
            return (_a = parameter.options.find(function (option) { return option.value === parameter.value; })) === null || _a === void 0 ? void 0 : _a.label;
        }
    };
    if (!conflict)
        return null;
    return (_jsx(Styled.ConflictConfirmationModal, { children: _jsxs(Container, { children: [_jsx("div", { className: "".concat(Styled.prefix, "-message"), children: t("conflict_modal.message") }), conflict.proposedChanges.map(function (change) { return (_jsxs("div", { className: Styled.prefix, children: [_jsxs("div", { className: "".concat(Styled.prefix, "-header"), children: [_jsx(Styled.ConflictIcon, { children: _jsx(AlertIcon, { size: 18 }) }), _jsx("span", { children: change.oldParameter.label })] }), _jsxs("div", { className: "".concat(Styled.prefix, "-values"), children: [_jsxs("div", { className: "".concat(Styled.prefix, "-value old-value"), children: [_jsx("span", { className: "".concat(Styled.prefix, "-value-title"), children: t("conflict_modal.current_value_title") }), getParameterValue(change.oldParameter)] }), _jsxs("div", { className: "".concat(Styled.prefix, "-value new-value"), children: [_jsx("span", { className: "".concat(Styled.prefix, "-value-title"), children: t("conflict_modal.new_value_title") }), getParameterValue(change.newParameter)] })] })] }, change.oldParameter.label)); })] }) }));
};
