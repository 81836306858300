var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { ActionCreators as UndoActionCreators } from "redux-undo";
import { useModal, Container } from "@animech/ui";
import { t } from "i18next";
import { useMemo, useCallback } from "react";
import { useAppDispatch, useAppSelector, selectors, actions } from "store";
import { extractOptionList } from "ui/components/elements/select-application-dropdown/helpers";
import { ConfirmationResult, ResetConfirmationModal, } from "ui/components/modals/reset-confirmation";
import { LoadingResetConfigurationModal } from "ui/components/modals/reset-confirmation/loading-configuration-reset";
import { TactonApplication } from "api/tacton/types";
/**
 * This hook isused to select an application and confirm that change.
 *
 * Call handleApplicationSelection when the user selects an application to
 * display a modal asking for confirmation. The function returns a boolean
 * indicating whether the user confirmed the change or not.
 */
export var useSelectApplication = function () {
    var openModal = useModal();
    var dispatch = useAppDispatch();
    var selectApplicationParameter = useAppSelector(selectors.selectApplicationNames);
    var systemDataDefineParameters = useAppSelector(selectors.selectSystemDataDefineParameters);
    var currentApplication = selectApplicationParameter === null || selectApplicationParameter === void 0 ? void 0 : selectApplicationParameter.value;
    var applicationOptions = useMemo(function () {
        return selectApplicationParameter &&
            extractOptionList(selectApplicationParameter.options);
    }, [selectApplicationParameter]);
    var changeApplication = useCallback(function (selectedApplication, resetConfiguration) { return __awaiter(void 0, void 0, void 0, function () {
        var loadingResetConfigurationModal, parameters, parameterIds;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if ((selectApplicationParameter === null || selectApplicationParameter === void 0 ? void 0 : selectApplicationParameter.id) === undefined) {
                        console.error("Could not change application, since parameter id was undefined");
                        return [2 /*return*/];
                    }
                    // NOTE: dispatching clearLatestSelectedCup avoids a bug where cups
                    // that are incompatible with the selected application are automatically applied
                    // TODO: remove this dispatch when the bug is fixed
                    dispatch(actions.clearLatestSelectedCup());
                    if (selectedApplication !== TactonApplication.CARDBOARD) {
                        dispatch(actions.setCardboardManualThroughLeak(undefined));
                    }
                    if (!resetConfiguration) return [3 /*break*/, 2];
                    loadingResetConfigurationModal = openModal(_jsx(LoadingResetConfigurationModal, {}));
                    dispatch(actions.resetScene());
                    parameters = systemDataDefineParameters === null || systemDataDefineParameters === void 0 ? void 0 : systemDataDefineParameters.parameters;
                    parameterIds = parameters
                        ? Object.values(parameters).map(function (parameter) { return parameter.id; })
                        : [];
                    return [4 /*yield*/, dispatch(actions.clearParameters(parameterIds))];
                case 1:
                    _a.sent();
                    loadingResetConfigurationModal.close();
                    _a.label = 2;
                case 2: 
                // NOTE: we need to dispatch getSystemDataSelectApplication to change to
                // the correct tacton step. otherwise the setParametersSelectApplication
                // action will not work
                // TODO: find a more graceful solution than calling
                // getSystemDataSelectApplication right before
                // setParametersSelectApplication
                return [4 /*yield*/, dispatch(actions.getSystemDataSelectApplication())];
                case 3:
                    // NOTE: we need to dispatch getSystemDataSelectApplication to change to
                    // the correct tacton step. otherwise the setParametersSelectApplication
                    // action will not work
                    // TODO: find a more graceful solution than calling
                    // getSystemDataSelectApplication right before
                    // setParametersSelectApplication
                    _a.sent();
                    return [4 /*yield*/, dispatch(actions.setParametersSelectApplication({
                            parameters: [selectApplicationParameter.id],
                            values: [selectedApplication],
                        }))];
                case 4:
                    _a.sent();
                    dispatch(UndoActionCreators.clearHistory());
                    return [2 /*return*/];
            }
        });
    }); }, [
        selectApplicationParameter,
        dispatch,
        openModal,
        systemDataDefineParameters === null || systemDataDefineParameters === void 0 ? void 0 : systemDataDefineParameters.parameters,
    ]);
    var handleApplicationSelection = useCallback(function (applicationSelection) { return __awaiter(void 0, void 0, void 0, function () {
        var label, confirmationResult;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    label = (_a = selectApplicationParameter === null || selectApplicationParameter === void 0 ? void 0 : selectApplicationParameter.options.find(function (_a) {
                        var value = _a.value;
                        return value === applicationSelection;
                    })) === null || _a === void 0 ? void 0 : _a.label;
                    return [4 /*yield*/, openModal(_jsx(ResetConfirmationModal, { children: _jsx(Container, { children: t("reset_modal.message", {
                                    material: label,
                                }) }) })).done()];
                case 1:
                    confirmationResult = _b.sent();
                    if (!confirmationResult) return [3 /*break*/, 3];
                    return [4 /*yield*/, changeApplication(applicationSelection, confirmationResult === ConfirmationResult.RESET_CHANGES)];
                case 2:
                    _b.sent();
                    _b.label = 3;
                case 3: return [2 /*return*/, confirmationResult];
            }
        });
    }); }, [changeApplication, openModal, selectApplicationParameter === null || selectApplicationParameter === void 0 ? void 0 : selectApplicationParameter.options]);
    return {
        currentApplication: currentApplication,
        applicationOptions: applicationOptions,
        handleApplicationSelection: handleApplicationSelection,
    };
};
