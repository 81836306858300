var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@emotion/styled";
import { css } from "@emotion/react";
export var prefix = "system-design-page";
export var CanvasUIOverlay = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var SystemDesignPage = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: row;\n\t\t\theight: 100%;\n\t\t\tbackground: ", ";\n\n\t\t\t.", "-main-content {\n\t\t\t\tposition: relative;\n\t\t\t\theight: 100%;\n\t\t\t\twidth: 100%;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tdisplay: flex;\n\t\t\t\tflex-flow: column;\n\t\t\t\tjustify-content: space-between;\n\t\t\t\tmax-height: 100vh;\n\t\t\t\tposition: absolute;\n\t\t\t\tleft: 0;\n\t\t\t\tright: 0;\n\t\t\t\ttop: 0;\n\t\t\t\tbottom: 0;\n\t\t\t\tpointer-events: none;\n\n\t\t\t\t> * {\n\t\t\t\t\tpointer-events: auto;\n\t\t\t\t}\n\t\t\t}\n\t\t"], ["\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: row;\n\t\t\theight: 100%;\n\t\t\tbackground: ", ";\n\n\t\t\t.", "-main-content {\n\t\t\t\tposition: relative;\n\t\t\t\theight: 100%;\n\t\t\t\twidth: 100%;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tdisplay: flex;\n\t\t\t\tflex-flow: column;\n\t\t\t\tjustify-content: space-between;\n\t\t\t\tmax-height: 100vh;\n\t\t\t\tposition: absolute;\n\t\t\t\tleft: 0;\n\t\t\t\tright: 0;\n\t\t\t\ttop: 0;\n\t\t\t\tbottom: 0;\n\t\t\t\tpointer-events: none;\n\n\t\t\t\t> * {\n\t\t\t\t\tpointer-events: auto;\n\t\t\t\t}\n\t\t\t}\n\t\t"])), theme.colors.palette.grey[700], prefix, CanvasUIOverlay);
});
var templateObject_1, templateObject_2, templateObject_3;
