var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { CheckBox } from "@animech/ui";
import * as Styled from "./node-list.style";
import { useGraphDataContext } from "providers/graph-panel";
import { defaultGraphStrokeWidth } from "ui/components/panels/graphs/constants";
import { useGraphLabels } from "hooks/graph-panel/graph-labels";
export var NodeList = function () {
    var pumpsAndCupsNodeTree = useGraphDataContext().pumpsAndCupsNodeTree;
    if (!pumpsAndCupsNodeTree)
        return null;
    return (_jsx(Styled.NodeList, { children: pumpsAndCupsNodeTree.map(function (pump) {
            var id = pump.yValueKey;
            return _jsx(NodeListAccordion, { pump: pump }, id);
        }) }));
};
export var NodeListAccordion = function (props) {
    var _a = useGraphDataContext(), selectedNodes = _a.selectedNodes, onSelectedNodeChange = _a.onSelectedNodeChange, printModeActive = _a.printModeActive;
    var _b = useGraphLabels(), getVacuumPumpLabel = _b.getVacuumPumpLabel, getSuctionCupLabel = _b.getSuctionCupLabel;
    var pump = props.pump;
    var connectedSuctionCups = pump.connectedSuctionCups, vacuumPumpId = pump.yValueKey;
    var _c = __read(useState(true), 2), isOpen = _c[0], setIsOpen = _c[1];
    var toggleIsOpen = function () {
        setIsOpen(function (prevState) { return !prevState; });
    };
    useEffect(function () {
        setIsOpen(printModeActive ? true : pump.isGoverningPump);
    }, [pump.isGoverningPump, printModeActive]);
    var accordionIcon = useMemo(function () { return (isOpen ? "chevron-up-thin" : "chevron-down-thin"); }, [isOpen]);
    var vacuumPumpLabel = getVacuumPumpLabel(pump);
    if (!selectedNodes)
        return null;
    return (_jsxs(Styled.NodeListAccordion, { "data-open": isOpen, children: [_jsx(VerticalLine, { color: pump.color, isOpen: isOpen }), _jsxs(Styled.OuterContainer, { children: [_jsxs(Styled.Accordion, { children: [_jsx(CheckBox, { appearance: "ghost", color: "inverted", onValueChange: function () { return onSelectedNodeChange(vacuumPumpId, true); }, value: selectedNodes[vacuumPumpId], label: vacuumPumpLabel }), _jsx(Styled.Icon, { rootAttributes: { onClick: function () { return toggleIsOpen(); } }, scale: 0.7, name: accordionIcon })] }), isOpen && (_jsx(Styled.AccordionItems, { children: connectedSuctionCups.map(function (suctionCup) {
                            var suctionCupId = suctionCup.yValueKey, name = suctionCup.name;
                            var suctionCupLabel = getSuctionCupLabel(__assign(__assign({}, suctionCup), { name: name }));
                            return (_jsxs(Styled.AccordionItem, { children: [_jsx(FilledCircle, { color: suctionCup.color }), _jsx(CheckBox, { appearance: "ghost", color: "inverted", onValueChange: function () { return onSelectedNodeChange(suctionCupId); }, value: selectedNodes[suctionCupId], label: suctionCupLabel })] }, suctionCupId));
                        }) }))] })] }));
};
function VerticalLine(_a) {
    var color = _a.color, isOpen = _a.isOpen;
    var drawPath = isOpen ? "M 0 0 L 0 100" : "M 0 20 L 0 80";
    return (_jsx(Styled.VerticalLine, { width: "100%", height: "100%", viewBox: "0 0 100 100", preserveAspectRatio: "none", children: _jsx("path", { d: drawPath, stroke: color, strokeWidth: defaultGraphStrokeWidth, vectorEffect: "non-scaling-stroke" }) }));
}
function FilledCircle(_a) {
    var color = _a.color;
    return (_jsx(Styled.FilledCircle, { width: "100%", height: "100%", viewBox: "0 0 100 100", preserveAspectRatio: "xMidYMid meet", children: _jsx("circle", { cx: "50", cy: "50", r: "40", fill: color }) }));
}
