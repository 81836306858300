var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defaultMetalCupMaterialTransform, } from "3d/types";
/** ---------------   **/
/**    XLF Family     **/
/** ---------------   **/
export var suctionCupsFamilyXLF = {
    XLF: __assign(__assign({ cupModelName: "XLF200", cupWidth: 0.2, cupHeight: 0.2 }, defaultMetalCupMaterialTransform.NP), { fittingModelName: "G38MS01" }),
};
