import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { H2, Button } from "@animech/ui";
import * as Styled from "./intro.style";
import { Logo } from "ui/components/elements/logo";
import { PageRoute } from "utility/url";
export var IntroPage = function () {
    var t = useTranslation().t;
    var navigate = useNavigate();
    return (_jsxs(Styled.IntroPage, { children: [_jsx("div", { className: "".concat(Styled.prefix, "-header"), children: _jsx("div", { className: "".concat(Styled.prefix, "-logo"), children: _jsx(Logo, { applicationSelectionIsDisabled: true }) }) }), _jsxs("div", { className: "".concat(Styled.prefix, "-content"), children: [_jsxs(H2, { children: [" ", t("intro.title"), " "] }), _jsxs("div", { className: "".concat(Styled.prefix, "-body-text"), children: [_jsxs("p", { children: [" ", t("intro.p1"), " "] }), _jsxs("p", { children: [" ", t("intro.p2"), " "] }), _jsx("p", { children: _jsxs(Trans, { i18nKey: "intro.p3", children: ["text before link", _jsx("a", { href: "https://www.piab.com", target: "_blank", rel: "noreferrer noopener", children: "link" }), "text after link"] }) }), _jsxs("p", { children: [" ", t("intro.p4"), " "] })] })] }), _jsxs("div", { className: "".concat(Styled.prefix, "-footer"), children: [_jsx("p", { className: "".concat(Styled.prefix, "-disclaimer"), children: t("intro.disclaimer") }), _jsx("div", { className: "".concat(Styled.prefix, "-button-container"), children: _jsx(Button, { size: "large", onClick: function () { return navigate(PageRoute.SELECT_APPLICATION); }, children: t("intro.button") }) })] })] }));
};
