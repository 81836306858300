var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { StyledImageSelect } from "@animech/ui";
import { StyledDropdown } from "ui/components/elements/input/dropdown";
export var AccordionToggle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var AccordionContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
export var AccordionLabel = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
export var Root = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme, isDisabled = _a.isDisabled;
    return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\t\t\tfont-size: ", ";\n\n\t\t\t", " {\n\t\t\t\t", ";\n\t\t\t\theight: 45px;\n\t\t\t\tpadding: 10px 14px;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\t", "\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tmargin-top: ", ";\n\t\t\t\t", " {\n\t\t\t\t\t", " {\n\t\t\t\t\t\tbackground-repeat: no-repeat;\n\t\t\t\t\t\tbackground-size: contain;\n\t\t\t\t\t}\n\n\t\t\t\t\t", " {\n\t\t\t\t\t\tfont-size: ", ";\n\t\t\t\t\t\tfont-weight: ", ";\n\t\t\t\t\t}\n\n\t\t\t\t\t&[data-checked] {\n\t\t\t\t\t\t", " {\n\t\t\t\t\t\t\tfont-size: ", ";\n\t\t\t\t\t\t\tfont-weight: ", ";\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t"], ["\n\t\t\tfont-size: ", ";\n\n\t\t\t", " {\n\t\t\t\t", ";\n\t\t\t\theight: 45px;\n\t\t\t\tpadding: 10px 14px;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\t", "\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tmargin-top: ", ";\n\t\t\t\t", " {\n\t\t\t\t\t", " {\n\t\t\t\t\t\tbackground-repeat: no-repeat;\n\t\t\t\t\t\tbackground-size: contain;\n\t\t\t\t\t}\n\n\t\t\t\t\t", " {\n\t\t\t\t\t\tfont-size: ", ";\n\t\t\t\t\t\tfont-weight: ", ";\n\t\t\t\t\t}\n\n\t\t\t\t\t&[data-checked] {\n\t\t\t\t\t\t", " {\n\t\t\t\t\t\t\tfont-size: ", ";\n\t\t\t\t\t\t\tfont-weight: ", ";\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t"])), theme.typography.fontSize.small, AccordionToggle, StyledDropdown.inputContainerStyle(theme, isDisabled), AccordionLabel, StyledDropdown.labelStyle, AccordionContent, theme.dimensions.margin.large, StyledImageSelect.Root, StyledImageSelect.Image, StyledImageSelect.Label, theme.components.label.small.typography.fontSize, theme.components.label.small.typography.fontWeight, StyledImageSelect.Label, theme.components.label.small.typography.fontSize, theme.components.label.small.typography
        .fontWeight);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
