var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createSlice, createAsyncThunk, } from "@reduxjs/toolkit";
import api from "api/system-performance";
export var initialState = {
    isLoading: false,
    isPerformanceDataPending: false,
    isPerformanceDataWithEsPending: false,
    isSanityCheckLoading: false,
    isPerformanceDataValid: false,
    isSanityCheckValid: false,
    showEnergySavePerformance: false,
};
var setShowEnergySavePerformance = function (state, action) {
    state.showEnergySavePerformance = action.payload;
};
var setIsPerformanceDataValid = function (state, action) {
    state.isPerformanceDataValid = action.payload;
};
var showPerformanceData = function (state) {
    //If a new sanity check or system performance is being fetched when called, instead set data to pending.
    if (state.isPerformanceDataPending ||
        state.isPerformanceDataWithEsPending ||
        state.isSanityCheckLoading) {
        state.isLoading = true;
    }
    else if (state.isSanityCheckValid && state.preloadedData) {
        state.data = state.preloadedData;
        state.isPerformanceDataValid = true;
        state.isLoading = false;
    }
    else if (state.error) {
        state.error = __assign(__assign({}, state.error), { visible: true });
    }
};
var clearPerformanceData = function (state) {
    state.data = undefined;
    state.preloadedData = undefined;
    state.isPerformanceDataPending = false;
    state.isPerformanceDataWithEsPending = false;
    state.systemDataSnapshot = undefined;
    state.isLoading = false;
    state.error = undefined;
    state.isPerformanceDataValid = false;
    state.showEnergySavePerformance = false;
    state.sanityCheckData = undefined;
    state.isSanityCheckLoading = false;
    state.isSanityCheckValid = false;
};
var getDataWithoutEs = createAsyncThunk("system-performance/getDataWithoutEs", function (_, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    var systemDataDefineParameters, response, err_1, error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                systemDataDefineParameters = thunkApi.getState().systemDataDefineParameters.dataState.data;
                return [4 /*yield*/, api.getDataWithoutEs(thunkApi.getState(), thunkApi.extra.computationApiBaseUrl)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, {
                        systemPerformance: {
                            energySavingOff: response.energySavingOff,
                        },
                        systemData: systemDataDefineParameters,
                        sanityCheck: response.sanityCheck,
                    }];
            case 2:
                err_1 = _a.sent();
                error = err_1;
                return [2 /*return*/, thunkApi.rejectWithValue({
                        message: error.message,
                        type: error.type,
                        extra: error.extra,
                        visible: false,
                    })];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getDataWithEs = createAsyncThunk("system-performance/getDataWithEs", function (_, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_2, error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, api.getDataWithEs(thunkApi.getState(), thunkApi.extra.computationApiBaseUrl)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, {
                        systemPerformance: {
                            energySavingOn: response === null || response === void 0 ? void 0 : response.energySavingOn,
                        },
                        sanityCheck: response === null || response === void 0 ? void 0 : response.sanityCheck,
                    }];
            case 2:
                err_2 = _a.sent();
                error = err_2;
                return [2 /*return*/, thunkApi.rejectWithValue({
                        message: error.message,
                        type: error.type,
                        extra: error.extra,
                        visible: false,
                    })];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getSanityCheckData = createAsyncThunk("system-performance/getSanityCheckData", function (_, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, api.getSanityCheck(thunkApi.getState(), thunkApi.extra.computationApiBaseUrl)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); });
var systemPerformanceSlice = createSlice({
    name: "system-performance",
    initialState: initialState,
    reducers: {
        setShowEnergySavePerformance: setShowEnergySavePerformance,
        setIsPerformanceDataValid: setIsPerformanceDataValid,
        clearPerformanceData: clearPerformanceData,
        showPerformanceData: showPerformanceData,
    },
    extraReducers: function (builder) {
        builder.addCase(getDataWithoutEs.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.preloadedData = payload.systemPerformance;
            state.systemDataSnapshot = payload.systemData;
            state.isPerformanceDataPending = false;
            state.error = undefined;
            state.sanityCheckData = payload.sanityCheck;
        });
        builder.addCase(getDataWithoutEs.rejected, function (state, _a) {
            var payload = _a.payload, meta = _a.meta;
            state.error = payload;
            state.isPerformanceDataPending = false;
            if (!meta.aborted) {
                state.preloadedData = undefined;
            }
        });
        builder.addCase(getDataWithoutEs.pending, function (state) {
            state.isPerformanceDataPending = true;
            /* Since we will always fire a request with ES after the initial without ES request is fulfilled,
             * we set WithEs to pending as well to ensure the "Calculate Performance" button don't falsely show it's state
             * as completed before the ES request is fulfilled as well. */
            state.isPerformanceDataWithEsPending = true;
            state.isPerformanceDataValid = false;
        });
        builder.addCase(getDataWithEs.fulfilled, function (state, _a) {
            var payload = _a.payload;
            if (state.preloadedData) {
                state.preloadedData.energySavingOn =
                    payload.systemPerformance.energySavingOn;
            }
            if (payload.sanityCheck) {
                state.sanityCheckData = payload.sanityCheck;
            }
            state.isPerformanceDataWithEsPending = false;
            state.error = undefined;
            var canShowEnergySavePerformance = (payload.systemPerformance.energySavingOn &&
                payload.systemPerformance.energySavingOn.systemTimeToEnergySave >
                    0) ||
                false;
            state.showEnergySavePerformance =
                canShowEnergySavePerformance && state.showEnergySavePerformance;
        });
        builder.addCase(getDataWithEs.rejected, function (state, _a) {
            var payload = _a.payload, meta = _a.meta;
            state.error = payload;
            state.isPerformanceDataWithEsPending = false;
            if (!meta.aborted) {
                state.preloadedData = undefined;
            }
        });
        builder.addCase(getDataWithEs.pending, function (state) {
            state.isPerformanceDataWithEsPending = true;
            state.isPerformanceDataValid = false;
        });
        builder.addCase(getSanityCheckData.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.isSanityCheckValid = true;
            state.sanityCheckData = payload;
            state.isSanityCheckLoading = false;
        });
        builder.addCase(getSanityCheckData.pending, function (state) {
            state.isSanityCheckLoading = true;
            state.isSanityCheckValid = false;
        });
        builder.addCase(getSanityCheckData.rejected, function (state, _a) {
            var meta = _a.meta;
            /* The action is rejected with meta.aborted = true when abort() is called
             * in observers.tx. We abort an action if it has not finished before a new
             * getSanityCheckData action is dispatched. If this happes, sanity check is
             * still loading and we wait for the response from the new action */
            if (!meta.aborted) {
                state.isSanityCheckLoading = false;
                state.sanityCheckData = undefined;
            }
        });
    },
});
var allActions = systemPerformanceSlice.actions, reducer = systemPerformanceSlice.reducer;
export var callableActions = {
    getSystemPerformance: getDataWithoutEs,
    getSystemPerformanceWithEs: getDataWithEs,
    getSanityCheckData: getSanityCheckData,
    setShowEnergySavePerformance: allActions.setShowEnergySavePerformance,
    setIsPerformanceDataValid: allActions.setIsPerformanceDataValid,
    clearPerformanceData: allActions.clearPerformanceData,
    showPerformanceData: allActions.showPerformanceData,
};
export var internalActions = {};
export default reducer;
