import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useRef, useCallback, createContext, useContext, } from "react";
import * as pc from "playcanvas";
import { usePlaycanvasApplication } from "hooks/playcanvas/application";
import { screenshotCameraView } from "3d/constants/common";
import { useDetachMouseWhenSceneIsBlocked } from "hooks/playcanvas/detach-mouse-when-scene-is-blocked";
var defaultPlaycanvasApplicationContext = {
    isLoading: true,
    generateScreenshot: function () { return "default"; },
};
var PlaycanvasApplicationContext = createContext(defaultPlaycanvasApplicationContext);
export var PlaycanvasApplicationProvider = function (_a) {
    var children = _a.children;
    var canvasRef = useRef(null);
    var _b = usePlaycanvasApplication(canvasRef), isLoading = _b.isLoading, app = _b.app, camera = _b.camera, isCameraMoving = _b.isCameraMoving;
    var blockingElementEvents = useDetachMouseWhenSceneIsBlocked(app, canvasRef);
    var generateScreenshot = useCallback(function (width, height) {
        if (app && (canvasRef === null || canvasRef === void 0 ? void 0 : canvasRef.current) && camera) {
            var resolution = window.devicePixelRatio > 2 ? window.devicePixelRatio : 2;
            app.setCanvasResolution(pc.RESOLUTION_FIXED, width * resolution, height * resolution);
            camera.setCurrentView(screenshotCameraView.rotx, screenshotCameraView.roty, screenshotCameraView.distance, new pc.Vec3(screenshotCameraView.x, screenshotCameraView.y, screenshotCameraView.z));
            app.render();
            var imageSrc = canvasRef.current.toDataURL();
            app.setCanvasResolution(pc.RESOLUTION_AUTO);
            return imageSrc;
        }
        return "";
    }, [app, canvasRef, camera]);
    return (_jsx(PlaycanvasApplicationContext.Provider, { value: {
            canvasRef: canvasRef,
            isLoading: isLoading,
            app: app,
            camera: camera,
            isCameraMoving: isCameraMoving,
            generateScreenshot: generateScreenshot,
            blockingElementEvents: blockingElementEvents,
        }, children: children }));
};
export var usePlaycanvasApplicationContext = function () {
    return useContext(PlaycanvasApplicationContext);
};
