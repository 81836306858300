var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { CustomSlider } from "./custom-slider";
import { MIXED_VALUES_CONSTANT } from "store/types";
/**
 * Renders a single slider that represents multiple values.
 * If the values are different, the slider will display a "Mixed" state in its left label, and the right label will be hidden.
 */
export var MultipleValuesCustomSlider = function (_a) {
    var valuesFromProps = _a.values, labelLeftFromProps = _a.labelLeft, labelRightFromProps = _a.labelRight, showValueFromProps = _a.showValue, restProps = __rest(_a, ["values", "labelLeft", "labelRight", "showValue"]);
    var t = useTranslation().t;
    var valuesAreMixed = valuesFromProps.some(function (value) { return value !== valuesFromProps[0]; });
    var value = valuesAreMixed ? undefined : valuesFromProps[0];
    var labelLeft = valuesAreMixed
        ? "".concat(t(MIXED_VALUES_CONSTANT), " (").concat(labelLeftFromProps, ")")
        : labelLeftFromProps;
    var labelRight = valuesAreMixed ? undefined : labelRightFromProps;
    return (_jsx(CustomSlider, __assign({}, restProps, { value: value, labelLeft: labelLeft, labelRight: labelRight })));
};
