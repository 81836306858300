import { TubeType, NodeComponentType, } from "store/types";
import { Unit } from "utility/number";
import { tubeConnectionModels } from "3d/constants/common";
export var TableCellType;
(function (TableCellType) {
    TableCellType["STRING"] = "string";
    TableCellType["NUMBER"] = "number";
    TableCellType["TRANSLATION_KEY"] = "translation-key";
    TableCellType["LINK"] = "link";
})(TableCellType || (TableCellType = {}));
export var getSelectionTableRows = function (selections, components) {
    var groupedSelections = Object.values(selections).reduce(function (grouped, current) {
        if (!current)
            return grouped;
        var component = components[current];
        if (grouped[current]) {
            grouped[current].quantity += 1;
        }
        else {
            grouped[current] = {
                name: component.label,
                quantity: 1,
                prelCode: component.articleNumber,
                link: component.link,
            };
        }
        return grouped;
    }, {});
    return Object.values(groupedSelections).map(function (group) { return [
        { value: group.name, type: TableCellType.STRING },
        {
            value: group.quantity,
            type: TableCellType.NUMBER,
        },
        {
            value: group.prelCode,
            type: TableCellType.STRING,
        },
        { value: group.link, type: TableCellType.LINK },
    ]; });
};
export var getTubeConnectionTableRows = function (nodes, convertNumber) {
    var tubeConnectionGroups = {};
    Object.values(nodes).forEach(function (node) {
        if (node.type === NodeComponentType.TUBE_CONNECTION) {
            var component = node.component;
            var convertedDiameters_1 = [];
            component.diameters.map(function (diameter) {
                convertedDiameters_1.push(convertNumber(diameter, Unit.M).formatted);
            });
            var componentKey = "".concat(component.type, "-").concat(component.diameters);
            if (tubeConnectionGroups[componentKey]) {
                tubeConnectionGroups[componentKey].quantity += 1;
            }
            else {
                tubeConnectionGroups[componentKey] = {
                    name: tubeConnectionModels[component.type].translationKey,
                    diameters: convertedDiameters_1,
                    quantity: 1,
                };
            }
        }
    });
    return Object.values(tubeConnectionGroups).map(function (group) {
        return [
            { value: group.name, type: TableCellType.TRANSLATION_KEY },
            { value: group.quantity, type: TableCellType.NUMBER },
            { value: group.diameters.join(", "), type: TableCellType.STRING },
        ];
    });
};
export var getTubeTableRows = function (edges, useDoubleCompressedAirTubes, convertNumber) {
    var edgeGroups = Object.values(edges).reduce(function (groups, currentEdge) {
        var addTubeToGroups = function (key, translationId, diameter) {
            if (groups[key]) {
                groups[key].quantity++;
            }
            else {
                groups[key] = {
                    translationId: translationId,
                    length: convertNumber(currentEdge.length, Unit.M).formatted,
                    diameter: convertNumber(diameter, Unit.M).formatted,
                    quantity: 1,
                };
            }
        };
        if (currentEdge.tubeType === TubeType.COMPRESSED_AIR &&
            useDoubleCompressedAirTubes) {
            addTubeToGroups("".concat(currentEdge.length, "-").concat(currentEdge.diameterDefaultTube, "-compressed-air"), "summary_modal.table.tube_types.".concat(TubeType.COMPRESSED_AIR), currentEdge.diameterDefaultTube);
            addTubeToGroups("".concat(currentEdge.length, "-").concat(currentEdge.diameterBlowOffTube, "-compressed-air-bo"), "summary_modal.table.tube_types.".concat(TubeType.COMPRESSED_AIR, "_blow_off"), currentEdge.diameterBlowOffTube);
        }
        else {
            addTubeToGroups("".concat(currentEdge.length, "-").concat(currentEdge.diameterDefaultTube, "-").concat(currentEdge.tubeType), "summary_modal.table.tube_types.".concat(currentEdge.tubeType), currentEdge.diameterDefaultTube);
        }
        return groups;
    }, {});
    return Object.values(edgeGroups).map(function (group) { return [
        { value: group.translationId, type: TableCellType.TRANSLATION_KEY },
        { value: group.quantity, type: TableCellType.NUMBER },
        { value: group.length, type: TableCellType.STRING },
        { value: group.diameter, type: TableCellType.STRING },
    ]; });
};
