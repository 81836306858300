import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import * as Styled from "ui/components/elements/edit-buttons/edit-buttons.style";
import { IconButton } from "ui/components/elements/icons/icon-button";
import { DeleteIcon } from "ui/components/elements/icons/delete";
import { RotateClockwiseIcon, RotateCounterClockwiseIcon, } from "ui/components/elements/icons/rotate";
import { DuplicateIcon } from "ui/components/elements/icons/duplicate";
import { ZoomInIcon } from "ui/components/elements/icons/zoom-in";
import { ZoomOutIcon } from "ui/components/elements/icons/zoom-out";
export var EditButtonIcon;
(function (EditButtonIcon) {
    EditButtonIcon["DELETE"] = "delete";
    EditButtonIcon["DUPLICATE"] = "duplicate";
    EditButtonIcon["ZOOM_IN"] = "zoomin";
    EditButtonIcon["ZOOM_OUT"] = "zoomout";
    EditButtonIcon["ROTATE_COUNTER_CLOCKWISE"] = "rotateCounterClockwise";
    EditButtonIcon["ROTATE_CLOCKWISE"] = "rotateClockwise";
})(EditButtonIcon || (EditButtonIcon = {}));
var icons = {
    rotateClockwise: _jsx(RotateClockwiseIcon, { size: 16 }),
    rotateCounterClockwise: _jsx(RotateCounterClockwiseIcon, { size: 16 }),
    delete: _jsx(DeleteIcon, { size: 16 }),
    duplicate: _jsx(DuplicateIcon, { size: 16 }),
    zoomin: _jsx(ZoomInIcon, { size: 16 }),
    zoomout: _jsx(ZoomOutIcon, { size: 16 }),
};
export var EditButtons = function (_a) {
    var _b = _a.hasBackground, hasBackground = _b === void 0 ? false : _b, className = _a.className, _c = _a.buttonSize, buttonSize = _c === void 0 ? "default" : _c, actions = _a.actions;
    return (_jsx(Styled.EditButtons, { className: className, children: _jsx("div", { className: [
                hasBackground ? "".concat(Styled.prefix, "-with-background") : "",
                buttonSize === "large" ? "".concat(Styled.prefix, "-large") : "",
            ]
                .filter(Boolean)
                .join(" "), children: actions.map(function (action, i) {
                return (_jsx(IconButton, { rootAttributes: {
                        onMouseDown: function (event) { return event.stopPropagation(); },
                    }, onClick: action.action, color: "neutral", rounded: true, children: icons[action.icon] }, i));
            }) }) }));
};
