import { useEffect } from "react";
import * as pc from "playcanvas";
import { useAssetLoader } from "hooks/playcanvas/asset-loader";
import { useAppDispatch, actions } from "store";
import { tubeConnectionModels, tubeConnectionMaterialAreaName, tubeMaterials, } from "3d/constants/common";
var TUBE_CONNECTION_ENTITY_NAME = "TubeConnection";
export var TubeConnection = function (_a) {
    var app = _a.app, nodeEntity = _a.nodeEntity, tubeConnection = _a.tubeConnection, modelAssets = _a.modelAssets, materialAssets = _a.materialAssets;
    var loadAssets = useAssetLoader(app).loadAssets;
    var dispatch = useAppDispatch();
    useEffect(function () {
        var tubeConnectionEntity = new pc.Entity(TUBE_CONNECTION_ENTITY_NAME);
        tubeConnectionEntity.addComponent("model", { type: "asset" });
        tubeConnectionEntity.setLocalScale(0.5, 0.5, 0.5);
        nodeEntity.addChild(tubeConnectionEntity);
        return function () {
            tubeConnectionEntity.destroy();
        };
    }, [app.root, nodeEntity, tubeConnection.nodeId]);
    useEffect(function () {
        var tubeConnectionEntity = nodeEntity.findByName(TUBE_CONNECTION_ENTITY_NAME);
        var tubeConnectionModel = tubeConnectionModels[tubeConnection.type];
        var tubeConnectionAsset = modelAssets[tubeConnectionModel.modelName];
        var updateTubeConnection = function () {
            if (tubeConnectionEntity && tubeConnectionEntity.model) {
                var hasNewAsset = tubeConnectionEntity.model.asset !== tubeConnectionAsset.id;
                // TODO: Figure out why we need to set the model asset to 0 for some inexplicable reason.
                // Currently the materials on the object won't change when applying transforms if we don't do this first.
                tubeConnectionEntity.model.asset = 0;
                tubeConnectionEntity.model.asset = tubeConnectionAsset;
                if (hasNewAsset) {
                    dispatch(actions.setNodeAttachments({
                        nodeId: tubeConnection.nodeId,
                        placeholders: tubeConnectionModel.placeholderNames.map(function (name) { return ({
                            name: name,
                            tubeType: tubeConnection.tubeType,
                        }); }),
                    }));
                }
                tubeConnectionEntity.model.meshInstances.forEach(function (meshInstance) {
                    if (meshInstance.material.name === tubeConnectionMaterialAreaName) {
                        meshInstance.material =
                            materialAssets[tubeMaterials[tubeConnection.tubeType][0]].resource;
                    }
                });
            }
        };
        loadAssets([tubeConnectionAsset], updateTubeConnection);
    }, [
        nodeEntity,
        tubeConnection.type,
        modelAssets,
        tubeConnection.tubeType,
        materialAssets,
        dispatch,
        loadAssets,
        tubeConnection.nodeId,
    ]);
    return null;
};
