var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState, useEffect } from "react";
export var DelayedFallback = function (_a) {
    var isLoading = _a.isLoading, fallback = _a.fallback, children = _a.children, _b = _a.delay, delay = _b === void 0 ? 300 : _b;
    var _c = __read(useState(false), 2), showFallback = _c[0], setShowFallback = _c[1];
    useEffect(function () {
        var timeoutId;
        if (isLoading && !showFallback) {
            timeoutId = setTimeout(function () { return setShowFallback(true); }, delay);
        }
        if (!isLoading && showFallback) {
            setShowFallback(false);
        }
        return function () { return clearTimeout(timeoutId); };
    }, [delay, showFallback, isLoading]);
    if (isLoading && showFallback) {
        return fallback;
    }
    else if (isLoading && !showFallback) {
        return null;
    }
    else {
        return children || null;
    }
};
