var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { CartesianGrid, Legend, Line, LineChart, ReferenceLine, Tooltip, XAxis, YAxis, } from "recharts";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useRef } from "react";
import { defaultGraphStrokeWidth, graphWidth, defaultYAxisKey, defaultXAxisProps, airConsumptionGraphHeight, airConsumptionColor, airConsumptionYAxisUnit, yAxisDyTSpan, graphMargins, yAxisPadding, defaultYAxisProps, } from "./constants";
import { VerticalReferenceLines } from "./common/reference-lines";
import { CustomLegend, useCustomLegendWrapperStyle, } from "./common/custom-legend";
import { CustomTooltip } from "./common/custom-tooltip";
import { CustomYAxisTick } from "./common/custom-y-axis-tick";
import { ZoomHighlightedArea } from "./common/custom-reference-area";
import { useTimeDataMinMaxDomain } from "hooks/graph-panel/graph";
import { selectors, useAppSelector } from "store";
import { useNumberConversion } from "hooks/number-conversion";
import { useGraphDataContext } from "providers/graph-panel";
import { UnitSystem } from "store/types";
import { conversions } from "utility/number";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var CustomAirConsumptionYTick = function (props) {
    var x = props.x, payload = props.payload;
    var convertNumber = useNumberConversion();
    var convertedValue = convertNumber(payload.value, airConsumptionYAxisUnit);
    var label = (_jsx("tspan", { x: x, dy: yAxisDyTSpan, children: convertedValue.value }));
    return _jsx(CustomYAxisTick, __assign({}, props, { labelElements: label }));
};
export var AirConsumptionGraph = function () {
    var lineChartRef = useRef(null);
    var t = useTranslation().t;
    var unitSystem = useAppSelector(selectors.selectUnitSystem);
    var xAxisDomain = useTimeDataMinMaxDomain();
    var parsedGraphData = useAppSelector(selectors.selectParsedGraphData);
    var _a = useGraphDataContext(), onMouseDown = _a.onMouseDown, onMouseMove = _a.onMouseMove, onMouseUp = _a.onMouseUp, zoomedTimeDomain = _a.zoomedTimeDomain, zoomedAirConsumptionDomain = _a.zoomedAirConsumptionDomain, setAirConsumptionGraphRef = _a.setAirConsumptionGraphRef;
    var customLegendWrapperStyle = useCustomLegendWrapperStyle(airConsumptionGraphHeight);
    var zoomTicksY = useMemo(function () {
        if (!zoomedAirConsumptionDomain)
            return;
        var min = zoomedAirConsumptionDomain[0];
        var max = zoomedAirConsumptionDomain[1];
        var midPoint = (min + max) / 2;
        return [min, midPoint, max].map(function (tick) { return Math.round(tick); });
    }, [zoomedAirConsumptionDomain]);
    useEffect(function () {
        if (lineChartRef.current) {
            setAirConsumptionGraphRef(lineChartRef);
        }
    }, [lineChartRef, setAirConsumptionGraphRef]);
    if (!parsedGraphData)
        return null;
    var airConsumptionData = parsedGraphData.airConsumptionData, airConsumptionDomain = parsedGraphData.airConsumptionDomain;
    var yDomain = zoomedAirConsumptionDomain !== null && zoomedAirConsumptionDomain !== void 0 ? zoomedAirConsumptionDomain : airConsumptionDomain;
    var xDomain = zoomedTimeDomain !== null && zoomedTimeDomain !== void 0 ? zoomedTimeDomain : xAxisDomain;
    var yAxisTicks = zoomTicksY !== null && zoomTicksY !== void 0 ? zoomTicksY : airConsumptionDomain;
    var yAxisUnit = unitSystem === UnitSystem.METRIC
        ? airConsumptionYAxisUnit
        : conversions[airConsumptionYAxisUnit].unit;
    return (_jsxs(LineChart, { ref: lineChartRef, width: graphWidth, height: airConsumptionGraphHeight, data: airConsumptionData, margin: graphMargins, onMouseDown: onMouseDown, onMouseMove: onMouseMove, onMouseUp: onMouseUp, children: [_jsx(CartesianGrid, { vertical: false, horizontal: false }), _jsx(VerticalReferenceLines, {}), _jsx(ReferenceLine, { y: yDomain[0] }), _jsx(ReferenceLine, { y: yDomain[1] }), _jsx(XAxis, __assign({}, defaultXAxisProps, { domain: xDomain, hide: true })), _jsx(YAxis, __assign({}, defaultYAxisProps, { interval: 0, tick: _jsx(CustomAirConsumptionYTick, {}), ticks: yAxisTicks, tickMargin: 10, tickLine: false, padding: yAxisPadding, domain: yDomain })), _jsx(Tooltip, { wrapperStyle: { zIndex: 100 }, content: _jsx(CustomTooltip, { xLabel: t("graph_panel.pump_x_label_time"), yLabel: t("graph_panel.air_consumption_tooltip_y_label"), yUnit: airConsumptionYAxisUnit }) }), _jsx(Line, { type: "stepAfter", dataKey: defaultYAxisKey, stroke: airConsumptionColor, strokeWidth: defaultGraphStrokeWidth, dot: false }), _jsx(Legend, { align: "left", wrapperStyle: customLegendWrapperStyle, content: function () { return (_jsx(CustomLegend, { label: "".concat(t("graph_panel.air_consumption_legend"), " (").concat(yAxisUnit, ")") })); } }), _jsx(ZoomHighlightedArea, { domain: yDomain })] }));
};
