var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TimedNotification } from "ui/components/elements/timed-notification";
import { ApiErrorType } from "api/errors";
import { useNodeWarnings } from "hooks/node-warnings";
import { useAppSelector, selectors } from "store";
export var NotificationPanel = function () {
    var t = useTranslation().t;
    var performanceData = useAppSelector(selectors.selectPerformanceData);
    var hasReachedPS = useAppSelector(selectors.selectHasReachedPartSecured);
    var isESAvailableForSystem = useAppSelector(selectors.selectIsEnergySaveAvailableForSystem);
    var hasReachedES = useAppSelector(selectors.selectHasReachedEnergySave);
    var isMultiSelecting = useAppSelector(selectors.selectIsMultiSelecting);
    var selectedNodesType = useAppSelector(selectors.selectSelectedNodesType);
    var _a = __read(useState(false), 2), showESNotification = _a[0], setShowESNotification = _a[1];
    var _b = __read(useState(false), 2), showPSNotification = _b[0], setShowPSNotification = _b[1];
    var _c = __read(useState(false), 2), showSuccessNotification = _c[0], setShowSuccessNotification = _c[1];
    useEffect(function () {
        setShowPSNotification(performanceData !== undefined && !hasReachedPS);
        setShowSuccessNotification(performanceData !== undefined && hasReachedPS);
        setShowESNotification(isESAvailableForSystem && !hasReachedES);
    }, [performanceData, hasReachedPS, isESAvailableForSystem, hasReachedES]);
    var selectedNodesAreNotTheSameType = selectedNodesType === null;
    var showMultiSelectingNodesTypeNotification = isMultiSelecting && selectedNodesAreNotTheSameType;
    var performanceError = useAppSelector(selectors.selectPerformanceError);
    var _d = __read(useState(false), 2), showPerformanceError = _d[0], setShowPerformanceError = _d[1];
    useEffect(function () {
        if (performanceError === null || performanceError === void 0 ? void 0 : performanceError.visible) {
            setShowPerformanceError(true);
        }
        else {
            setShowPerformanceError(false);
        }
    }, [performanceError]);
    var systemDataErrors = useAppSelector(selectors.selectSystemDataErrors);
    var _e = __read(useState(false), 2), showSystemDataError = _e[0], setShowSystemDataError = _e[1];
    useEffect(function () {
        if (systemDataErrors) {
            setShowSystemDataError(true);
        }
    }, [systemDataErrors]);
    var selectedNode = useAppSelector(selectors.selectSelectedNode);
    var nodeWarnings = useNodeWarnings(selectedNode);
    var getTimeoutByErrorType = function (type) {
        switch (type) {
            case ApiErrorType.RESTORE_SAVED_ERROR:
                return 0;
            default:
                return 8000;
        }
    };
    var getSystemDataErrorContext = function (error) {
        if (!error.extra)
            return {};
        var context = error.extra.parameters
            ? "parameters"
            : error.extra.components
                ? "components"
                : undefined;
        var count = error.extra.parameters
            ? error.extra.parameters.length
            : error.extra.components
                ? error.extra.components.length
                : undefined;
        return __assign(__assign({}, error.extra), { context: context, count: count });
    };
    var dataErrors = useMemo(function () {
        if (!systemDataErrors || !showSystemDataError)
            return [];
        return systemDataErrors.map(function (error) { return ({
            timeout: getTimeoutByErrorType(error.type),
            text: t("tacton_api_errors.".concat(error.type), getSystemDataErrorContext(error)),
            onClose: function () { return setShowSystemDataError(false); },
        }); });
    }, [showSystemDataError, systemDataErrors, t]);
    var nodeWarningArray = useMemo(function () {
        if (nodeWarnings.length === 0)
            return [];
        return nodeWarnings.map(function (warning) { return ({
            timeout: -1,
            text: t("node_warnings.".concat(warning)),
            color: "tertiary",
        }); });
    }, [nodeWarnings, t]);
    var notifications = useMemo(function () {
        var notifications = __spreadArray(__spreadArray([], __read(nodeWarningArray), false), __read(dataErrors), false);
        if (showPerformanceError && performanceError) {
            notifications.push({
                timeout: getTimeoutByErrorType(performanceError.type),
                text: t("computation_api_errors.".concat(performanceError.type), __assign({}, performanceError.extra)),
                onClose: function () { return setShowPerformanceError(false); },
            });
        }
        if (showESNotification) {
            notifications.push({
                timeout: -1,
                text: t("notifications.full_calculation_es_not_reached"),
                onClose: function () { return setShowESNotification(false); },
                color: "tertiary",
            });
        }
        if (showPSNotification) {
            notifications.push({
                timeout: -1,
                text: t("notifications.full_calculation_ps_not_reached"),
                onClose: function () { return setShowPSNotification(false); },
                color: "alert",
            });
        }
        if (showSuccessNotification) {
            notifications.push({
                timeout: 4000,
                text: t("notifications.calculations_completed"),
                onClose: function () { return setShowSuccessNotification(false); },
                color: "primary",
            });
        }
        if (showMultiSelectingNodesTypeNotification) {
            notifications.push({
                timeout: 4000,
                text: t("notifications.multi_selecting_nodes_type_warning"),
                color: "tertiary",
            });
        }
        return notifications;
    }, [
        dataErrors,
        nodeWarningArray,
        performanceError,
        showESNotification,
        showMultiSelectingNodesTypeNotification,
        showPSNotification,
        showPerformanceError,
        showSuccessNotification,
        t,
    ]);
    return (_jsx(_Fragment, { children: notifications.map(function (notification) { return (_jsx(TimedNotification, { text: notification.text, timeout: notification.timeout, color: notification.color, onClose: notification.onClose }, notification.text)); }) }));
};
