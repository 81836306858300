var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { TooltipPortal } from "@animech/ui";
import { useEffect, useRef, useState } from "react";
import * as Styled from "./text-box.style";
import { useShowInfo, } from "ui/components/elements/info-button";
export var TextBox = function (_a) {
    var xRect = _a.x, yRect = _a.y, width = _a.width, height = _a.height, text = _a.text, tooltipText = _a.tooltipText, _b = _a.color, color = _b === void 0 ? "#000000" : _b, description = _a.description;
    var textElementRef = useRef(null);
    var _c = __read(useState(0), 2), textX = _c[0], setTextX = _c[1];
    var _d = __read(useState(0), 2), textY = _d[0], setTextY = _d[1];
    useEffect(function () {
        if (!textElementRef.current)
            return;
        var bbox = textElementRef.current.getBBox();
        var textWidth = bbox.width;
        var textHeight = bbox.height;
        // We calculate how much space will be left between the box and the text.
        // Then we use half of that length so that the padding on the left/right/top/bottom of the text will be equal
        var horizontalPAdding = (width - textWidth) / 2;
        setTextX(xRect + horizontalPAdding);
        var verticalPadding = (height - textHeight) / 2;
        setTextY(yRect + height - verticalPadding);
    }, [text, textElementRef, width, height, xRect, yRect]);
    var showInfo = useShowInfo(tooltipText !== null && tooltipText !== void 0 ? tooltipText : "", description);
    var hoverStyle = {
        "&:hover": {
            cursor: "pointer",
            fillOpacity: "50%",
        },
    };
    return (_jsxs("g", { children: [_jsx(TooltipPortal, { anchorElement: _jsx("rect", { css: tooltipText ? hoverStyle : {}, x: xRect, y: yRect, rx: 3, ry: 3, width: width, height: height, stroke: "#FFFFFF", fill: color }), toggleEvent: tooltipText ? "click" : undefined, placement: "top", children: _jsx(Styled.Tooltip, { color: "inverted", children: _jsx(Styled.TooltipContent, { onClick: showInfo, children: tooltipText }) }) }), _jsx("text", { ref: textElementRef, css: { pointerEvents: "none" }, x: textX, y: textY, fontSize: 12, fill: "#FFFFFF", alignmentBaseline: "text-after-edge", children: text })] }));
};
