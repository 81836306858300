/** Given a sorted array of numbers (ascending) and a target, this function will do a binary search and return the index of the value in the array that is closest to the given target number */
export function indexOfClosest(sortedArray, target) {
    var left = 0;
    var right = sortedArray.length - 1;
    if (target <= sortedArray[left])
        return left;
    if (target >= sortedArray[right])
        return right;
    // Binary search
    while (left <= right) {
        var mid = Math.floor((left + right) / 2);
        if (sortedArray[mid] === target) {
            return mid;
        }
        else if (sortedArray[mid] < target) {
            left = mid + 1;
        }
        else {
            right = mid - 1;
        }
    }
    // At the end of the binary search, left and right will be around the closest value
    // Determine whether sortedArray[left] or sortedArray[right] is closer to the target
    if (Math.abs(sortedArray[left] - target) <=
        Math.abs(sortedArray[right] - target)) {
        return left;
    }
    else {
        return right;
    }
}
