import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useCallback } from "react";
import * as Styled from "./tape-ends-toggle.style";
import { useAppDispatch, useAppSelector, selectors, actions } from "store";
export var TapeEndsToggle = function () {
    var dispatch = useAppDispatch();
    var tapeEnds = useAppSelector(selectors.selectTapeEndsParameter);
    var setValue = useCallback(function (id, value) {
        return dispatch(actions.setParametersDefineParameters({
            parameters: [id],
            values: [value],
        }));
    }, [dispatch]);
    if (!tapeEnds)
        return null;
    var _a = tapeEnds, value = _a.value, options = _a.options, label = _a.label, id = _a.id;
    var items = options.map(function (option) { return option.value; });
    var labels = [label, label];
    return (_jsx(Styled.CheckBox, { items: items, itemLabels: labels, onValueChange: function (v) { return setValue(id, v); }, value: value }));
};
