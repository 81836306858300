var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defaultMetalCupMaterialTransform, } from "3d/types";
/** ---------------   **/
/**    PAB Family     **/
/** ---------------   **/
export var suctionCupsFamilyPAB = {
    PAB: __assign(__assign({ cupModelName: "PAB50", cupWidth: 0.05, cupHeight: 0.05 }, defaultMetalCupMaterialTransform.NP), { fittingModelName: "G38M01-PA" }),
    // PAB30
    "suction_cup_S.PAB30NP60.G38M.01": __assign(__assign({ cupModelName: "PAB30", cupWidth: 0.03, cupHeight: 0.03 }, defaultMetalCupMaterialTransform.NP), { fittingModelName: "G38M01-PA" }),
};
