import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTranslation } from "react-i18next";
import * as Styled from "./lifting-forces-legend.style";
import { ArrowRightIcon } from "ui/components/elements/icons/arrow-right";
import { ArrowUpIcon } from "ui/components/elements/icons/arrow-up";
import { CupIcon } from "ui/components/elements/icons/cup";
import { CustomLegend } from "ui/components/panels/graphs/common/custom-legend";
import { liftingForcesYAxisUnit } from "ui/components/panels/graphs/constants";
import { useNumberConversion } from "hooks/number-conversion";
var IconGroup = function (_a) {
    var arrow = _a.arrow, unit = _a.unit;
    return (_jsx(Styled.LegendIconContainer, { children: _jsxs(Styled.LegendIconContainerInner, { children: [arrow, _jsx(CupIcon, { width: 21, height: 14.5 }), _jsxs(Styled.LegendIconUnit, { children: ["[", unit, "]"] })] }) }));
};
export var LiftingForcesLegend = function () {
    var t = useTranslation().t;
    var convertNumber = useNumberConversion();
    // since the unit to display depends on if the application is in metric or
    // imperial mode, we use the convertNumber method to get the correct unit.
    // we pass any number as the value since we don't care about the value, just the unit.
    var unit = convertNumber(0, liftingForcesYAxisUnit).unit;
    return (_jsx(CustomLegend, { label: "".concat(t("graph_panel.lifting_forces_legend"), " (").concat(unit, ")"), extraContent: _jsxs(Styled.LegendContent, { children: [_jsx(IconGroup, { arrow: _jsx(ArrowUpIcon, { size: "18", className: Styled.arrowIconClassName }), unit: unit }), _jsx(IconGroup, { arrow: _jsx(ArrowRightIcon, { dashed: true, size: "18", className: Styled.arrowIconClassName }), unit: unit })] }) }));
};
