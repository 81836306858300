import { useEffect } from "react";
import { useAppDispatch, actions } from "store";
export var KeyBindings;
(function (KeyBindings) {
    KeyBindings["DELETE"] = "Delete";
    KeyBindings["ESCAPE"] = "Escape";
})(KeyBindings || (KeyBindings = {}));
export var useKeyBindings = function (node, keys) {
    var dispatch = useAppDispatch();
    useEffect(function () {
        if (!node)
            return;
        var handleDelAndEscKeys = function (event) {
            var shouldDelete = keys.includes(KeyBindings.DELETE) && event.key === "Delete";
            var shouldDeselect = keys.includes(KeyBindings.ESCAPE) && event.key === "Escape";
            if (shouldDelete) {
                dispatch(actions.deleteNode(node.id));
            }
            else if (shouldDeselect) {
                dispatch(actions.deselectEntities());
            }
        };
        window.addEventListener("keydown", handleDelAndEscKeys);
        return function () {
            window.removeEventListener("keydown", handleDelAndEscKeys);
        };
    }, [dispatch, keys, node]);
};
