export var NumberType;
(function (NumberType) {
    NumberType["INT"] = "int";
    NumberType["FLOAT"] = "float";
})(NumberType || (NumberType = {}));
export var ChoiceType;
(function (ChoiceType) {
    ChoiceType["DROPDOWN"] = "dropdown";
    ChoiceType["IMAGE_SELECT"] = "image-select";
    ChoiceType["SLIDER"] = "slider";
})(ChoiceType || (ChoiceType = {}));
export var SpecialGroupLayout;
(function (SpecialGroupLayout) {
    SpecialGroupLayout["CARDBOARD"] = "cardboard";
    SpecialGroupLayout["DEFAULT"] = "default";
})(SpecialGroupLayout || (SpecialGroupLayout = {}));
export var SystemPerformanceNodeType;
(function (SystemPerformanceNodeType) {
    SystemPerformanceNodeType["SUCTION_CUP"] = "suctionCup";
    SystemPerformanceNodeType["VACUUM_PUMP"] = "vacuumPump";
})(SystemPerformanceNodeType || (SystemPerformanceNodeType = {}));
