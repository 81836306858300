var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Container, useModal } from "@animech/ui";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAppDispatch, actions, useAppSelector, selectors } from "store";
import { UnitSystem } from "store/types";
import { Switch } from "ui/components/elements/switch";
import { CustomConfirmModal } from "ui/components/modals/custom-confirm-modal/custom-confirm-modal";
import { PageRoute } from "utility/url";
export var UnitSwitch = function () {
    var t = useTranslation().t;
    var dispatch = useAppDispatch();
    var openModal = useModal();
    var unit = useAppSelector(selectors.selectUnitSystem);
    var currentRoute = useLocation().pathname;
    var isSystemDesignPage = currentRoute === PageRoute.SYSTEM_DESIGN;
    var handleUnitSystemChange = useCallback(function (value) { return __awaiter(void 0, void 0, void 0, function () {
        var confirmed;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!isSystemDesignPage) {
                        dispatch(actions.setUnitSystem(value));
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, openModal(_jsx(CustomConfirmModal, { title: t("change_unit_system_prompt.title"), cancelActionLabel: t("change_unit_system_prompt.button_dismiss"), okActionLabel: t("change_unit_system_prompt.button_accept"), color: "primary", children: _jsx(Container, { children: t("change_unit_system_prompt.message") }) })).done()];
                case 1:
                    confirmed = _a.sent();
                    if (confirmed) {
                        dispatch(actions.setUnitSystem(value));
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [dispatch, isSystemDesignPage, openModal, t]);
    return (_jsx(Switch, { options: [
            { label: t("topbar.metric"), value: UnitSystem.METRIC },
            { label: t("topbar.imperial"), value: UnitSystem.IMPERIAL },
        ], value: unit, onValueChange: function (value) {
            handleUnitSystemChange(value);
        } }));
};
