var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createTransform } from "redux-persist";
import { initialState } from "./reducer";
import { getAllSelections } from "store/system-data/helpers";
export var transform = createTransform(function (inboundState) {
    var _a, _b;
    var getPendingParametersFromData = function () {
        if (!inboundState.data)
            return undefined;
        return Object.values(inboundState.data.parameters).reduce(function (acc, curr) {
            if (curr.isLocked) {
                acc[curr.id] = curr.value;
            }
            return acc;
        }, {});
    };
    var getPendingSelectionsFromData = function () {
        if (!inboundState.data)
            return undefined;
        var allSelections = getAllSelections(inboundState.data);
        return Object.keys(allSelections).reduce(function (acc, curr) {
            if (allSelections[curr]) {
                acc[curr] = allSelections[curr];
            }
            return acc;
        }, {});
    };
    var newPendingParameters = ((_a = inboundState.pending) === null || _a === void 0 ? void 0 : _a.parameters) || getPendingParametersFromData();
    var newPendingSelections = ((_b = inboundState.pending) === null || _b === void 0 ? void 0 : _b.selections) || getPendingSelectionsFromData();
    return __assign(__assign({}, initialState.dataState), { pending: {
            parameters: newPendingParameters,
            selections: newPendingSelections,
        } });
}, undefined, { whitelist: ["dataState"] });
export default transform;
