import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import * as Styled from "./custom-chart-background.style";
import { useGraphDataContext } from "providers/graph-panel";
export var ChartBackground = function (_a) {
    var children = _a.children;
    var airConsumptionGraphRef = useGraphDataContext().airConsumptionGraphRef;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var airGraphRef = airConsumptionGraphRef === null || airConsumptionGraphRef === void 0 ? void 0 : airConsumptionGraphRef.current;
    var leftXPixelCoordinate = airGraphRef === null || airGraphRef === void 0 ? void 0 : airGraphRef.state.offset.left;
    var rightXPixelCoordinate = airGraphRef === null || airGraphRef === void 0 ? void 0 : airGraphRef.state.offset.width;
    return (_jsxs(Styled.ChartBackgroundContainer, { children: [_jsx(Styled.ChartBackgroundColor, { leftXPixelCoordinate: leftXPixelCoordinate, rightXPixelCoordinate: rightXPixelCoordinate }), children] }));
};
