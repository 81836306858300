import { useEffect } from "react";
import * as pc from "playcanvas";
import { useAppDispatch, actions } from "store";
import { TubeType, } from "store/types";
import { feedPressurePointModelName, feedPressurePointPlaceholderName, feedPressurePointEntityName, } from "3d/constants/common";
import { KeyBindings, useKeyBindings } from "hooks/keybindings";
export var FeedPressurePoint = function (_a) {
    var nodeEntity = _a.nodeEntity, materialAssets = _a.materialAssets, modelAssets = _a.modelAssets, feedPressurePoint = _a.feedPressurePoint, node = _a.node;
    var dispatch = useAppDispatch();
    useKeyBindings(node, [KeyBindings.ESCAPE]);
    useEffect(function () {
        var feedPressureEntity = new pc.Entity(feedPressurePointEntityName);
        feedPressureEntity.addComponent("model", {
            type: "asset",
            asset: modelAssets[feedPressurePointModelName],
        });
        nodeEntity.addChild(feedPressureEntity);
        dispatch(actions.setNodeAttachments({
            nodeId: node.id,
            placeholders: [
                {
                    name: feedPressurePointPlaceholderName,
                    tubeType: TubeType.COMPRESSED_AIR,
                },
            ],
        }));
        return function () {
            feedPressureEntity.destroy();
        };
    }, [
        node.id,
        nodeEntity,
        materialAssets,
        modelAssets,
        feedPressurePoint.nodeId,
        dispatch,
    ]);
    return null;
};
