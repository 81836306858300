var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@emotion/styled";
import { css } from "@emotion/react";
export var CalculationTimeContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var CalculationTime = styled.p(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
export var CalculationCountdown = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
export var ButtonContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\t\t\twidth: fit-content;\n\t\t\tmargin-left: ", ";\n\n\t\t\t", " {\n\t\t\t\tfont-size: ", ";\n\t\t\t\tmargin: ", ";\n\n\t\t\t\t", " {\n\t\t\t\t\tmargin-bottom: ", ";\n\t\t\t\t}\n\t\t\t}\n\t\t"], ["\n\t\t\twidth: fit-content;\n\t\t\tmargin-left: ", ";\n\n\t\t\t", " {\n\t\t\t\tfont-size: ", ";\n\t\t\t\tmargin: ", ";\n\n\t\t\t\t", " {\n\t\t\t\t\tmargin-bottom: ", ";\n\t\t\t\t}\n\t\t\t}\n\t\t"])), theme.dimensions.margin.small, CalculationTimeContainer, theme.typography.fontSize.xSmall, theme.dimensions.margin.small, CalculationTime, theme.dimensions.margin.xxSmall);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
