import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { actions, selectors, useAppDispatch, useAppSelector } from "store";
import { PageRoute } from "utility/url";
export var useGetDataForWizardStep = function () {
    var dispatch = useAppDispatch();
    var systemDataSelectApplication = useAppSelector(selectors.selectSystemDataSelectApplication);
    var currentPath = useLocation().pathname;
    useEffect(function () {
        if (currentPath !== PageRoute.SELECT_APPLICATION)
            return;
        dispatch(actions.getSystemDataSelectApplication());
    }, [dispatch, currentPath]);
    useEffect(function () {
        if (currentPath !== PageRoute.DEFINE_PARAMETERS)
            return;
        if (!systemDataSelectApplication) {
            dispatch(actions.getSystemDataSelectApplication());
            return;
        }
        dispatch(actions.getSystemDataDefineParameters());
    }, [dispatch, currentPath, systemDataSelectApplication]);
};
