var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as pc from "playcanvas";
export var seamWidth = 0.01; // 0.1 cm
export var tapeWidth = 0.25; // 2.5 cm
/* -------------------- *
 * ----- SCALING  ----- *
 * -------------------- */
export var scaleLength = function (dimensions) {
    var height = dimensions.height, length = dimensions.length;
    return new pc.Vec3(1, height, length);
};
export var scaleLengthFlipWidth = function (dimensions) {
    var scale = scaleLength(dimensions);
    return new pc.Vec3(-scale.x, scale.y, scale.z);
};
export var scaleWidth = function (dimensions) {
    var height = dimensions.height, width = dimensions.width;
    return new pc.Vec3(width, height, 1);
};
export var scaleWidthFlipLength = function (dimensions) {
    var scale = scaleWidth(dimensions);
    return new pc.Vec3(scale.x, scale.y, -scale.z);
};
export var scaleAll = function (dimensions) {
    var width = dimensions.width, height = dimensions.height, length = dimensions.length;
    return new pc.Vec3(width, height, length);
};
export var scaleAllWithSeam = function (dimensions, centerPoint, offsetSeamWidth) {
    var offset = offsetSeamWidth ? seamWidth : 0;
    var customDimensions = __assign(__assign({}, dimensions), { width: dimensions.width * centerPoint - offset });
    return scaleAll(customDimensions);
};
export var scaleWidthWithSeam = function (dimensions, centerPoint, offsetSeamWidth) {
    var offset = offsetSeamWidth ? seamWidth : 0;
    var customDimensions = __assign(__assign({}, dimensions), { width: dimensions.width * centerPoint - offset });
    return scaleWidth(customDimensions);
};
var defaultScaleTapeOptions = {
    multiplierWidth: 0.25,
    multiplierHeight: 1,
    multiplierLength: 0,
    offsetWidth: 0,
    offsetHeight: 0,
    offsetLength: 0,
};
export var scaleTape = function (dimensions, optionsFromProps) {
    var length = dimensions.length, height = dimensions.height;
    var options = __assign(__assign({}, defaultScaleTapeOptions), (optionsFromProps && __assign({}, optionsFromProps)));
    var multiplierLength = options.multiplierLength, multiplierHeight = options.multiplierHeight, offsetLength = options.offsetLength, offsetHeight = options.offsetHeight;
    return new pc.Vec3(tapeWidth, height * multiplierHeight + offsetHeight, multiplierLength
        ? length * multiplierLength + offsetLength
        : 1 + offsetLength);
};
export var scaleTapeLength = function (dimensions) {
    var options = {
        multiplierLength: 1,
    };
    return scaleTape(dimensions, options);
};
export var scaleTapeFlipLength = function (dimensions) {
    var customDimensions = __assign(__assign({}, dimensions), { length: 1 });
    var options = {
        multiplierLength: -1,
    };
    return scaleTape(customDimensions, options);
};
/* -------------------- *
 * ---- MIRRORING  ---- *
 * -------------------- */
export var flipWidth = function (_d) {
    return new pc.Vec3(-1, 1, 1);
};
export var flipLength = function (_d) {
    return new pc.Vec3(1, 1, -1);
};
/* -------------------- *
 * --- TRANSLATING  --- *
 * -------------------- */
var defaultTranslateOptions = {
    multiplierWidth: 0,
    multiplierHeight: 0,
    multiplierLength: 0,
    offsetWidth: 0,
    offsetHeight: 0,
    offsetLength: 0,
};
export var translateWidth = function (dimensions, multiplier, offset) {
    if (multiplier === void 0) { multiplier = 1; }
    if (offset === void 0) { offset = 0; }
    var width = dimensions.width;
    return new pc.Vec3(width * multiplier + offset, 0, 0);
};
export var translateLength = function (dimensions, multiplier) {
    if (multiplier === void 0) { multiplier = 1; }
    var length = dimensions.length;
    return new pc.Vec3(0, 0, length * multiplier);
};
export var translate = function (dimensions, optionsFromProps) {
    var width = dimensions.width, length = dimensions.length, height = dimensions.height;
    var options = __assign(__assign({}, defaultTranslateOptions), optionsFromProps);
    var multiplierWidth = options.multiplierWidth, multiplierLength = options.multiplierLength, multiplierHeight = options.multiplierHeight, offsetWidth = options.offsetWidth, offsetLength = options.offsetLength, offsetHeight = options.offsetHeight;
    return new pc.Vec3(width * multiplierWidth + offsetWidth, height * multiplierHeight + offsetHeight, length * multiplierLength + offsetLength);
};
/* -------------------- *
 * ----- SCALING UV  ----- *
 * -------------------- */
var defaultUvScaleFactor = 5;
var scaleUV = function (x, y) {
    var getUvScale = function (v) {
        return v ? Math.abs(v) * defaultUvScaleFactor : defaultUvScaleFactor;
    };
    return new pc.Vec2(getUvScale(x), getUvScale(y));
};
// Corner models of the box will never be scaled and should therefore default to (defaultUvScale, defaultUvScale).
// It corresponds to (1dm, 1dm) dimensions.
export var scaleUVDefault = function (_d) {
    return new pc.Vec2(defaultUvScaleFactor, defaultUvScaleFactor);
};
export var scaleUVLength = function (dimensions) {
    var length = dimensions.length;
    return scaleUV(undefined, length);
};
export var scaleUVWidth = function (dimensions, multiplier, offset) {
    if (multiplier === void 0) { multiplier = 1; }
    if (offset === void 0) { offset = 0; }
    var width = dimensions.width;
    return scaleUV(width * multiplier + offset, undefined);
};
export var scaleUVAll = function (dimensions, multiplierWidth, offsetWidth, multiplierLength, offsetLength) {
    if (multiplierWidth === void 0) { multiplierWidth = 1; }
    if (offsetWidth === void 0) { offsetWidth = 0; }
    if (multiplierLength === void 0) { multiplierLength = 1; }
    if (offsetLength === void 0) { offsetLength = 0; }
    var width = dimensions.width, length = dimensions.length;
    return scaleUV(width * multiplierWidth + offsetWidth, length * multiplierLength + offsetLength);
};
