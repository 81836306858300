import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { cardboardSurfaceRoughnessFieldName, cardboardThroughLeakFieldName, } from "api/tacton/constants";
import { actions, selectors, useAppDispatch, useAppSelector } from "store";
import { CustomSlider } from "ui/components/elements/custom-slider/custom-slider";
export var QualitySliders = function () {
    var dispatch = useAppDispatch();
    var systemDataDefineParameters = useAppSelector(selectors.selectSystemDataDefineParameters);
    var manualCalculationInput = useAppSelector(selectors.selectManualCalculationInput);
    var manualThroughLeak = manualCalculationInput === null || manualCalculationInput === void 0 ? void 0 : manualCalculationInput.manualThroughLeak;
    var manualSurfaceRoughness = manualCalculationInput === null || manualCalculationInput === void 0 ? void 0 : manualCalculationInput.manualSurfaceRoughness;
    if (!systemDataDefineParameters)
        return null;
    var parameters = systemDataDefineParameters.parameters;
    var throughLeakParameter = parameters[cardboardThroughLeakFieldName];
    var surfaceRoughnessParameter = parameters[cardboardSurfaceRoughnessFieldName];
    var onThroughLeakValueChange = function (value) {
        dispatch(actions.setCardboardManualThroughLeak(value));
    };
    var onSurfaceRoughnessValueChange = function (value) {
        dispatch(actions.setCardboardManualSurfaceRoughness(value));
    };
    return (_jsxs(_Fragment, { children: [_jsx(QualitySlider, { parameter: throughLeakParameter, onValueChange: onThroughLeakValueChange, manualValue: manualThroughLeak, numberOfSteps: 10 }), _jsx(QualitySlider, { parameter: surfaceRoughnessParameter, onValueChange: onSurfaceRoughnessValueChange, manualValue: manualSurfaceRoughness, numberOfSteps: 8 })] }));
};
var QualitySlider = function (_a) {
    var parameter = _a.parameter, manualValue = _a.manualValue, onValueChange = _a.onValueChange, numberOfSteps = _a.numberOfSteps;
    var valueFromTacton = parameter.value, sliderMin = parameter.sliderMin, sliderMax = parameter.sliderMax, sliderMinLabel = parameter.sliderMinLabel, sliderMaxLabel = parameter.sliderMaxLabel;
    var normalizeValue = useCallback(function (value) {
        var v = Number(value);
        var range = sliderMax - sliderMin;
        return v === 0 ? 0 : (v - sliderMin) / range;
    }, [sliderMax, sliderMin]);
    var deNormalizeValue = function (value) {
        return Number(value) * (sliderMax - sliderMin) + sliderMin;
    };
    var value = useMemo(function () {
        if (manualValue !== undefined) {
            return normalizeValue(manualValue);
        }
        return normalizeValue(valueFromTacton);
    }, [manualValue, normalizeValue, valueFromTacton]);
    // The step size is divided by 4 since the slider has 4 moves per step
    var calculatedStepSize = 1 / numberOfSteps / 4;
    return (_jsx(CustomSlider, { header: parameter.label, min: 0, max: 1, stepSize: calculatedStepSize, showSteps: true, showValue: false, labelLeft: sliderMinLabel, labelRight: sliderMaxLabel, value: value, onValueChange: function (v) { return onValueChange(deNormalizeValue(v)); }, defaultValue: normalizeValue(valueFromTacton), resetValue: function () { return onValueChange(undefined); }, description: parameter.description, instantValueChange: true }));
};
