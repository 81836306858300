var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState } from "react";
import * as pc from "playcanvas";
import { objectShapes } from "3d/constants/common";
import { useAppSelector, selectors } from "store";
export var useCameraViews = function (app, camera, setPickerDisabled) {
    var selectedId = useAppSelector(selectors.selectSelectedId);
    var cameraViewId = useAppSelector(selectors.selectCameraViewId);
    var objectShapeId = useAppSelector(selectors.selectObjectShape);
    var _a = __read(useState(), 2), prevCameraViewId = _a[0], setPreviousCameraViewId = _a[1];
    //Switch view when cameraViewId changes
    useEffect(function () {
        var view = objectShapes[objectShapeId].cameraViews[cameraViewId];
        var setCameraView = function (position) {
            camera.maxDistance = view.maxDistance;
            camera.minDistance = view.minDistance;
            camera.setNextView(view.rotx, view.roty, view.distance, position, 0.5);
        };
        if (view.useNodePosition && selectedId) {
            var entity = app.root.findByName(selectedId);
            if (!entity)
                return;
            var position = entity.getPosition();
            setCameraView(position);
        }
        else {
            // This check is used to avoid triggering the camera render stack when position haven't actually changed
            if (cameraViewId === prevCameraViewId)
                return;
            var position = new pc.Vec3(view.x || 0, view.y || 0, view.z || 0);
            setCameraView(position);
        }
        setPreviousCameraViewId(cameraViewId);
        camera.allowOrbitInteraction = !view.locked;
        setPickerDisabled(!view.locked);
    }, [app, cameraViewId, selectedId, objectShapeId, camera, setPickerDisabled]);
};
