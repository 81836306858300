import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useEffect, useMemo } from "react";
import { Routes as BrowserRoutes, Route } from "react-router-dom";
import { ModalRoot, NotificationRoot, TooltipRoot } from "@animech/ui";
import { DefineSystemParametersPage, SelectApplicationPage, } from "./pages/parameters/parameters";
import { LoadingPanel } from "ui/components/panels/loading";
import { SystemDesignPage, IntroPage } from "ui/pages";
import { useConfigIdContext } from "providers/config-id";
import { StoreProvider, getStore } from "store";
import { useURLParam } from "hooks/url";
import { DelayedFallback } from "ui/utility/delayed-fallback";
import { ErrorPanel } from "ui/components/panels/error";
import { PageRoute, getURLParameter } from "utility/url";
import { Auth0AuthenticationGuard } from "auth0/auth0-authentication-guard";
import { PlaycanvasApplicationProvider } from "providers/playcanvas-application";
import { CollisionsProvider } from "providers/collisions";
var Routes = function () {
    var _a = useConfigIdContext(), configId = _a.configId, ticket = _a.ticket, isLoading = _a.isLoading, error = _a.error, startConfig = _a.startConfig;
    var computationApiBaseUrl = useURLParam("computation-base-url");
    /* If a user adds a ticket to the URL, this component will re-render but the ConfigIdProvider won't.
     * That's why it's important that we fetch the ticket in this component then start a new config
     * everytime the ticket changes. It solves an issue where the user can get confused by a false re-render
     * that doesn't actually restart the tacton session with a new ticket. */
    var ticketFromUrl = getURLParameter("ticket");
    useEffect(function () {
        startConfig(ticketFromUrl);
    }, [startConfig, ticketFromUrl]);
    var store = useMemo(function () {
        if (!configId)
            return;
        var _a = getStore(configId, ticket, computationApiBaseUrl), store = _a.store, persistor = _a.persistor;
        return { store: store, persistor: persistor };
    }, [configId, ticket, computationApiBaseUrl]);
    var withProviderAndRoots = function (component) { return (_jsx(DelayedFallback, { isLoading: isLoading, fallback: _jsx(LoadingPanel, {}), children: store && (_jsx(StoreProvider, { store: store.store, persistor: store.persistor, children: _jsx(PlaycanvasApplicationProvider, { children: _jsxs(CollisionsProvider, { children: [component, _jsx(ModalRoot, { zIndex: 7 }), _jsx(TooltipRoot, { zIndex: 8 }), _jsx(NotificationRoot, { zIndex: 5, rootAttributes: { id: "notification-root" } })] }) }) })) })); };
    if (error) {
        return _jsx(ErrorPanel, {});
    }
    return (_jsxs(BrowserRoutes, { children: [_jsx(Route, { path: "/", element: withProviderAndRoots(_jsx(IntroPage, {})) }), _jsx(Route, { path: PageRoute.SELECT_APPLICATION, element: _jsx(Auth0AuthenticationGuard, { component: function () { return withProviderAndRoots(_jsx(SelectApplicationPage, {})); }, returnTo: PageRoute.SELECT_APPLICATION }) }), _jsx(Route, { path: PageRoute.DEFINE_PARAMETERS, element: _jsx(Auth0AuthenticationGuard, { component: function () {
                        return withProviderAndRoots(_jsx(DefineSystemParametersPage, {}));
                    }, returnTo: PageRoute.DEFINE_PARAMETERS }) }), _jsx(Route, { path: PageRoute.SYSTEM_DESIGN, element: _jsx(Auth0AuthenticationGuard, { component: function () { return withProviderAndRoots(_jsx(SystemDesignPage, {})); }, returnTo: PageRoute.SYSTEM_DESIGN }) })] }));
};
export default Routes;
