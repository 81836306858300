import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { PerformanceTooltip, } from "./performance-tooltip";
import { tubeConnectionModels } from "3d/constants/common";
import { useAppDispatch, actions } from "store";
import { TubeConnectionId, } from "store/types";
import { TooltipInput, TooltipInputType, } from "ui/components/elements/input/tooltip-input";
import { round, Unit } from "utility/number";
export var ToolChangerTooltip = function (_a) {
    var node = _a.node, x = _a.x, y = _a.y, _b = _a.align, align = _b === void 0 ? "left" : _b;
    var t = useTranslation().t;
    var dispatch = useAppDispatch();
    var tubeConnection = node.component;
    var toolchangerDiameter = tubeConnection.diameters[0];
    var tubeConnectionModel = tubeConnectionModels[tubeConnection.type];
    var onChangeDiameter = function (diameter) {
        var roundedDiameter = Math.round(diameter * 2) / 2;
        dispatch(actions.setTubeConnectionDiameters({
            nodeIds: [node.id],
            diameters: [roundedDiameter / 1000],
        }));
    };
    if (isTubeConnection(node.component) &&
        node.component.type === TubeConnectionId.TOOL_CHANGER) {
        return (_jsx(PerformanceTooltip, { x: x, y: y, title: t("canvas.tube_connection_title"), align: align, showValidityMessage: false, children: _jsx(TooltipInput, { label: "".concat(t("canvas.tube_connection_diameter"), ":"), parameterType: TooltipInputType.NUMBER_INPUT, parameter: {
                    value: round(toolchangerDiameter * 1000),
                    min: tubeConnectionModel.diameterRange[0] * 1000,
                    max: tubeConnectionModel.diameterRange[1] * 1000,
                    unit: Unit.MM,
                    type: "float",
                    setValue: function (value) {
                        onChangeDiameter(value);
                    },
                } }) }, "tube-connection"));
    }
    else
        return null;
};
function isTubeConnection(component) {
    return component.tubeType !== undefined;
}
