var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import * as Styled from "./parameters.style";
import { useAppSelector, selectors } from "store";
import { WizardPanel } from "ui/components/panels/wizard";
import { LoadingOverlay } from "ui/components/elements/loading-overlay";
import { DelayedFallback } from "ui/utility/delayed-fallback";
import { Logo } from "ui/components/elements/logo";
import { useConflictHandler } from "hooks/conflict-handler";
import { PageRoute } from "utility/url";
import { TactonStepName } from "api/tacton/types";
import { useGetDataForWizardStep } from "hooks/get-system-data";
import { UnitSwitch } from "ui/components/elements/unit-switch/unit-switch";
var wizardPanelProps = (_a = {},
    _a[TactonStepName.DEFINE_PARAMETERS] = {
        nextRoute: PageRoute.SYSTEM_DESIGN,
        previousRoute: PageRoute.SELECT_APPLICATION,
        wizardProps: {
            nextLabel: "wizard.button_next",
            previousLabel: "wizard.button_previous",
        },
        titleID: "wizard.define_application_parameters_title",
    },
    _a[TactonStepName.SELECT_APPLICATION] = {
        nextRoute: PageRoute.DEFINE_PARAMETERS,
        wizardProps: {
            nextLabel: "wizard.button_select",
            previousLabel: "wizard.button_previous",
        },
        titleID: "wizard.select_application_title",
    },
    _a);
export var ParametersPage = function (props) {
    var step = props.step, showUnitSwitch = props.showUnitSwitch;
    var isLoading = useAppSelector(selectors.selectIsSystemDataLoading);
    useConflictHandler();
    useGetDataForWizardStep();
    return (_jsxs(_Fragment, { children: [_jsxs(Styled.Logo, { children: [_jsx(Logo, { applicationSelectionIsDisabled: true }), showUnitSwitch && _jsx(UnitSwitch, {})] }), _jsxs(Styled.Content, { children: [_jsx(Styled.GroupContainer, { children: _jsx(WizardPanel, __assign({}, wizardPanelProps[step])) }), _jsx(DelayedFallback, { isLoading: isLoading, fallback: _jsx(LoadingOverlay, {}) })] })] }));
};
export var DefineSystemParametersPage = function () {
    return (_jsx(Styled.ParametersPage, { children: _jsx(ParametersPage, { step: TactonStepName.DEFINE_PARAMETERS, showUnitSwitch: true }) }));
};
export var SelectApplicationPage = function () {
    return (_jsx(Styled.SelectApplicationPage, { children: _jsx(ParametersPage, { step: TactonStepName.SELECT_APPLICATION }) }));
};
