import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { H6 } from "@animech/ui";
import * as Styled from "./energy-usage-box.style";
import { SimpleBox } from "./simple-box";
import * as StyledShared from "./shared.style";
import { useAppSelector, selectors } from "store";
import { InfoButton } from "ui/components/elements/info-button";
import { ElectricTowerIcon } from "ui/components/elements/icons/electric-tower";
import { CompressorIcon } from "ui/components/elements/icons/compressor";
import { Unit } from "utility/number";
import { MessageLevel, MessagesPopover, } from "ui/components/elements/messages-popover";
import { useSystemPerformanceValidityMessage } from "hooks/messages";
import { useNumberConversion } from "hooks/number-conversion";
export var EnergyUsageBox = function (_a) {
    var isOpen = _a.isOpen;
    var t = useTranslation().t;
    var isSystemDataLoading = useAppSelector(selectors.selectIsSystemDataLoading);
    var isSystemPerformanceLoading = useAppSelector(selectors.selectIsPerformanceLoading);
    var dashboardData = useAppSelector(selectors.selectDashboardData);
    var systemPerformanceMessage = useSystemPerformanceValidityMessage();
    return (_jsx(Styled.EnergyUsageRoot, { isOpen: isOpen, children: _jsx(SimpleBox, { isLoading: isSystemDataLoading || isSystemPerformanceLoading, roundedCorners: "all", children: _jsxs(Styled.Grid, { children: [_jsxs(Styled.GridMain, { children: [_jsx(TitleWithIcon, { icon: _jsx(ElectricTowerIcon, {}), title: t("dashboard.power_consumption") }), _jsx(Styled.ItalicText, { children: t("dashboard.energy_usage_per_day") }), _jsx(EnergyUsageValue, { value: dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.energyUsage.perDay.value, unit: Unit.KWH }), _jsx(TitleWithIcon, { icon: _jsx(CompressorIcon, {}), title: t("dashboard.energy_usage_compressor_usage") }), _jsx(EnergyUsageValue, { value: dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.energyUsage.compressorPower.value, unit: Unit.W })] }), _jsxs(Styled.GridSide, { children: [_jsx(InfoButton, { title: t("dashboard.power_consumption"), description: {
                                    long: t("descriptions.dashboard.energy_usage"),
                                } }), systemPerformanceMessage && (_jsx(MessagesPopover, { messages: [systemPerformanceMessage], level: MessageLevel.WARNING, toggleEvent: "hover", size: "small" }))] })] }) }) }));
};
var EnergyUsageValue = function (_a) {
    var value = _a.value, unit = _a.unit;
    var convertNumber = useNumberConversion();
    var convertedValue = value ? convertNumber(value, unit).value : "-";
    return (_jsxs("span", { children: [convertedValue, " ", unit] }));
};
var TitleWithIcon = function (_a) {
    var icon = _a.icon, title = _a.title;
    return (_jsxs(StyledShared.TitleContainer, { children: [icon, _jsxs(H6, { children: [" ", title, " "] })] }));
};
