var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { CanvasEffects } from "./effects";
import { Node } from "./node";
import { Edge } from "./edge/edge";
import { ApplicationObject } from "./application-object";
import { CardboardBuilder } from "./cardboard-builder/cardboard-builder";
import { SelectionRectangle } from "./selection-rectangle/selection-rectangle";
import { useAppSelector, selectors } from "store";
import { usePlaycanvasApplicationContext } from "providers/playcanvas-application";
import { usePicker } from "hooks/playcanvas/picker";
import { trimModelName } from "3d/helpers";
import { LoadingOverlay } from "ui/components/elements/loading-overlay";
import { DelayedFallback } from "ui/utility/delayed-fallback";
import { TactonApplication } from "api/tacton/types";
export var CanvasPanel = function () {
    var _a = usePlaycanvasApplicationContext(), canvasRef = _a.canvasRef, isLoading = _a.isLoading, app = _a.app, camera = _a.camera;
    var _b = usePicker(app, canvasRef), setPickerDisabled = _b.setPickerDisabled, movingNodeIds = _b.movingNodeIds, hoverId = _b.hoverId, selectionRectangle = _b.selectionRectangle;
    var _c = __read(useState(), 2), hoveredNodeTooltipId = _c[0], setHoveredNodeTooltipId = _c[1];
    var activeApplication = useAppSelector(selectors.selectActiveApplication);
    var isCardboardApplication = activeApplication === TactonApplication.CARDBOARD;
    var nodes = useAppSelector(selectors.selectNodes);
    var edges = useAppSelector(selectors.selectEdges);
    var modelAssets = useMemo(function () {
        return app === null || app === void 0 ? void 0 : app.assets.filter(function (asset) {
            return asset.type === "model" &&
                !asset.name.toLowerCase().endsWith(".model");
        }).reduce(function (result, currentAsset) {
            result[trimModelName(currentAsset.name)] = currentAsset;
            return result;
        }, {});
    }, [app]);
    var materialAssets = useMemo(function () {
        return app === null || app === void 0 ? void 0 : app.assets.filter(function (asset) { return asset.type === "material"; }).reduce(function (result, currentAsset) {
            result[trimModelName(currentAsset.name)] = currentAsset;
            return result;
        }, {});
    }, [app]);
    var canRender = !isLoading && app && modelAssets && materialAssets && camera;
    var setNodeTooltipHovered = useCallback(function (nodeId) {
        setHoveredNodeTooltipId(nodeId);
    }, []);
    return (_jsxs("div", { css: { height: "100%", width: "100%" }, children: [canRender && (_jsxs(_Fragment, { children: [Object.values(nodes).map(function (node) { return (_jsx(Node, { app: app, modelAssets: modelAssets, materialAssets: materialAssets, node: node, movingNodeIds: movingNodeIds, hoverId: hoverId, onTooltipHover: setNodeTooltipHovered, hoveredNodeTooltipId: hoveredNodeTooltipId }, node.id)); }), Object.values(edges).map(function (edge) { return (_jsx(Edge, { app: app, modelAssets: modelAssets, materialAssets: materialAssets, edge: edge, movingNodeIds: movingNodeIds, hoverId: hoverId }, edge.id)); }), isCardboardApplication ? (_jsx(CardboardBuilder, { app: app, modelAssets: modelAssets, materialAssets: materialAssets })) : (_jsx(ApplicationObject, { app: app, modelAssets: modelAssets, materialAssets: materialAssets })), _jsx(CanvasEffects, { app: app, camera: camera, setPickerDisabled: setPickerDisabled }), selectionRectangle && (_jsx(SelectionRectangle, { selectionRectangle: selectionRectangle }))] })), _jsx("canvas", { ref: canvasRef, css: {
                    height: "100%",
                    width: "100%",
                    display: isLoading ? "none" : "inherit",
                    outline: "none",
                } }), _jsx(DelayedFallback, { isLoading: isLoading, fallback: _jsx(LoadingOverlay, {}) })] }));
};
