var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef, useState, } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import * as Styled from "./stepper-input.style";
import { selectors, useAppSelector } from "store";
import { MIXED_VALUES_CONSTANT, tubeDiameterOptions, UnitSystem, } from "store/types";
import { Unit } from "utility/number";
var CounterButton = function (_a) {
    var onClick = _a.onClick, enabled = _a.enabled, iconName = _a.iconName, isTooltip = _a.isTooltip;
    return (_jsx(Styled.CounterButton, { onClick: onClick, children: _jsx(Styled.Icon, { name: iconName, color: enabled ? "primary" : "neutral", scale: isTooltip ? 1 : 1.2 }) }));
};
var throttleUpdateValue = _.throttle(function (diameter, diameterIndex, onValueChange) {
    onValueChange(diameter, diameterIndex);
}, 200);
var getFormattedLabel = function (option) {
    return "".concat(option.outerLabel, " | ").concat(option.innerLabel);
};
export var StepperInput = function (_a) {
    var label = _a.label, diameterIndexes = _a.diameterIndexes, onValueChange = _a.onValueChange, _b = _a.isTooltip, isTooltip = _b === void 0 ? false : _b;
    var t = useTranslation().t;
    var unitSystem = useAppSelector(selectors.selectUnitSystem);
    var _c = __read(useState(false), 2), isFocused = _c[0], setIsFocused = _c[1];
    var optionsArray = tubeDiameterOptions[unitSystem];
    var inMixedState = diameterIndexes.some(function (index) { return index !== diameterIndexes[0]; });
    var minIndex = Math.min.apply(Math, __spreadArray([], __read(diameterIndexes), false));
    var maxIndex = Math.max.apply(Math, __spreadArray([], __read(diameterIndexes), false));
    var middleIndex = Math.floor((minIndex + maxIndex) / 2);
    var selectedIndex = inMixedState ? -1 : diameterIndexes[0];
    var previousIndex = inMixedState ? middleIndex : selectedIndex - 1;
    var nextIndex = inMixedState ? middleIndex + 1 : selectedIndex + 1;
    var currentOptionLabel = inMixedState
        ? t(MIXED_VALUES_CONSTANT)
        : getFormattedLabel(optionsArray[selectedIndex]);
    var previousOptionLabel = optionsArray[previousIndex]
        ? getFormattedLabel(optionsArray[previousIndex])
        : undefined;
    var nextOptionLabel = optionsArray[nextIndex]
        ? getFormattedLabel(optionsArray[nextIndex])
        : undefined;
    var increase = useCallback(function () {
        var canIncrease = previousIndex > -1;
        if (!canIncrease)
            return;
        throttleUpdateValue(optionsArray[previousIndex].value, previousIndex, onValueChange);
    }, [onValueChange, optionsArray, previousIndex]);
    var decrease = useCallback(function () {
        var canDecrease = nextIndex < optionsArray.length;
        if (!canDecrease)
            return;
        throttleUpdateValue(optionsArray[nextIndex].value, nextIndex, onValueChange);
    }, [nextIndex, onValueChange, optionsArray]);
    var inputRef = useRef(null);
    useEffect(function () {
        var handleOnWheel = function (event) {
            if (inputRef.current === document.activeElement) {
                event.preventDefault();
                event.stopPropagation();
                setIsFocused(true);
                if (event.deltaY > 0) {
                    increase();
                }
                else {
                    decrease();
                }
            }
        };
        document.body.addEventListener("wheel", handleOnWheel, { passive: false });
        return function () {
            document.body.removeEventListener("wheel", handleOnWheel);
        };
    }, [decrease, increase, inputRef]);
    var StepperContent = useMemo(function () {
        return (_jsxs(_Fragment, { children: [_jsxs(Styled.ValueWrapper, { children: [!isTooltip && (_jsx(Styled.PreviousValue, { children: previousOptionLabel })), _jsx(Styled.CurrentValue, { children: currentOptionLabel }), !isTooltip && _jsx(Styled.NextValue, { children: nextOptionLabel })] }), _jsxs(Styled.ButtonsAndUnit, { children: [_jsxs(Styled.Buttons, { children: [_jsx(CounterButton, { onClick: increase, enabled: isFocused && previousOptionLabel !== undefined, iconName: "chevron-up-bold", isTooltip: isTooltip }), _jsx(CounterButton, { onClick: decrease, enabled: isFocused && nextOptionLabel !== undefined, iconName: "chevron-down-bold", isTooltip: isTooltip })] }), _jsx(Styled.Unit, { children: unitSystem === UnitSystem.METRIC ? Unit.MM : Unit.IN })] })] }));
    }, [
        currentOptionLabel,
        decrease,
        increase,
        isFocused,
        isTooltip,
        nextOptionLabel,
        previousOptionLabel,
        unitSystem,
    ]);
    return (_jsx(Styled.InputContainer, { label: label, children: _jsx("span", { onBlur: function () { return setIsFocused(false); }, onFocus: function () { return setIsFocused(true); }, children: isTooltip ? (_jsx(Styled.TooltipInput, { tabIndex: 0, ref: inputRef, unitSystem: unitSystem, children: StepperContent })) : (_jsx(Styled.Input, { tabIndex: 0, ref: inputRef, children: StepperContent })) }) }));
};
