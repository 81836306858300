var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState, useEffect, useCallback } from "react";
import * as pc from "playcanvas";
import { SceneEngine } from "@animech/platform";
import { getComponent } from "@animech/platform/utilities/playcanvas";
import { Camera } from "@animech/playcanvas-scripts";
export var usePlaycanvasApplication = function (canvasRef) {
    var _a = __read(useState(true), 2), isLoading = _a[0], setIsLoading = _a[1];
    var _b = __read(useState(undefined), 2), sceneEngine = _b[0], setSceneEngine = _b[1];
    var _c = __read(useState(undefined), 2), camera = _c[0], setCamera = _c[1];
    var _d = __read(useState(false), 2), isCameraMoving = _d[0], setIsCameraMoving = _d[1];
    var onRenderStackChange = useCallback(function (renderStack) {
        setIsCameraMoving(renderStack !== 0);
    }, []);
    useEffect(function () {
        if (!(canvasRef === null || canvasRef === void 0 ? void 0 : canvasRef.current) || sceneEngine)
            return;
        var initScene = function (canvas) { return __awaiter(void 0, void 0, void 0, function () {
            var se, app, cameraEntity, cameraScript, camera;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsLoading(true);
                        se = new SceneEngine();
                        return [4 /*yield*/, se.init(canvas, "playcanvas/config.json", "1369166.json", {
                                assetPrefix: "playcanvas/",
                            })];
                    case 1:
                        _a.sent();
                        setSceneEngine(se);
                        app = se.Application;
                        app.root.name = "RootNode";
                        pc.registerScript(Camera);
                        cameraEntity = app.root.findByName("Camera");
                        cameraScript = getComponent(cameraEntity, "script");
                        if (!cameraScript) {
                            cameraScript = cameraEntity.addComponent("script", {});
                        }
                        camera = cameraScript.create("Camera");
                        camera.allowPanInteraction = false;
                        camera.minDistance = 0.25;
                        camera.minElevation = -0.1;
                        camera.maxElevation = 100;
                        camera.onRenderStackChanged = onRenderStackChange;
                        setCamera(camera);
                        setIsLoading(false);
                        return [2 /*return*/];
                }
            });
        }); };
        initScene(canvasRef.current);
    }, [sceneEngine, canvasRef]);
    return {
        isLoading: isLoading,
        app: sceneEngine === null || sceneEngine === void 0 ? void 0 : sceneEngine.Application,
        sceneEngine: sceneEngine,
        camera: camera,
        isCameraMoving: isCameraMoving,
    };
};
