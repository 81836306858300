/**
 * This file is copied from @animech/ui as it is not exported
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { Children } from "react";
import { getElementsOfType, TreeNavItem } from "@animech/ui";
/**
 * Find TreeNavItemElement matching a given Id in child tree.
 */
export function getTreeNavItemElement(id, children, parent) {
    var e_1, _a;
    var childList = Children.toArray(children !== null && children !== void 0 ? children : []);
    if (childList.length === 0) {
        return undefined;
    }
    var items = getElementsOfType(childList, TreeNavItem).map(function (item) { return (__assign(__assign({}, item), { parent: parent })); });
    try {
        for (var items_1 = __values(items), items_1_1 = items_1.next(); !items_1_1.done; items_1_1 = items_1.next()) {
            var item = items_1_1.value;
            if (item.props.id === id) {
                return item;
            }
            var childItem = getTreeNavItemElement(id, item.props.children, item);
            if (childItem) {
                return childItem;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (items_1_1 && !items_1_1.done && (_a = items_1.return)) _a.call(items_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return undefined;
}
/**
 * Get depth of TreeNavItemElement matching a given Id in child tree.
 */
export function getTreeNavItemDepth(id, children, currentDepth) {
    var e_2, _a;
    if (currentDepth === void 0) { currentDepth = 1; }
    var childList = Children.toArray(children !== null && children !== void 0 ? children : []);
    if (childList.length === 0) {
        return undefined;
    }
    var items = getElementsOfType(childList, TreeNavItem);
    try {
        for (var items_2 = __values(items), items_2_1 = items_2.next(); !items_2_1.done; items_2_1 = items_2.next()) {
            var item = items_2_1.value;
            if (item.props.id === id) {
                return currentDepth;
            }
            var childDepth = getTreeNavItemDepth(id, item.props.children, currentDepth + 1);
            if (childDepth !== undefined) {
                return childDepth;
            }
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (items_2_1 && !items_2_1.done && (_a = items_2.return)) _a.call(items_2);
        }
        finally { if (e_2) throw e_2.error; }
    }
    return undefined;
}
