/** Extracts the items and itemLabels from an OptionList. */
export function extractOptionList(options) {
    return options.reduce(function (acc, option) {
        acc.items.push(option.value);
        acc.itemLabels.push(option.label);
        return acc;
    }, {
        items: [],
        itemLabels: [],
    });
}
