import { useMemo } from "react";
import { EditButtonIcon, } from "ui/components/elements/edit-buttons/edit-buttons";
import { CameraViewId } from "3d/types";
import { useAppDispatch, useAppSelector, selectors, actions } from "store";
import { NodeComponentType } from "store/types";
export var useNodeActions = function (node) {
    var dispatch = useAppDispatch();
    var isZoomedIn = useAppSelector(selectors.selectIsZoomedIn(node.id));
    var canAddGripPosition = useAppSelector(selectors.selectCanAddGripPosition);
    var canAddCentralizedPump = useAppSelector(selectors.selectCanAddCentralizedPump);
    var canAddTubeConnection = useAppSelector(selectors.selectCanAddTubeConnection);
    var canDuplicate = useMemo(function () {
        if (node.type === NodeComponentType.GRIP_POSITION) {
            return canAddGripPosition;
        }
        else if (node.type === NodeComponentType.CENTRALIZED_VACUUM_PUMP) {
            return canAddCentralizedPump;
        }
        else if (node.type === NodeComponentType.TUBE_CONNECTION) {
            return canAddTubeConnection;
        }
        else {
            return false;
        }
    }, [
        node.type,
        canAddGripPosition,
        canAddCentralizedPump,
        canAddTubeConnection,
    ]);
    var rotateClockwiseAction = {
        icon: EditButtonIcon.ROTATE_CLOCKWISE,
        action: function () {
            return dispatch(actions.setNodeRotation({
                nodeId: node.id,
                rotation: node.rotationY - 45,
            }));
        },
    };
    var rotateCounterClockwiseAction = {
        icon: EditButtonIcon.ROTATE_COUNTER_CLOCKWISE,
        action: function () {
            return dispatch(actions.setNodeRotation({
                nodeId: node.id,
                rotation: node.rotationY + 45,
            }));
        },
    };
    var deleteAction = {
        icon: EditButtonIcon.DELETE,
        action: function () { return dispatch(actions.deleteNode(node.id)); },
        isDelete: true,
    };
    var duplicateAction = {
        icon: EditButtonIcon.DUPLICATE,
        action: function () { return dispatch(actions.duplicateNode(node.id)); },
    };
    var zoomAction = {
        icon: isZoomedIn ? EditButtonIcon.ZOOM_OUT : EditButtonIcon.ZOOM_IN,
        action: function () {
            dispatch(actions.setCameraView(isZoomedIn ? CameraViewId.EDIT_VIEW : CameraViewId.NODE_VIEW));
        },
    };
    var returnActions = [
        rotateCounterClockwiseAction,
        rotateClockwiseAction,
        canDuplicate ? duplicateAction : undefined,
        zoomAction,
        node.type === NodeComponentType.FEED_PRESSURE_POINT
            ? undefined
            : deleteAction,
    ].filter(Boolean);
    return returnActions;
};
