var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import * as Styled from "./custom-lifting-forces-graph-tooltip.style";
import { isVerticalForce } from "./helper-functions";
import { useNumberConversion } from "hooks/number-conversion";
import { xAxisUnit } from "ui/components/panels/graphs/constants";
import { ArrowRightIcon } from "ui/components/elements/icons/arrow-right";
import { ArrowUpIcon } from "ui/components/elements/icons/arrow-up";
export var CustomLiftingForcesGraphTooltip = function (_a) {
    var active = _a.active, payload = _a.payload, label = _a.label, xLabel = _a.xLabel, yLabel = _a.yLabel, yUnit = _a.yUnit;
    var convertNumber = useNumberConversion();
    var t = useTranslation().t;
    var selectedSystemMinMax = useMemo(function () {
        var e_1, _a;
        if (!payload)
            return;
        var verticalMin = Number.MAX_SAFE_INTEGER;
        var verticalMax = Number.MIN_SAFE_INTEGER;
        var horizontalMin = Number.MAX_SAFE_INTEGER;
        var horizontalMax = Number.MIN_SAFE_INTEGER;
        try {
            for (var payload_1 = __values(payload), payload_1_1 = payload_1.next(); !payload_1_1.done; payload_1_1 = payload_1.next()) {
                var element = payload_1_1.value;
                if (isVerticalForce(element.dataKey)) {
                    verticalMin = Math.min(element.value, verticalMin);
                    verticalMax = Math.max(element.value, verticalMax);
                }
                else {
                    horizontalMin = Math.min(element.value, horizontalMin);
                    horizontalMax = Math.max(element.value, horizontalMax);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (payload_1_1 && !payload_1_1.done && (_a = payload_1.return)) _a.call(payload_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        var formatNumber = function (value) {
            return convertNumber(value, yUnit).formatted;
        };
        return {
            vertical: [formatNumber(verticalMin), formatNumber(verticalMax)],
            horizontal: [formatNumber(horizontalMin), formatNumber(horizontalMax)],
        };
    }, [convertNumber, payload, yUnit]);
    if (active &&
        payload &&
        payload.length &&
        payload[0].value !== undefined &&
        selectedSystemMinMax) {
        var IndentedParagraph = function (_a) {
            var label = _a.label, value = _a.value;
            return (_jsxs(Styled.SystemMinMaxParagraph, { children: [label, ": ", _jsx("strong", { children: value })] }));
        };
        var verticalLabel = (_jsxs(_Fragment, { children: [_jsx(ArrowUpIcon, { size: "12" }), " ", t("graph_panel.lifting_forces_tooltip_y_label_vertical")] }));
        var horizontalLabel = (_jsxs(_Fragment, { children: [_jsx(ArrowRightIcon, { size: "12" }), " ", t("graph_panel.lifting_forces_tooltip_y_label_horizontal")] }));
        return (_jsxs(Styled.CustomLiftForceTooltip, { children: [_jsx("p", { children: "".concat(xLabel, ": ").concat(convertNumber(label, xAxisUnit).formatted) }), _jsx("p", { children: _jsx("strong", { children: yLabel }) }), _jsx(PayloadList, { payload: payload, yUnit: yUnit }), _jsxs(Styled.SystemMinMaxContainer, { children: [_jsxs(Styled.ZeroMarginParagraph, { children: [t("graph_panel.selected_system_min"), ":"] }), _jsx(IndentedParagraph, { label: verticalLabel, value: selectedSystemMinMax.vertical[0] }), _jsx(IndentedParagraph, { label: horizontalLabel, value: selectedSystemMinMax.horizontal[0] })] }), _jsxs(Styled.SystemMinMaxContainer, { children: [_jsxs(Styled.ZeroMarginParagraph, { children: [t("graph_panel.selected_system_max"), ":"] }), _jsx(IndentedParagraph, { label: verticalLabel, value: selectedSystemMinMax.vertical[1] }), _jsx(IndentedParagraph, { label: horizontalLabel, value: selectedSystemMinMax.horizontal[1] })] })] }));
    }
    return null;
};
var PayloadList = function (_a) {
    var payload = _a.payload, yUnit = _a.yUnit;
    var t = useTranslation().t;
    var maxEntries = 6;
    var list = payload
        .slice(0, maxEntries)
        .map(function (entry) { return (_jsx(PayloadEntry, { entry: entry, yUnit: yUnit }, entry.dataKey)); });
    var andMore = payload.length > maxEntries && (_jsx("p", { children: t("graph_panel.and_number_more", {
            number: payload.length - maxEntries,
        }) }));
    return (_jsxs(_Fragment, { children: [list, andMore] }));
};
var PayloadEntry = function (_a) {
    var entry = _a.entry, yUnit = _a.yUnit;
    var t = useTranslation().t;
    var convertNumber = useNumberConversion();
    var isVertical = isVerticalForce(entry.dataKey);
    var ArrowIcon = isVertical ? ArrowUpIcon : ArrowRightIcon;
    var prefixedLabel = isVertical
        ? t("graph_panel.lifting_forces_tooltip_y_label_vertical")
        : t("graph_panel.lifting_forces_tooltip_y_label_horizontal");
    return (_jsxs("p", { children: [_jsx(ArrowIcon, { size: "12", color: entry.color }), " ".concat(prefixedLabel, " - ").concat(entry.name, ": ").concat(convertNumber(entry.value, yUnit).formatted)] }, entry.dataKey));
};
