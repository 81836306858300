var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Line, LineChart, YAxis, CartesianGrid, XAxis, Legend, ReferenceLine, Tooltip, } from "recharts";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { graphMargins, graphWidth, defaultXAxisProps, liftingForcesGraphHeight, yAxisDyTSpan, yAxisPadding, liftingForcesYAxisHorizontalKey, liftingForcesYAxisVerticalKey, defaultGraphStrokeWidth, liftingForcesYAxisUnit, liftingForceTooltipPosition, defaultYAxisProps, xAxisKey, } from "./constants";
import { VerticalReferenceLines } from "./common/reference-lines";
import { ZoomHighlightedArea } from "./common/custom-reference-area";
import { CustomXAxisTick } from "./common/custom-x-axis-tick";
import { useCustomLegendWrapperStyle } from "./common/custom-legend";
import { useTimeDataMinMaxDomain } from "hooks/graph-panel/graph";
import { selectors, useAppSelector } from "store";
import { useNumberConversion } from "hooks/number-conversion";
import { CustomYAxisTick } from "ui/components/panels/graphs/common/custom-y-axis-tick";
import { CustomLiftingForcesGraphTooltip } from "ui/components/panels/graphs/common/custom-lifting-forces-graph-tooltip";
import { LiftingForcesLegend } from "ui/components/panels/graphs/lifting-forces-legend";
import { useGraphDataContext } from "providers/graph-panel";
import { useGraphLabels } from "hooks/graph-panel/graph-labels";
import { UnitSystem } from "store/types";
import { conversions } from "utility/number";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var YAxisTick = function (props) {
    var x = props.x, payload = props.payload;
    var convertNumber = useNumberConversion();
    var convertedValue = convertNumber(payload.value, liftingForcesYAxisUnit);
    var label = (_jsx("tspan", { x: x, dx: 0, dy: yAxisDyTSpan, children: convertedValue.value }));
    return _jsx(CustomYAxisTick, __assign({}, props, { labelElements: label }));
};
export var LiftingForcesGraph = function () {
    var t = useTranslation().t;
    var xAxisDomain = useTimeDataMinMaxDomain();
    var unitSystem = useAppSelector(selectors.selectUnitSystem);
    var graphData = useAppSelector(selectors.selectGraphData);
    var parsedGraphData = useAppSelector(selectors.selectParsedGraphData);
    var showEnergySave = useAppSelector(selectors.selectShowEnergySave);
    var systemProperty = graphData.systemProperty;
    var esIndex = systemProperty.esIndex, atmIndex = systemProperty.atmIndex, psIndex = systemProperty.psIndex, timeData = systemProperty.timeData;
    var _a = useGraphDataContext(), pumpsAndCupsNodeTree = _a.pumpsAndCupsNodeTree, selectedNodes = _a.selectedNodes, onMouseDown = _a.onMouseDown, onMouseMove = _a.onMouseMove, onMouseUp = _a.onMouseUp, zoomedLiftForceDomain = _a.zoomedLiftForceDomain, zoomedTimeDomain = _a.zoomedTimeDomain;
    var getSuctionCupLabel = useGraphLabels().getSuctionCupLabel;
    var customLegendWrapperStyle = useCustomLegendWrapperStyle(liftingForcesGraphHeight);
    var selectedNodesCount = useMemo(function () {
        return Object.values(selectedNodes !== null && selectedNodes !== void 0 ? selectedNodes : {}).reduce(function (acc, isSelected) { return (isSelected ? acc + 1 : acc); }, 0);
    }, [selectedNodes]);
    // Piab wants vacuum pump lines to be thinner when multiple nodes are selected
    var strokeWidth = selectedNodesCount > 1
        ? defaultGraphStrokeWidth / 2
        : defaultGraphStrokeWidth;
    var lineElements = useMemo(function () {
        if (pumpsAndCupsNodeTree === undefined || selectedNodes === undefined)
            return null;
        var lines = [];
        pumpsAndCupsNodeTree.forEach(function (vacuumPump) {
            var connectedSuctionCups = vacuumPump.connectedSuctionCups;
            connectedSuctionCups.forEach(function (suctionCup) {
                var suctionCupId = suctionCup.yValueKey, suctionCupColor = suctionCup.color, suctionCupName = suctionCup.name, alphabeticalId = suctionCup.alphabeticalId;
                var suctionCupLabel = getSuctionCupLabel({
                    alphabeticalId: alphabeticalId,
                    name: suctionCupName,
                });
                var verticalForceDataKey = liftingForcesYAxisVerticalKey + "-" + suctionCup.nodeId;
                var horizontalForceDataKey = liftingForcesYAxisHorizontalKey + "-" + suctionCup.nodeId;
                var isSelected = selectedNodes[suctionCupId];
                if (isSelected) {
                    lines.push(_jsx(Line, { type: "linear", dataKey: verticalForceDataKey, stroke: suctionCupColor, strokeWidth: strokeWidth, dot: false, name: suctionCupLabel }, verticalForceDataKey));
                    lines.push(_jsx(Line, { type: "linear", dataKey: horizontalForceDataKey, stroke: suctionCupColor, strokeWidth: strokeWidth, dot: false, strokeDasharray: "6 4", name: suctionCupLabel }, horizontalForceDataKey));
                }
            });
        });
        return lines;
    }, [getSuctionCupLabel, pumpsAndCupsNodeTree, selectedNodes, strokeWidth]);
    var zoomTicksX = useMemo(function () {
        if (!zoomedTimeDomain)
            return;
        var min = zoomedTimeDomain[0];
        var max = zoomedTimeDomain[1];
        var ticksX = [];
        var addIndexIfInRange = function (index) {
            var isInRange = index < max && index > min;
            if (!isInRange)
                return;
            ticksX.push(index);
        };
        addIndexIfInRange(timeData[psIndex]);
        addIndexIfInRange(timeData[atmIndex]);
        if (showEnergySave) {
            addIndexIfInRange(timeData[esIndex]);
            ticksX.sort();
        }
        ticksX.push(max);
        return ticksX;
    }, [atmIndex, esIndex, psIndex, showEnergySave, timeData, zoomedTimeDomain]);
    var zoomTicksY = useMemo(function () {
        if (!zoomedLiftForceDomain)
            return;
        return [zoomedLiftForceDomain[0], zoomedLiftForceDomain[1]];
    }, [zoomedLiftForceDomain]);
    if (!parsedGraphData)
        return null;
    var liftingForcesDomain = parsedGraphData.liftingForcesDomain, liftingForcesData = parsedGraphData.liftingForcesData;
    // this reassignment is a hack to make the graph ticks start from zero,
    // ignoring the domain min value that can be slightly less than zero. we do it
    // here for display purposes only, the actual domain from the selector is not
    // changed.
    var liftingForcesDomainThatStartsFromZero = [0, liftingForcesDomain[1]];
    var yAxisTicks = zoomTicksY !== null && zoomTicksY !== void 0 ? zoomTicksY : liftingForcesDomainThatStartsFromZero;
    var xDomain = zoomedTimeDomain !== null && zoomedTimeDomain !== void 0 ? zoomedTimeDomain : xAxisDomain;
    var yDomain = zoomedLiftForceDomain !== null && zoomedLiftForceDomain !== void 0 ? zoomedLiftForceDomain : liftingForcesDomainThatStartsFromZero;
    var yAxisUnitconversion = conversions[liftingForcesYAxisUnit];
    var yAxisUnit = unitSystem === UnitSystem.METRIC
        ? liftingForcesYAxisUnit
        : yAxisUnitconversion.unit;
    var xAxisTicks = zoomTicksX
        ? zoomTicksX
        : [
            timeData[psIndex],
            timeData[atmIndex],
            liftingForcesData[liftingForcesData.length - 1][xAxisKey],
        ];
    if (showEnergySave && !zoomTicksX) {
        xAxisTicks.push(timeData[esIndex]);
        xAxisTicks.sort();
    }
    return (_jsxs(LineChart, { width: graphWidth, height: liftingForcesGraphHeight, data: liftingForcesData, margin: __assign(__assign({}, graphMargins), { bottom: 0 }), onMouseDown: onMouseDown, onMouseMove: onMouseMove, onMouseUp: onMouseUp, children: [_jsx(CartesianGrid, { vertical: false, horizontal: false }), _jsx(XAxis, __assign({}, defaultXAxisProps, { ticks: xAxisTicks, domain: xDomain, tick: _jsx(CustomXAxisTick, {}), interval: 0, height: 35 })), _jsx(YAxis, __assign({}, defaultYAxisProps, { interval: 0, unit: yAxisUnit, tick: _jsx(YAxisTick, {}), ticks: yAxisTicks, tickLine: false, padding: yAxisPadding, domain: yDomain })), _jsx(Tooltip, { allowEscapeViewBox: { x: true, y: true }, position: liftingForceTooltipPosition, wrapperStyle: { zIndex: 100 }, content: _jsx(CustomLiftingForcesGraphTooltip, { xLabel: t("graph_panel.pump_x_label_time"), yLabel: t("graph_panel.lifting_forces_tooltip_y_label"), yUnit: liftingForcesYAxisUnit }) }), lineElements, _jsx(ZoomHighlightedArea, { domain: yDomain }), _jsx(VerticalReferenceLines, { withLabel: true }), _jsx(ReferenceLine, { y: yAxisTicks[0] }), _jsx(ReferenceLine, { y: yAxisTicks[1] }), _jsx(Legend, { align: "left", wrapperStyle: customLegendWrapperStyle, content: function () { return _jsx(LiftingForcesLegend, {}); } })] }));
};
