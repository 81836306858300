var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var _a, _b, _c;
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import * as Styled from "./checklist.style";
import { bubbleWidth, bubbleHeight, verticalLineWidth, verticalLineHeight, checkboxWidth, checkboxHeight, } from "./checklist.style";
import { actions, selectors, useAppDispatch, useAppSelector } from "store";
import { ChecklistItemName } from "store/types";
import { isCentralizedSystem } from "store/system-data/helpers";
var ItemBubbleSize;
(function (ItemBubbleSize) {
    ItemBubbleSize["SMALL"] = "small";
    ItemBubbleSize["DEFAULT"] = "default";
    ItemBubbleSize["LARGE"] = "large";
})(ItemBubbleSize || (ItemBubbleSize = {}));
var scales = (_a = {},
    _a[ItemBubbleSize.SMALL] = 1,
    _a[ItemBubbleSize.DEFAULT] = 1.4,
    _a[ItemBubbleSize.LARGE] = 2,
    _a);
var fontSizes = (_b = {},
    _b[ItemBubbleSize.SMALL] = 11,
    _b[ItemBubbleSize.DEFAULT] = 15,
    _b[ItemBubbleSize.LARGE] = 18,
    _b);
var checklistActions = (_c = {},
    _c[ChecklistItemName.ADD_GRIP_POSITION] = actions.addGripPosition,
    _c[ChecklistItemName.ADD_PUMP] = actions.checklistActionAddPump,
    _c[ChecklistItemName.ADD_TUBE_CONNECTION] = actions.addTubeConnection,
    _c[ChecklistItemName.CALCULATE] = actions.showPerformanceData,
    _c[ChecklistItemName.SELECT_CUP] = actions.checklistActionSelectCup,
    _c);
export var Checklist = function () {
    var t = useTranslation().t;
    var sortedChecklist = useAppSelector(selectors.selectSortedChecklist);
    var nextChecklistStepIndex = useAppSelector(selectors.selectNextChecklistStepIndex);
    var systemData = useAppSelector(selectors.selectSystemDataDefineParameters);
    var canSendCalculationRequest = useAppSelector(selectors.selectCalculatePerformanceEnabled);
    var canAddCentralizedPump = useAppSelector(selectors.selectCanAddCentralizedPump);
    var lastItemIndex = Object.entries(sortedChecklist).length - 1;
    return (_jsx(Styled.Checklist, { children: _jsx(Styled.Steps, { children: sortedChecklist.map(function (_a, index) {
                var _b = __read(_a, 2), id = _b[0], checklistItem = _b[1];
                var completed = checklistItem.completed;
                var currentStepRelativeToNextUncompletedStep = index - nextChecklistStepIndex;
                var size = nextChecklistStepIndex === -1
                    ? ItemBubbleSize.SMALL
                    : currentStepRelativeToNextUncompletedStep === 0
                        ? ItemBubbleSize.LARGE
                        : Math.abs(currentStepRelativeToNextUncompletedStep) === 1
                            ? ItemBubbleSize.DEFAULT
                            : ItemBubbleSize.SMALL;
                var scale = scales[size];
                var fontSize = fontSizes[size];
                var itemName = id;
                var action = checklistActions[itemName];
                var itemIsCalculateAndCalculationIsDisabled = itemName === ChecklistItemName.CALCULATE &&
                    !canSendCalculationRequest;
                var itemIsCentralizedPumpAndCannotAddPump = isCentralizedSystem(systemData) &&
                    itemName === ChecklistItemName.ADD_PUMP &&
                    !canAddCentralizedPump;
                if (itemIsCalculateAndCalculationIsDisabled ||
                    itemIsCentralizedPumpAndCannotAddPump) {
                    action = undefined;
                }
                return (_jsx(Step, { withVerticalLine: completed && index !== lastItemIndex, label: t(checklistItem.translationKey), completed: completed, scale: scale, currentStepRelativeToNextUncompletedStep: currentStepRelativeToNextUncompletedStep, fontSize: fontSize, action: action }, id));
            }) }) }));
};
var Step = function (_a) {
    var label = _a.label, completed = _a.completed, scale = _a.scale, fontSize = _a.fontSize, action = _a.action, currentStepRelativeToNextUncompletedStep = _a.currentStepRelativeToNextUncompletedStep, withVerticalLine = _a.withVerticalLine;
    return (_jsxs(Styled.Step, { clickable: action !== undefined, children: [_jsx(Styled.StepLabel, { fontSize: fontSize, children: label }), _jsx(StepSvg, { withVerticalLine: withVerticalLine, currentStepRelativeToNextUncompletedStep: currentStepRelativeToNextUncompletedStep, completed: completed, scale: scale, action: action })] }));
};
var StepSvg = function (_a) {
    var completed = _a.completed, scale = _a.scale, currentStepRelativeToNextUncompletedStep = _a.currentStepRelativeToNextUncompletedStep, withVerticalLine = _a.withVerticalLine, action = _a.action;
    var theme = useTheme();
    var dispatch = useAppDispatch();
    var white = theme.colors.palette.white;
    var green = theme.colors.palette.green[500];
    return (_jsxs("div", { onClick: function () {
            action && dispatch(action());
        }, children: [_jsx(BubbleSvg, { completed: completed, scale: scale, currentStepRelativeToNextUncompletedStep: currentStepRelativeToNextUncompletedStep }), completed && _jsx(CheckboxSvg, { color: white, scale: scale }), withVerticalLine && _jsx(VerticalLineSvg, { color: green })] }));
};
var VerticalLineSvg = function (_a) {
    var color = _a.color;
    return (_jsx(Styled.VerticalLineSvgContainer, { children: _jsx("svg", { width: "10", height: "100", viewBox: "-5 0 10 100", preserveAspectRatio: "none", children: _jsx("rect", { width: verticalLineWidth, height: verticalLineHeight, fill: color }) }) }));
};
var BubbleSvg = function (_a) {
    var completed = _a.completed, scale = _a.scale, currentStepRelativeToNextUncompletedStep = _a.currentStepRelativeToNextUncompletedStep;
    var theme = useTheme();
    var gray = theme.colors.palette.grey[600];
    var green = theme.colors.palette.green[500];
    var strokeWidth = 1;
    var hasStroke = currentStepRelativeToNextUncompletedStep < 1;
    return (_jsx(Styled.BubbleSvgContainer, { scale: scale, children: _jsx("svg", { width: bubbleWidth, height: bubbleHeight, viewBox: "0 -".concat(strokeWidth / 2, " ").concat(bubbleWidth - strokeWidth, " ").concat(bubbleHeight + strokeWidth), fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M54.2 15.9416C54.2 24.7457 47.0628 31.8828 38.2588 31.8828L15.9411 31.8828C7.13707 31.8828 -4.89671e-05 24.7457 -4.81974e-05 15.9416V15.9416C-4.74278e-05 7.13757 7.13706 0.000457457 15.9411 0.000458227L38.2588 0.000460178C47.0628 0.000460947 54.2 7.13757 54.2 15.9416V15.9416Z", fill: completed ? green : gray, stroke: hasStroke ? green : gray, strokeWidth: strokeWidth }) }) }));
};
var CheckboxSvg = function (_a) {
    var color = _a.color, scale = _a.scale;
    var scaleXY = scale + 0.3;
    return (_jsx(Styled.CheckboxSvgContainer, { scale: scaleXY, children: _jsx("svg", { width: checkboxWidth, height: checkboxHeight, viewBox: "0 0 ".concat(checkboxWidth, " ").concat(checkboxHeight), fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M7.13152 2.07422L3.43827 5.76747L1.75952 4.08872", stroke: color, strokeWidth: "1", strokeLinecap: "round", strokeLinejoin: "round" }) }) }));
};
