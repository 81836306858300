import { NodeComponentType } from "store/scene/types-versions/types.v0";
import { TubeType } from "store/types";
export var allowEdgeBetween = function (attachment1, attachment2) {
    var nodeType1 = attachment1.nodeType, tubeType1 = attachment1.tubeType, nodeId1 = attachment1.nodeId;
    var nodeType2 = attachment2.nodeType, tubeType2 = attachment2.tubeType, nodeId2 = attachment2.nodeId;
    var isBothNodesTheSameNode = nodeId1 === nodeId2;
    var bothNodesAreGripPositions = nodeType1 === NodeComponentType.GRIP_POSITION &&
        nodeType2 === NodeComponentType.GRIP_POSITION;
    var bothNodesAreCentralizedPumps = nodeType1 === NodeComponentType.CENTRALIZED_VACUUM_PUMP &&
        nodeType2 === NodeComponentType.CENTRALIZED_VACUUM_PUMP;
    /* Connecting two tube connections with unkown tubetypes to eachother can cause infinite loops in our
     * recursive method that determines tubetypes */
    var bothTubeTypesAreUnknown = tubeType1 === TubeType.UNKNOWN && tubeType2 === TubeType.UNKNOWN;
    var nodeConnectionNotAllowed = isBothNodesTheSameNode ||
        bothNodesAreCentralizedPumps ||
        bothNodesAreGripPositions ||
        bothTubeTypesAreUnknown;
    var tubeTypesAreCompatible = tubeType1 === tubeType2 ||
        tubeType1 === TubeType.UNKNOWN ||
        tubeType2 === TubeType.UNKNOWN;
    var edgeAllowed = !nodeConnectionNotAllowed && tubeTypesAreCompatible;
    return edgeAllowed;
};
