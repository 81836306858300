var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable camelcase */
import { defaultMetalCupMaterialTransform, } from "3d/types";
/** ---------------   **/
/**    BFFT Family    **/
/** ---------------   **/
export var suctionCupsFamilyBFFT = {
    BFFT: __assign(__assign({ cupModelName: "BFFT70P", cupWidth: 0.07, cupHeight: 0.07 }, defaultMetalCupMaterialTransform.PU55), { fittingModelName: "G38M50" }),
    // BFFT23P
    suction_cup_0210557: __assign(__assign({ cupModelName: "BFFT23P", cupWidth: 0.023, cupHeight: 0.023 }, defaultMetalCupMaterialTransform.PU55), { fittingModelName: "G38M50-S1" }),
    // BFFT35P
    suction_cup_0210566: __assign(__assign({ cupModelName: "BFFT35P", cupWidth: 0.035, cupHeight: 0.035 }, defaultMetalCupMaterialTransform.PU55), { fittingModelName: "G38M50-S1" }),
};
