var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSelector } from "@reduxjs/toolkit";
import { TubeType, TubeConnectionId, ChecklistItemName, } from "store/types";
import { objectShapes, suctionCupModels, toolChangerDefaultLength, } from "3d/constants/common";
import { getCylinderVolumeDm3 } from "3d/helpers";
import * as uiSelectors from "store/ui/selectors";
import * as sceneSelectors from "store/scene/selectors";
import * as systemDataSelectorsDefineParameters from "store/system-data/define-parameters/selectors";
import * as systemDataSelectorsSelectApplication from "store/system-data/select-application/selectors";
import * as systemPerformanceSelectors from "store/system-performance/selectors";
import { metalObjectShapes, suctionCupModelsMetal } from "3d/constants/metal";
import { glassObjectShapes, suctionCupModelsGlass } from "3d/constants/glass";
import { plasticObjectShapes, suctionCupModelsPlastic, } from "3d/constants/plastic";
import { TactonApplication } from "api/tacton/types";
import { systemDataConstants } from "api/system-data";
import { cardboardObjectShapes, suctionCupModelsCardboard, } from "3d/constants/cardboard";
import { BuildInfo } from "utility/buildinfo";
var selectSelectedNode = createSelector(uiSelectors.selectSelectedId, sceneSelectors.selectNodes, function (selectedId, nodes) { return (selectedId ? nodes[selectedId] : undefined); });
var selectSelectedNodes = createSelector(uiSelectors.selectSelectedIds, sceneSelectors.selectNodes, function (selectedIds, nodes) { return selectedIds.map(function (id) { return nodes[id]; }).filter(Boolean); });
var selectSelectedNodesType = createSelector(selectSelectedNodes, function (selectedNodes) {
    var _a;
    var selectedNodesType = selectedNodes.every(function (node) { var _a; return (node === null || node === void 0 ? void 0 : node.type) === ((_a = selectedNodes[0]) === null || _a === void 0 ? void 0 : _a.type); })
        ? (_a = selectedNodes[0]) === null || _a === void 0 ? void 0 : _a.type
        : null;
    return selectedNodesType;
});
var selectSelectedAttachment = createSelector(uiSelectors.selectSelectedId, sceneSelectors.selectAttachments, function (selectedId, attachments) {
    return selectedId ? attachments[selectedId] : undefined;
});
var selectSelectedEdge = createSelector(uiSelectors.selectSelectedId, sceneSelectors.selectEdges, function (selectedId, edges) { return (selectedId ? edges[selectedId] : undefined); });
var selectSelectedEdges = createSelector(uiSelectors.selectSelectedIds, sceneSelectors.selectEdges, function (selectedIds, edges) { return selectedIds.map(function (id) { return edges[id]; }).filter(Boolean); });
var selectCanAddGripPosition = createSelector(sceneSelectors.selectHasAvailableIdsForGripPosition, systemDataSelectorsDefineParameters.selectHasAvailableSelectionsForGripPosition, function (hasIds, hasSelections) { return hasIds && hasSelections; });
var selectCanAddCentralizedPump = createSelector(systemDataSelectorsDefineParameters.selectIsCentralizedSystem, sceneSelectors.selectHasAvailableIdsForPump, systemDataSelectorsDefineParameters.selectHasAvailableSelectionsForPump, sceneSelectors.allowAddCentralizedPump, function (isCentralizedSystem, hasIds, hasSelections, allowAddCentralizedPump) {
    return isCentralizedSystem && hasIds && hasSelections && allowAddCentralizedPump;
});
var selectCanAddTubeConnection = createSelector(sceneSelectors.selectHasAvailableIdsForTubeConnection, function (hasIds) { return hasIds; });
var selectVacuumSystemVolume = createSelector(systemDataSelectorsDefineParameters.selectIsCentralizedSystem, systemDataSelectorsDefineParameters.selectSystemDataDefineParameters, systemDataSelectorsDefineParameters.selectPumpSelectionDecentralMap, sceneSelectors.selectEdges, sceneSelectors.selectTubeConnections, sceneSelectors.selectGripPositions, function (isCentralizedSystem, systemData, pumpSelectionDecentralMap, edges, tubeConnections, gripPositions) {
    if (!systemData)
        return 0;
    var edgeVolume = Object.values(edges || {}).reduce(function (volume, edge) {
        if (edge.tubeType === TubeType.VACUUM) {
            return (volume + getCylinderVolumeDm3(edge.length, edge.diameterDefaultTube));
        }
        else {
            return volume;
        }
    }, 0);
    var toolChangerVolume = tubeConnections.reduce(function (volume, tubeConnection) {
        var component = tubeConnection.component;
        if (component.type === TubeConnectionId.TOOL_CHANGER &&
            component.tubeType === TubeType.VACUUM) {
            return (volume +
                getCylinderVolumeDm3(toolChangerDefaultLength, component.diameters[0]));
        }
        else {
            return volume;
        }
    }, 0);
    var inlineTubesVolume = isCentralizedSystem
        ? 0
        : gripPositions.reduce(function (volume, gripPosition) {
            var _a;
            var selectionId = pumpSelectionDecentralMap[gripPosition.id];
            if (!selectionId)
                return volume;
            var pumpValue = systemData.vacuumPumpSelectionsDecentralized[selectionId];
            if (!pumpValue)
                return volume;
            var isInline = (_a = systemData.vacuumPumpComponents[pumpValue]) === null || _a === void 0 ? void 0 : _a.isInlineDecentralPump;
            if (isInline) {
                var component = gripPosition.component;
                return (volume +
                    getCylinderVolumeDm3(component.inlineTubeLength, component.inlineTubeDiameter));
            }
            else {
                return volume;
            }
        }, 0);
    return (edgeVolume +
        toolChangerVolume +
        systemData.output.totalVolume +
        inlineTubesVolume);
});
var selectIsSystemDataLoading = createSelector(systemDataSelectorsDefineParameters.selectIsLoadingDefineParameters, systemDataSelectorsSelectApplication.selectIsLoadingSelectApplication, function (isLoadingDefineParameters, isLoadingSelectApplication) {
    return isLoadingDefineParameters || isLoadingSelectApplication;
});
export var selectUseTexturedSurfaces = createSelector(systemDataSelectorsDefineParameters.selectSystemDataDefineParameters, function (systemData) {
    var _a;
    var texturedSurfaceValue = (_a = systemData === null || systemData === void 0 ? void 0 : systemData.parameters[systemDataConstants.parameterNames.texturedSurface]) === null || _a === void 0 ? void 0 : _a.value;
    // Currently, if the active application is any other than Plastic, this value will be undefined.
    // We could just check if the active application is Plastic, but in the future this value might be exposed in the General application.
    // So I thought it would be best to prepare for that.
    var texturedSurfaceValueExists = texturedSurfaceValue !== undefined;
    if (!texturedSurfaceValueExists)
        return false;
    return (texturedSurfaceValue ===
        systemDataConstants.parameterValues.texturedSurfaceOn);
});
var selectSystemDataErrors = createSelector(systemDataSelectorsDefineParameters.selectErrorsDefineParameters, systemDataSelectorsSelectApplication.selectErrorsSelectApplication, function (errorsDefineParameters, errorsSelectApplication) {
    var errors = [];
    if (!errorsDefineParameters && !errorsSelectApplication)
        return;
    errorsDefineParameters && errors.push.apply(errors, __spreadArray([], __read(errorsDefineParameters), false));
    errorsSelectApplication && errors.push.apply(errors, __spreadArray([], __read(errorsSelectApplication), false));
    return errors;
});
export var selectObjectShapes = createSelector(systemDataSelectorsSelectApplication.selectActiveApplication, selectUseTexturedSurfaces, function (activeApplication, useTexturedSurfaces) {
    var filterObjectShapes = function (objectShapes, filterFn) {
        return Object.entries(objectShapes)
            .filter(function (_a) {
            var _b = __read(_a, 2), _key = _b[0], objectShape = _b[1];
            return filterFn(objectShape);
        })
            .reduce(function (acc, _a) {
            var _b = __read(_a, 2), key = _b[0], objectShape = _b[1];
            acc[key] = objectShape;
            return acc;
        }, {});
    };
    var filterByTexturedSurfaceCompability = function (objectShape) {
        return useTexturedSurfaces ? !!objectShape.texturedMaterialTransforms : true;
    };
    var filterByEnvironment = function (objectShape) {
        return BuildInfo.environment === "production" ? !objectShape.hiddenInProd : true;
    };
    switch (activeApplication) {
        case TactonApplication.SHEET_METAL:
            return filterObjectShapes(metalObjectShapes, filterByEnvironment);
        case TactonApplication.GLASS:
            return glassObjectShapes;
        case TactonApplication.PLASTIC:
            return filterObjectShapes(plasticObjectShapes, filterByTexturedSurfaceCompability);
        case TactonApplication.CARDBOARD:
            return cardboardObjectShapes;
        default:
            return filterObjectShapes(objectShapes, filterByTexturedSurfaceCompability);
    }
});
export var selectSuctionCupModels = createSelector(systemDataSelectorsSelectApplication.selectActiveApplication, function (activeApplication) {
    switch (activeApplication) {
        case TactonApplication.SHEET_METAL:
            return suctionCupModelsMetal;
        case TactonApplication.GLASS:
            return suctionCupModelsGlass;
        case TactonApplication.PLASTIC:
            return suctionCupModelsPlastic;
        case TactonApplication.CARDBOARD:
            return suctionCupModelsCardboard;
        default:
            return suctionCupModels;
    }
});
export var selectCalculatePerformanceEnabled = createSelector(systemPerformanceSelectors.selectIsPerformanceLoading, systemPerformanceSelectors.selectIsSanityCheckLoading, systemPerformanceSelectors.selectSanityCheckData, uiSelectors.selectChecklist, function (isSystemPerformanceLoading, isSanityCheckLoading, sanityCheckData, checklist) {
    return (!isSystemPerformanceLoading &&
        !isSanityCheckLoading &&
        (sanityCheckData === null || sanityCheckData === void 0 ? void 0 : sanityCheckData.validationPassed) &&
        checklist[ChecklistItemName.CONNECT_PARTS].completed);
});
var combinedSelectors = {
    selectSelectedNode: selectSelectedNode,
    selectSelectedNodes: selectSelectedNodes,
    selectSelectedNodesType: selectSelectedNodesType,
    selectSelectedAttachment: selectSelectedAttachment,
    selectSelectedEdge: selectSelectedEdge,
    selectSelectedEdges: selectSelectedEdges,
    selectCanAddTubeConnection: selectCanAddTubeConnection,
    selectCanAddGripPosition: selectCanAddGripPosition,
    selectCanAddCentralizedPump: selectCanAddCentralizedPump,
    selectVacuumSystemVolume: selectVacuumSystemVolume,
    selectIsSystemDataLoading: selectIsSystemDataLoading,
    selectSystemDataErrors: selectSystemDataErrors,
    selectObjectShapes: selectObjectShapes,
    selectSuctionCupModels: selectSuctionCupModels,
    selectUseTexturedSurfaces: selectUseTexturedSurfaces,
    selectCalculatePerformanceEnabled: selectCalculatePerformanceEnabled,
};
export var selectors = __assign(__assign(__assign(__assign(__assign(__assign({}, combinedSelectors), uiSelectors), sceneSelectors), systemDataSelectorsDefineParameters), systemDataSelectorsSelectApplication), systemPerformanceSelectors);
