var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { ImageSelect, StyledImageSelect } from "@animech/ui";
import styled from "@emotion/styled";
var ImageSelectWithProps = function (props) { return _jsx(ImageSelect, __assign({}, props)); };
export var CustomImageSelect = styled(ImageSelectWithProps)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t", " {\n\t\tbox-shadow: none !important;\n\t}\n"], ["\n\t", " {\n\t\tbox-shadow: none !important;\n\t}\n"])), StyledImageSelect.ImageBorder);
var templateObject_1;
