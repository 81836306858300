var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { CustomBaseModal } from "ui/components/modals/custom-base-modal/custom-base-modal";
export var Content = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var Introduction = styled.p(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
export var Steps = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
export var Step = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
export var Paragraph = styled.p(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
export var Root = styled(CustomBaseModal)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n\t\t\t", " {\n\t\t\t\tdisplay: flex;\n\t\t\t\talign-items: center;\n\t\t\t\tflex-direction: column;\n\t\t\t\twidth: clamp(500px, 66vw, 1100px);\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tpadding: 0 2em;\n\t\t\t\tfont-size: ", ";\n\t\t\t\tfont-style: italic;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tposition: relative;\n\t\t\t\tpadding: 0 2em 2em 3em;\n\n\t\t\t\tsvg {\n\t\t\t\t\tposition: absolute;\n\t\t\t\t\tleft: -", ";\n\t\t\t\t}\n\t\t\t}\n\n\t\t\th6 {\n\t\t\t\tmargin: 0 0 0.5em 0;\n\t\t\t}\n\t\t"], ["\n\t\t\t", " {\n\t\t\t\tdisplay: flex;\n\t\t\t\talign-items: center;\n\t\t\t\tflex-direction: column;\n\t\t\t\twidth: clamp(500px, 66vw, 1100px);\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tpadding: 0 2em;\n\t\t\t\tfont-size: ", ";\n\t\t\t\tfont-style: italic;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tposition: relative;\n\t\t\t\tpadding: 0 2em 2em 3em;\n\n\t\t\t\tsvg {\n\t\t\t\t\tposition: absolute;\n\t\t\t\t\tleft: -", ";\n\t\t\t\t}\n\t\t\t}\n\n\t\t\th6 {\n\t\t\t\tmargin: 0 0 0.5em 0;\n\t\t\t}\n\t\t"])), Content, Introduction, theme.typography.fontSize.small, Step, theme.components.container.sizes.default.paddingHorizontal);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
