var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Container, useModal } from "@animech/ui";
import React from "react";
import * as Styled from "./info-button.style";
import { IconButton } from "ui/components/elements/icons/icon-button";
import { CustomBaseModal } from "ui/components/modals/custom-base-modal/custom-base-modal";
var titleIdentifier = "{title}";
var InfoModal = function (_a) {
    var title = _a.title, description = _a.description, baseModalProps = __rest(_a, ["title", "description"]);
    if (description === undefined)
        return null;
    var long = description.long, imageScale = description.imageScale, imageSource = description.imageSource, layout = description.layout, maxSizePixels = description.maxSizePixels;
    if (long === undefined)
        return null;
    var hasImage = imageSource !== undefined;
    var sections = long.split("\\s");
    var sectionElements = sections.map(function (section, i) {
        var lines = section.split("\\n");
        return (_jsx(Styled.Section, { children: lines.map(function (line, j) {
                var isTitle = line.includes(titleIdentifier);
                var text = isTitle ? line.replace(titleIdentifier, "") : line;
                text.trim();
                return (_jsxs(React.Fragment, { children: [_jsx(Styled.SectionText, { isTitle: isTitle, children: text }), !isTitle && _jsx("br", {})] }, "".concat(line, "-").concat(i, "-").concat(j)));
            }) }, "".concat(section, "-").concat(i)));
    });
    return (_jsx(CustomBaseModal, __assign({}, baseModalProps, { title: title, color: "inverted", children: _jsx(Container, { children: _jsxs(Styled.Description, { imageScale: imageScale, layout: layout, maxWidth: maxSizePixels, children: [hasImage && _jsx("img", { src: imageSource }), _jsx("div", { className: "long-description", children: sectionElements })] }) }) })));
};
export var useShowInfo = function (title, description) {
    var openModal = useModal();
    return function () {
        openModal(_jsx(InfoModal, { title: title, description: description }));
    };
};
export var InfoButton = function (_a) {
    var title = _a.title, description = _a.description, className = _a.className, iconButtonProps = _a.iconButtonProps;
    var openModal = useModal();
    var showInfo = function () {
        openModal(_jsx(InfoModal, { title: title, description: description }));
    };
    var _b = iconButtonProps !== null && iconButtonProps !== void 0 ? iconButtonProps : {}, _c = _b.icon, icon = _c === void 0 ? "info" : _c, _d = _b.color, color = _d === void 0 ? "primary" : _d, _e = _b.appearance, appearance = _e === void 0 ? "text" : _e, _f = _b.onClick, onClick = _f === void 0 ? showInfo : _f, _g = _b.rounded, rounded = _g === void 0 ? true : _g, _h = _b.variant, variant = _h === void 0 ? "circle" : _h;
    return (_jsx(Styled.Root, { children: _jsx(IconButton, { rootAttributes: {
                "data-testid": "input-button-info",
            }, icon: icon, color: color, appearance: appearance, onClick: onClick, rounded: rounded, className: className, variant: variant }) }));
};
