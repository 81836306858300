var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Label } from "@animech/ui";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
export var Image = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
export var Half = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
export var ApplicationName = styled(Label)(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
export var ActiveApplication = styled(Label)(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
export var Logo = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme, isInverted = _a.isInverted;
    return css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n\t\t\tdisplay: flex;\n\t\t\tjustify-content: center;\n\n\t\t\t", " {\n\t\t\t\twidth: 120px;\n\t\t\t\tmargin: 0;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tdisplay: flex;\n\t\t\t\tflex-direction: column;\n\t\t\t\tjustify-content: center;\n\t\t\t\tborder-left: 1px solid ", ";\n\n\t\t\t\t", " {\n\t\t\t\t\tdisplay: flex;\n\t\t\t\t\tflex-direction: column;\n\t\t\t\t\tmin-height: 30px;\n\t\t\t\t}\n\n\t\t\t\t", ":has(", ") {\n\t\t\t\t\tjustify-content: flex-end;\n\t\t\t\t}\n\n\t\t\t\t", " {\n\t\t\t\t\tmargin: 0;\n\t\t\t\t\tpadding-right: 16px;\n\t\t\t\t\tpadding-left: 16px;\n\t\t\t\t\tcolor: ", ";\n\t\t\t\t}\n\n\t\t\t\t", " {\n\t\t\t\t\tline-height: ", ";\n\t\t\t\t\tfont-size: ", ";\n\t\t\t\t}\n\t\t\t}\n\t\t"], ["\n\t\t\tdisplay: flex;\n\t\t\tjustify-content: center;\n\n\t\t\t", " {\n\t\t\t\twidth: 120px;\n\t\t\t\tmargin: 0;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tdisplay: flex;\n\t\t\t\tflex-direction: column;\n\t\t\t\tjustify-content: center;\n\t\t\t\tborder-left: 1px solid ", ";\n\n\t\t\t\t", " {\n\t\t\t\t\tdisplay: flex;\n\t\t\t\t\tflex-direction: column;\n\t\t\t\t\tmin-height: 30px;\n\t\t\t\t}\n\n\t\t\t\t", ":has(", ") {\n\t\t\t\t\tjustify-content: flex-end;\n\t\t\t\t}\n\n\t\t\t\t", " {\n\t\t\t\t\tmargin: 0;\n\t\t\t\t\tpadding-right: 16px;\n\t\t\t\t\tpadding-left: 16px;\n\t\t\t\t\tcolor: ", ";\n\t\t\t\t}\n\n\t\t\t\t", " {\n\t\t\t\t\tline-height: ", ";\n\t\t\t\t\tfont-size: ", ";\n\t\t\t\t}\n\t\t\t}\n\t\t"])), Image, Title, theme.colors.palette.grey[500], Half, Half, ApplicationName, ApplicationName, isInverted
        ? theme.colors.palette.black
        : theme.colors.palette.white, ApplicationName, theme.typography.fontSize.large, theme.typography.fontSize.large);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
