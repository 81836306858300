var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from "@emotion/react";
import styled from "@emotion/styled";
export var prefix = "lifting-forces-graph";
export var arrowIconClassName = "".concat(prefix, "-arrow-icon");
export var LegendIconContainerInner = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var LegendIconUnit = styled.em(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
export var LegendIconContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t\t\tdisplay: flex;\n\t\t\talign-items: center;\n\t\t\tjustify-content: center;\n\t\t\tgap: ", ";\n\n\t\t\t", " {\n\t\t\t\tdisplay: flex;\n\t\t\t\talign-items: center;\n\t\t\t\tflex-direction: column;\n\t\t\t\tjustify-content: center;\n\n\t\t\t\t.", " {\n\t\t\t\t\tstroke: ", ";\n\t\t\t\t}\n\t\t\t}\n\t\t"], ["\n\t\t\tdisplay: flex;\n\t\t\talign-items: center;\n\t\t\tjustify-content: center;\n\t\t\tgap: ", ";\n\n\t\t\t", " {\n\t\t\t\tdisplay: flex;\n\t\t\t\talign-items: center;\n\t\t\t\tflex-direction: column;\n\t\t\t\tjustify-content: center;\n\n\t\t\t\t.", " {\n\t\t\t\t\tstroke: ", ";\n\t\t\t\t}\n\t\t\t}\n\t\t"])), theme.dimensions.margin.xSmall, LegendIconContainerInner, arrowIconClassName, theme.colors.palette.green[500]);
});
export var LegendContent = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\t\t\tdisplay: flex;\n\t\t\talign-items: center;\n\t\t\tflex-direction: row;\n\t\t\tgap: ", ";\n\t\t\tjustify-content: center;\n\t\t"], ["\n\t\t\tdisplay: flex;\n\t\t\talign-items: center;\n\t\t\tflex-direction: row;\n\t\t\tgap: ", ";\n\t\t\tjustify-content: center;\n\t\t"])), theme.dimensions.margin.small);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
