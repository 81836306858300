import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Tooltip, TooltipPortal, Button } from "@animech/ui";
import { useTheme } from "@emotion/react";
import * as Styled from "./toolbar.style";
import { VacuumPumpIcon } from "ui/components/elements/icons/vacuum-pump";
import { DecentralizedGripPositionIcon } from "ui/components/elements/icons/decentralized-grip-position";
import { CentralizedGripPositionIcon } from "ui/components/elements/icons/centralized-grip-position";
import { TubeConnectionIcon } from "ui/components/elements/icons/tube-connection";
import { SwitchCameraIcon } from "ui/components/elements/icons/switch-camera";
import { PlusIconWithBackground } from "ui/components/elements/icons/plus-with-background";
import { CameraViewId } from "3d/types";
import { useAppDispatch, useAppSelector, selectors, actions } from "store";
import { useFeature } from "hooks/feature";
var ToolbarButtonIcon;
(function (ToolbarButtonIcon) {
    ToolbarButtonIcon["GRIP_POSITION"] = "gripPosition";
    ToolbarButtonIcon["TUBE_CONNECTION"] = "tubeConnection";
    ToolbarButtonIcon["VACUUM_PUMP"] = "vacuumPump";
    ToolbarButtonIcon["SWITCH_CAMERA"] = "switchCamera";
})(ToolbarButtonIcon || (ToolbarButtonIcon = {}));
export var Toolbar = function () {
    var t = useTranslation().t;
    var cameraSwitchEnabled = useFeature("secret-camera-button");
    var cameraViewId = useAppSelector(selectors.selectCameraViewId);
    var dispatch = useAppDispatch();
    var onAddGripPosition = function () {
        dispatch(actions.addGripPosition());
    };
    var onAddCentralizedVacuumPump = function () {
        dispatch(actions.addCentralizedPump());
    };
    var onAddConnectionPoint = function () {
        dispatch(actions.addTubeConnection());
    };
    var onTmp = function () {
        dispatch(actions.setCameraView(cameraViewId === CameraViewId.INITIAL_3D_VIEW
            ? CameraViewId.EDIT_VIEW
            : CameraViewId.INITIAL_3D_VIEW));
    };
    var canAddGripPosition = useAppSelector(selectors.selectCanAddGripPosition);
    var canAddCentralizedVacuumPump = useAppSelector(selectors.selectCanAddCentralizedPump);
    var canAddTubeConnection = useAppSelector(selectors.selectCanAddTubeConnection);
    var isCentralizedSystem = useAppSelector(selectors.selectIsCentralizedSystem);
    var icons = {
        gripPosition: isCentralizedSystem ? (_jsx(CentralizedGripPositionIcon, { size: 36 })) : (_jsx(DecentralizedGripPositionIcon, { size: 36 })),
        tubeConnection: _jsx(TubeConnectionIcon, { size: 36 }),
        vacuumPump: _jsx(VacuumPumpIcon, { size: 36 }),
        switchCamera: _jsx(SwitchCameraIcon, { size: 36 }),
    };
    var toolbarButtons = [
        {
            icon: ToolbarButtonIcon.GRIP_POSITION,
            onClick: onAddGripPosition,
            enabled: canAddGripPosition,
            tooltip: t("sidebar.toolbar.add_grip_position"),
        },
        {
            icon: ToolbarButtonIcon.TUBE_CONNECTION,
            onClick: onAddConnectionPoint,
            enabled: canAddTubeConnection,
            tooltip: t("sidebar.toolbar.add_connection_point"),
        },
        {
            icon: ToolbarButtonIcon.VACUUM_PUMP,
            onClick: onAddCentralizedVacuumPump,
            enabled: canAddCentralizedVacuumPump,
            tooltip: t("sidebar.toolbar.add_pump"),
        },
        {
            icon: ToolbarButtonIcon.SWITCH_CAMERA,
            onClick: onTmp,
            enabled: cameraSwitchEnabled,
            tooltip: t("sidebar.toolbar.toggle_view_button"),
        },
    ];
    var theme = useTheme();
    return (_jsx(Styled.Toolbar, { children: _jsx("div", { className: "".concat(Styled.prefix), children: toolbarButtons.map(function (button) {
                return button.enabled && (_jsx(TooltipPortal, { anchorElement: _jsxs(Button, { rootAttributes: {
                            onMouseDown: function (event) {
                                return event.stopPropagation();
                            },
                        }, onClick: button.onClick, appearance: "text", rounded: true, children: [_jsx(Styled.ToolbarPlusIcon, { children: _jsx(PlusIconWithBackground, { size: 18, color: theme.colors.palette.green[500] }) }), icons[button.icon]] }), toggleEvent: "hover", placement: "bottom", children: _jsx(Tooltip, { color: "neutral", children: button.tooltip }) }, button.icon));
            }) }) }));
};
