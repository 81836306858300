var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defaultMetalCupMaterialTransform, } from "3d/types";
/** ---------------   **/
/**    PAOF Family    **/
/** ---------------   **/
export var suctionCupsFamilyPAOF = {
    PAOF: __assign(__assign({ cupModelName: "PAOF4080", cupWidth: 0.08, cupHeight: 0.04 }, defaultMetalCupMaterialTransform.NP), { fittingModelName: "G38MS01", collisionComponentType: "capsule" }),
    // PAOF16X50
    "suction_cup_S.PAOF16X50NP60.G38M.01": __assign(__assign({ cupModelName: "PAOF16X50", cupWidth: 0.05, cupHeight: 0.016 }, defaultMetalCupMaterialTransform.NP), { fittingModelName: "NT38M00", collisionComponentType: "capsule" }),
};
