var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@emotion/styled";
import { StyledTextBox } from "@animech/ui";
import { InputContainer, prefix as inputContainerPrefix, } from "ui/components/elements/input/input-container/input-container.style";
export var TooltipLabel = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var TooltipInputWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tdisplay: flex;\n\tgap: 10px;\n\talign-items: center;\n\tpointer-events: auto;\n\n\t", " {\n\t\t:hover,\n\t\t:focus-within {\n\t\t\tbackground: #555b5e !important;\n\t\t}\n\n\t\t", " {\n\t\t\theight: 100%;\n\t\t\tpadding: 0;\n\t\t}\n\n\t\t", " {\n\t\t\theight: 30px;\n\t\t\tpadding: 0;\n\t\t\tpadding-top: 7px;\n\t\t\tbottom: 0;\n\t\t}\n\t}\n\n\t", " {\n\t\tmax-width: 100px;\n\t\tflex-direction: column;\n\t\t.", "-input-container {\n\t\t\t.", "-button-container {\n\t\t\t\tdisplay: none;\n\t\t\t}\n\t\t}\n\n\t\t.", "-error {\n\t\t\tmax-width: 90px;\n\t\t\tmargin-bottom: 5px;\n\t\t}\n\n\t\t.", "-label {\n\t\t\tdisplay: none;\n\t\t}\n\t}\n"], ["\n\tdisplay: flex;\n\tgap: 10px;\n\talign-items: center;\n\tpointer-events: auto;\n\n\t", " {\n\t\t:hover,\n\t\t:focus-within {\n\t\t\tbackground: #555b5e !important;\n\t\t}\n\n\t\t", " {\n\t\t\theight: 100%;\n\t\t\tpadding: 0;\n\t\t}\n\n\t\t", " {\n\t\t\theight: 30px;\n\t\t\tpadding: 0;\n\t\t\tpadding-top: 7px;\n\t\t\tbottom: 0;\n\t\t}\n\t}\n\n\t", " {\n\t\tmax-width: 100px;\n\t\tflex-direction: column;\n\t\t.", "-input-container {\n\t\t\t.", "-button-container {\n\t\t\t\tdisplay: none;\n\t\t\t}\n\t\t}\n\n\t\t.", "-error {\n\t\t\tmax-width: 90px;\n\t\t\tmargin-bottom: 5px;\n\t\t}\n\n\t\t.", "-label {\n\t\t\tdisplay: none;\n\t\t}\n\t}\n"])), StyledTextBox.Root, StyledTextBox.Input, StyledTextBox.SupportContainer, InputContainer, inputContainerPrefix, inputContainerPrefix, inputContainerPrefix, inputContainerPrefix);
var templateObject_1, templateObject_2;
