var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { StyledButton } from "@animech/ui";
export var prefix = "toolbar-panel";
export var Toolbar = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t\t\t.", " {\n\t\t\t\tdisplay: flex;\n\t\t\t\tjustify-content: space-around;\n\t\t\t\tpadding: ", " 44px 0 44px;\n\t\t\t\tborder-top: solid 1px ", ";\n\t\t\t}\n\t\t\t", " {\n\t\t\t\tpadding: 0;\n\t\t\t\tmin-width: 0;\n\t\t\t\tbackground: none !important;\n\t\t\t\tbox-shadow: none !important;\n\t\t\t\toverflow: visible !important;\n\t\t\t\ttransition: opacity 0.3s;\n\n\t\t\t\t:hover,\n\t\t\t\t:focus-visible {\n\t\t\t\t\topacity: 0.7;\n\t\t\t\t}\n\t\t\t}\n\t\t"], ["\n\t\t\t.", " {\n\t\t\t\tdisplay: flex;\n\t\t\t\tjustify-content: space-around;\n\t\t\t\tpadding: ", " 44px 0 44px;\n\t\t\t\tborder-top: solid 1px ", ";\n\t\t\t}\n\t\t\t", " {\n\t\t\t\tpadding: 0;\n\t\t\t\tmin-width: 0;\n\t\t\t\tbackground: none !important;\n\t\t\t\tbox-shadow: none !important;\n\t\t\t\toverflow: visible !important;\n\t\t\t\ttransition: opacity 0.3s;\n\n\t\t\t\t:hover,\n\t\t\t\t:focus-visible {\n\t\t\t\t\topacity: 0.7;\n\t\t\t\t}\n\t\t\t}\n\t\t"])), prefix, theme.dimensions.margin.large, theme.colors.palette.grey[600], StyledButton.Root);
});
export var ToolbarPlusIcon = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\tposition: absolute;\n\ttransform: translate(50%, 50%);\n\tright: 3px;\n\tbottom: 7px;\n"], ["\n\tposition: absolute;\n\ttransform: translate(50%, 50%);\n\tright: 3px;\n\tbottom: 7px;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
