var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from "@emotion/react";
import styled from "@emotion/styled";
export var Label = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var Code = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
export var CardboardCodeRoot = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function () {
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t\t\tdisplay: flex;\n\t\t\tjustify-content: center;\n\t\t\talign-items: center;\n\t\t\tflex-direction: column;\n\t\t\trow-gap: 14px;\n\n\t\t\t", " {\n\t\t\t\ttext-transform: uppercase;\n\t\t\t}\n\t\t\t", " {\n\t\t\t\ttext-transform: uppercase;\n\t\t\t\tfont-size: 45px;\n\t\t\t\tfont-weight: bold;\n\t\t\t\tcolor: #b8ae79;\n\t\t\t}\n\t\t"], ["\n\t\t\tdisplay: flex;\n\t\t\tjustify-content: center;\n\t\t\talign-items: center;\n\t\t\tflex-direction: column;\n\t\t\trow-gap: 14px;\n\n\t\t\t", " {\n\t\t\t\ttext-transform: uppercase;\n\t\t\t}\n\t\t\t", " {\n\t\t\t\ttext-transform: uppercase;\n\t\t\t\tfont-size: 45px;\n\t\t\t\tfont-weight: bold;\n\t\t\t\tcolor: #b8ae79;\n\t\t\t}\n\t\t"])), Label, Code);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
