var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import * as Styled from "./tooltip-input.style";
import { ConvertedNumberInput, } from "ui/components/elements/input/converted-number-input";
import { ParameterInput } from "ui/components/elements/parameter-input";
export var TooltipInputType;
(function (TooltipInputType) {
    TooltipInputType["PARAMETER"] = "parameter";
    TooltipInputType["NUMBER_INPUT"] = "number-input";
})(TooltipInputType || (TooltipInputType = {}));
export var TooltipInput = function (_a) {
    var label = _a.label, type = _a.parameterType, parameter = _a.parameter;
    // if a label is passed as a prop we hide the default label for the parameter
    var hideDefaultLabel = label !== undefined;
    return (_jsxs(Styled.TooltipInputWrapper, { children: [label && _jsx(Styled.TooltipLabel, { children: label }), type === TooltipInputType.PARAMETER ? (_jsx(ParameterInput, { parameter: parameter, hideLabel: hideDefaultLabel })) : (_jsx(ConvertedNumberInput, __assign({}, parameter)))] }));
};
