var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { Icon } from "@animech/ui";
import * as Styled from "./accordion.style";
import { InputContainer, } from "ui/components/elements/input/input-container";
export var CustomAccordion = function (_a) {
    var title = _a.title, selectedItemLabel = _a.selectedItemLabel, lock = _a.lock, disabled = _a.disabled, description = _a.description, children = _a.children;
    var _b = __read(useState(false), 2), isOpen = _b[0], setIsOpen = _b[1];
    var accordionContentRef = useRef(null);
    var toggleOpen = function () {
        setIsOpen(function (open) { return !open; });
    };
    /* When a user opens one of the accordions, we want to automatically scroll the content so all items are
     * in view at the same time. We achieve this with a delay so the DOM has time to update the element dimensions before we try to scroll */
    useEffect(function () {
        var accordionContentElement = accordionContentRef.current;
        if (!accordionContentElement || !isOpen)
            return;
        var scrollOptions = {
            block: "end",
            behavior: "smooth",
        };
        var delayedScrollEvent = setTimeout(function () {
            accordionContentElement.scrollIntoView(scrollOptions);
        }, 200);
        return function () {
            clearTimeout(delayedScrollEvent);
        };
    }, [isOpen, accordionContentRef]);
    return (_jsx(InputContainer, { label: title, lock: lock, description: description, children: _jsxs(Styled.Root, { isDisabled: disabled, children: [_jsxs(Styled.AccordionToggle, { onClick: toggleOpen, children: [_jsx(Icon, { color: "primary", name: "".concat(isOpen ? "chevron-up" : "chevron-down") }), _jsx(Styled.AccordionLabel, { children: selectedItemLabel !== null && selectedItemLabel !== void 0 ? selectedItemLabel : "" })] }), isOpen && (_jsx(Styled.AccordionContent, { ref: accordionContentRef, children: children }))] }) }));
};
