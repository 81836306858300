var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable camelcase */
import { defaultMetalCupMaterialTransform, } from "3d/types";
/** ---------------   **/
/**    BFF Family     **/
/** ---------------   **/
export var suctionCupsFamilyBFF = {
    BFF: __assign(__assign({ cupModelName: "BFF60P", cupWidth: 0.06, cupHeight: 0.06 }, defaultMetalCupMaterialTransform.PU55), { fittingModelName: "G38M01" }),
    // BFF30P
    suction_cup_0201821: __assign(__assign({ cupModelName: "BFF30P", cupWidth: 0.03, cupHeight: 0.03 }, defaultMetalCupMaterialTransform.PU55), { fittingModelName: "G38M01-S1" }),
    // BFF40P
    suction_cup_0118991: __assign(__assign({ cupModelName: "BFF40P", cupWidth: 0.04, cupHeight: 0.04 }, defaultMetalCupMaterialTransform.PU55), { fittingModelName: "G38M01-S2" }),
};
