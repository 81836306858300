var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { H6 } from "@animech/ui";
import { useRef } from "react";
import * as Styled from "./application-speed-box.style";
import { SimpleBox } from "ui/components/panels/dashboard/boxes/simple-box";
import { DashboardValue as DashboardValueBase } from "ui/components/panels/dashboard/boxes/dashboard-value";
import { Chart, getScreenPosXFromRef, } from "ui/components/elements/chart/chart";
import { ArrowUpLeftIcon } from "ui/components/elements/icons/arrow-up-left";
import { GraphIcon } from "ui/components/elements/icons/graph";
import { IconButton } from "ui/components/elements/icons/icon-button";
import { MenuItem } from "store/types";
import { Unit } from "utility/number";
import { useAppSelector, selectors, useAppDispatch, actions } from "store";
import { useSystemPerformanceValidityMessage } from "hooks/messages";
import { MessagesPopover, MessageLevel, } from "ui/components/elements/messages-popover";
import { InfoButton } from "ui/components/elements/info-button";
import * as StyledShared from "ui/components/panels/dashboard/boxes/shared.style";
export var ApplicationSpeedBox = function () {
    var _a, _b;
    var isGraphPanelOpen = useAppSelector(selectors.selectIsGraphPanelOpen);
    var isSystemPerformanceLoading = useAppSelector(selectors.selectIsPerformanceLoading);
    var dashboardData = useAppSelector(selectors.selectDashboardData);
    var isSystemDataLoading = useAppSelector(selectors.selectIsSystemDataLoading);
    var transferValueRef = useRef(null);
    var returnValueRef = useRef(null);
    var transferPosX = getScreenPosXFromRef(transferValueRef);
    var returnPosX = getScreenPosXFromRef(returnValueRef);
    var dispatch = useAppDispatch();
    var t = useTranslation().t;
    var systemPerformanceMessage = useSystemPerformanceValidityMessage();
    return (_jsx(Styled.ApplicationSpeedBox, { children: _jsxs(SimpleBox, { isLoading: isSystemDataLoading || isSystemPerformanceLoading, children: [_jsxs(StyledShared.TitleContainer, { children: [dashboardData && (_jsxs(_Fragment, { children: [_jsx(IconButton, { color: "secondary", appearance: "inverted", onClick: function () {
                                        return dispatch(actions.openMenuItem(MenuItem.APPLICATION_SPEED));
                                    }, children: _jsx(ArrowUpLeftIcon, { size: 18 }) }), _jsx(IconButton, { color: "secondary", appearance: "inverted", onClick: function () {
                                        return dispatch(actions.toggleGraphPanel(!isGraphPanelOpen));
                                    }, children: _jsx(GraphIcon, { size: 18 }) })] })), _jsxs(H6, { children: [" ", t("dashboard.application_speed"), " "] }), _jsx(InfoButton, { title: t("dashboard.application_speed"), description: {
                                long: t("descriptions.dashboard.application_speed"),
                            } }), systemPerformanceMessage && (_jsx(MessagesPopover, { messages: [systemPerformanceMessage], level: MessageLevel.WARNING, toggleEvent: "hover", size: "small" }))] }), _jsx(Chart, { transferValueElementPosX: transferPosX, returnValueElementPosX: returnPosX }), _jsxs("div", { className: "".concat(Styled.prefix, "-values-container"), children: [_jsxs("div", { className: "".concat(Styled.prefix, "-values-section"), children: [_jsx(DashboardValue, { name: t("dashboard.parts_per_minute"), value: dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.targetPartsPerMinute.value }), _jsx(DashboardValue, { name: t("dashboard.sec_per_cycle"), value: dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.targetSecsPerCycle.value })] }), _jsx("div", { className: "".concat(Styled.prefix, "-values-section"), ref: transferValueRef, children: _jsx(DashboardValue, { name: t("dashboard.transfer_time"), value: (_a = dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.applicationSpeed) === null || _a === void 0 ? void 0 : _a.transferToPlace.value, unit: Unit.S }) }), _jsxs("div", { className: "".concat(Styled.prefix, "-values-section-with-gap"), children: [_jsx(DashboardValue, { name: t("dashboard.total_time"), value: dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.totalTransferTime.value, unit: Unit.S }), _jsx("div", { ref: returnValueRef, children: _jsx(DashboardValue, { name: t("dashboard.return_time"), value: (_b = dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.applicationSpeed) === null || _b === void 0 ? void 0 : _b.returnToPick.value, unit: Unit.S }) })] })] })] }) }));
};
var DashboardValue = function (_a) {
    var props = __rest(_a, []);
    return _jsx(DashboardValueBase, __assign({}, props));
};
