var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defaultMetalCupMaterialTransform, } from "3d/types";
/** ---------------   **/
/**    OCF Family     **/
/** ---------------   **/
export var suctionCupsFamilyOCF = {
    OCF: __assign(__assign({ cupModelName: "OCF2080P", cupWidth: 0.08, cupHeight: 0.02 }, defaultMetalCupMaterialTransform.PU55), { fittingModelName: "NT38F00", collisionComponentType: "capsule" }),
};
