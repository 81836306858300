var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNumberConversion } from "hooks/number-conversion";
import * as Styled from "ui/components/panels/graphs/graph.style";
import { xAxisUnit } from "ui/components/panels/graphs/constants";
export var CustomVacuumPumpGraphTooltip = function (_a) {
    var active = _a.active, payload = _a.payload, label = _a.label, xLabel = _a.xLabel, yLabel = _a.yLabel, yUnit = _a.yUnit;
    var convertNumber = useNumberConversion();
    var t = useTranslation().t;
    /**
     * Get the display value for a given value and unit, as a string.
     * Inverts the value if invert is true, i.e. 1 -> -1.
     */
    var getDisplayValue = useCallback(function (value, fromUnit, invert) {
        if (invert === void 0) { invert = false; }
        var displayValue = "";
        var isValuePositive = value >= 0;
        if (isValuePositive) {
            /* We perform the string replace of the "-" sign from the default unit "-kpa/-inHg" if the values are positive,
             * to avoid having double negatives like: ( -15 -kpa/-inHg ) */
            displayValue = convertNumber(value, fromUnit).formatted.replace("-", "");
        }
        else {
            var invertedValue = value * -1;
            displayValue = convertNumber(invert ? invertedValue : value, fromUnit).formatted;
        }
        return displayValue;
    }, [convertNumber]);
    var selectedSystemMinMax = useMemo(function () {
        var e_1, _a;
        if (!payload)
            return [0, 0];
        var min = Number.MAX_SAFE_INTEGER;
        var max = Number.MIN_SAFE_INTEGER;
        try {
            for (var payload_1 = __values(payload), payload_1_1 = payload_1.next(); !payload_1_1.done; payload_1_1 = payload_1.next()) {
                var element = payload_1_1.value;
                min = Math.min(element.value, min);
                max = Math.max(element.value, max);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (payload_1_1 && !payload_1_1.done && (_a = payload_1.return)) _a.call(payload_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return [min, max];
    }, [payload]);
    if (!active || !payload || payload.length === 0)
        return null;
    var time = getDisplayValue(label, xAxisUnit);
    // Since the values are flipped for the end-user, we access the max value for the minimum value, and vice versa.
    var systemMin = getDisplayValue(selectedSystemMinMax[1], yUnit, true);
    var systemMax = getDisplayValue(selectedSystemMinMax[0], yUnit, true);
    return (_jsxs(Styled.CustomTooltip, { children: [_jsx("p", { children: "".concat(xLabel, ": ").concat(time) }), _jsx("p", { children: _jsx("strong", { children: yLabel }) }), _jsx(PayloadList, { payload: payload, getDisplayValue: getDisplayValue, yUnit: yUnit }), _jsxs("p", { children: [t("graph_panel.selected_system_min"), ": ", _jsx("strong", { children: systemMin })] }), _jsxs("p", { children: [t("graph_panel.selected_system_max"), ": ", _jsx("strong", { children: systemMax })] })] }));
};
var PayloadList = function (_a) {
    var payload = _a.payload, getDisplayValue = _a.getDisplayValue, yUnit = _a.yUnit;
    var t = useTranslation().t;
    var maxEntries = 6;
    var list = payload
        .slice(0, maxEntries)
        .map(function (entry) { return (_jsx(PayloadEntry, { entry: entry, getDisplayValue: getDisplayValue, yUnit: yUnit }, entry.dataKey)); });
    var andMore = payload.length > maxEntries && (_jsx("p", { children: t("graph_panel.and_number_more", {
            number: payload.length - maxEntries,
        }) }));
    return (_jsxs(_Fragment, { children: [list, andMore] }));
};
var PayloadEntry = function (_a) {
    var entry = _a.entry, getDisplayValue = _a.getDisplayValue, yUnit = _a.yUnit;
    return (_jsxs("p", { children: [_jsx("strong", { style: { color: entry.color }, children: "- " }), "".concat(entry.name, ": "), _jsx("strong", { children: getDisplayValue(entry.value, yUnit, true) })] }, entry.dataKey));
};
