var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@emotion/styled";
import { css } from "@emotion/react";
export var bubbleWidth = 27;
export var bubbleHeight = 32;
export var verticalLineWidth = 5;
export var verticalLineHeight = 100;
export var checkboxWidth = 9;
export var checkboxHeight = 9;
export var BubbleSvgContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var scale = _a.scale;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t\t\tdisplay: flex;\n\t\t\tjustify-content: end;\n\t\t\ttransform: scale(", ");\n\t\t"], ["\n\t\t\tdisplay: flex;\n\t\t\tjustify-content: end;\n\t\t\ttransform: scale(", ");\n\t\t"])), scale);
});
export var CheckboxSvgContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var scale = _a.scale;
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t\t\tposition: absolute;\n\t\t\tright: 7px;\n\t\t\ttop: 3px;\n\t\t\ttransform: scale(", ");\n\t\t"], ["\n\t\t\tposition: absolute;\n\t\t\tright: 7px;\n\t\t\ttop: 3px;\n\t\t\ttransform: scale(", ");\n\t\t"])), scale);
});
export var VerticalLineSvgContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\tdisplay: flex;\n\tjustify-content: end;\n"], ["\n\tdisplay: flex;\n\tjustify-content: end;\n"])));
export var Steps = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
export var Step = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var clickable = _a.clickable;
    return css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n\t\t\t", "\n\t\t"], ["\n\t\t\t", "\n\t\t"])), clickable
        ? css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n\t\t\t\t\t\tsvg:hover {\n\t\t\t\t\t\t\tcursor: pointer;\n\t\t\t\t\t\t}\n\t\t\t\t  "], ["\n\t\t\t\t\t\tsvg:hover {\n\t\t\t\t\t\t\tcursor: pointer;\n\t\t\t\t\t\t}\n\t\t\t\t  "]))) : "");
});
export var StepLabel = styled.h6(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var fontSize = _a.fontSize;
    return css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n\t\t\tfont-size: ", "px;\n\t\t"], ["\n\t\t\tfont-size: ", "px;\n\t\t"])), fontSize);
});
export var Checklist = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n\t\t\tposition: absolute;\n\t\t\tright: 0;\n\t\t\tbottom: 50vh;\n\t\t\theight: 500px;\n\t\t\twidth: 10%;\n\t\t\ttransform: translateY(50%);\n\n\t\t\t", " {\n\t\t\t\tposition: absolute;\n\t\t\t\tright: ", "px;\n\t\t\t\twidth: 150px;\n\t\t\t\ttext-align: end;\n\t\t\t\tmargin: 0;\n\t\t\t\tpadding-right: ", ";\n\t\t\t\tdisplay: flex;\n\t\t\t\theight: ", "px;\n\t\t\t\talign-items: center;\n\t\t\t\tjustify-content: flex-end;\n\t\t\t\tpointer-events: none;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tdisplay: flex;\n\t\t\t\tflex-direction: column;\n\t\t\t\talign-items: end;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tposition: relative;\n\t\t\t\theight: 100px;\n\t\t\t}\n\t\t"], ["\n\t\t\tposition: absolute;\n\t\t\tright: 0;\n\t\t\tbottom: 50vh;\n\t\t\theight: 500px;\n\t\t\twidth: 10%;\n\t\t\ttransform: translateY(50%);\n\n\t\t\t", " {\n\t\t\t\tposition: absolute;\n\t\t\t\tright: ", "px;\n\t\t\t\twidth: 150px;\n\t\t\t\ttext-align: end;\n\t\t\t\tmargin: 0;\n\t\t\t\tpadding-right: ", ";\n\t\t\t\tdisplay: flex;\n\t\t\t\theight: ", "px;\n\t\t\t\talign-items: center;\n\t\t\t\tjustify-content: flex-end;\n\t\t\t\tpointer-events: none;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tdisplay: flex;\n\t\t\t\tflex-direction: column;\n\t\t\t\talign-items: end;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tposition: relative;\n\t\t\t\theight: 100px;\n\t\t\t}\n\t\t"])), StepLabel, bubbleWidth + 5, theme.dimensions.margin.base, bubbleHeight, Steps, Step);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13;
