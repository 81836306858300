var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Unit } from "utility/number";
// Set this to true to use local graph data for faster implementation of the graph data visual;
export var USE_LOCAL_GRAPH_DATA = false;
export var graphRightPadding = 500;
export var graphPadding = 15;
export var graphWidth = 1300 - graphRightPadding;
export var graphMargins = {
    top: -24,
    right: 30,
    left: 50,
    bottom: -24,
};
export var defaultGraphStrokeWidth = 3;
export var xAxisKey = "time";
export var xAxisUnit = Unit.S;
export var defaultYAxisKey = "y";
export var graphGap = 32;
export var chartParts = 4;
export var vacuumPumpGraphHeight = 170;
export var vacuumPumpYAxisUnit = Unit.KPA;
export var robotMotionGraphHeight = 94;
export var robotMotionYAxisKey = defaultYAxisKey;
export var robotMotionColor = "#c4c4c4";
export var robotMotionYAxisUnit = Unit.PERCENTAGE;
export var airConsumptionGraphHeight = 74;
export var airConsumptionYAxisKey = defaultYAxisKey;
export var airConsumptionColor = "#c4c4c4";
export var airConsumptionYAxisUnit = Unit.NL_MIN;
export var liftingForcesGraphHeight = 170;
export var liftingForcesColor = "#c4c4c4";
export var liftingForcesYAxisUnit = Unit.N;
export var liftingForcesYAxisHorizontalKey = "y-horizontal";
export var liftingForcesYAxisVerticalKey = "y-vertical";
export var yAxisTickColor = { fill: "white" };
export var xAxisTickColor = { fill: "white" };
export var yAxisPadding = { top: 34, bottom: 28 };
export var vacuumPumpIdPrefix = "vacuumPump-";
export var suctionCupIdPrefix = "suctionCup-";
export var liftingForceTooltipPosition = { x: graphWidth, y: -300 };
export var vacuumTooltipPosition = {
    x: graphWidth,
    y: -50,
};
export var graphHeight = robotMotionGraphHeight +
    airConsumptionGraphHeight +
    vacuumPumpGraphHeight +
    liftingForcesGraphHeight +
    graphGap * (chartParts - 1) +
    185;
export var graphContainerHeight = graphHeight - 100;
// This property is needed for the zoom to function properly. I'm not sure why though.
var defaultAxisProps = {
    allowDataOverflow: true,
};
export var defaultXAxisProps = __assign(__assign({}, defaultAxisProps), { dataKey: xAxisKey, type: "number", tickMargin: 10 });
export var defaultYAxisProps = __assign({}, defaultAxisProps);
export var yAxisUnitDxMargin = "-2em";
export var yAxisDyTSpan = "0.355em";
