var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { H6, Icon } from "@animech/ui";
import { useState } from "react";
import * as Styled from "./air-consumption-box.style";
import { SimpleBox } from "./simple-box";
import { DashboardValue } from "./dashboard-value";
import * as StyledShared from "./shared.style";
import { EnergyUsageBox } from "./energy-usage-box";
import { Toggle } from "ui/components/elements/toggle";
import { Unit } from "utility/number";
import { useAppSelector, useAppDispatch, selectors, actions } from "store";
import { useSystemPerformanceValidityMessage } from "hooks/messages";
import { MessagesPopover, MessageLevel, } from "ui/components/elements/messages-popover";
import { InfoButton } from "ui/components/elements/info-button";
import { LightningBoltIcon } from "ui/components/elements/icons/lightning-bolt";
export var AirConsumptionBox = function () {
    var t = useTranslation().t;
    var dispatch = useAppDispatch();
    var _a = __read(useState(false), 2), isEnergyUsageOpen = _a[0], setIsEnergyUsageOpen = _a[1];
    var showEnergySavePerformance = useAppSelector(selectors.selectShowEnergySave);
    var hasReachedEnergySave = useAppSelector(selectors.selectHasReachedEnergySave);
    var isSystemDataLoading = useAppSelector(selectors.selectIsSystemDataLoading);
    var isSystemPerformanceLoading = useAppSelector(selectors.selectIsPerformanceLoading);
    var dashboardData = useAppSelector(selectors.selectDashboardData);
    var systemVolume = useAppSelector(selectors.selectVacuumSystemVolume);
    var systemPerformanceMessage = useSystemPerformanceValidityMessage();
    var toggleEnergySaving = function () {
        dispatch(actions.setShowEnergySavePerformance(!showEnergySavePerformance));
    };
    var toggleEnergyUsageOpen = function () {
        setIsEnergyUsageOpen(function (prevOpenState) { return !prevOpenState; });
    };
    return (_jsxs(Styled.AirConsumptionBox, { energyUsageOpen: isEnergyUsageOpen, children: [_jsx(EnergyUsageBox, { isOpen: isEnergyUsageOpen }), _jsx(SimpleBox, { isLoading: isSystemDataLoading || isSystemPerformanceLoading, className: "".concat(Styled.prefix, "-simple-box"), children: _jsxs(Styled.Grid, { children: [_jsxs(Styled.GridLeft, { children: [_jsx(LightningBoltIcon, {}), _jsx(Styled.ArrowIconContainer, { onClick: toggleEnergyUsageOpen, children: _jsx(Icon, { name: "chevron-up", color: "primary" }) })] }), _jsxs(Styled.GridCenter, { children: [_jsx(StyledShared.TitleContainer, { children: _jsxs(H6, { children: [" ", t("dashboard.air_consumption"), " "] }) }), _jsx("div", { className: "".concat(Styled.prefix, "-es-toggle"), children: _jsx(Toggle, { heading: "ES", checked: showEnergySavePerformance, onChange: toggleEnergySaving, checkedLabel: t("dashboard.es_on"), uncheckedLabel: t("dashboard.es_off"), disabled: !hasReachedEnergySave }) })] }), _jsxs(Styled.GridRight, { children: [_jsx(InfoButton, { title: t("dashboard.air_consumption"), description: {
                                        long: t("descriptions.dashboard.air_consumption"),
                                    } }), systemPerformanceMessage && (_jsx(MessagesPopover, { messages: [systemPerformanceMessage], level: MessageLevel.WARNING, toggleEvent: "hover", size: "small" }))] }), _jsx(Styled.GridValues, { children: _jsxs("div", { className: "".concat(Styled.prefix, "-airconsumption-values"), children: [_jsx(DashboardValue, { value: dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.airConsumption.perCycle.value, unit: Unit.NL_CYCLE }), _jsx(DashboardValue, { value: dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.airConsumption.perMinute.value, unit: Unit.NL_MIN })] }) }), _jsx(Styled.GridBottom, { children: _jsx(DashboardValue, { name: t("dashboard.system_volume"), value: systemVolume, unit: Unit.L, flexDirection: "row-reverse" }) })] }) })] }));
};
