var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { ReferenceArea } from "recharts";
import { useGraphDataContext } from "providers/graph-panel";
export var ZoomHighlightedArea = function (props) {
    var domain = props.domain, restProps = __rest(props, ["domain"]);
    var _a = useGraphDataContext(), zoomAreaLeftX = _a.zoomAreaLeftX, zoomAreaRightX = _a.zoomAreaRightX;
    if (zoomAreaLeftX === undefined || zoomAreaRightX === undefined)
        return null;
    return (_jsx(ReferenceArea, __assign({}, restProps, { y1: domain[0], y2: domain[1], x1: zoomAreaLeftX, x2: zoomAreaRightX, strokeOpacity: 0.3 })));
};
ZoomHighlightedArea.displayName = ReferenceArea.displayName;
ZoomHighlightedArea.defaultProps = ReferenceArea.defaultProps;
