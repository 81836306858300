var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
/* eslint-disable camelcase */
import { adjustableBox, adjustableCylinder } from "./adjustable-shapes";
import { defaultObjectCameraViews } from "./camera-views";
import { suctionCupsFamilyDCF, suctionCupsFamilyFCF, suctionCupsFamilyBFFT, suctionCupsFamilyBFF, suctionCupsFamilyBXF, suctionCupsFamilyOCF, suctionCupsFamilyOBF, suctionCupsFamilyPAF, suctionCupsFamilyPAB, suctionCupsFamilyPAOF, suctionCupsFamilyPAOB, suctionCupsFamilyPAXB, suctionCupsFamilyXLF, } from "./cup-families";
import { Material } from "3d/types";
import { ObjectShapeId as MetalObjectShapeId } from "store/types";
export var suctionCupModelsMetal = __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, suctionCupsFamilyDCF), suctionCupsFamilyFCF), suctionCupsFamilyBFFT), suctionCupsFamilyBFF), suctionCupsFamilyBXF), suctionCupsFamilyOCF), suctionCupsFamilyOBF), suctionCupsFamilyPAF), suctionCupsFamilyPAB), suctionCupsFamilyPAOF), suctionCupsFamilyPAOB), suctionCupsFamilyPAXB), suctionCupsFamilyXLF);
var defaultMetalObjectShapeData = {
    cameraViews: __assign({}, defaultObjectCameraViews),
    objectDefaultSize: { x: 1, y: 1, z: 1 },
    materialTransforms: [
        {
            sourceMaterial: Material.MA_TESTOBJECT,
            targetMaterial: Material.METAL,
        },
    ],
};
export var metalObjectShapes = (_a = {},
    _a[MetalObjectShapeId.ADJUSTABLE_METAL_BOX] = __assign(__assign(__assign({}, defaultMetalObjectShapeData), adjustableBox), { image: "img/app/object-shapes/metal/MetalCube100x100x100cm_Arrows.webp" }),
    _a[MetalObjectShapeId.ADJUSTABLE_METAL_CYLINDER] = __assign(__assign(__assign({}, defaultMetalObjectShapeData), adjustableCylinder), { image: "img/app/object-shapes/metal/MetalCylinderHorizontal100x100cm_Arrows.webp" }),
    _a[MetalObjectShapeId.CAR_HOOD] = __assign(__assign({}, defaultMetalObjectShapeData), { translationKey: "sidebar.object_shape.objects.metal.hood", modelName: "TestObj-MetalBonnet01", image: "img/app/object-shapes/metal/hood.webp", gripPositionY: 5.05, tubeNetworkY: 5.43 }),
    _a[MetalObjectShapeId.CAR_DOOR_OUTER] = __assign(__assign({}, defaultMetalObjectShapeData), { translationKey: "sidebar.object_shape.objects.metal.door_outer", modelName: "TestObj-MetalDoorpanelOuter01", image: "img/app/object-shapes/metal/door-outer.webp", gripPositionY: 5.02, tubeNetworkY: 5.43 }),
    _a[MetalObjectShapeId.CAR_ROOF] = __assign(__assign({}, defaultMetalObjectShapeData), { translationKey: "sidebar.object_shape.objects.metal.roof_1", modelName: "TestObj-MetalRoof01", image: "img/app/object-shapes/metal/roof.webp", gripPositionY: 5.0, tubeNetworkY: 5.43 }),
    _a[MetalObjectShapeId.CAR_ROOF_2] = __assign(__assign({}, defaultMetalObjectShapeData), { translationKey: "sidebar.object_shape.objects.metal.roof_2", modelName: "TestObj-MetalRoof02", image: "img/app/object-shapes/metal/roof2.webp", gripPositionY: 5.0, tubeNetworkY: 5.43, hiddenInProd: true }),
    _a[MetalObjectShapeId.CAR_ROOF_3] = __assign(__assign({}, defaultMetalObjectShapeData), { translationKey: "sidebar.object_shape.objects.metal.roof_3", modelName: "TestObj-MetalRoof03", image: "img/app/object-shapes/metal/roof3.webp", gripPositionY: 5.0, tubeNetworkY: 5.43, hiddenInProd: true }),
    _a[MetalObjectShapeId.CAR_ROOF_4] = __assign(__assign({}, defaultMetalObjectShapeData), { translationKey: "sidebar.object_shape.objects.metal.roof_4", modelName: "TestObj-MetalRoof04", image: "img/app/object-shapes/metal/roof4.webp", gripPositionY: 5.0, tubeNetworkY: 5.43, hiddenInProd: true }),
    _a[MetalObjectShapeId.CAR_BODY_SIDE] = __assign(__assign({}, defaultMetalObjectShapeData), { translationKey: "sidebar.object_shape.objects.metal.body_side", modelName: "TestObj-MetalBodySide01", image: "img/app/object-shapes/metal/body-side.webp", gripPositionX: -0.15, gripPositionY: 5.0, tubeNetworkY: 5.43 }),
    _a[MetalObjectShapeId.CAR_DOOR_INNER] = __assign(__assign({}, defaultMetalObjectShapeData), { translationKey: "sidebar.object_shape.objects.metal.door_inner", modelName: "TestObj-MetalDoorpanelInner01", image: "img/app/object-shapes/metal/door-inner.webp", gripPositionY: 5.1, tubeNetworkY: 5.43 }),
    _a[MetalObjectShapeId.CAR_FENDER_FRONT] = __assign(__assign({}, defaultMetalObjectShapeData), { translationKey: "sidebar.object_shape.objects.metal.fender_front", modelName: "TestObj-MetalFenderFront01", image: "img/app/object-shapes/metal/fender-front.webp", gripPositionX: -0.35, gripPositionY: 5.0, gripPositionZ: -0.24, tubeNetworkY: 5.43 }),
    _a[MetalObjectShapeId.CAR_FLOOR_PANEL] = __assign(__assign({}, defaultMetalObjectShapeData), { translationKey: "sidebar.object_shape.objects.metal.floor_panel", modelName: "TestObj-MetalFloorpanel01", image: "img/app/object-shapes/metal/floor-panel.webp", gripPositionY: 5.0, tubeNetworkY: 5.43 }),
    _a[MetalObjectShapeId.CAR_TRUNK_COVER] = __assign(__assign({}, defaultMetalObjectShapeData), { translationKey: "sidebar.object_shape.objects.metal.trunk_cover", modelName: "TestObj-MetalTrunkcover01", image: "img/app/object-shapes/metal/trunk-cover.webp", gripPositionY: 5.0, tubeNetworkY: 5.43 }),
    _a);
