var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@emotion/styled";
import { css } from "@emotion/react";
export var prefix = "simple-box";
// We export this color since its also reused for the graph panel;
export var dashBoardBackgroundColor = "rgba(33, 36, 38, 0.94)";
export var simpleBoxHeight = 175;
export var SimpleBox = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme, roundedCorners = _a.roundedCorners;
    var borderRadiusSize = theme.dimensions.borderRadius;
    var borderRadius = "none";
    switch (roundedCorners) {
        case "all":
            borderRadius = "".concat(borderRadiusSize);
            break;
        case "top":
            borderRadius = "".concat(borderRadiusSize, " ").concat(borderRadiusSize, " 0 0");
            break;
        case "bottom":
            borderRadius = "0 0 ".concat(borderRadiusSize, " ").concat(borderRadiusSize);
            break;
    }
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t\t\th6 {\n\t\t\t\tfont-size: 15px;\n\t\t\t}\n\t\t\twidth: 100%;\n\t\t\theight: ", "px;\n\t\t\tbackground-color: ", ";\n\t\t\tpadding: ", ";\n\t\t\tposition: relative;\n\t\t\ttext-align: center;\n\t\t\tborder-radius: ", ";\n\t\t"], ["\n\t\t\th6 {\n\t\t\t\tfont-size: 15px;\n\t\t\t}\n\t\t\twidth: 100%;\n\t\t\theight: ", "px;\n\t\t\tbackground-color: ", ";\n\t\t\tpadding: ", ";\n\t\t\tposition: relative;\n\t\t\ttext-align: center;\n\t\t\tborder-radius: ", ";\n\t\t"])), simpleBoxHeight, dashBoardBackgroundColor, theme.dimensions.margin.small, borderRadius);
});
var templateObject_1, templateObject_2;
