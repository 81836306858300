/*
 * Before updating the version, make sure you have
 * read and followed the "Guidelines for updating versioned
 * state types" section in the README
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ObjectShapeId as ObjectShapeIdOld, } from "./types.v5";
export var CardboardObjectShapeId = {
    WITH_SEAM: "with_seam",
    WITHOUT_SEAM: "without_seam",
};
export var ObjectShapeId = __assign(__assign({}, ObjectShapeIdOld), CardboardObjectShapeId);
export { TubeConnectionId, TubeType, GripPositionMarkerId, NodeComponentType, GlassObjectShapeId, PlasticObjectShapeId, MetalObjectShapeId, } from "./types.v5";
