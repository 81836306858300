var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { CheckBox as CheckBoxFromProps, StyledCheckBox } from "@animech/ui";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
export var CheckBox = styled(CheckBoxFromProps)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t\t\tfloat: right;\n\t\t\talign-items: center;\n\t\t\tmargin-bottom: ", ";\n\n\t\t\t", " {\n\t\t\t\tcolor: ", " !important;\n\t\t\t}\n\n\t\t\t&[data-color=\"primary\"][data-appearance=\"solid\"] {\n\t\t\t\t", " {\n\t\t\t\t\tbackground: none;\n\t\t\t\t\tborder: 2px solid ", ";\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t&[data-color=\"primary\"][data-appearance=\"solid\"][data-checked] {\n\t\t\t\t", " {\n\t\t\t\t\tborder: none;\n\t\t\t\t}\n\t\t\t}\n\t\t"], ["\n\t\t\tfloat: right;\n\t\t\talign-items: center;\n\t\t\tmargin-bottom: ", ";\n\n\t\t\t", " {\n\t\t\t\tcolor: ", " !important;\n\t\t\t}\n\n\t\t\t&[data-color=\"primary\"][data-appearance=\"solid\"] {\n\t\t\t\t", " {\n\t\t\t\t\tbackground: none;\n\t\t\t\t\tborder: 2px solid ", ";\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t&[data-color=\"primary\"][data-appearance=\"solid\"][data-checked] {\n\t\t\t\t", " {\n\t\t\t\t\tborder: none;\n\t\t\t\t}\n\t\t\t}\n\t\t"])), theme.dimensions.margin.small, StyledCheckBox.Label, theme.colors.palette.white, StyledCheckBox.CheckedIcon, theme.colors.palette.green[500], StyledCheckBox.CheckedIcon);
});
var templateObject_1, templateObject_2;
