var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable camelcase */
import { Material } from "3d/types";
/** ---------------   **/
/**      F Family     **/
/** ---------------   **/
var cupModelTemplate = {
    F15: {
        cupModelName: "F15",
        cupWidth: 0.0157,
        cupHeight: 0.016,
        fittingModelName: "MM5M-S1",
    },
    F30_2: {
        cupModelName: "F30-2",
        cupWidth: 0.032,
        cupHeight: 0.0154,
        fittingModelName: "G38M01-S5",
    },
};
var materialTransformTemplate = {
    RED_SIL: {
        materialTransforms: [
            { sourceMaterial: Material.MA_MAIN, targetMaterial: Material.S },
        ],
    },
    MF: {
        materialTransforms: [
            { sourceMaterial: Material.MA_MAIN, targetMaterial: Material.SF },
        ],
    },
    HNBR: {
        materialTransforms: [
            { sourceMaterial: Material.MA_MAIN, targetMaterial: Material.HN },
        ],
    },
};
export var suctionCupsFamilyF = {
    // F15 Red Sil
    suction_cup_3150014S: __assign(__assign({}, cupModelTemplate.F15), materialTransformTemplate.RED_SIL),
    // F15MF
    suction_cup_3250074: __assign(__assign({}, cupModelTemplate.F15), materialTransformTemplate.MF),
    // F20 Red Sil
    suction_cup_0101271: __assign(__assign({}, cupModelTemplate.F30_2), materialTransformTemplate.RED_SIL),
    // F20MF
    suction_cup_0101282: __assign(__assign({}, cupModelTemplate.F30_2), materialTransformTemplate.MF),
    // F25 Red Sil
    suction_cup_0101304: __assign(__assign({}, cupModelTemplate.F30_2), materialTransformTemplate.RED_SIL),
    // F25MF
    suction_cup_9911577: __assign(__assign({}, cupModelTemplate.F30_2), materialTransformTemplate.MF),
    // F30-2 Red Sil
    suction_cup_0101342: __assign(__assign({}, cupModelTemplate.F30_2), materialTransformTemplate.RED_SIL),
    // F30MF
    suction_cup_0101358: __assign(__assign({}, cupModelTemplate.F30_2), materialTransformTemplate.MF),
    // F40-2 Red Sil
    suction_cup_0101550: __assign(__assign({}, cupModelTemplate.F30_2), materialTransformTemplate.RED_SIL),
    // F40MF
    suction_cup_0101588: __assign(__assign({}, cupModelTemplate.F30_2), materialTransformTemplate.MF),
    // F50-2 Red Sil
    suction_cup_0101750: __assign(__assign({}, cupModelTemplate.F30_2), materialTransformTemplate.RED_SIL),
    // F50-2 HNBR
    suction_cup_0108183: __assign(__assign({}, cupModelTemplate.F30_2), materialTransformTemplate.HNBR),
    // F50MF
    suction_cup_0101788: __assign(__assign({}, cupModelTemplate.F30_2), materialTransformTemplate.MF),
};
