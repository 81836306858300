var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useLocation, useNavigate } from "react-router-dom";
import { H5, MessageBox, Wizard, WizardStep } from "@animech/ui";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as Styled from "./wizard.style";
import { ParameterInput } from "ui/components/elements/parameter-input";
import { ErrorPanel } from "ui/components/panels/error";
import { actions, useAppSelector, selectors, useAppDispatch } from "store";
import { PageRoute } from "utility/url";
import { DefineCardboardContainer } from "ui/components/modals/define-cardboard";
import { SpecialGroupLayout } from "api/types";
import { applicationGroupName, cardboardBoxSpecificationGroupName, } from "api/tacton/constants";
export var WizardPanel = function (_a) {
    var nextRoute = _a.nextRoute, wizardProps = _a.wizardProps, previousRoute = _a.previousRoute, titleID = _a.titleID;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var systemDataErrors = useAppSelector(selectors.selectSystemDataErrors);
    var _b = __read(useState(), 2), activeStep = _b[0], setActiveStep = _b[1];
    var isDefineParametersPage = useLocation().pathname === PageRoute.DEFINE_PARAMETERS;
    var systemDataGroupsDefineParameters = useAppSelector(selectors.selectGroupedParametersDefineParameters);
    var systemDataGroupsSelectApplication = useAppSelector(selectors.selectGroupedParametersSelectApplication);
    var dispatch = useAppDispatch();
    var groups = useMemo(function () {
        return isDefineParametersPage
            ? systemDataGroupsDefineParameters
            : systemDataGroupsSelectApplication;
    }, [
        isDefineParametersPage,
        systemDataGroupsDefineParameters,
        systemDataGroupsSelectApplication,
    ]);
    var applicationTemplateCode = useAppSelector(selectors.selectApplicationTemplateCode);
    var onNextStep = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!applicationTemplateCode) return [3 /*break*/, 2];
                    return [4 /*yield*/, dispatch(actions.loadTemplate(applicationTemplateCode))];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    navigate(nextRoute);
                    return [2 /*return*/];
            }
        });
    }); }, [applicationTemplateCode, navigate, nextRoute, dispatch]);
    var onPreviousStep = useCallback(function () {
        if (!previousRoute)
            return;
        navigate(previousRoute);
    }, [navigate, previousRoute]);
    useEffect(function () {
        if (!groups.length || activeStep)
            return;
        setActiveStep(groups[0].id);
    }, [activeStep, groups]);
    if (!systemDataErrors && groups.length === 0) {
        return null;
    }
    return (_jsx(Styled.WizardPanel, { activeStepIsCardboard: activeStep === cardboardBoxSpecificationGroupName, hidePreviousButton: activeStep === applicationGroupName, children: systemDataErrors && groups.length === 0 ? (_jsx(ErrorPanel, {})) : (_jsx(Wizard, { nextLabel: wizardProps.nextLabel && t("".concat(wizardProps.nextLabel)), previousLabel: wizardProps.previousLabel && t("".concat(wizardProps.previousLabel)), onNextStep: onNextStep, className: "".concat(Styled.prefix, "-container"), onPreviousStep: onPreviousStep, onActiveStepChange: setActiveStep, activeStep: activeStep, children: groups
                .filter(function (group) { return !group.hiddenInWizard; })
                .map(function (group) {
                var isCardboardStep = group.specialGroupLayout === SpecialGroupLayout.CARDBOARD;
                var stepContent = isCardboardStep ? (_jsx(DefineCardboardContainer, {})) : (_jsx(DefaultStepContent, { group: group, titleID: titleID }));
                return (_jsx(WizardStep, { id: group.id, children: stepContent }, group.id));
            }) })) }));
};
var DefaultStepContent = function (_a) {
    var group = _a.group, titleID = _a.titleID;
    var t = useTranslation().t;
    var systemDataErrors = useAppSelector(selectors.selectSystemDataErrors);
    return (_jsxs(_Fragment, { children: [_jsx(H5, { children: t(titleID, group.title && group.title !== ""
                    ? {
                        stepName: group.title,
                    }
                    : undefined) }), systemDataErrors &&
                systemDataErrors.map(function (error) {
                    var _a, _b;
                    return (_jsx(MessageBox, { color: "alert", className: "".concat(Styled.prefix, "-error"), children: t("tacton_api_errors.".concat(error === null || error === void 0 ? void 0 : error.type), __assign(__assign({}, error === null || error === void 0 ? void 0 : error.extra), { context: ((_a = error === null || error === void 0 ? void 0 : error.extra) === null || _a === void 0 ? void 0 : _a.parameters) && "parameters", count: ((_b = error === null || error === void 0 ? void 0 : error.extra) === null || _b === void 0 ? void 0 : _b.parameters) && error.extra.parameters.length })) }, error.message));
                }), _jsx("div", { className: "".concat(Styled.prefix, "-input-container"), children: group.parameters.map(function (parameter) {
                    if (parameter.hiddenInWizard)
                        return null;
                    return _jsx(ParameterInput, { parameter: parameter }, parameter.id);
                }) })] }));
};
