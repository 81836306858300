/* eslint-disable camelcase */
export var TactonResponseStatus;
(function (TactonResponseStatus) {
    TactonResponseStatus["RESOLVABLE"] = "RESOLVABLE";
    TactonResponseStatus["OK"] = "OK";
    TactonResponseStatus["NOT_OK"] = "not OK";
})(TactonResponseStatus || (TactonResponseStatus = {}));
export var TactonElementState;
(function (TactonElementState) {
    TactonElementState["GREEN"] = "green";
    TactonElementState["ORANGE"] = "orange";
    TactonElementState["RED"] = "red";
})(TactonElementState || (TactonElementState = {}));
export var ComponentType;
(function (ComponentType) {
    ComponentType["SUCTION_CUP"] = "suction_cup";
    ComponentType["VACUUM_PUMP_CENTRAL"] = "vac_gen_central";
    ComponentType["VACUUM_PUMP_DECENTRAL"] = "vac_gen_decentral";
    ComponentType["CUP_MOUNTING"] = "cup_mounting";
})(ComponentType || (ComponentType = {}));
export var ComponentList;
(function (ComponentList) {
    ComponentList["SUCTION_CUP"] = "suction_cup";
    ComponentList["VACUUM_PUMP"] = "vac_gen";
    ComponentList["CUP_MOUNTING"] = "cup_mounting";
})(ComponentList || (ComponentList = {}));
export var TactonStepName;
(function (TactonStepName) {
    TactonStepName["DEFINE_PARAMETERS"] = "define_application_parameters";
    TactonStepName["SELECT_APPLICATION"] = "select_application";
})(TactonStepName || (TactonStepName = {}));
export var TactonApplication;
(function (TactonApplication) {
    TactonApplication["GENERAL"] = "application_general";
    TactonApplication["SHEET_METAL"] = "application_sheetmetal";
    TactonApplication["PLASTIC"] = "application_plastic";
    TactonApplication["CARDBOARD"] = "application_cardboard";
    TactonApplication["GLASS"] = "application_glass";
})(TactonApplication || (TactonApplication = {}));
//#endregion
