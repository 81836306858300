var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Material } from "3d/types";
/** ---------------   **/
/**    FX Family    **/
/** ---------------   **/
var cupModelTemplate = {
    "FX28-B1": {
        cupModelName: "FX28-B1",
        cupWidth: 0.028,
        cupHeight: 0.0242,
        fittingModelName: "G38M01-S7",
    },
    "FX55-B1": {
        cupModelName: "FX55-B1",
        cupWidth: 0.055,
        cupHeight: 0.029,
        fittingModelName: "G38M01-S7",
    },
    "FX28-B3": {
        cupModelName: "FX28-B3",
        cupWidth: 0.028,
        cupHeight: 0.0363,
        fittingModelName: "G38M01-S7",
    },
    "FX55-B3": {
        cupModelName: "FX55-B3",
        cupWidth: 0.055,
        cupHeight: 0.0455,
        fittingModelName: "G38M01-S7",
    },
    "FX28-B6": {
        cupModelName: "FX28-B6",
        cupWidth: 0.028,
        cupHeight: 0.0552,
        fittingModelName: "G38M01-S7",
    },
    "FX55-B6": {
        cupModelName: "FX55-B6",
        cupWidth: 0.055,
        cupHeight: 0.0719,
        fittingModelName: "G38M01-S7",
    },
};
var materialTransformTemplate = {
    DURAFLEX_GREEN_T30: {
        materialTransforms: [
            {
                sourceMaterial: Material.MA_MAIN,
                targetMaterial: Material.DURAFLEX_GREEN,
            },
            {
                sourceMaterial: Material.MA_MAIN2,
                targetMaterial: Material.T30,
            },
        ],
    },
    DURAFLEX_GREEN_T50: {
        materialTransforms: [
            {
                sourceMaterial: Material.MA_MAIN,
                targetMaterial: Material.DURAFLEX_GREEN,
            },
            {
                sourceMaterial: Material.MA_MAIN2,
                targetMaterial: Material.T50,
            },
        ],
    },
};
export var suctionCupsFamilyFX = {
    // FX28-B1 Blue
    "suction_cup_G.FX28T50.B1.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FX28-B1"]), materialTransformTemplate.DURAFLEX_GREEN_T50),
    // FX39-B1 Blue
    "suction_cup_G.FX39T50.B1.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FX55-B1"]), materialTransformTemplate.DURAFLEX_GREEN_T50),
    // FX55-B1 Blue
    "suction_cup_G.FX55T50.B1.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FX55-B1"]), materialTransformTemplate.DURAFLEX_GREEN_T50),
    // FX77-B1 Blue
    "suction_cup_G.FX77T50.B1.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FX55-B1"]), materialTransformTemplate.DURAFLEX_GREEN_T50),
    // FX28-B3 Blue
    "suction_cup_G.FX28T50.B3.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FX28-B3"]), materialTransformTemplate.DURAFLEX_GREEN_T50),
    // FX39-B3 Blue
    "suction_cup_G.FX39T50.B3.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FX55-B3"]), materialTransformTemplate.DURAFLEX_GREEN_T50),
    // FX55-B3 Blue
    "suction_cup_G.FX55T50.B3.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FX55-B3"]), materialTransformTemplate.DURAFLEX_GREEN_T50),
    // FX77-B3 Blue
    "suction_cup_G.FX77T50.B3.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FX55-B3"]), materialTransformTemplate.DURAFLEX_GREEN_T50),
    // FX28-B6 Blue
    "suction_cup_G.FX28T50.B6.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FX28-B6"]), materialTransformTemplate.DURAFLEX_GREEN_T50),
    // FX39-B6 Blue
    "suction_cup_G.FX39T50.B6.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FX55-B6"]), materialTransformTemplate.DURAFLEX_GREEN_T50),
    // FX55-B6 Blue
    "suction_cup_G.FX55T50.B6.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FX55-B6"]), materialTransformTemplate.DURAFLEX_GREEN_T50),
    // FX77-B6 Blue
    "suction_cup_G.FX77T50.B6.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FX55-B6"]), materialTransformTemplate.DURAFLEX_GREEN_T50),
    // FX28-B1 Yellow
    "suction_cup_G.FX28T30.B1.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FX28-B1"]), materialTransformTemplate.DURAFLEX_GREEN_T30),
    // FX39-B1 Yellow
    "suction_cup_G.FX39T30.B1.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FX55-B1"]), materialTransformTemplate.DURAFLEX_GREEN_T30),
    // FX55 B1 Yellow
    "suction_cup_G.FX55T30.B1.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FX55-B1"]), materialTransformTemplate.DURAFLEX_GREEN_T30),
    // FX77 B1 Yellow
    "suction_cup_G.FX77T30.B1.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FX55-B1"]), materialTransformTemplate.DURAFLEX_GREEN_T30),
    // FX28-B3 Yellow
    "suction_cup_G.FX28T30.B3.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FX28-B3"]), materialTransformTemplate.DURAFLEX_GREEN_T30),
    // FX39-B3 Yellow
    "suction_cup_G.FX39T30.B3.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FX55-B3"]), materialTransformTemplate.DURAFLEX_GREEN_T30),
    // FX55-B3 Yellow
    "suction_cup_G.FX55T30.B3.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FX55-B3"]), materialTransformTemplate.DURAFLEX_GREEN_T30),
    // FX77-B3 Yellow
    "suction_cup_G.FX77T30.B3.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FX55-B3"]), materialTransformTemplate.DURAFLEX_GREEN_T30),
    // FX28-B6 Yellow
    "suction_cup_G.FX28T30.B6.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FX28-B6"]), materialTransformTemplate.DURAFLEX_GREEN_T30),
    // FX39-B6 Yellow
    "suction_cup_G.FX39T30.B6.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FX55-B6"]), materialTransformTemplate.DURAFLEX_GREEN_T30),
    // FX55-B6 Yellow
    "suction_cup_G.FX55T30.B6.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FX55-B6"]), materialTransformTemplate.DURAFLEX_GREEN_T30),
    // FX77-B6 Yellow
    "suction_cup_G.FX77T30.B6.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FX55-B6"]), materialTransformTemplate.DURAFLEX_GREEN_T30),
};
