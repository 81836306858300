import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTheme } from "@emotion/react";
export var AlertIcon = function (_a) {
    var size = _a.size, isDisabled = _a.isDisabled;
    var theme = useTheme();
    var circleColor = isDisabled
        ? theme.colors.palette.grey[500]
        : theme.colors.components.tertiary.shades[500].main;
    var contentColor = isDisabled
        ? theme.colors.palette.grey[500]
        : theme.colors.palette.white;
    return (_jsxs("svg", { width: size, height: size, viewBox: "0 0 28 28", children: [_jsx("path", { d: "M14 27C21.1797 27 27 21.1797 27 14C27 6.8203 21.1797 1 14 1C6.8203 1 1 6.8203 1 14C1 21.1797 6.8203 27 14 27Z", stroke: circleColor, strokeWidth: "2", fill: "none", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M12.9182 17.0178L12.9182 18.9265L15.0824 18.9265L15.0824 17.0178L12.9182 17.0178ZM12.9482 15.7252L15.0524 15.7252L15.0524 8L12.9482 8L12.9482 15.7252Z", fill: contentColor })] }));
};
