var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { TextBox } from "@animech/ui";
import { useNumberValidation } from "hooks/validation";
import { round } from "utility/number";
import { InputContainer, } from "ui/components/elements/input/input-container";
export var NumberInput = function (_a) {
    var valueFromProps = _a.value, label = _a.label, type = _a.type, setValue = _a.setValue, errorFromProps = _a.error, min = _a.min, max = _a.max, unit = _a.unit, _b = _a.instantUpdate, instantUpdate = _b === void 0 ? false : _b, description = _a.description, lock = _a.lock, isLoading = _a.isLoading, disabled = _a.disabled, precision = _a.precision;
    var _c = __read(useState(valueFromProps), 2), internalValue = _c[0], setInternalValue = _c[1];
    var _d = __read(useState(false), 2), isDisabled = _d[0], setIsDisabled = _d[1];
    var _e = __read(useState(""), 2), error = _e[0], setError = _e[1];
    var validate = useNumberValidation(type, min, max).validate;
    useEffect(function () {
        if (disabled || isLoading) {
            setIsDisabled(true);
        }
        else if (!disabled) {
            setIsDisabled(false);
            setInternalValue(valueFromProps);
        }
    }, [isLoading, disabled, valueFromProps]);
    useEffect(function () {
        if (errorFromProps) {
            setError(errorFromProps);
        }
    }, [errorFromProps]);
    var onChange = useCallback(function (value) {
        setInternalValue(value);
        var error = validate(value);
        if (error) {
            if (value !== "") {
                setError(error);
            }
            else {
                setError("");
            }
        }
        else {
            instantUpdate && setValue(value);
            setError("");
        }
    }, [instantUpdate, setInternalValue, validate, setValue, setError]);
    var update = useCallback(function () {
        if (internalValue === valueFromProps)
            return;
        if (internalValue !== "" && error === "") {
            var rounded = precision !== undefined
                ? round(Number(internalValue), precision)
                : internalValue;
            setValue(rounded.toString());
            setInternalValue(rounded.toString());
        }
        else {
            setInternalValue(valueFromProps);
            setError("");
        }
    }, [
        internalValue,
        valueFromProps,
        precision,
        setValue,
        setInternalValue,
        error,
        setError,
    ]);
    var onKeyPress = useCallback(function (event) {
        if (event.key === "Enter") {
            update();
        }
    }, [update]);
    var onBlur = useCallback(function () {
        update();
    }, [update]);
    return (_jsx(InputContainer, { label: label, error: error, description: description, lock: lock, children: _jsx(TextBox, { className: "number-input", rootAttributes: {
                onBlur: onBlur,
                onKeyPress: onKeyPress,
            }, color: error ? "alert" : "primary", value: internalValue, onValueChange: onChange, unit: unit, instantValueChange: true, disabled: isDisabled }) }));
};
