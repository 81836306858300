var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { v4 as uuidv4 } from "uuid";
import undoable, { includeAction } from "redux-undo";
import { createSlice } from "@reduxjs/toolkit";
import { NodeComponentType, GripPositionMarkerId, TubeConnectionId, TubeType, } from "./types.current";
import { updateTubeTypes } from "./helpers";
import { tubeDiameterOptions } from "store/types";
import { gripPositionMarkerDefaultWidth, defaultTubeConnectionType, tubeConnectionDefaultDiameter, tubeDefaultLength, objectShapes, defaultObject, feedPressurePointDefaultX, feedPressurePointDefaultZ, centralizedVacuumPumpDefaultX, centralizedVacuumPumpDefaultZ, feedPressurePointNodeId, defaultInlineTubeLength, defaultInlineTubeDiameter, } from "3d/constants/common";
import { defaultObjectSize } from "3d/constants/adjustable-shapes";
export var initialState = {
    nodes: (_a = {},
        _a[feedPressurePointNodeId] = {
            id: feedPressurePointNodeId,
            type: NodeComponentType.FEED_PRESSURE_POINT,
            component: {
                nodeId: feedPressurePointNodeId,
            },
            rotationY: 0,
            attachmentIds: [],
            position: {
                x: feedPressurePointDefaultX,
                y: objectShapes[defaultObject].tubeNetworkY,
                z: feedPressurePointDefaultZ,
            },
        },
        _a),
    attachments: {},
    edges: {},
    objectShape: defaultObject,
    objectSize: defaultObjectSize,
    hasDisconnectedEdges: true,
};
export var initialStateWithHistory = {
    past: [],
    present: __assign({}, initialState),
    future: [],
};
var addGripPosition = function (state, action) {
    var _a, _b;
    state.latestAddedGripPosition = action.payload;
    state.nodes[action.payload] = {
        id: action.payload,
        type: NodeComponentType.GRIP_POSITION,
        component: {
            nodeId: action.payload,
            marker: GripPositionMarkerId.CIRCLE,
            width: gripPositionMarkerDefaultWidth[GripPositionMarkerId.CIRCLE],
            inlineTubeLength: defaultInlineTubeLength,
            inlineTubeDiameter: defaultInlineTubeDiameter,
        },
        rotationY: 0,
        attachmentIds: [],
        position: {
            x: (_a = objectShapes[state.objectShape].gripPositionX) !== null && _a !== void 0 ? _a : 0,
            y: objectShapes[state.objectShape].gripPositionY,
            z: (_b = objectShapes[state.objectShape].gripPositionZ) !== null && _b !== void 0 ? _b : 0,
        },
    };
};
var addTubeConnection = function (state, action) {
    state.nodes[action.payload] = {
        id: action.payload,
        type: NodeComponentType.TUBE_CONNECTION,
        component: {
            nodeId: action.payload,
            type: state.latestSelectedConnectionType
                ? state.latestSelectedConnectionType
                : defaultTubeConnectionType,
            tubeType: TubeType.UNKNOWN,
            diameters: [tubeConnectionDefaultDiameter],
        },
        attachmentIds: [],
        rotationY: 0,
        position: {
            x: 0,
            y: objectShapes[state.objectShape].tubeNetworkY,
            z: 0,
        },
    };
};
var addCentralizedPump = function (state, action) {
    state.nodes[action.payload] = {
        id: action.payload,
        type: NodeComponentType.CENTRALIZED_VACUUM_PUMP,
        component: {
            nodeId: action.payload,
        },
        rotationY: 0,
        attachmentIds: [],
        position: {
            x: centralizedVacuumPumpDefaultX,
            y: objectShapes[state.objectShape].tubeNetworkY,
            z: centralizedVacuumPumpDefaultZ,
        },
    };
};
var addEdge = function (state, action) {
    var defaultDiameterIndex = action.payload.initialTubeDiamaterIndex;
    var blowOffDiameterIndex = action.payload.initialTubeDiamaterIndex;
    state.edges[action.payload.edgeId] = {
        id: action.payload.edgeId,
        diameterDefaultTube: tubeDiameterOptions[action.payload.unitSystem][defaultDiameterIndex]
            .value,
        diameterBlowOffTube: tubeDiameterOptions[action.payload.unitSystem][blowOffDiameterIndex]
            .value,
        defaultDiameterIndex: defaultDiameterIndex,
        blowOffDiameterIndex: blowOffDiameterIndex,
        length: tubeDefaultLength,
        tubeType: TubeType.UNKNOWN,
        fromAttachmentId: action.payload.fromAttachmentId,
        toAttachmentId: action.payload.toAttachmentId,
    };
    state.attachments[action.payload.fromAttachmentId].edgeId =
        action.payload.edgeId;
    state.attachments[action.payload.toAttachmentId].edgeId =
        action.payload.edgeId;
    updateTubeTypes(state);
};
var deleteNode = function (state, action) {
    if (!state.nodes[action.payload] ||
        state.nodes[action.payload].type === NodeComponentType.FEED_PRESSURE_POINT)
        return;
    state.nodes[action.payload].attachmentIds.forEach(function (attachmentId) {
        var edgeId = state.attachments[attachmentId].edgeId;
        if (edgeId) {
            var edge = state.edges[edgeId];
            var otherAttachmentId = edge.fromAttachmentId === attachmentId
                ? edge.toAttachmentId
                : edge.fromAttachmentId;
            state.attachments[otherAttachmentId].edgeId = undefined;
            delete state.edges[edgeId];
        }
        delete state.attachments[attachmentId];
    });
    delete state.nodes[action.payload];
    updateTubeTypes(state);
};
var setNodeAttachments = function (state, action) {
    var _a = action.payload, placeholders = _a.placeholders, nodeId = _a.nodeId;
    var node = state.nodes[nodeId];
    /*
     * Setting the attachments of a node means we create a new attachment
     * for each placeholder.
     */
    var newAttachments = placeholders.map(function (placeholder) {
        var attachment = {
            id: "attachment-".concat(uuidv4()),
            nodeId: nodeId,
            placeholderName: placeholder.name,
            tubeType: placeholder.tubeType,
            nodeType: node.type,
            placeholderEntityName: placeholder.entityName,
        };
        state.attachments[attachment.id] = attachment;
        return attachment;
    });
    /* The current edges should be mapped to the new attachments. */
    var updateEdge = function (edgeId, oldAttachmentId, newAttachmentId) {
        state.edges[edgeId].fromAttachmentId =
            state.edges[edgeId].fromAttachmentId === oldAttachmentId
                ? newAttachmentId
                : state.edges[edgeId].fromAttachmentId;
        state.edges[edgeId].toAttachmentId =
            state.edges[edgeId].toAttachmentId === oldAttachmentId
                ? newAttachmentId
                : state.edges[edgeId].toAttachmentId;
    };
    /* If possible, we map the edge to a new attachment with the same
     * placeholder name as the old one. This is an simple way of making sure
     * tubes are attached to the same position as before when re-initializing a state */
    var oldAttachmentWithEdges = [];
    node.attachmentIds.forEach(function (attachmentId) {
        var oldAttachment = state.attachments[attachmentId];
        if (oldAttachment.edgeId) {
            var newAttachment = newAttachments.find(function (newAttachment) {
                return newAttachment.placeholderName === oldAttachment.placeholderName &&
                    !newAttachment.edgeId;
            });
            if (newAttachment) {
                newAttachment.edgeId = oldAttachment.edgeId;
                updateEdge(oldAttachment.edgeId, oldAttachment.id, newAttachment.id);
            }
            else {
                oldAttachmentWithEdges.push(oldAttachment);
            }
        }
    });
    /* For edges we could not map based on name, we map the edge to any
     * new attachment that does not have an edge already */
    oldAttachmentWithEdges.forEach(function (oldAttachment) {
        var edgeId = oldAttachment.edgeId; //We know this is not undefined
        var newAttachment = newAttachments.find(function (newAttachment) { return !newAttachment.edgeId; });
        if (newAttachment) {
            newAttachment.edgeId = edgeId;
            updateEdge(edgeId, oldAttachment.id, newAttachment.id);
            /* If no new (available) attachment exists, we delete the edge */
        }
        else {
            var edgeToDelete = state.edges[edgeId];
            delete state.edges[edgeId];
            var otherAttachmentId = edgeToDelete.fromAttachmentId === oldAttachment.id
                ? edgeToDelete.toAttachmentId
                : edgeToDelete.fromAttachmentId;
            state.attachments[otherAttachmentId].edgeId = undefined;
        }
    });
    /* Delete all existing attachments */
    node.attachmentIds.forEach(function (attachmentId) {
        delete state.attachments[attachmentId];
    });
    node.attachmentIds = newAttachments.map(function (attachment) { return attachment.id; });
    updateTubeTypes(state);
};
var setNodePosition = function (state, action) {
    state.nodes[action.payload.nodeId].position = action.payload.position;
};
var setGripPositionMarker = function (state, action) {
    var component = state.nodes[action.payload.nodeId]
        .component;
    component.marker = action.payload.marker;
    component.width = gripPositionMarkerDefaultWidth[action.payload.marker];
};
var setGripPositionWidth = function (state, action) {
    var component = state.nodes[action.payload.nodeId]
        .component;
    component.width = action.payload.width;
};
var setGripPositionTubeLength = function (state, action) {
    var component = state.nodes[action.payload.nodeId]
        .component;
    component.inlineTubeLength = action.payload.length;
};
var setGripPositionTubeDiameter = function (state, action) {
    var component = state.nodes[action.payload.nodeId]
        .component;
    component.inlineTubeDiameter = action.payload.diameter;
};
var setNodeRotation = function (state, action) {
    state.nodes[action.payload.nodeId].rotationY = action.payload.rotation;
};
var setTubeConnectionDiameters = function (state, action) {
    var e_1, _a;
    try {
        for (var _b = __values(action.payload.nodeIds), _c = _b.next(); !_c.done; _c = _b.next()) {
            var nodeId = _c.value;
            var component = state.nodes[nodeId].component;
            component.diameters = __spreadArray([], __read(action.payload.diameters), false);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
};
var setTubeConnectionType = function (state, action) {
    var e_2, _a;
    try {
        for (var _b = __values(action.payload.nodeIds), _c = _b.next(); !_c.done; _c = _b.next()) {
            var nodeId = _c.value;
            var tubeConnectionType = action.payload.type;
            var component = state.nodes[nodeId].component;
            component.type = tubeConnectionType;
            // If the new tubeConnection type is Toolchanger, we set the current diameter to the largest of the diameters.
            // Since the toolchanger only needs one diameter.
            var isToolChanger = tubeConnectionType === TubeConnectionId.TOOL_CHANGER;
            if (isToolChanger) {
                component.diameters = [Math.max.apply(Math, __spreadArray([], __read(component.diameters), false))];
            }
            state.latestSelectedConnectionType = tubeConnectionType;
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_2) throw e_2.error; }
    }
};
var duplicateNode = function (state, action) {
    var _a = action.payload, fromNodeId = _a.fromNodeId, toNodeId = _a.toNodeId;
    if (state.nodes[fromNodeId].type === NodeComponentType.FEED_PRESSURE_POINT)
        return;
    state.nodes[toNodeId] = __assign(__assign({}, state.nodes[fromNodeId]), { id: toNodeId, position: __assign(__assign({}, state.nodes[fromNodeId].position), { x: state.nodes[fromNodeId].position.x + 0.1 }), component: __assign(__assign({}, state.nodes[fromNodeId].component), { nodeId: toNodeId }), attachmentIds: [] });
};
var deleteEdge = function (state, action) {
    var edge = state.edges[action.payload];
    state.attachments[edge.fromAttachmentId].edgeId = undefined;
    state.attachments[edge.toAttachmentId].edgeId = undefined;
    delete state.edges[action.payload];
    updateTubeTypes(state);
};
var deleteAllEdges = function (state) {
    var clearAttachment = function (attachmentId) {
        var attachment = state.attachments[attachmentId];
        attachment.edgeId = undefined;
    };
    Object.values(state.edges).forEach(function (edge) {
        clearAttachment(edge.fromAttachmentId);
        clearAttachment(edge.toAttachmentId);
    });
    state.edges = {};
    /* Make all tube connector tube types unknown */
    updateTubeTypes(state);
};
var setEdgeDiameterBlowOffTube = function (state, action) {
    var e_3, _a;
    try {
        for (var _b = __values(action.payload.edgeIds), _c = _b.next(); !_c.done; _c = _b.next()) {
            var edgeId = _c.value;
            state.edges[edgeId].diameterBlowOffTube = action.payload.diameter;
            state.edges[edgeId].blowOffDiameterIndex = action.payload.index;
        }
    }
    catch (e_3_1) { e_3 = { error: e_3_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_3) throw e_3.error; }
    }
};
var setEdgeDiameterDefaultTube = function (state, action) {
    var e_4, _a;
    try {
        for (var _b = __values(action.payload.edgeIds), _c = _b.next(); !_c.done; _c = _b.next()) {
            var edgeId = _c.value;
            state.edges[edgeId].diameterDefaultTube = action.payload.diameter;
            state.edges[edgeId].defaultDiameterIndex = action.payload.index;
        }
    }
    catch (e_4_1) { e_4 = { error: e_4_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_4) throw e_4.error; }
    }
};
var setEdgeLengthLocked = function (state, action) {
    var e_5, _a;
    try {
        for (var _b = __values(action.payload.edgeIds), _c = _b.next(); !_c.done; _c = _b.next()) {
            var edgeId = _c.value;
            state.edges[edgeId].lengthLocked = action.payload.isLocked;
        }
    }
    catch (e_5_1) { e_5 = { error: e_5_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_5) throw e_5.error; }
    }
};
var setEdgeLength = function (state, action) {
    var e_6, _a;
    try {
        for (var _b = __values(action.payload.edgeIds), _c = _b.next(); !_c.done; _c = _b.next()) {
            var edgeId = _c.value;
            state.edges[edgeId].length = action.payload.length;
            state.edges[edgeId].lengthLocked = action.payload.isLocked;
        }
    }
    catch (e_6_1) { e_6 = { error: e_6_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_6) throw e_6.error; }
    }
};
var setObjectShape = function (state, action) {
    var e_7, _a;
    var objectShapeData = objectShapes[action.payload];
    state.objectShape = action.payload;
    if (objectShapeData.objectDefaultSize) {
        state.objectSize = objectShapeData.objectDefaultSize;
    }
    try {
        for (var _b = __values(Object.values(state.nodes)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var node = _c.value;
            if (node.type === NodeComponentType.GRIP_POSITION) {
                node.position.y = objectShapeData.gripPositionY;
            }
            else {
                node.position.y = objectShapeData.tubeNetworkY;
            }
        }
    }
    catch (e_7_1) { e_7 = { error: e_7_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_7) throw e_7.error; }
    }
};
var setObjectSize = function (state, action) {
    state.objectSize = action.payload;
};
var clearScene = function (state) {
    state.nodes = initialState.nodes;
    state.edges = initialState.edges;
    state.attachments = initialState.attachments;
};
var sceneSlice = createSlice({
    name: "scene",
    initialState: initialState,
    reducers: {
        addGripPosition: addGripPosition,
        addTubeConnection: addTubeConnection,
        addEdge: addEdge,
        addCentralizedPump: addCentralizedPump,
        deleteNode: deleteNode,
        duplicateNode: duplicateNode,
        setNodeAttachments: setNodeAttachments,
        setNodePosition: setNodePosition,
        setGripPositionMarker: setGripPositionMarker,
        setGripPositionWidth: setGripPositionWidth,
        setGripPositionTubeLength: setGripPositionTubeLength,
        setGripPositionTubeDiameter: setGripPositionTubeDiameter,
        setNodeRotation: setNodeRotation,
        setTubeConnectionType: setTubeConnectionType,
        setTubeConnectionDiameters: setTubeConnectionDiameters,
        deleteEdge: deleteEdge,
        deleteAllEdges: deleteAllEdges,
        setEdgeDiameterBlowOffTube: setEdgeDiameterBlowOffTube,
        setEdgeDiameterDefaultTube: setEdgeDiameterDefaultTube,
        setEdgeLength: setEdgeLength,
        setEdgeLengthLocked: setEdgeLengthLocked,
        setObjectShape: setObjectShape,
        setObjectSize: setObjectSize,
        clearScene: clearScene,
    },
});
var allActions = sceneSlice.actions, reducer = sceneSlice.reducer;
export var callableActions = {
    setNodeAttachments: allActions.setNodeAttachments,
    setGripPositionMarker: allActions.setGripPositionMarker,
    setGripPositionWidth: allActions.setGripPositionWidth,
    setGripPositionTubeLength: allActions.setGripPositionTubeLength,
    setGripPositionTubeDiameter: allActions.setGripPositionTubeDiameter,
    setNodeRotation: allActions.setNodeRotation,
    setNodePosition: allActions.setNodePosition,
    setTubeConnectionType: allActions.setTubeConnectionType,
    setTubeConnectionDiameters: allActions.setTubeConnectionDiameters,
    setEdgeDiameterDefaultTube: allActions.setEdgeDiameterDefaultTube,
    setEdgeDiameterBlowOffTube: allActions.setEdgeDiameterBlowOffTube,
    setEdgeLength: allActions.setEdgeLength,
    setEdgeLengthLocked: allActions.setEdgeLengthLocked,
    setObjectSize: allActions.setObjectSize,
    setObjectShape: allActions.setObjectShape,
    deleteAllEdges: allActions.deleteAllEdges,
    clearScene: allActions.clearScene,
};
/* IMPORTANT: All of these actions will be added to the undo history.
 * Only add actions that we want to be able to undo here. */
export var internalActions = {
    addGripPosition: allActions.addGripPosition,
    addTubeConnection: allActions.addTubeConnection,
    addCentralizedPump: allActions.addCentralizedPump,
    addEdge: allActions.addEdge,
    deleteNode: allActions.deleteNode,
    duplicateNode: allActions.duplicateNode,
    deleteEdge: allActions.deleteEdge,
};
var options = {
    limit: 20,
    filter: includeAction(Object.values(internalActions).map(function (action) { return action.type; })),
    syncFilter: true,
    ignoreInitialState: true,
};
var undoableSceneReducer = undoable(reducer, options);
export default undoableSceneReducer;
