var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { StyledButton } from "@animech/ui";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
export var IconButton = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme, iconSize = _a.iconSize, variant = _a.variant, focused = _a.focused;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t\t\t", " {\n\t\t\t\tdisplay: flex;\n\t\t\t\tmargin: 4px;\n\t\t\t\tpadding: ", ";\n\t\t\t\tmin-width: 0;\n\t\t\t\tborder-radius: ", ";\n\n\t\t\t\t", "\n\n\t\t\t\t&[data-color=\"inverted\"][data-appearance=\"solid\"] {\n\t\t\t\t\tbackground: ", ";\n\n\t\t\t\t\t&:hover {\n\t\t\t\t\t\tbackground: ", ";\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t\t", " {\n\t\t\t\tdisplay: flex;\n\t\t\t\talign-items: center;\n\t\t\t\tjustify-content: center;\n\t\t\t\theight: ", "px;\n\t\t\t\twidth: ", "px;\n\t\t\t\tline-height: ", "px;\n\t\t\t}\n\t\t"], ["\n\t\t\t", " {\n\t\t\t\tdisplay: flex;\n\t\t\t\tmargin: 4px;\n\t\t\t\tpadding: ", ";\n\t\t\t\tmin-width: 0;\n\t\t\t\tborder-radius: ", ";\n\n\t\t\t\t", "\n\n\t\t\t\t&[data-color=\"inverted\"][data-appearance=\"solid\"] {\n\t\t\t\t\tbackground: ", ";\n\n\t\t\t\t\t&:hover {\n\t\t\t\t\t\tbackground: ", ";\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t\t", " {\n\t\t\t\tdisplay: flex;\n\t\t\t\talign-items: center;\n\t\t\t\tjustify-content: center;\n\t\t\t\theight: ", "px;\n\t\t\t\twidth: ", "px;\n\t\t\t\tline-height: ", "px;\n\t\t\t}\n\t\t"])), StyledButton.Root, variant === "circle" ? "5px" : "4px 8px", variant === "circle"
        ? "50%"
        : theme.dimensions.borderRadius, focused && "opacity: 0.3;", theme.colors.palette.grey[700], theme.colors.palette.grey[600], StyledButton.Label, iconSize, iconSize, iconSize);
});
var templateObject_1, templateObject_2;
