var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Legend, Line, LineChart, Tooltip, YAxis, CartesianGrid, XAxis, ReferenceLine, } from "recharts";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { graphMargins, defaultGraphStrokeWidth, graphWidth, vacuumPumpYAxisUnit, defaultXAxisProps, vacuumPumpGraphHeight, yAxisPadding, yAxisDyTSpan, defaultYAxisProps, vacuumTooltipPosition, } from "./constants";
import { CustomLegend, useCustomLegendWrapperStyle, } from "./common/custom-legend";
import { VerticalReferenceLines } from "./common/reference-lines";
import { CustomVacuumPumpGraphTooltip } from "./common/custom-vacuum-pump-graph-tooltip";
import { CustomYAxisTick } from "./common/custom-y-axis-tick";
import { SegmentLines } from "./common/segment-lines";
import { ZoomHighlightedArea } from "./common/custom-reference-area";
import { selectors, useAppSelector } from "store";
import { useNumberConversion } from "hooks/number-conversion";
import { conversions } from "utility/number";
import { UnitSystem } from "store/types";
import { useTimeDataMinMaxDomain } from "hooks/graph-panel/graph";
import { useGraphDataContext } from "providers/graph-panel";
import { useGraphLabels } from "hooks/graph-panel/graph-labels";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var CustomVacuumLevelYTick = function (props) {
    var x = props.x, payload = props.payload;
    var convertNumber = useNumberConversion();
    var convertedValue = convertNumber(payload.value, vacuumPumpYAxisUnit);
    // Since we display the values in unit (-kPa), we need to flip the value from negative to positive.
    var value = convertedValue.value * -(1).toString();
    var labelElements = (_jsx("tspan", { x: x, dx: 0, dy: yAxisDyTSpan, children: value }));
    return _jsx(CustomYAxisTick, __assign({}, props, { labelElements: labelElements }));
};
export var VacuumPumpGraph = function () {
    var t = useTranslation().t;
    var unitSystem = useAppSelector(selectors.selectUnitSystem);
    var xAxisDomain = useTimeDataMinMaxDomain();
    var parsedGraphData = useAppSelector(selectors.selectParsedGraphData);
    var _a = useGraphDataContext(), pumpsAndCupsNodeTree = _a.pumpsAndCupsNodeTree, selectedNodes = _a.selectedNodes, onMouseDown = _a.onMouseDown, onMouseMove = _a.onMouseMove, onMouseUp = _a.onMouseUp, zoomedVacuumLevelDomain = _a.zoomedVacuumLevelDomain, zoomedTimeDomain = _a.zoomedTimeDomain;
    var _b = useGraphLabels(), getVacuumPumpLabel = _b.getVacuumPumpLabel, getSuctionCupLabel = _b.getSuctionCupLabel;
    var customLegendWrapperStyle = useCustomLegendWrapperStyle(vacuumPumpGraphHeight);
    var selectedNodesCount = useMemo(function () {
        return Object.values(selectedNodes !== null && selectedNodes !== void 0 ? selectedNodes : {}).reduce(function (acc, isSelected) { return (isSelected ? acc + 1 : acc); }, 0);
    }, [selectedNodes]);
    // Piab wants vacuum pump lines to be thinner when multiple nodes are selected
    var strokeWidth = selectedNodesCount > 1
        ? defaultGraphStrokeWidth / 2
        : defaultGraphStrokeWidth;
    var lineElements = useMemo(function () {
        if (pumpsAndCupsNodeTree === undefined || selectedNodes === undefined)
            return null;
        var lines = [];
        pumpsAndCupsNodeTree.forEach(function (vacuumPump) {
            var vacuumPumpId = vacuumPump.yValueKey, color = vacuumPump.color, connectedSuctionCups = vacuumPump.connectedSuctionCups;
            var vacuumPumpLabel = getVacuumPumpLabel(vacuumPump);
            var isSelected = selectedNodes[vacuumPumpId];
            if (isSelected) {
                lines.push(_jsx(Line, { type: "linear", dataKey: vacuumPumpId, stroke: color, strokeWidth: strokeWidth, dot: false, name: vacuumPumpLabel }, vacuumPumpId));
            }
            connectedSuctionCups.forEach(function (suctionCup) {
                var suctionCupId = suctionCup.yValueKey, suctionCupColor = suctionCup.color, suctionCupName = suctionCup.name, alphabeticalId = suctionCup.alphabeticalId;
                var suctionCupLabel = getSuctionCupLabel({
                    alphabeticalId: alphabeticalId,
                    name: suctionCupName,
                });
                var isSelected = selectedNodes[suctionCupId];
                if (isSelected) {
                    lines.push(_jsx(Line, { type: "linear", dataKey: suctionCupId, stroke: suctionCupColor, strokeWidth: strokeWidth, dot: false, name: suctionCupLabel }, suctionCupId));
                }
            });
        });
        return lines;
    }, [
        getSuctionCupLabel,
        getVacuumPumpLabel,
        pumpsAndCupsNodeTree,
        selectedNodes,
        strokeWidth,
    ]);
    var zoomTicksY = useMemo(function () {
        if (!zoomedVacuumLevelDomain)
            return;
        var min = zoomedVacuumLevelDomain[0];
        var max = zoomedVacuumLevelDomain[1];
        var incr = (max - min) / 3;
        return [0, 1, 2, 3].map(function (i) { return max - incr * i; });
    }, [zoomedVacuumLevelDomain]);
    var yAxisTicks = zoomTicksY
        ? zoomTicksY
        : unitSystem === UnitSystem.METRIC
            ? [0, -25, -50, -75]
            : [0, -23.63795, -47.2759, -70.95]; // should result in [0, -7, -14, -21] inhg
    if (!parsedGraphData)
        return null;
    var vacuumPumpData = parsedGraphData.nodeData, vacuumLevelDomain = parsedGraphData.vacuumLevelDomain;
    if (!vacuumPumpData)
        return null;
    var yAxisUnitconversion = conversions[vacuumPumpYAxisUnit];
    var yAxisUnit = unitSystem === UnitSystem.METRIC
        ? vacuumPumpYAxisUnit
        : yAxisUnitconversion.unit;
    var yDomain = zoomedVacuumLevelDomain !== null && zoomedVacuumLevelDomain !== void 0 ? zoomedVacuumLevelDomain : vacuumLevelDomain;
    var xDomain = zoomedTimeDomain !== null && zoomedTimeDomain !== void 0 ? zoomedTimeDomain : xAxisDomain;
    return (_jsxs(LineChart, { width: graphWidth, height: vacuumPumpGraphHeight, data: vacuumPumpData, margin: graphMargins, onMouseDown: onMouseDown, onMouseMove: onMouseMove, onMouseUp: onMouseUp, children: [_jsx(CartesianGrid, { vertical: false, horizontal: false }), _jsx(XAxis, __assign({}, defaultXAxisProps, { domain: xDomain, hide: true })), _jsx(YAxis, __assign({}, defaultYAxisProps, { interval: 0, unit: yAxisUnit, tick: _jsx(CustomVacuumLevelYTick, {}), ticks: yAxisTicks, tickLine: false, padding: yAxisPadding, domain: yDomain })), _jsx(Tooltip, { allowEscapeViewBox: { x: true, y: true }, position: vacuumTooltipPosition, wrapperStyle: { zIndex: 100 }, content: _jsx(CustomVacuumPumpGraphTooltip, { xLabel: t("graph_panel.pump_x_label_time"), yLabel: t("graph_panel.pump_tooltip_y_label"), yUnit: vacuumPumpYAxisUnit }) }), lineElements, _jsx(Legend, { align: "left", wrapperStyle: customLegendWrapperStyle, content: function () { return (_jsx(CustomLegend, { label: "".concat(t("graph_panel.pump_tooltip_y_label"), " (").concat(yAxisUnit, ")") })); } }), _jsx(ZoomHighlightedArea, { domain: yDomain }), _jsx(VerticalReferenceLines, {}), _jsx(ReferenceLine, { y: yAxisTicks[0] }), _jsx(ReferenceLine, { y: yAxisTicks[1] }), _jsx(ReferenceLine, { y: yAxisTicks[2] }), _jsx(ReferenceLine, { y: yAxisTicks[3] }), _jsx(SegmentLines, {})] }));
};
