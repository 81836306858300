var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState, useEffect } from "react";
export var useIsKeyPressed = function (key) {
    var _a = __read(useState(false), 2), keyPressed = _a[0], setKeyPressed = _a[1];
    useEffect(function () {
        var handleKeyDown = function (event) {
            if (event.key === key) {
                setKeyPressed(true);
            }
        };
        var handleKeyUp = function (event) {
            if (event.key === key) {
                setKeyPressed(false);
            }
        };
        var handleBlur = function () {
            setKeyPressed(false);
        };
        window.addEventListener("keydown", handleKeyDown);
        window.addEventListener("keyup", handleKeyUp);
        window.addEventListener("blur", handleBlur);
        return function () {
            window.removeEventListener("keydown", handleKeyDown);
            window.removeEventListener("keyup", handleKeyUp);
            window.removeEventListener("blur", handleBlur);
        };
    }, [key]);
    return keyPressed;
};
