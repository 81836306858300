var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable camelcase */
import { defaultMetalCupMaterialTransform, } from "3d/types";
/** ---------------   **/
/**     DCF Family    **/
/** ---------------   **/
export var suctionCupsFamilyDCF = {
    DCF: __assign(__assign({ cupModelName: "DCF65P", cupWidth: 0.065, cupHeight: 0.065 }, defaultMetalCupMaterialTransform.PU55), { fittingModelName: "G38M50" }),
    // DCF 30P
    suction_cup_0210590: __assign(__assign({ cupModelName: "DCF30P", cupWidth: 0.03, cupHeight: 0.03 }, defaultMetalCupMaterialTransform.PU55), { fittingModelName: "G38M50-S1" }),
    // DCF 40P
    suction_cup_0210598: __assign(__assign({ cupModelName: "DCF40P", cupWidth: 0.04, cupHeight: 0.04 }, defaultMetalCupMaterialTransform.PU55), { fittingModelName: "G38M50-S1" }),
};
