var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useMemo, useState } from "react";
import * as pc from "playcanvas";
import { NodeComponentType, TubeType, } from "store/types";
import { attachmentModel, attachmentMaterialArea, tubeCylinderAsset, attachmentMaterials, } from "3d/constants/common";
import { allowEdgeBetween } from "3d/rules";
import { emphasizeEntity, resetMaterial, connectNodes, almostEqual, } from "3d/helpers";
import { useAppSelector, selectors } from "store";
var ArrowDirection;
(function (ArrowDirection) {
    ArrowDirection["IN"] = "in";
    ArrowDirection["OUT"] = "out";
    ArrowDirection["NONE"] = "none";
})(ArrowDirection || (ArrowDirection = {}));
export var Attachment = function (_a) {
    var app = _a.app, nodeEntity = _a.nodeEntity, node = _a.node, attachment = _a.attachment, materialAssets = _a.materialAssets, modelAssets = _a.modelAssets, hoverId = _a.hoverId;
    var _b = __read(useState(null), 2), attachmentEntity = _b[0], setAttachmentEntity = _b[1];
    var _c = __read(useState(ArrowDirection.NONE), 2), arrowDirection = _c[0], setArrowDirection = _c[1];
    var nodes = useAppSelector(selectors.selectNodes);
    var selectedAttachment = useAppSelector(selectors.selectSelectedAttachment);
    var selectedNode = useAppSelector(selectors.selectSelectedNode);
    var isCentralizedSystem = useAppSelector(selectors.selectIsCentralizedSystem);
    var attachmentNodeIsEmptyGripPosition = useAppSelector(selectors.selectSelectedCup(attachment.nodeId)) ===
        undefined && attachment.nodeType === NodeComponentType.GRIP_POSITION;
    /* Set the material of the arrow for this attachment.
     * Each attachment already has information regarding its tube type, wether its Vacuum (Blue), Compressed air (Red) or unkown (Grey).
     * We utilize this to save the material for the attachment, based on the individual attachments tubetype.
     *
     * However since a tube-connection doesn't have a tubeType unless its already connected with another node, we need to handle them differently.
     * If the user has selected an attachment that isn't from a tube-connection node,
     * then we use the selected attachments tubeType to define the color of all connectable attachments.
     * Otherwise we let the individual attachment define its own color. */
    var arrowMaterial = useMemo(function () {
        var isAttachmentSelectedAndNotATubeConnection = selectedAttachment && selectedAttachment.tubeType !== TubeType.UNKNOWN;
        if (isAttachmentSelectedAndNotATubeConnection) {
            return attachmentMaterials[selectedAttachment.tubeType];
        }
        else {
            return attachmentMaterials[attachment.tubeType];
        }
    }, [attachment.tubeType, selectedAttachment]);
    useEffect(function () {
        var entity = new pc.Entity(attachment.id.toString());
        entity.addComponent("model", {
            type: "asset",
            asset: modelAssets[attachmentModel],
        });
        if (entity.model) {
            entity.model.meshInstances.forEach(function (meshInstance) {
                if (meshInstance.material.name === attachmentMaterialArea) {
                    meshInstance.material =
                        materialAssets[arrowMaterial.standard].resource;
                }
            });
        }
        entity.setLocalScale(0.4, 0.5, 0.5);
        nodeEntity.addChild(entity);
        setAttachmentEntity(entity);
        entity.enabled = false;
        return function () {
            entity.destroy();
            setAttachmentEntity(null);
        };
    }, [
        nodeEntity,
        attachment.id,
        materialAssets,
        modelAssets,
        arrowMaterial.standard,
    ]);
    /* Set direction of the arrow for this attachment. Should be facing out when
     * the node of the attachment is selected, and in when another attachment is
     * selected. If there is an edge attached at this position or if the node is
     * an empty grip position, the arrow should not be shown */
    useEffect(function () {
        var arrowDirection = ArrowDirection.NONE;
        if (attachment.edgeId || attachmentNodeIsEmptyGripPosition) {
            arrowDirection = ArrowDirection.NONE;
        }
        else if ((selectedNode === null || selectedNode === void 0 ? void 0 : selectedNode.id) === attachment.nodeId) {
            arrowDirection = ArrowDirection.OUT;
        }
        else if (selectedAttachment && selectedAttachment.id !== attachment.id) {
            if (allowEdgeBetween(attachment, selectedAttachment)) {
                arrowDirection = ArrowDirection.IN;
            }
        }
        setArrowDirection(arrowDirection);
    }, [
        attachment,
        selectedAttachment,
        selectedNode,
        isCentralizedSystem,
        node,
        nodes,
        attachmentNodeIsEmptyGripPosition,
    ]);
    /* Render arrow */
    useEffect(function () {
        var _a;
        if (arrowDirection === ArrowDirection.NONE)
            return;
        var placeholderEntity = nodeEntity.findByName(attachment.placeholderName);
        if (!attachmentEntity || !placeholderEntity)
            return;
        var direction = placeholderEntity.forward.clone().mulScalar(-1);
        /* Reposition arrow if the placholder is facing upwards */
        if (almostEqual(direction.y, 1) &&
            almostEqual(direction.x, 0) &&
            almostEqual(direction.z, 0)) {
            direction.z -= 1;
            direction.y = 0;
        }
        var lookAt = arrowDirection === ArrowDirection.OUT
            ? placeholderEntity.getPosition().clone().add(direction)
            : placeholderEntity.getPosition().clone();
        var position = arrowDirection === ArrowDirection.OUT
            ? placeholderEntity.getPosition().clone()
            : placeholderEntity
                .getPosition()
                .clone()
                .add(direction.clone().mulScalar(0.07));
        attachmentEntity.setPosition(position);
        attachmentEntity.lookAt(lookAt);
        var angles = attachmentEntity.getLocalEulerAngles().clone();
        angles.x -= 90;
        attachmentEntity.setLocalEulerAngles(angles);
        (_a = attachmentEntity.model) === null || _a === void 0 ? void 0 : _a.meshInstances.forEach(function (mi) {
            mi.material = materialAssets[arrowMaterial.standard].resource;
            mi.material.update();
        });
        attachmentEntity.enabled = true;
        return function () {
            attachmentEntity.enabled = false;
        };
    }, [
        arrowDirection,
        attachment.placeholderName,
        attachmentEntity,
        materialAssets,
        arrowMaterial.standard,
        nodeEntity,
    ]);
    /* "Preview" an edge between two attachments on hover */
    useEffect(function () {
        if (arrowDirection !== ArrowDirection.IN)
            return;
        if (hoverId !== attachment.id)
            return;
        if (!selectedAttachment)
            return;
        var fromAttachment = selectedAttachment;
        var fromNode = nodes[fromAttachment.nodeId];
        var fromNodeEntity = app.root.findByName(fromAttachment.nodeId.toString());
        var segmentsEntity = new pc.Entity();
        nodeEntity.addChild(segmentsEntity);
        if (fromNodeEntity) {
            connectNodes({
                app: app,
                fromNode: fromNode,
                fromAttachment: fromAttachment,
                toNode: node,
                toAttachment: attachment,
                cylinderAsset: modelAssets[tubeCylinderAsset],
                material: materialAssets[arrowMaterial.transparent].resource,
                rootEntity: segmentsEntity,
                diameter: 0.01,
                offsetX: 0,
            });
        }
        return function () {
            segmentsEntity.destroy();
        };
    }, [
        app,
        arrowDirection,
        arrowMaterial.transparent,
        hoverId,
        attachment,
        selectedAttachment,
        nodes,
        node,
        nodeEntity,
        materialAssets,
        modelAssets,
    ]);
    useEffect(function () {
        if (attachmentEntity && hoverId === attachment.id) {
            emphasizeEntity(attachmentEntity);
        }
        return function () {
            if (attachmentEntity) {
                resetMaterial(attachmentEntity, materialAssets);
            }
        };
    }, [attachmentEntity, hoverId, materialAssets, attachment.id]);
    return null;
};
