var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from "@emotion/react";
import styled from "@emotion/styled";
export var Root = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var SectionText = styled.span(function (_a) {
    var theme = _a.theme, isTitle = _a.isTitle;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t\t", "\n\t"], ["\n\t\t", "\n\t"])), isTitle &&
        "\n\t\t\tdisplay: list-item;\n\t\t\tmargin-left: 1em;\n\t\t\tfont-weight: bold;\n\t\t\tfont-size: ".concat(theme.typography.fontSize.large, ";\n\t\t"));
});
export var Section = styled.div("\n\t\tmargin-bottom: 2rem;\n\t");
export var Description = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var layoutFromProps = _a.layout, maxWidthFromProps = _a.maxWidth, imageScaleFromProps = _a.imageScale;
    var layout = layoutFromProps === "horizontal"
        ? "row"
        : layoutFromProps === "vertical"
            ? "column"
            : "row";
    var maxWidth = maxWidthFromProps === undefined || isNaN(maxWidthFromProps)
        ? 700
        : maxWidthFromProps;
    var imageScale = imageScaleFromProps === undefined || isNaN(imageScaleFromProps)
        ? 40
        : imageScaleFromProps;
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: ", ";\n\t\t\talign-items: center;\n\t\t\tmargin: 2em;\n\t\t\tmax-width: ", "px;\n\n\t\t\t.long-description {\n\t\t\t\tflex-direction: row;\n\t\t\t}\n\n\t\t\t> img {\n\t\t\t\torder: 0;\n\t\t\t\tmargin-right: 40px;\n\t\t\t\tobject-fit: contain;\n\t\t\t\theight: 100%;\n\t\t\t\twidth: ", "%;\n\t\t\t}\n\t\t"], ["\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: ", ";\n\t\t\talign-items: center;\n\t\t\tmargin: 2em;\n\t\t\tmax-width: ", "px;\n\n\t\t\t.long-description {\n\t\t\t\tflex-direction: row;\n\t\t\t}\n\n\t\t\t> img {\n\t\t\t\torder: 0;\n\t\t\t\tmargin-right: 40px;\n\t\t\t\tobject-fit: contain;\n\t\t\t\theight: 100%;\n\t\t\t\twidth: ", "%;\n\t\t\t}\n\t\t"])), layout, maxWidth, imageScale);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
