var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a, _b, _c;
import * as pc from "playcanvas";
import { CardboardModelId } from "./types";
import { flipWidth, scaleAll, scaleLength, scaleLengthFlipWidth, scaleWidth, translateLength, translateWidth, translate, scaleUVAll, scaleUVDefault, scaleUVWidth, scaleUVLength, scaleAllWithSeam, seamWidth, scaleWidthWithSeam, scaleTape, scaleTapeFlipLength, scaleTapeLength, tapeWidth, flipLength, scaleWidthFlipLength, } from "./transform-helpers";
import { paperCoatingParameterValues, tapeParameterValues, } from "api/tacton/constants";
import { Material } from "3d/types";
import { CardboardObjectShapeId, } from "store/types";
export var cardboardMaterialMap = (_a = {},
    _a[paperCoatingParameterValues.coated] = Material.CARDBOARD_KRAFT_WAXED,
    _a[paperCoatingParameterValues.painted] = Material.CARDBOARD_KRAFT_PAINTED,
    _a[paperCoatingParameterValues.rawColored] = Material.CARDBOARD_COLORED,
    _a[paperCoatingParameterValues.rawNatural] = Material.CARDBOARD_KRAFT,
    _a);
var cardboardCorners = [
    // Bottom Left Corner
    {
        modelId: CardboardModelId.CORNER_BOTTOM_LEFT,
        scaleUVVec2: scaleUVDefault,
    },
    // Top Left Corner
    {
        modelId: CardboardModelId.CORNER_TOP_LEFT,
        translateVec3: function (d) { return translateLength(d, -1); },
        scaleUVVec2: scaleUVDefault,
    },
];
var cardboardCornersWithSeam = __spreadArray(__spreadArray([], __read(cardboardCorners), false), [
    // Bottom Right Corner
    {
        modelId: CardboardModelId.CORNER_BOTTOM_RIGHT,
        translateVec3: translateWidth,
        scaleUVVec2: scaleUVDefault,
    },
    // Top Right Corner
    {
        modelId: CardboardModelId.CORNER_TOP_RIGHT,
        translateVec3: function (d) {
            return translate(d, { multiplierWidth: 1, multiplierLength: -1 });
        },
        scaleUVVec2: scaleUVDefault,
    },
], false);
var cardboardCornersWithoutSeam = __spreadArray(__spreadArray([], __read(cardboardCorners), false), [
    // Bottom Right Corner
    {
        modelId: CardboardModelId.CORNER_BOTTOM_LEFT,
        translateVec3: translateWidth,
        scaleVec3: flipWidth,
        scaleUVVec2: scaleUVDefault,
    },
    // Top Right Corner
    {
        modelId: CardboardModelId.CORNER_TOP_LEFT,
        translateVec3: function (d) {
            return translate(d, { multiplierWidth: 1, multiplierLength: -1 });
        },
        scaleVec3: flipWidth,
        scaleUVVec2: scaleUVDefault,
    },
], false);
export var cardboardBoxWithoutSeam = __spreadArray(__spreadArray([], __read(cardboardCornersWithoutSeam), false), [
    // Top and Bottom filler
    {
        modelId: CardboardModelId.FILL_LEFT,
        scaleVec3: scaleAll,
        scaleUVVec2: scaleUVAll,
    },
    // Left Edge
    {
        modelId: CardboardModelId.EDGE_MIDDLE_LEFT,
        scaleVec3: scaleLength,
        scaleUVVec2: scaleUVLength,
    },
    // Right Edge
    {
        modelId: CardboardModelId.EDGE_MIDDLE_LEFT,
        scaleVec3: scaleLengthFlipWidth,
        translateVec3: translateWidth,
        scaleUVVec2: scaleUVLength,
    },
    // Bottom Edge
    {
        modelId: CardboardModelId.EDGE_BOTTOM_LEFT,
        scaleVec3: scaleWidth,
        scaleUVVec2: scaleUVWidth,
    },
    // Top Edge
    {
        modelId: CardboardModelId.EDGE_TOP_RIGHT,
        scaleVec3: scaleWidth,
        translateVec3: function (d) { return translateLength(d, -1); },
        scaleUVVec2: scaleUVWidth,
    },
], false);
export var cardboardBoxWithSeam = function (centerPoint) { return __spreadArray(__spreadArray([], __read(cardboardCornersWithSeam), false), [
    // Top and Bottom filler Left
    {
        modelId: CardboardModelId.FILL_LEFT,
        scaleVec3: function (d) { return scaleAllWithSeam(d, centerPoint); },
        scaleUVVec2: function (d) { return scaleUVAll(d, centerPoint); },
    },
    // Middle Seam
    {
        modelId: CardboardModelId.SEAM_MIDDLE,
        translateVec3: function (d) { return translateWidth(d, centerPoint); },
        scaleVec3: scaleLength,
        scaleUVVec2: scaleUVDefault,
    },
    // Top and Bottom filler Right
    {
        modelId: CardboardModelId.FILL_RIGHT,
        scaleVec3: function (d) { return scaleAllWithSeam(d, 1 - centerPoint, true); },
        translateVec3: function (d) { return translateWidth(d, centerPoint, seamWidth); },
        scaleUVVec2: function (d) { return scaleUVAll(d, 1 - centerPoint, -seamWidth); },
    },
    // Left Edge
    {
        modelId: CardboardModelId.EDGE_MIDDLE_LEFT,
        scaleVec3: scaleLength,
        scaleUVVec2: scaleUVLength,
    },
    // Right Edge
    {
        modelId: CardboardModelId.EDGE_MIDDLE_LEFT,
        scaleVec3: scaleLengthFlipWidth,
        translateVec3: translateWidth,
        scaleUVVec2: scaleUVLength,
    },
    // Bottom Edge Left
    {
        modelId: CardboardModelId.EDGE_BOTTOM_LEFT,
        scaleVec3: function (d) { return scaleWidthWithSeam(d, centerPoint, false); },
        scaleUVVec2: function (d) { return scaleUVWidth(d, centerPoint); },
    },
    // Bottom Seam
    {
        modelId: CardboardModelId.SEAM_BOTTOM,
        translateVec3: function (d) { return translateWidth(d, centerPoint); },
        scaleUVVec2: scaleUVDefault,
    },
    // Bottom Edge Right
    {
        modelId: CardboardModelId.EDGE_BOTTOM_RIGHT,
        scaleVec3: function (d) { return scaleWidthWithSeam(d, 1 - centerPoint, true); },
        translateVec3: function (d) { return translateWidth(d, centerPoint, seamWidth); },
        scaleUVVec2: function (d) { return scaleUVWidth(d, 1 - centerPoint, -seamWidth); },
    },
    // Top Edge Left
    {
        modelId: CardboardModelId.EDGE_TOP_LEFT,
        scaleVec3: function (d) { return scaleWidthWithSeam(d, centerPoint); },
        translateVec3: function (d) { return translateLength(d, -1); },
        scaleUVVec2: function (d) { return scaleUVWidth(d, centerPoint); },
    },
    // Top Seam
    {
        modelId: CardboardModelId.SEAM_TOP,
        translateVec3: function (d) {
            var translateOptions = {
                multiplierLength: -1,
                multiplierWidth: centerPoint,
            };
            return translate(d, translateOptions);
        },
        scaleUVVec2: scaleUVDefault,
    },
    // Top Edge Right
    {
        modelId: CardboardModelId.EDGE_TOP_RIGHT,
        scaleVec3: function (d) { return scaleWidthWithSeam(d, 1 - centerPoint, true); },
        translateVec3: function (d) {
            var translateOptions = {
                multiplierLength: -1,
                multiplierWidth: centerPoint,
                offsetWidth: seamWidth,
            };
            return translate(d, translateOptions);
        },
        scaleUVVec2: function (d) { return scaleUVWidth(d, 1 - centerPoint, -seamWidth); },
    },
], false); };
export var cardboardBoxMap = function (objectShapeId, centerPoint) {
    if (objectShapeId === CardboardObjectShapeId.WITHOUT_SEAM) {
        return cardboardBoxWithoutSeam;
    }
    return cardboardBoxWithSeam(centerPoint);
};
var singleTapeParts = function (centerPoint) {
    return [
        // Bottom Tape Edge
        {
            modelId: CardboardModelId.TAPE_START,
            scaleVec3: scaleTape,
            translateVec3: function (d) { return translateWidth(d, centerPoint); },
        },
        // Center Tape
        {
            modelId: CardboardModelId.TAPE_MIDDLE,
            scaleVec3: scaleTapeLength,
            translateVec3: function (d) { return translateWidth(d, centerPoint); },
        },
        // Top Tape Edge
        {
            modelId: CardboardModelId.TAPE_START,
            scaleVec3: scaleTapeFlipLength,
            translateVec3: function (d) {
                return translate(d, {
                    multiplierWidth: centerPoint,
                    multiplierLength: -1,
                });
            },
        },
    ];
};
var doubleTapeParts = function (centerPoint) {
    var widthOffset = tapeWidth * 0.05 + 0.001;
    return [
        // Bottom Tape Left Edge
        {
            modelId: CardboardModelId.TAPE_START,
            scaleVec3: scaleTape,
            translateVec3: function (d) { return translateWidth(d, centerPoint, widthOffset); },
        },
        // Bottom Tape Right Edge
        {
            modelId: CardboardModelId.TAPE_START,
            scaleVec3: scaleTape,
            translateVec3: function (d) { return translateWidth(d, centerPoint, -widthOffset); },
        },
        // Center Tape Left
        {
            modelId: CardboardModelId.TAPE_MIDDLE,
            scaleVec3: scaleTapeLength,
            translateVec3: function (d) { return translateWidth(d, centerPoint, widthOffset); },
        },
        // Center Tape Right
        {
            modelId: CardboardModelId.TAPE_MIDDLE,
            scaleVec3: scaleTapeLength,
            translateVec3: function (d) { return translateWidth(d, centerPoint, -widthOffset); },
        },
        // Top Tape Left Edge
        {
            modelId: CardboardModelId.TAPE_START,
            translateVec3: function (d) {
                return translate(d, {
                    multiplierWidth: centerPoint,
                    multiplierLength: -1,
                    offsetWidth: widthOffset,
                });
            },
            scaleVec3: scaleTapeFlipLength,
        },
        // Top Tape Right Edge
        {
            modelId: CardboardModelId.TAPE_START,
            translateVec3: function (d) {
                return translate(d, {
                    multiplierWidth: centerPoint,
                    multiplierLength: -1,
                    offsetWidth: -widthOffset,
                });
            },
            scaleVec3: scaleTapeFlipLength,
        },
    ];
};
var multiplier = 0.5;
var centerCenterTape = [
    // ---- Vertical Tape ----
    // Bottom Tape Edge
    {
        modelId: CardboardModelId.TAPE_START,
        scaleVec3: scaleTape,
        translateVec3: function (d) { return translateWidth(d, multiplier); },
    },
    // Center Tape
    {
        modelId: CardboardModelId.TAPE_MIDDLE,
        scaleVec3: scaleTapeLength,
        translateVec3: function (d) { return translateWidth(d, multiplier); },
    },
    // Top Tape Edge
    {
        modelId: CardboardModelId.TAPE_START,
        scaleVec3: scaleTapeFlipLength,
        translateVec3: function (d) {
            return translate(d, {
                multiplierWidth: multiplier,
                multiplierLength: -1,
            });
        },
    },
    // ---- Horizontal Tape ----
    // Bottom Tape Edge
    {
        modelId: CardboardModelId.TAPE_START,
        scaleVec3: function (d) {
            var customDimensions = __assign(__assign({}, d), { length: 1 });
            return scaleTape(customDimensions, {
                multiplierHeight: 1.02,
                multiplierLength: 1.01,
            });
        },
        translateVec3: function (d) {
            return translate(d, {
                multiplierLength: -multiplier,
                offsetHeight: 0.001,
            });
        },
        rotateVec3: function (_d) { return new pc.Vec3(0, -90, 0); },
    },
    // Center Tape
    {
        modelId: CardboardModelId.TAPE_MIDDLE,
        scaleVec3: function (d) {
            var customDimensions = __assign(__assign({}, d), { length: d.width });
            return scaleTape(customDimensions, {
                multiplierHeight: 1.02,
                multiplierLength: 1,
            });
        },
        translateVec3: function (d) {
            return translate(d, {
                multiplierLength: -multiplier,
                offsetHeight: 0.001,
            });
        },
        rotateVec3: function (_d) { return new pc.Vec3(0, -90, 0); },
    },
    // Top Tape Edge
    {
        modelId: CardboardModelId.TAPE_START,
        scaleVec3: function (d) {
            var customDimensions = __assign(__assign({}, d), { length: 1 });
            return scaleTape(customDimensions, {
                multiplierHeight: 1.02,
                multiplierLength: 1.01,
            });
        },
        translateVec3: function (d) {
            return translate(d, {
                multiplierWidth: 1,
                multiplierLength: -multiplier,
                offsetHeight: 0.001,
            });
        },
        rotateVec3: function (_d) { return new pc.Vec3(0, 90, 0); },
    },
];
export var tapeEndsParts = [
    // Bottom Left Corner
    {
        modelId: CardboardModelId.TAPE_EDGE_CORNER,
    },
    // Bottom Edge
    {
        modelId: CardboardModelId.TAPE_EDGE_BOTTOM,
        scaleVec3: scaleWidth,
    },
    // Bottom Right Corner
    {
        modelId: CardboardModelId.TAPE_EDGE_CORNER,
        translateVec3: translateWidth,
        scaleVec3: flipWidth,
    },
    // Left Edge
    {
        modelId: CardboardModelId.TAPE_EDGE_SIDE,
        scaleVec3: scaleLength,
    },
    // Right Edge
    {
        modelId: CardboardModelId.TAPE_EDGE_SIDE,
        scaleVec3: scaleLengthFlipWidth,
        translateVec3: translateWidth,
    },
    // Top Left Corner
    {
        modelId: CardboardModelId.TAPE_EDGE_CORNER,
        scaleVec3: flipLength,
        translateVec3: function (d) { return translateLength(d, -1); },
    },
    // Top Edge
    {
        modelId: CardboardModelId.TAPE_EDGE_BOTTOM,
        scaleVec3: scaleWidthFlipLength,
        translateVec3: function (d) { return translateLength(d, -1); },
    },
    // Top Right Corner
    {
        modelId: CardboardModelId.TAPE_EDGE_CORNER,
        scaleVec3: function (_d) { return new pc.Vec3(-1, 1, -1); },
        translateVec3: function (d) {
            var options = {
                multiplierLength: -1,
                multiplierWidth: 1,
            };
            return translate(d, options);
        },
    },
];
var noTape = tapeParameterValues.noTape, singleCenter = tapeParameterValues.singleCenter, doubleCenter = tapeParameterValues.doubleCenter, left = tapeParameterValues.left, right = tapeParameterValues.right, doubleLeft = tapeParameterValues.doubleLeft, doubleRight = tapeParameterValues.doubleRight, centerCenter = tapeParameterValues.centerCenter;
export var tapeCenterPointMap = (_b = {},
    _b[noTape] = 0.5,
    _b[singleCenter] = 0.5,
    _b[doubleCenter] = 0.5,
    _b[left] = 0.2,
    _b[right] = 0.8,
    _b[doubleLeft] = 0.2,
    _b[doubleRight] = 0.8,
    _b[centerCenter] = 0.5,
    _b);
export var tapeModelsMap = (_c = {},
    _c[noTape] = [],
    _c[singleCenter] = singleTapeParts(tapeCenterPointMap[singleCenter]),
    _c[doubleCenter] = doubleTapeParts(tapeCenterPointMap[doubleCenter]),
    _c[left] = singleTapeParts(tapeCenterPointMap[left]),
    _c[right] = singleTapeParts(tapeCenterPointMap[right]),
    _c[doubleLeft] = doubleTapeParts(tapeCenterPointMap[doubleLeft]),
    _c[doubleRight] = doubleTapeParts(tapeCenterPointMap[doubleRight]),
    _c[centerCenter] = centerCenterTape,
    _c);
