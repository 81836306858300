import { createMigrate } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import { migrations } from "./persist-migrations";
import { currentSceneVersion } from "./types.current";
export var persistKey = "scene";
export var persistConfig = {
    version: currentSceneVersion,
    key: persistKey,
    storage: storage,
    migrate: createMigrate(migrations),
    blacklist: ["past", "future"],
};
