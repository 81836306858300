import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { PerformanceTooltip, } from "./performance-tooltip";
import { useNumberConversion } from "hooks/number-conversion";
import { Unit, round } from "utility/number";
import { selectors, useAppSelector } from "store";
import { useGraphDataContext } from "providers/graph-panel";
import { AlphabeticalNodeType } from "hooks/graph-panel/graph";
export var CupTooltip = function (_a) {
    var _b;
    var node = _a.node, x = _a.x, y = _a.y, align = _a.align, onTooltipHover = _a.onTooltipHover, shouldFadeTooltip = _a.shouldFadeTooltip;
    var convertNumber = useNumberConversion();
    var getAlphabeticalId = useGraphDataContext().getAlphabeticalId;
    var cupPerformance = useAppSelector(selectors.selectSuctionCupPerformance(node.id));
    var isSystemPerformanceLoading = useAppSelector(selectors.selectIsPerformanceLoading);
    var cupComponent = useAppSelector(selectors.selectSelectedCup(node.id));
    var t = useTranslation().t;
    var alphabeticalId = getAlphabeticalId(node.id, AlphabeticalNodeType.CUP);
    if (!cupPerformance || isSystemPerformanceLoading)
        return null;
    var cupLabel = (_b = cupComponent === null || cupComponent === void 0 ? void 0 : cupComponent.label) !== null && _b !== void 0 ? _b : "";
    return (_jsxs(PerformanceTooltip, { x: x, y: y, align: align, title: t("canvas.suction_cup_title", {
            alphabeticalId: alphabeticalId,
        }), name: cupLabel, onTooltipHover: onTooltipHover, shouldFadeTooltip: shouldFadeTooltip, children: [_jsxs(_Fragment, { children: [_jsx("br", {}), t("canvas.time_to_part_secured", {
                        value: round(cupPerformance.timeToPartSecured * 1000, 0),
                        context: cupPerformance.timeToPartSecured === 0 ? "not_reached" : "",
                    }), _jsx("br", {}), cupPerformance.timeToEnergySave ? (_jsxs(_Fragment, { children: [t("canvas.time_to_energy_save", {
                                value: round(cupPerformance.timeToEnergySave * 1000, 0),
                            }), _jsx("br", {})] })) : null] }), "".concat(t("canvas.final_vacuum_level"), ": ").concat(convertNumber(cupPerformance.finalVacuumLevel, Unit.KPA).formatted, "\n\t\t\t")] }));
};
