import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import * as Styled from "ui/components/panels/sidebar/sidebar.style";
import { systemDataConstants } from "api/system-data";
import { ParameterInput } from "ui/components/elements/parameter-input";
import { useAppSelector, selectors } from "store";
import { EditButtons } from "ui/components/elements/edit-buttons/edit-buttons";
import { useNodeActions } from "hooks/node-actions";
export var FeedPressurePointMenu = function (_a) {
    var node = _a.node;
    var systemDataDefineParameters = useAppSelector(selectors.selectSystemDataDefineParameters);
    var actions = useNodeActions(node);
    return (_jsxs(_Fragment, { children: [_jsx(EditButtons, { className: "".concat(Styled.prefix, "-edit-buttons"), buttonSize: "large", actions: actions }), systemDataDefineParameters && (_jsx(Styled.SidebarSection, { children: _jsx(ParameterInput, { parameter: systemDataDefineParameters.parameters[systemDataConstants.parameterNames.feedPressure] }) }))] }));
};
