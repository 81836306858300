var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { NodeComponentType, TubeType, } from "./types.current";
import { systemDataConstants } from "api/system-data";
import { tubeDiameterOptionInitialIndex } from "store/types";
export var getInitialTubeDiameterIndex = function (unitSystem, systemData) {
    var systemType = systemData.parameters[systemDataConstants.parameterNames.vacuumSystemType]
        .value === systemDataConstants.parameterValues.centralizedSystem
        ? "centralized"
        : "decentralized";
    return tubeDiameterOptionInitialIndex[unitSystem][systemType];
};
export var updateTubeTypes = function (state) {
    var e_1, _a;
    var traversed = new Set();
    var notTraversed = new Set();
    var recursiveUpdateTubes = function (currentNode, currentType) {
        /* If the current node is a tube connection, update the type of that connection*/
        if (currentNode.type === NodeComponentType.TUBE_CONNECTION) {
            var tubeConnection = currentNode.component;
            tubeConnection.tubeType = currentType;
            currentNode.attachmentIds.forEach(function (attachmentId) {
                var attachment = state.attachments[attachmentId];
                attachment.tubeType = currentType;
            });
        }
        /* Look at all attachments for this node, if the attachment has an edge that
         * we have not yet traversed, update it's type and recursively call this
         * function for the node that the edge leads to */
        currentNode.attachmentIds.forEach(function (attachmentId) {
            var attachment = state.attachments[attachmentId];
            if (attachment.edgeId && !traversed.has(attachment.edgeId)) {
                /* The tube type changes when we encounter a vacuum pump. If the current
                 * attachment is the back of the pump (leading to the feed pressure point),
                 * the type of the edge and any nodes/edges that edge is connected to will be
                 * "compressed_air". If it's the front of the pump it will be "vacuum" */
                var changedType = null;
                if (currentNode.type === NodeComponentType.CENTRALIZED_VACUUM_PUMP) {
                    changedType = attachment.tubeType;
                }
                var currentEdge = state.edges[attachment.edgeId];
                currentEdge.tubeType = changedType || currentType;
                var otherAttachment = currentEdge.fromAttachmentId === attachmentId
                    ? state.attachments[currentEdge.toAttachmentId]
                    : state.attachments[currentEdge.fromAttachmentId];
                var otherNode = state.nodes[otherAttachment.nodeId];
                traversed.add(attachment.edgeId);
                recursiveUpdateTubes(otherNode, changedType || currentType);
            }
        });
    };
    /* Start the traversal from the feed pressure point and with type "compressed_air"*/
    recursiveUpdateTubes(state.nodes["feed-pressure-point"], TubeType.COMPRESSED_AIR);
    try {
        /* The network may contain node and edges that are not yet connected to the feed
         * pressure point. These should all have type "unknown" */
        /* Go through all edges, if they have not been traversed in the recursive function
         * they should have type "unknown".
         * However the tube connections connected to the edges might have a leaf node with a set tubeType.
         * To figure out if that is the case, we recursively travel across all edges to find a disconnected cup/pump
         * which will determine the tubeType of the disconnected tube connection and all its attachments */
        for (var _b = __values(Object.values(state.edges)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var edge = _c.value;
            if (traversed.has(edge.id))
                continue;
            notTraversed.add(edge.id);
            edge.tubeType = TubeType.UNKNOWN;
            var fromAttachment = state.attachments[edge.fromAttachmentId];
            var fromNode = state.nodes[fromAttachment.nodeId];
            var toAttachment = state.attachments[edge.toAttachmentId];
            var toNode = state.nodes[toAttachment.nodeId];
            var setTubeTypeFromEdgeConnections = function (thisNode, otherNode, thisAttachment, otherAttachment) {
                var tubeType = recursivelyLookForTubeTypeFromEdgeConnections(state, otherNode, otherAttachment, thisAttachment);
                // The edge from our connection had a tubeType, so we set that type to all this connections attachments.
                thisNode.attachmentIds.forEach(function (attachmentId) {
                    var attachment = state.attachments[attachmentId];
                    attachment.tubeType = tubeType;
                });
                thisNode.component.tubeType = tubeType;
            };
            if (fromNode.type === NodeComponentType.TUBE_CONNECTION) {
                setTubeTypeFromEdgeConnections(fromNode, toNode, fromAttachment, toAttachment);
            }
            if (toNode.type === NodeComponentType.TUBE_CONNECTION) {
                setTubeTypeFromEdgeConnections(toNode, fromNode, toAttachment, fromAttachment);
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    resetCompletetlyDisconnectedTubeConnections(state);
    state.hasDisconnectedEdges = notTraversed.size > 0;
    return state;
};
var recursivelyLookForTubeTypeFromEdgeConnections = function (state, connectedNode, connectedAttachment, fromAttachment) {
    var e_2, _a;
    var newTubeType = TubeType.UNKNOWN;
    /* If the connected attachment already has a type,
     * we can just set that type for our Tube Connection and its attachments */
    if (connectedAttachment.tubeType !== TubeType.UNKNOWN) {
        return connectedAttachment.tubeType;
    }
    try {
        // If not, we traverse all edges
        for (var _b = __values(connectedNode.attachmentIds), _c = _b.next(); !_c.done; _c = _b.next()) {
            var attachmentId = _c.value;
            var attachment = state.attachments[attachmentId];
            // If the attachment has an edge id, and it's not the attachment we just came from, we follow the new edge.
            if (attachment.edgeId && attachment.id !== fromAttachment.id) {
                var edge = state.edges[attachment.edgeId];
                var nextAttachment = edge.fromAttachmentId === attachment.id
                    ? state.attachments[edge.toAttachmentId]
                    : state.attachments[edge.fromAttachmentId];
                var nextNode = state.nodes[nextAttachment.nodeId];
                newTubeType = recursivelyLookForTubeTypeFromEdgeConnections(state, nextNode, nextAttachment, attachment);
                break;
            }
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_2) throw e_2.error; }
    }
    return newTubeType;
};
/* If a node is completely disconnected and has no edges it should have type "unknown" */
var resetCompletetlyDisconnectedTubeConnections = function (state) {
    var e_3, _a;
    try {
        for (var _b = __values(Object.values(state.nodes)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var node = _c.value;
            if (node.type !== NodeComponentType.TUBE_CONNECTION)
                continue;
            var hasEdgeConnections = node.attachmentIds.some(function (attachmentId) { return state.attachments[attachmentId].edgeId; });
            if (hasEdgeConnections)
                continue;
            var tubeConnection = node.component;
            tubeConnection.tubeType = TubeType.UNKNOWN;
            node.attachmentIds.forEach(function (attachmentId) {
                var attachment = state.attachments[attachmentId];
                attachment.tubeType = TubeType.UNKNOWN;
            });
        }
    }
    catch (e_3_1) { e_3 = { error: e_3_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_3) throw e_3.error; }
    }
};
