var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { StyledLabel } from "@animech/ui";
export var prefix = "topbar-panel";
export var TopBarWidget = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var TopBarWidgetsContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
export var WidgetRow = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
export var LabelRow = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
export var TopbarPanel = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\t\t\twidth: 100%;\n\t\t\tpadding: ", ";\n\t\t\tdisplay: flex;\n\n\t\t\t", " {\n\t\t\t\tdisplay: grid;\n\t\t\t\tgrid-template-rows: repeat(2, auto);\n\t\t\t\tgrid-auto-flow: column;\n\t\t\t\tgrid-row-gap: ", ";\n\t\t\t\tgrid-column-gap: ", ";\n\n\t\t\t\t", ", ", " {\n\t\t\t\t\tdisplay: flex;\n\t\t\t\t\tjustify-content: center;\n\t\t\t\t\ttext-align: center;\n\n\t\t\t\t\t", " {\n\t\t\t\t\t\tmargin: 0;\n\t\t\t\t\t}\n\t\t\t\t}\n\n\t\t\t\t", " {\n\t\t\t\t\talign-items: center;\n\t\t\t\t}\n\n\t\t\t\t", " {\n\t\t\t\t\talign-items: baseline;\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t.", "-left-aligned {\n\t\t\t\tdisplay: flex;\n\t\t\t\tmargin-left: auto;\n\t\t\t}\n\n\t\t\t.", "-calculations {\n\t\t\t\twidth: fit-content;\n\t\t\t\tmargin-left: ", ";\n\t\t\t}\n\t\t"], ["\n\t\t\twidth: 100%;\n\t\t\tpadding: ", ";\n\t\t\tdisplay: flex;\n\n\t\t\t", " {\n\t\t\t\tdisplay: grid;\n\t\t\t\tgrid-template-rows: repeat(2, auto);\n\t\t\t\tgrid-auto-flow: column;\n\t\t\t\tgrid-row-gap: ", ";\n\t\t\t\tgrid-column-gap: ", ";\n\n\t\t\t\t", ", ", " {\n\t\t\t\t\tdisplay: flex;\n\t\t\t\t\tjustify-content: center;\n\t\t\t\t\ttext-align: center;\n\n\t\t\t\t\t", " {\n\t\t\t\t\t\tmargin: 0;\n\t\t\t\t\t}\n\t\t\t\t}\n\n\t\t\t\t", " {\n\t\t\t\t\talign-items: center;\n\t\t\t\t}\n\n\t\t\t\t", " {\n\t\t\t\t\talign-items: baseline;\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t.", "-left-aligned {\n\t\t\t\tdisplay: flex;\n\t\t\t\tmargin-left: auto;\n\t\t\t}\n\n\t\t\t.", "-calculations {\n\t\t\t\twidth: fit-content;\n\t\t\t\tmargin-left: ", ";\n\t\t\t}\n\t\t"])), theme.dimensions.margin.large, TopBarWidgetsContainer, theme.dimensions.margin.xSmall, theme.dimensions.margin.large, WidgetRow, LabelRow, StyledLabel.Root, WidgetRow, LabelRow, prefix, prefix, theme.dimensions.margin.small);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
