var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useState, useEffect } from "react";
import { TooltipPortal } from "@animech/ui";
import * as Styled from "./messages-popover.style";
import { AlertIcon } from "ui/components/elements/icons/alert";
import { ErrorIcon } from "ui/components/elements/icons/error";
import { InfoIcon } from "ui/components/elements/icons/info";
import { IconButton } from "ui/components/elements/icons/icon-button";
export var MessageLevel;
(function (MessageLevel) {
    MessageLevel["WARNING"] = "warning";
    MessageLevel["ERROR"] = "error";
    MessageLevel["INFO"] = "info";
})(MessageLevel || (MessageLevel = {}));
var getContent = function (level, size) {
    if (level === MessageLevel.WARNING) {
        return {
            title: "Warnings",
            Icon: AlertIcon,
            toggleSize: size === "small" ? 18 : 25,
        };
    }
    else if (level === MessageLevel.INFO) {
        return {
            title: "Info",
            Icon: InfoIcon,
            toggleSize: size === "small" ? 18 : 25,
        };
    }
    else {
        return {
            title: "Errors",
            Icon: ErrorIcon,
            toggleSize: size === "small" ? 18 : 27,
        };
    }
};
export var MessagesPopover = function (_a) {
    var level = _a.level, messages = _a.messages, openOnChange = _a.openOnChange, isLoading = _a.isLoading, _b = _a.toggleEvent, toggleEvent = _b === void 0 ? "click" : _b, _c = _a.size, size = _c === void 0 ? "default" : _c;
    var _d = __read(useState(false), 2), isOpen = _d[0], setIsOpen = _d[1];
    var _e = __read(useState([]), 2), previousMessages = _e[0], setPreviousMessages = _e[1];
    var _f = getContent(level, size), title = _f.title, Icon = _f.Icon, toggleSize = _f.toggleSize;
    var isDisabled = isLoading || messages.length < 1;
    useEffect(function () {
        if (!openOnChange || messages === previousMessages)
            return;
        if (messages.some(function (message) { return !previousMessages.includes(message); })) {
            setIsOpen(true);
        }
    }, [messages, previousMessages, openOnChange]);
    useEffect(function () {
        setPreviousMessages(messages);
    }, [messages]);
    useEffect(function () {
        if (messages.length === 0) {
            setIsOpen(false);
        }
    }, [messages]);
    return (_jsx(TooltipPortal, { anchorElement: _jsxs(Styled.ToggleIcon, { children: [_jsx(IconButton, { color: "neutral", appearance: "text", iconSize: size === "small" ? 18 : 27, disabled: isDisabled, children: isLoading ? (_jsx("div", { className: "".concat(Styled.prefix, "-loading") })) : (_jsx(Icon, { size: toggleSize, isDisabled: isDisabled })) }), size === "default" && !isLoading && messages.length > 0 && (_jsx("span", { className: "".concat(Styled.prefix, "-indicator"), children: messages.length }))] }), toggleEvent: isDisabled ? undefined : toggleEvent, open: isOpen, onToggle: setIsOpen, placement: "bottom", children: _jsxs(Styled.Tooltip, { children: [_jsx("div", { className: "".concat(Styled.prefix, "-title"), children: title }), messages.map(function (message) { return (_jsxs("div", { className: "".concat(Styled.prefix, "-messages"), children: [_jsx("div", { className: "".concat(Styled.prefix, "-message-icon"), children: _jsx(Icon, { size: 16 }) }), _jsx("div", { className: "".concat(Styled.prefix, "-message"), children: message.split("\\n").map(function (line) { return (_jsxs("span", { children: [line, _jsx("br", {})] }, line)); }) })] }, message)); })] }) }));
};
