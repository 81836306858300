var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@emotion/styled";
import { css } from "@emotion/react";
export var prefix = "node-warning";
export var NodeWarning = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t\t\ttransform: translate(-50%, -50%);\n\t\t\tbackground-color: ", ";\n\t\t\tborder-radius: 12px;\n\t\t\theight: 22px;\n\t\t\twidth: 22px;\n\t\t\tpadding-top: 2px;\n\t\t\ttext-align: center;\n\t\t"], ["\n\t\t\ttransform: translate(-50%, -50%);\n\t\t\tbackground-color: ", ";\n\t\t\tborder-radius: 12px;\n\t\t\theight: 22px;\n\t\t\twidth: 22px;\n\t\t\tpadding-top: 2px;\n\t\t\ttext-align: center;\n\t\t"])), theme.colors.components.tertiary.shades[900].main);
});
var templateObject_1, templateObject_2;
