var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useEffect, useRef } from "react";
import * as Styled from "./performance-tooltip.style";
import { CanvasOverlay } from "ui/components/panels/canvas/overlay-elements/canvas-overlay";
import { useSystemPerformanceValidityMessage } from "hooks/messages";
import useHover from "hooks/usehooks-ts/hover";
import { MessagesPopover, MessageLevel, } from "ui/components/elements/messages-popover";
import { selectors, useAppSelector } from "store";
import { CameraViewId } from "3d/types";
export var PerformanceTooltip = function (_a) {
    var x = _a.x, y = _a.y, align = _a.align, children = _a.children, title = _a.title, name = _a.name, _b = _a.showValidityMessage, showValidityMessage = _b === void 0 ? true : _b, onTooltipHover = _a.onTooltipHover, shouldFadeTooltip = _a.shouldFadeTooltip, restProps = __rest(_a, ["x", "y", "align", "children", "title", "name", "showValidityMessage", "onTooltipHover", "shouldFadeTooltip"]);
    var performanceValidityMessage = useSystemPerformanceValidityMessage();
    var hoverRef = useRef(null);
    var isHover = useHover(hoverRef);
    var isTooltipInComponentDataList = align === undefined;
    var isZoomedIn = useAppSelector(selectors.selectCameraViewId) === CameraViewId.NODE_VIEW;
    useEffect(function () {
        if (!onTooltipHover)
            return;
        onTooltipHover(isHover);
    }, [isHover, onTooltipHover, title]);
    /* If the user has zoomed in on a node, we shouldn't display the performance tooltip.
     * This is because the tooltip will take up a lot of screen space in a zoomed environment */
    if (isZoomedIn)
        return null;
    var toolTipContent = (_jsx(Styled.PerformanceTooltipWrapper, { align: align, shouldFadeTooltip: shouldFadeTooltip, ref: hoverRef, children: _jsx(Styled.UITooltip, { hideArrow: true, children: _jsxs("div", { className: "".concat(Styled.prefix, "-tooltip-container"), children: [_jsxs("div", { className: "".concat(Styled.prefix, "-tooltip-title"), children: [_jsxs(Styled.TooltipTitle, { children: [_jsx("span", { children: title }), name && _jsx(Styled.Name, { children: name })] }), showValidityMessage && performanceValidityMessage && (_jsx(MessagesPopover, { messages: [performanceValidityMessage], level: MessageLevel.WARNING, toggleEvent: "hover", size: "small" }))] }), children] }) }) }));
    // We don't need to wrap the tooltips in CanvasOverlay when they're in the componend data list.
    return isTooltipInComponentDataList ? (toolTipContent) : (_jsx(CanvasOverlay, __assign({ x: x !== null && x !== void 0 ? x : 0, y: y !== null && y !== void 0 ? y : 0 }, restProps, { children: toolTipContent })));
};
