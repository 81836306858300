var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createElement as _createElement } from "@emotion/react";
import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { useMemo } from "react";
import { ReferenceLine } from "recharts";
import { useGraphDataContext } from "providers/graph-panel";
import { selectors, useAppSelector } from "store";
var CustomizedLabel = function (props) {
    var value = props.value, viewBox = props.viewBox;
    if (!viewBox)
        return null;
    var cartesianViewbox = viewBox;
    var x = cartesianViewbox.x, y = cartesianViewbox.y, height = cartesianViewbox.height;
    return (_jsx("g", { children: _jsx("text", { x: x, y: y, fill: "white", dy: Number(height) - 10, textAnchor: "middle", children: value }) }));
};
export var VerticalReferenceLines = function (props) {
    var graphData = useAppSelector(selectors.selectGraphData);
    var showEnergySave = useAppSelector(selectors.selectShowEnergySave);
    var zoomedTimeDomain = useGraphDataContext().zoomedTimeDomain;
    var systemProperty = graphData.systemProperty;
    var psIndex = systemProperty.psIndex, esIndex = systemProperty.esIndex, atmIndex = systemProperty.atmIndex, timeData = systemProperty.timeData;
    var timeDomainMax = zoomedTimeDomain
        ? zoomedTimeDomain[1]
        : timeData[timeData.length - 1];
    var referenceLineXCoords = useMemo(function () {
        var referenceLines = [
            {
                x: timeData[psIndex],
            },
            {
                x: timeData[atmIndex],
            },
            {
                x: timeDomainMax,
            },
        ];
        if (showEnergySave) {
            referenceLines.push({
                x: timeData[esIndex],
            });
        }
        return referenceLines;
    }, [atmIndex, esIndex, psIndex, showEnergySave, timeData, timeDomainMax]);
    var withLabel = props.withLabel, defaultProps = __rest(props, ["withLabel"]);
    return (_jsx(_Fragment, { children: referenceLineXCoords.map(function (referenceLine) { return (_createElement(ReferenceLine, __assign({}, defaultProps, { key: referenceLine.x, label: withLabel && _jsx(CustomizedLabel, { value: referenceLine.label }), x: referenceLine.x }))); }) }));
};
VerticalReferenceLines.displayName = ReferenceLine.displayName;
VerticalReferenceLines.defaultProps = ReferenceLine.defaultProps;
