import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useMemo } from "react";
import * as Styled from "./node-alphabetical-id.style";
import { CanvasOverlay } from "./canvas-overlay";
import { useAppSelector, selectors } from "store";
import { NodeComponentType, OverlayData } from "store/types";
import { useGraphDataContext } from "providers/graph-panel";
import { AlphabeticalNodeType } from "hooks/graph-panel/graph";
export var NodeAlphabeticalId = function (_a) {
    var node = _a.node, nodeCenterX = _a.nodeCenterX, nodeCenterY = _a.nodeCenterY;
    var getAlphabeticalId = useGraphDataContext().getAlphabeticalId;
    var selectedId = useAppSelector(selectors.selectSelectedId);
    var isPerformanceOverlay = useAppSelector(selectors.selectOverlayData) ===
        OverlayData.PERFORMANCE_DATA;
    var isCentralizedSystem = useAppSelector(selectors.selectIsCentralizedSystem);
    var alphabeticalIdCup = useMemo(function () {
        var isGripPosition = node.type === NodeComponentType.GRIP_POSITION;
        if (!isGripPosition)
            return;
        return getAlphabeticalId(node.id, AlphabeticalNodeType.CUP);
    }, [getAlphabeticalId, node.id, node.type]);
    var alphabeticalIdPump = useMemo(function () {
        var isCentralizedPump = node.type === NodeComponentType.CENTRALIZED_VACUUM_PUMP &&
            isCentralizedSystem;
        var isGripPositionInDecentralized = node.type === NodeComponentType.GRIP_POSITION && !isCentralizedSystem;
        if (!isCentralizedPump && !isGripPositionInDecentralized)
            return;
        return getAlphabeticalId(node.id, AlphabeticalNodeType.PUMP);
    }, [getAlphabeticalId, isCentralizedSystem, node.id, node.type]);
    var overlayOffset = isCentralizedSystem ? 0 : 30;
    /* We only want to render if:
     * Show component data is enabled.
     * If the node has an alphabeticalId (Only cups and pumps)
     * If no node is selected, meaning that the user isn't hovering any of the elements in the component data list. */
    if (!isPerformanceOverlay || selectedId)
        return null;
    return (_jsxs(_Fragment, { children: [alphabeticalIdCup && (_jsx(CanvasOverlay, { x: nodeCenterX, y: nodeCenterY, children: _jsx(Styled.NodeAlphabeticalId, { children: alphabeticalIdCup }) })), alphabeticalIdPump && (_jsx(CanvasOverlay, { x: nodeCenterX - overlayOffset, y: nodeCenterY - overlayOffset, children: _jsx(Styled.NodeAlphabeticalId, { children: alphabeticalIdPump }) }))] }));
};
