var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { TapeEndsToggle } from "./tape-ends-toggle/tape-ends-toggle";
import * as Styled from "./cardboard-image-select.style";
import { cardboardParameterNames, tapeEndsParameterValues, } from "api/tacton/constants";
import { useAppDispatch, useAppSelector, selectors, actions } from "store";
import { CustomAccordion } from "ui/components/elements/accordion";
export var CardboardImageSelectAccordions = function () {
    var dispatch = useAppDispatch();
    var systemDataDefineParameters = useAppSelector(selectors.selectSystemDataDefineParameters);
    var setValue = useCallback(function (id, value) {
        return dispatch(actions.setParametersDefineParameters({
            parameters: [id],
            values: [value],
        }));
    }, [dispatch]);
    var clearValue = useCallback(function (parameterId) { return dispatch(actions.clearParameters([parameterId])); }, [dispatch]);
    if (!systemDataDefineParameters)
        return null;
    var parameters = systemDataDefineParameters.parameters;
    var tapeParameter = parameters[cardboardParameterNames.cardboardTape];
    return (_jsx(CardboardImageSelect, { item: tapeParameter, setValue: function (v) { return setValue(tapeParameter.id, v); }, clearValue: function () { return clearValue(tapeParameter.id); }, isTapeField: true }));
};
var CardboardImageSelect = function (_a) {
    var _b;
    var item = _a.item, setValue = _a.setValue, clearValue = _a.clearValue, _c = _a.isTapeField, isTapeField = _c === void 0 ? false : _c;
    var tapeEnds = useAppSelector(selectors.selectTapeEndsParameter);
    var selectedItemIndex = useMemo(function () { return item.options.findIndex(function (option) { return option.value === item.value; }); }, [item.options, item.value]);
    var selectedItemLabel = (_b = item.options[selectedItemIndex]) === null || _b === void 0 ? void 0 : _b.label;
    var isDisabled = item.options.length < 2;
    var hasTapeEndParameter = isTapeField && tapeEnds;
    var shouldShowTapeEnds = hasTapeEndParameter && tapeEnds.value === tapeEndsParameterValues.withTape;
    var options = item.options;
    var itemImages = useMemo(function () {
        return options.map(function (option) {
            var imgSource = shouldShowTapeEnds
                ? option.imageSourceAlt
                : option.imageSource;
            return imgSource !== null && imgSource !== void 0 ? imgSource : "";
        });
    }, [options, shouldShowTapeEnds]);
    var selectedItemImage = useMemo(function () {
        return itemImages[selectedItemIndex];
    }, [itemImages, selectedItemIndex]);
    return (_jsx(CustomAccordion, { title: item.label, selectedItemLabel: selectedItemLabel, lock: {
            isLocked: item.isLocked,
            onLock: function () { return setValue(item.value); },
            onUnlock: clearValue,
            isLockDisabled: item.lockDisabled,
        }, disabled: isDisabled, description: __assign(__assign({}, item.description), (selectedItemImage && {
            imageSource: selectedItemImage,
        })), children: _jsxs(_Fragment, { children: [hasTapeEndParameter && _jsx(TapeEndsToggle, {}), _jsx(Styled.CustomImageSelect, { itemImages: itemImages, itemLabels: options.map(function (option) { return option.label; }), items: options.map(function (option) { return option.value; }), value: item.value, onValueChange: setValue, gridItemSize: 3, gridSpacing: "large" })] }) }, item.id));
};
