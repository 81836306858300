export var FeedPressureType;
(function (FeedPressureType) {
    FeedPressureType["EJECTOR"] = "ejector";
    FeedPressureType["BLOW_OFF"] = "blow_off";
})(FeedPressureType || (FeedPressureType = {}));
export var SystemModel;
(function (SystemModel) {
    SystemModel["CENTRALIZED"] = "centralized";
    SystemModel["DECENTRALIZED"] = "decentralized";
    SystemModel["MULTI_CIRCUIT"] = "multi_circuit";
})(SystemModel || (SystemModel = {}));
