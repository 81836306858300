/*
 * Before updating the version, make sure you have
 * read and followed the "Guidelines for updating versioned
 * state types" section in the README
 */
export var ObjectShapeId;
(function (ObjectShapeId) {
    ObjectShapeId["CAR_HOOD"] = "car_hood";
    ObjectShapeId["CAR_DOOR_OUTER"] = "car_door_outer";
    ObjectShapeId["CAR_ROOF"] = "car_roof";
    ObjectShapeId["CAR_BODY_SIDE"] = "car_body_side";
    ObjectShapeId["CAR_DOOR_INNER"] = "car_door_inner";
    ObjectShapeId["CAR_FENDER_FRONT"] = "car_fender_front";
    ObjectShapeId["CAR_FLOOR_PANEL"] = "car_floor_panel";
    ObjectShapeId["CAR_TRUNK_COVER"] = "car_trunk_cover";
    ObjectShapeId["BOX"] = "box";
    ObjectShapeId["CYLINDER"] = "cylinder";
})(ObjectShapeId || (ObjectShapeId = {}));
export var TubeConnectionId;
(function (TubeConnectionId) {
    TubeConnectionId["THREE_WAY_T"] = "3T";
    TubeConnectionId["THREE_WAY_Y"] = "3Y";
    TubeConnectionId["TWO_WAY_90"] = "90";
    TubeConnectionId["FOUR_WAY_X"] = "4X";
    TubeConnectionId["FOUR_WAY_TT"] = "2x3T";
    TubeConnectionId["FOUR_WAY_TT_ALTERNATE"] = "2x3T_Alt";
    TubeConnectionId["FOUR_WAY_TT_ALTERNATE_MIRROR"] = "2x3T_Alt_Mir";
    TubeConnectionId["TOOL_CHANGER"] = "TC";
})(TubeConnectionId || (TubeConnectionId = {}));
export var TubeType;
(function (TubeType) {
    TubeType["VACUUM"] = "vacuum";
    TubeType["COMPRESSED_AIR"] = "compressed_air";
    TubeType["UNKNOWN"] = "unknown";
})(TubeType || (TubeType = {}));
export var GripPositionMarkerId;
(function (GripPositionMarkerId) {
    GripPositionMarkerId["CIRCLE"] = "circle";
    GripPositionMarkerId["OVAL"] = "oval";
})(GripPositionMarkerId || (GripPositionMarkerId = {}));
export var NodeComponentType;
(function (NodeComponentType) {
    NodeComponentType["GRIP_POSITION"] = "grip_position";
    NodeComponentType["TUBE_CONNECTION"] = "tube_connection";
    NodeComponentType["CENTRALIZED_VACUUM_PUMP"] = "vacuum_pump_centralized";
    NodeComponentType["FEED_PRESSURE_POINT"] = "feed_pressure_point";
})(NodeComponentType || (NodeComponentType = {}));
