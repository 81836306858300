var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defaultMetalCupMaterialTransform, } from "3d/types";
/** ---------------   **/
/**    PAF Family     **/
/** ---------------   **/
export var suctionCupsFamilyPAF = {
    PAF: __assign(__assign({ cupModelName: "PAF50", cupWidth: 0.05, cupHeight: 0.05 }, defaultMetalCupMaterialTransform.NP), { fittingModelName: "G38M01-PA", collisionComponentType: "capsule" }),
};
