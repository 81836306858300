var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Material } from "3d/types";
/** ---------------   **/
/**    FXI Family    **/
/** ---------------   **/
var cupModelTemplate = {
    "FXI30-B1": {
        cupModelName: "FXI30-B1",
        cupWidth: 0.03,
        cupHeight: 0.0276,
        fittingModelName: "G38M01-S7",
    },
    "FXI60-B1": {
        cupModelName: "FXI60-B1",
        cupWidth: 0.06,
        cupHeight: 0.0332,
        fittingModelName: "G38M01-S7",
    },
    "FXI30-B3": {
        cupModelName: "FXI30-B3",
        cupWidth: 0.03,
        cupHeight: 0.0397,
        fittingModelName: "G38M01-S7",
    },
    "FXI60-B3": {
        cupModelName: "FXI60-B3",
        cupWidth: 0.06,
        cupHeight: 0.0497,
        fittingModelName: "G38M01-S7",
    },
    "FXI30-B6": {
        cupModelName: "FXI30-B6",
        cupWidth: 0.03,
        cupHeight: 0.0586,
        fittingModelName: "G38M01-S7",
    },
    "FXI60-B6": {
        cupModelName: "FXI60-B6",
        cupWidth: 0.06,
        cupHeight: 0.0761,
        fittingModelName: "G38M01-S7",
    },
};
var materialTransformTemplate = {
    DURAFLEX_GREEN_T18: {
        materialTransforms: [
            {
                sourceMaterial: Material.MA_MAIN,
                targetMaterial: Material.DURAFLEX_GREEN,
            },
            {
                sourceMaterial: Material.MA_MAIN2,
                targetMaterial: Material.T18,
            },
        ],
    },
    DURAFLEX_GREEN_T30: {
        materialTransforms: [
            {
                sourceMaterial: Material.MA_MAIN,
                targetMaterial: Material.DURAFLEX_GREEN,
            },
            {
                sourceMaterial: Material.MA_MAIN2,
                targetMaterial: Material.T30,
            },
        ],
    },
};
export var suctionCupsFamilyFXI = {
    // FXI30-B1 Pink
    "suction_cup_G.FXI30T18.B1.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FXI30-B1"]), materialTransformTemplate.DURAFLEX_GREEN_T18),
    // FXI42-B1 Pink
    "suction_cup_G.FXI42T18.B1.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FXI60-B1"]), materialTransformTemplate.DURAFLEX_GREEN_T18),
    // FXI60-B1 Pink
    "suction_cup_G.FXI60T18.B1.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FXI60-B1"]), materialTransformTemplate.DURAFLEX_GREEN_T18),
    // FXI84-B1 Pink
    "suction_cup_G.FXI84T18.B1.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FXI60-B1"]), materialTransformTemplate.DURAFLEX_GREEN_T18),
    // FXI30-B3 Pink
    "suction_cup_G.FXI30T18.B3.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FXI30-B3"]), materialTransformTemplate.DURAFLEX_GREEN_T18),
    // FXI42-B3 Pink
    "suction_cup_G.FXI42T18.B3.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FXI60-B3"]), materialTransformTemplate.DURAFLEX_GREEN_T18),
    // FXI60-B3 Pink
    "suction_cup_G.FXI60T18.B3.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FXI60-B3"]), materialTransformTemplate.DURAFLEX_GREEN_T18),
    // FXI84-B3 Pink
    "suction_cup_G.FXI84T18.B3.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FXI60-B3"]), materialTransformTemplate.DURAFLEX_GREEN_T18),
    // FXI30-B6 Pink
    "suction_cup_G.FXI30T18.B6.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FXI30-B6"]), materialTransformTemplate.DURAFLEX_GREEN_T18),
    // FXI42-B6 Pink
    "suction_cup_G.FXI42T18.B6.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FXI60-B6"]), materialTransformTemplate.DURAFLEX_GREEN_T18),
    // FXI60-B6 Pink
    "suction_cup_G.FXI60T18.B6.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FXI60-B6"]), materialTransformTemplate.DURAFLEX_GREEN_T18),
    // FXI84-B6 Pink
    "suction_cup_G.FXI84T18.B6.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FXI60-B6"]), materialTransformTemplate.DURAFLEX_GREEN_T18),
    // FXI30-B1 Yellow
    "suction_cup_G.FXI30T30.B1.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FXI30-B1"]), materialTransformTemplate.DURAFLEX_GREEN_T30),
    // FXI42-B1 Yellow
    "suction_cup_G.FXI42T30.B1.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FXI60-B1"]), materialTransformTemplate.DURAFLEX_GREEN_T30),
    // FXI60-B1 Yellow
    "suction_cup_G.FXI60T30.B1.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FXI60-B1"]), materialTransformTemplate.DURAFLEX_GREEN_T30),
    // FXI84-B1 Yellow
    "suction_cup_G.FXI84T30.B1.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FXI60-B1"]), materialTransformTemplate.DURAFLEX_GREEN_T30),
    // FXI30-B3 Yellow
    "suction_cup_G.FXI30T30.B3.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FXI30-B3"]), materialTransformTemplate.DURAFLEX_GREEN_T30),
    // FXI42-B3 Yellow
    "suction_cup_G.FXI42T30.B3.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FXI60-B3"]), materialTransformTemplate.DURAFLEX_GREEN_T30),
    // FXI60-B3 Yellow
    "suction_cup_G.FXI60T30.B3.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FXI60-B3"]), materialTransformTemplate.DURAFLEX_GREEN_T30),
    // FXI84-B3 Yellow
    "suction_cup_G.FXI84T30.B3.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FXI60-B3"]), materialTransformTemplate.DURAFLEX_GREEN_T30),
    // FXI30-B6 Yellow
    "suction_cup_G.FXI30T30.B6.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FXI30-B6"]), materialTransformTemplate.DURAFLEX_GREEN_T30),
    // FXI42-B6 Yellow
    "suction_cup_G.FXI42T30.B6.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FXI60-B6"]), materialTransformTemplate.DURAFLEX_GREEN_T30),
    // FXI60-B6 Yellow
    "suction_cup_G.FXI60T30.B6.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FXI60-B6"]), materialTransformTemplate.DURAFLEX_GREEN_T30),
    // FXI84-B6 Yellow
    "suction_cup_G.FXI84T30.B6.S1.G38M.00": __assign(__assign({}, cupModelTemplate["FXI60-B6"]), materialTransformTemplate.DURAFLEX_GREEN_T30),
};
