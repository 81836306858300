var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { useEffect, useState, useMemo, useCallback } from "react";
import { TooltipPortal } from "@animech/ui";
import * as pc from "playcanvas";
import { t } from "i18next";
import * as Styled from "./edge.style";
import { EditButtonIcon, EditButtons, } from "ui/components/elements/edit-buttons/edit-buttons";
import { CanvasOverlay, StyledCanvasOverlay, } from "ui/components/panels/canvas/overlay-elements/canvas-overlay";
import { TubeType } from "store/types";
import { round, clamp, Unit } from "utility/number";
import { useAppSelector, useAppDispatch, selectors, actions } from "store";
import { connectNodes, connectNodesWithLockedEdgeLength, } from "3d/helpers";
import { tubeCylinderAsset, tubeMaterials, tubeMaterialsLengthLocked, } from "3d/constants/common";
import { useInteractiveAppearance } from "hooks/playcanvas/interactive-appearance";
var MIN_DIAMETER_VISUALIZED = 0.008;
var MAX_DIAMETER_VISUALIZED = 0.012;
export var Edge = function (_a) {
    var _b;
    var app = _a.app, edge = _a.edge, modelAssets = _a.modelAssets, materialAssets = _a.materialAssets, movingNodeIds = _a.movingNodeIds, hoverId = _a.hoverId;
    var _c = __read(useState(0), 2), curveLength = _c[0], setCurveLength = _c[1];
    var _d = __read(useState(null), 2), edgeEntity = _d[0], setEdgeEntity = _d[1];
    var _e = __read(useState(null), 2), middlePosition = _e[0], setMiddlePosition = _e[1];
    var objectShapeId = useAppSelector(selectors.selectObjectShape);
    var useDoubleCompressedAirTubes = useAppSelector(selectors.selectUseDoubleCompressedAirTubes);
    var unitSystem = useAppSelector(selectors.selectUnitSystem);
    var dispatch = useAppDispatch();
    var isDoubleTube = edge.tubeType === TubeType.COMPRESSED_AIR && useDoubleCompressedAirTubes;
    var _f = useAppSelector(selectors.selectEdgeConnections(edge.id)), fromAttachment = _f.fromAttachment, toAttachment = _f.toAttachment, fromNode = _f.fromNode, toNode = _f.toNode;
    var selectedEdge = useAppSelector(selectors.selectSelectedEdge);
    var isSelected = (selectedEdge === null || selectedEdge === void 0 ? void 0 : selectedEdge.id) === edge.id;
    var isMultiSelecting = useAppSelector(selectors.selectIsMultiSelecting);
    var hideEdge = (_b = movingNodeIds === null || movingNodeIds === void 0 ? void 0 : movingNodeIds.some(function (id) { return id === fromNode.id || id === toNode.id; })) !== null && _b !== void 0 ? _b : false;
    var onChangeDefaultDiameter = useCallback(function (diameter, index) {
        dispatch(actions.setEdgeDiameterDefaultTube({
            edgeIds: [edge.id],
            diameter: diameter,
            index: index,
        }));
    }, [dispatch, edge.id]);
    var onChangeBlowOffDiameter = useCallback(function (diameter, index) {
        dispatch(actions.setEdgeDiameterBlowOffTube({
            edgeIds: [edge.id],
            diameter: diameter,
            index: index,
        }));
    }, [dispatch, edge.id]);
    useEffect(function () {
        var entity = new pc.Entity(edge.id.toString());
        app.root.addChild(entity);
        setEdgeEntity(entity);
        return function () {
            entity.destroy();
            setEdgeEntity(null);
        };
    }, [app.root, edge.id]);
    useEffect(function () {
        if (!edgeEntity || hideEdge)
            return;
        var tube1Entity = new pc.Entity();
        var tube2Entity = new pc.Entity();
        edgeEntity.addChild(tube1Entity);
        edgeEntity.addChild(tube2Entity);
        var isEdgeLengthLocked = edge.lengthLocked !== undefined;
        var drawTube = function (diameter, materialIndex, entity, offset) {
            var defaultConnectNodesProps = {
                app: app,
                fromNode: fromNode,
                fromAttachment: fromAttachment,
                toNode: toNode,
                toAttachment: toAttachment,
                cylinderAsset: modelAssets[tubeCylinderAsset],
                material: materialAssets[tubeMaterials[edge.tubeType][materialIndex]].resource,
                rootEntity: entity,
                diameter: diameter,
                offsetX: offset !== null && offset !== void 0 ? offset : 0,
            };
            if (!isEdgeLengthLocked) {
                return connectNodes(__assign({}, defaultConnectNodesProps));
            }
            return connectNodesWithLockedEdgeLength(__assign(__assign({}, defaultConnectNodesProps), { lengthLockedMaterial: materialAssets[tubeMaterialsLengthLocked[edge.tubeType][materialIndex]].resource }));
        };
        var tubeLength = 0;
        if (isDoubleTube) {
            var diameterTotal = edge.diameterDefaultTube + edge.diameterBlowOffTube;
            var diameterFractions = [
                edge.diameterDefaultTube / diameterTotal,
                edge.diameterBlowOffTube / diameterTotal,
            ];
            /* Show the two tubes as fractions of the total, where the total is clamped to the
             * min and max visualized values */
            var diameter1Vizualized = diameterFractions[0] *
                clamp(diameterTotal, MIN_DIAMETER_VISUALIZED, MAX_DIAMETER_VISUALIZED);
            var diameter2Vizualized = diameterFractions[1] *
                clamp(diameterTotal, MIN_DIAMETER_VISUALIZED, MAX_DIAMETER_VISUALIZED);
            /* Tube should have an offset such that the tubes are centered
             * and positioned next to eachother */
            var diff = (diameter2Vizualized - diameter1Vizualized) / 2;
            var tube1Offset = diameter1Vizualized / 2 + diff;
            var tube2Offset = -diameter2Vizualized / 2 + diff;
            tubeLength = drawTube(diameter1Vizualized, 0, tube1Entity, tube1Offset);
            drawTube(diameter2Vizualized, 1, tube2Entity, tube2Offset);
        }
        else {
            tubeLength = drawTube(clamp(edge.diameterDefaultTube, MIN_DIAMETER_VISUALIZED, MAX_DIAMETER_VISUALIZED), 0, tube1Entity);
        }
        var middleSegment = tube1Entity === null || tube1Entity === void 0 ? void 0 : tube1Entity.children[Math.round((tube1Entity === null || tube1Entity === void 0 ? void 0 : tube1Entity.children.length) / 2)];
        if (middleSegment) {
            setMiddlePosition(middleSegment.getPosition().clone());
        }
        setCurveLength(tubeLength);
        return function () {
            tube1Entity.destroy();
            tube2Entity.destroy();
        };
    }, [
        app,
        hideEdge,
        edgeEntity,
        edge.diameterDefaultTube,
        edge.diameterBlowOffTube,
        materialAssets,
        modelAssets,
        edge.tubeType,
        setCurveLength,
        fromNode,
        fromAttachment,
        toNode,
        toAttachment,
        isDoubleTube,
        objectShapeId,
        edge.lengthLocked,
    ]);
    useEffect(function () {
        if (!edge.lengthLocked) {
            dispatch(actions.setEdgeLength({
                edgeIds: [edge.id],
                length: round(curveLength),
            }));
        }
    }, [curveLength, edge.id, dispatch, edge.lengthLocked]);
    useInteractiveAppearance(edge.id, edgeEntity, materialAssets, hoverId);
    var labelAndButtons = useMemo(function () {
        if (hideEdge)
            return null;
        if (middlePosition) {
            var cameraEntity = app.root.findByName("Camera");
            var camera = cameraEntity === null || cameraEntity === void 0 ? void 0 : cameraEntity.camera;
            if (!camera)
                return;
            var position = camera.worldToScreen(middlePosition);
            var deleteAction = {
                icon: EditButtonIcon.DELETE,
                action: function () { return dispatch(actions.deleteEdge(edge.id)); },
            };
            var setTubeLengthLocked_1 = function (isLocked) {
                dispatch(actions.setEdgeLengthLocked({ edgeIds: [edge.id], isLocked: isLocked }));
            };
            return (_jsx(_Fragment, { children: !isMultiSelecting && isSelected && (_jsxs(_Fragment, { children: [_jsx(TooltipPortal
                        /* We need to acces the styled component directly when used as an anchor element
                         * Otherwise the position cannot be calculated properly for the TooltipPortal */
                        , { 
                            /* We need to acces the styled component directly when used as an anchor element
                             * Otherwise the position cannot be calculated properly for the TooltipPortal */
                            anchorElement: _jsx(StyledCanvasOverlay, { x: position.x, y: position.y }), open: true, children: _jsxs(Styled.InputTooltip, { pointerEvents: isSelected, children: [_jsxs(Styled.LengthAndDiameter, { children: [_jsx(Styled.LengthWrapper, { children: _jsx(Styled.Length, { value: edge.length, min: 0, max: 20, unit: Unit.M, type: "float", setValue: function (value) {
                                                        setCurveLength(value);
                                                        dispatch(actions.setEdgeLength({
                                                            edgeIds: [edge.id],
                                                            length: round(value),
                                                            isLocked: true,
                                                        }));
                                                    }, lock: {
                                                        isLocked: edge.lengthLocked === true,
                                                        onLock: function () { return setTubeLengthLocked_1(true); },
                                                        onUnlock: function () { return setTubeLengthLocked_1(false); },
                                                    }, label: t("sidebar.edit_tube.tube_length") }) }), _jsx(Styled.DiameterWrapper, { children: _jsx(Styled.Diameter, { diameterIndexes: [edge.defaultDiameterIndex], onValueChange: onChangeDefaultDiameter, label: t("sidebar.edit_tube.tube_diameter_tooltip"), isTooltip: true }) })] }), isDoubleTube && (_jsx(Styled.DoubleTubeWrapper, { unitSystem: unitSystem, children: _jsx(Styled.Diameter, { diameterIndexes: [edge.blowOffDiameterIndex], onValueChange: onChangeBlowOffDiameter, label: t("sidebar.edit_tube.tube_diameter_tooltip"), isTooltip: true }) }))] }) }), _jsx(CanvasOverlay, { x: position.x, y: position.y - 40, children: _jsx(EditButtons, { actions: [deleteAction] }) })] })) }));
        }
        return null;
    }, [
        hideEdge,
        middlePosition,
        app.root,
        isMultiSelecting,
        isSelected,
        edge.length,
        edge.lengthLocked,
        edge.defaultDiameterIndex,
        edge.blowOffDiameterIndex,
        edge.id,
        onChangeDefaultDiameter,
        isDoubleTube,
        unitSystem,
        onChangeBlowOffDiameter,
        dispatch,
    ]);
    return _jsx(_Fragment, { children: labelAndButtons });
};
