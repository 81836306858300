var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Dropdown } from "../dropdown";
import { MIXED_VALUES_CONSTANT } from "store/types";
/**
 * Renders a single dropdown that represents multiple values.
 * If all values are the same, the dropdown will display that value as its label.
 * If the values are different, the dropdown will display a "Mixed" state as its label,
 * and the selected values will be displayed as checked items in the options list.
 */
export var MultipleValuesDropdown = function (_a) {
    var valuesFromProps = _a.values, itemsFromProps = _a.items, itemLabelsFromProps = _a.itemLabels, itemImagesFromProps = _a.itemImages, restProps = __rest(_a, ["values", "items", "itemLabels", "itemImages"]);
    var t = useTranslation().t;
    var valuesAreMixed = valuesFromProps.some(function (value) { return value !== valuesFromProps[0]; });
    var checkedValuesIndexes = valuesFromProps
        .map(function (value) {
        if (value === undefined)
            return;
        var index = itemsFromProps.indexOf(value);
        if (index === -1)
            return;
        return index;
    })
        .filter(function (index) { return index !== undefined; });
    var labelsWithMultipleChecked = itemLabelsFromProps.map(function (label, i) { return ({
        label: label,
        icon: checkedValuesIndexes.includes(i) ? "checkbox" : undefined,
    }); });
    var items = valuesAreMixed
        ? __spreadArray([MIXED_VALUES_CONSTANT], __read(itemsFromProps), false) : itemsFromProps;
    var itemLabels = valuesAreMixed
        ? __spreadArray([t(MIXED_VALUES_CONSTANT)], __read(labelsWithMultipleChecked), false) : itemLabelsFromProps;
    var itemImages = itemImagesFromProps && valuesAreMixed
        ? __spreadArray([undefined], __read(itemImagesFromProps), false) : itemImagesFromProps;
    var value = valuesAreMixed ? MIXED_VALUES_CONSTANT : valuesFromProps[0];
    return (_jsx(Dropdown, __assign({}, restProps, { value: value, items: items, itemLabels: itemLabels, itemImages: itemImages })));
};
