export var componentsTheme = {
    components: {
        wizardNav: {
            stepMinWidth: "40px",
            paddingVertical: "0px",
            paddingHorizontal: "16px",
            stepDotOutlineWidth: "2px",
        },
    },
};
