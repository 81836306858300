var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defaultAdjustableObjectCameraViews } from "./camera-views";
import { AdjustableObjectType } from "3d/types";
export var defaultBoxHeight = 0.02;
export var defaultObjectSize = { x: 1, y: defaultBoxHeight, z: 1 };
export var adjustableBox = {
    translationKey: "sidebar.object_shape.objects.box",
    modelName: "Primitive-Cube100x100x100cm",
    objectDefaultSize: __assign({}, defaultObjectSize),
    sizeRange: {
        min: {
            x: 0.1,
            y: 0.1,
        },
        max: {
            x: 5,
            y: 5,
        },
    },
    gripPositionY: 5.001,
    tubeNetworkY: 5.43,
    adjustableObject: { type: AdjustableObjectType.BOX },
    cameraViews: __assign({}, defaultAdjustableObjectCameraViews),
};
export var adjustableCylinder = {
    translationKey: "sidebar.object_shape.objects.cylinder",
    modelName: "Primitive-CylinderHorizontal100x100cm",
    objectDefaultSize: { x: 1.5, y: 0.4, z: 0.4 },
    sizeRange: {
        min: {
            x: 0.1,
            y: 0.2,
        },
        max: {
            x: 5,
            y: 1,
        },
    },
    gripPositionY: 5.001,
    tubeNetworkY: 5.43,
    adjustableObject: { type: AdjustableObjectType.CYLINDER },
    cameraViews: __assign({}, defaultAdjustableObjectCameraViews),
};
