var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useSpring, animated } from "@react-spring/web";
import { Label } from "@animech/ui";
import * as Styled from "./toggle.style";
export var Toggle = function (_a) {
    var checked = _a.checked, _b = _a.checkedLabel, checkedLabel = _b === void 0 ? "" : _b, _c = _a.uncheckedLabel, uncheckedLabel = _c === void 0 ? "" : _c, _d = _a.heading, heading = _d === void 0 ? "" : _d, props = __rest(_a, ["checked", "checkedLabel", "uncheckedLabel", "heading"]);
    var animationStyle = useSpring({
        flexGrow: checked ? 1 : 0,
    });
    return (_jsxs(Styled.Toggle, { children: [heading && (_jsx(Label, { className: "".concat(Styled.prefix, "-heading"), size: "small", children: heading })), _jsxs("div", { className: "".concat(Styled.prefix, "-input"), children: [_jsx("input", __assign({ type: "checkbox", checked: checked }, props)), _jsxs("div", { className: "".concat(Styled.prefix, "-toggle-container"), children: [_jsx(animated.div, { style: animationStyle }), _jsx("div", { className: "".concat(Styled.prefix, "-toggle-indicator ").concat(props.disabled && "disabled") })] })] }), (checkedLabel || uncheckedLabel) && (_jsx(Label, { className: "".concat(Styled.prefix, "-value"), size: "small", children: checked ? checkedLabel : uncheckedLabel }))] }));
};
