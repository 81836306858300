import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import * as Styled from "./system-design.style";
import { SidebarPanel } from "ui/components/panels/sidebar";
import { TopbarPanel } from "ui/components/panels/topbar";
import { DashboardPanel } from "ui/components/panels/dashboard";
import { CanvasPanel } from "ui/components/panels/canvas";
import { NotificationPanel } from "ui/components/panels/notification";
import { useConflictHandler } from "hooks/conflict-handler";
import { GraphDataProvider } from "providers/graph-panel";
import { Checklist } from "ui/components/panels/checklist";
import { ComponentDataPanel } from "ui/components/panels/dashboard/component-data-list";
export var SystemDesignPage = function () {
    useConflictHandler();
    return (_jsx(Styled.SystemDesignPage, { children: _jsxs(GraphDataProvider, { children: [_jsx(SidebarPanel, {}), _jsxs("div", { className: "".concat(Styled.prefix, "-main-content"), children: [_jsx(CanvasPanel, {}), _jsxs(Styled.CanvasUIOverlay, { children: [_jsx(TopbarPanel, {}), _jsx(NotificationPanel, {}), _jsx(Checklist, {}), _jsx(ComponentDataPanel, {}), _jsx(DashboardPanel, {})] })] })] }) }));
};
