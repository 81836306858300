var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import * as pc from "playcanvas";
import { useCallback, createContext, useContext, useState, useEffect, } from "react";
import { usePlaycanvasApplicationContext } from "./playcanvas-application";
import { TactonApplication } from "api/tacton/types";
import { selectors, useAppSelector } from "store";
import { cardboardMaterialMap } from "ui/components/panels/canvas/cardboard-builder/constants";
import { Material } from "3d/types";
var highlightTapeColor = new pc.Color(1, 0.1, 0.1);
var updateTapeMaterial = function (app, entity, defaultMaterialName, color) {
    var materialAsset = app.assets.find(defaultMaterialName, "material");
    var onMaterialLoaded = function (loadedMaterialAsset) {
        var _a;
        var defaultMaterial = loadedMaterialAsset.resource;
        if ((_a = entity.model) === null || _a === void 0 ? void 0 : _a.meshInstances) {
            entity.model.meshInstances.forEach(function (mi) {
                var material = mi.material.clone();
                if (color) {
                    material.diffuse = color;
                }
                else {
                    material.diffuse = defaultMaterial.diffuse;
                }
                material.update();
                mi.material = material;
            });
        }
    };
    materialAsset === null || materialAsset === void 0 ? void 0 : materialAsset.ready(onMaterialLoaded);
};
var defaultCollisionsContext = {
    addCollision: function () {
        //
    },
    removeCollision: function () {
        //
    },
    setCollisionTriggers: function () {
        //
    },
    resetCollision: function () {
        //
    },
    gripPositionHasCollision: function () { return false; },
};
var CollisionsContext = createContext(defaultCollisionsContext);
export var CollisionsProvider = function (_a) {
    var children = _a.children;
    var paperCoating = useAppSelector(selectors.selectPaperCoatingValue);
    var app = usePlaycanvasApplicationContext().app;
    var gripPositions = useAppSelector(selectors.selectGripPositions);
    var _b = __read(useState({}), 2), gripPositionCollisions = _b[0], setGripPositionCollisions = _b[1];
    var _c = __read(useState([]), 2), collisionTriggers = _c[0], _setCollisionTriggers = _c[1];
    var setCollisionTriggers = useCallback(function (newCardboardParts) {
        _setCollisionTriggers(__spreadArray([], __read(newCardboardParts), false));
    }, []);
    var activeApplication = useAppSelector(selectors.selectActiveApplication);
    var isCardboardApplication = activeApplication === TactonApplication.CARDBOARD;
    var addCollision = useCallback(function (gripPositionId, collidedEntity) {
        setGripPositionCollisions(function (prevCollisions) {
            var newCollisions = __assign({}, prevCollisions);
            var gripPositionCollisions = newCollisions[gripPositionId];
            if (!gripPositionCollisions) {
                newCollisions[gripPositionId] = [collidedEntity.name];
            }
            else {
                gripPositionCollisions.push(collidedEntity.name);
            }
            return newCollisions;
        });
    }, []);
    var removeCollision = useCallback(function (gripPositionId, collidedEntity) {
        setGripPositionCollisions(function (prevCollisions) {
            var newCollisions = __assign({}, prevCollisions);
            var gripPositionCollisions = newCollisions[gripPositionId];
            if (gripPositionCollisions) {
                var indexOfCollision = gripPositionCollisions.findIndex(function (collisionEntityName) {
                    return collisionEntityName === collidedEntity.name;
                });
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                newCollisions[gripPositionId].splice(indexOfCollision, 1);
            }
            if (!(gripPositionCollisions === null || gripPositionCollisions === void 0 ? void 0 : gripPositionCollisions.length)) {
                delete newCollisions[gripPositionId];
            }
            return newCollisions;
        });
    }, []);
    var gripPositionHasCollision = useCallback(function (gripPositionId) {
        return gripPositionCollisions[gripPositionId] !== undefined;
    }, [gripPositionCollisions]);
    // We only worry about collisions for cardboard applications. If the user changes application we remove all collisions
    useEffect(function () {
        if (!isCardboardApplication) {
            setGripPositionCollisions({});
            return;
        }
    }, [isCardboardApplication]);
    /* Whenever a node is deleted from the scene, we need to update our _gripPositionCollisions
     * state object and delete the removed Grip Position.*/
    useEffect(function () {
        var e_1, _a;
        var collisionsToRemove = [];
        try {
            for (var _b = __values(Object.keys(gripPositionCollisions)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var key = _c.value;
                var gripPositionIDs = Object.values(gripPositions).map(function (gripPosition) { return gripPosition.id; });
                var gripPositionExist = gripPositionIDs.includes(key);
                if (!gripPositionExist) {
                    collisionsToRemove.push(key);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (collisionsToRemove.length) {
            setGripPositionCollisions(function (prevCollisions) {
                var newCollisions = __assign({}, prevCollisions);
                collisionsToRemove.forEach(function (gripPositionId) {
                    delete newCollisions[gripPositionId];
                });
                return newCollisions;
            });
        }
    }, [gripPositions, gripPositionCollisions]);
    /* See if any Grip Position is currently colliding with a cardboard part, if yes we highlight the material.
     * If not, we reset the material back to original. */
    useEffect(function () {
        if (!collisionTriggers.length || !paperCoating || !app)
            return;
        var partsWithCollisions = new Set();
        Object.values(gripPositionCollisions).forEach(function (collidedTapeEntityNames) {
            collidedTapeEntityNames === null || collidedTapeEntityNames === void 0 ? void 0 : collidedTapeEntityNames.forEach(function (collidedTapeEntityName) {
                partsWithCollisions.add(collidedTapeEntityName);
            });
        });
        collisionTriggers.forEach(function (part) {
            var hasCollision = partsWithCollisions.has(part.entity.name);
            var material = cardboardMaterialMap[paperCoating];
            if (part.modelId.includes("Tape")) {
                material = Material.PLASTIC_GLOSSY_BROWN;
            }
            if (hasCollision) {
                updateTapeMaterial(app, part.entity, material, highlightTapeColor);
            }
            else {
                updateTapeMaterial(app, part.entity, material);
            }
        });
    }, [gripPositionCollisions, collisionTriggers, paperCoating, app]);
    var resetCollision = useCallback(function (gripPositionId) {
        setGripPositionCollisions(function (prevCollisions) {
            var newCollisions = __assign({}, prevCollisions);
            delete newCollisions[gripPositionId];
            return newCollisions;
        });
    }, []);
    return (_jsx(CollisionsContext.Provider, { value: {
            addCollision: addCollision,
            removeCollision: removeCollision,
            setCollisionTriggers: setCollisionTriggers,
            resetCollision: resetCollision,
            gripPositionHasCollision: gripPositionHasCollision,
        }, children: children }));
};
export var useCollisionsContext = function () { return useContext(CollisionsContext); };
