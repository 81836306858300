var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState, useCallback } from "react";
/**
 * The purpose of this hook is to orchestrate the logic for "freezing" the PlayCanvas scene whenever one or more
 * elements (e.g. modals, overlays) are blocking the scene. When the scene is "frozen", the mouse is detached from
 * the scene, and the user can interact with the blocking elements instead.
 * It returns two functions that are to be used in the useBlockingElementEffect hook.
 * See the useBlockingElementEffect hook for more information.
 */
export function useDetachMouseWhenSceneIsBlocked(app, canvasRef) {
    var _a = __read(useState(0), 2), elementsBlockingTheSceneCount = _a[0], setElementsBlockingTheSceneCount = _a[1];
    if (elementsBlockingTheSceneCount > 0) {
        app === null || app === void 0 ? void 0 : app.mouse.detach();
    }
    else {
        canvasRef.current && (app === null || app === void 0 ? void 0 : app.mouse.attach(canvasRef.current));
    }
    var onBlockingElementEnter = useCallback(function () {
        setElementsBlockingTheSceneCount(function (count) { return count + 1; });
    }, []);
    var onBlockingElementExit = useCallback(function () { return setElementsBlockingTheSceneCount(function (count) { return count - 1; }); }, []);
    return { onBlockingElementEnter: onBlockingElementEnter, onBlockingElementExit: onBlockingElementExit };
}
