var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable camelcase */
import { Material } from "3d/types";
/** ---------------   **/
/**      U Family     **/
/** ---------------   **/
var cupModelTemplate = {
    U8: {
        cupModelName: "U8",
        cupWidth: 0.009,
        cupHeight: 0.011,
        fittingModelName: "MM5M-S2",
    },
    U10: {
        cupModelName: "U10",
        cupWidth: 0.011,
        cupHeight: 0.0155,
        fittingModelName: "MM5M-S2",
    },
    U15: {
        cupModelName: "U15",
        cupWidth: 0.0165,
        cupHeight: 0.0165,
        fittingModelName: "MM5M-S2",
    },
    U20: {
        cupModelName: "U20",
        cupWidth: 0.022,
        cupHeight: 0.0125,
        fittingModelName: "G38M01-S4",
    },
    U40_2: {
        cupModelName: "U40-2",
        cupWidth: 0.041,
        cupHeight: 0.019,
        fittingModelName: "G38M01-S5",
    },
};
var materialTransformTemplate = {
    RED_SIL: {
        materialTransforms: [
            { sourceMaterial: Material.MA_MAIN, targetMaterial: Material.S },
            {
                sourceMaterial: Material.MA_SECONDARY,
                targetMaterial: Material.S,
            },
        ],
    },
    HNBR: {
        materialTransforms: [
            { sourceMaterial: Material.MA_MAIN, targetMaterial: Material.HN },
            {
                sourceMaterial: Material.MA_SECONDARY,
                targetMaterial: Material.HN,
            },
        ],
    },
};
export var suctionCupsFamilyU = {
    // U6 Red Sil
    suction_cup_3150003S: __assign(__assign({}, cupModelTemplate.U8), materialTransformTemplate.RED_SIL),
    // U6 HNBR
    suction_cup_9914275: __assign(__assign({}, cupModelTemplate.U8), materialTransformTemplate.HNBR),
    // U8 Red Sil
    suction_cup_3150004S: __assign(__assign({}, cupModelTemplate.U8), materialTransformTemplate.RED_SIL),
    // U10 Red Sil
    suction_cup_3150005S: __assign(__assign({}, cupModelTemplate.U10), materialTransformTemplate.RED_SIL),
    // U10 HNBR
    suction_cup_9906863: __assign(__assign({}, cupModelTemplate.U10), materialTransformTemplate.HNBR),
    // U15 Red Sil
    suction_cup_3150006S: __assign(__assign({}, cupModelTemplate.U15), materialTransformTemplate.RED_SIL),
    // U15 HNBR
    suction_cup_9906864: __assign(__assign({}, cupModelTemplate.U15), materialTransformTemplate.HNBR),
    // U20 Red sil
    suction_cup_0101385: __assign(__assign({}, cupModelTemplate.U20), materialTransformTemplate.RED_SIL),
    // U20 HNBR
    suction_cup_9906869: __assign(__assign({}, cupModelTemplate.U20), materialTransformTemplate.HNBR),
    // U30 Red Sil
    suction_cup_0101396: __assign(__assign({}, cupModelTemplate.U20), materialTransformTemplate.RED_SIL),
    // U40-2 Red Sil
    suction_cup_0101607: __assign(__assign({}, cupModelTemplate.U40_2), materialTransformTemplate.RED_SIL),
    // U50-2 Red Sil
    suction_cup_0101807: __assign(__assign({}, cupModelTemplate.U40_2), materialTransformTemplate.RED_SIL),
};
