var BuildInfo = /** @class */ (function () {
    function BuildInfo() {
    }
    Object.defineProperty(BuildInfo, "mode", {
        get: function () {
            return process.env.NODE_ENV === "production" ? "production" : "development";
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BuildInfo, "environment", {
        get: function () {
            return process.env.ENVIRONMENT
                ? process.env.ENVIRONMENT.toString()
                : "production";
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BuildInfo, "version", {
        get: function () {
            return process.env.VERSION;
        },
        enumerable: false,
        configurable: true
    });
    BuildInfo.isDevEnvironment = function () {
        return this.environment === "development" || this.environment === "local";
    };
    return BuildInfo;
}());
export { BuildInfo };
