import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import * as Styled from "./table.style";
export var Root = function (_a) {
    var children = _a.children;
    return _jsx(Styled.Root, { children: children });
};
export var Body = function (_a) {
    var children = _a.children;
    return _jsx("tbody", { children: children });
};
export var Row = function (_a) {
    var children = _a.children;
    return _jsx(Styled.Row, { children: children });
};
export var HeaderRow = function (_a) {
    var children = _a.children;
    return (_jsx(Styled.HeaderRow, { children: children }));
};
export var Col = function (_a) {
    var children = _a.children;
    return _jsx(Styled.Col, { children: children });
};
export var HeaderCol = function (_a) {
    var children = _a.children;
    return (_jsx(Styled.HeaderCol, { children: children }));
};
