export var PerformanceChartGroup;
(function (PerformanceChartGroup) {
    PerformanceChartGroup["BEFORE_PICK"] = "before_pick";
    PerformanceChartGroup["BEFORE_PLACE"] = "before_place";
    PerformanceChartGroup["AFTER_PLACE"] = "after_place";
    PerformanceChartGroup["VACUUM_LEVELS"] = "vacuum_levels";
})(PerformanceChartGroup || (PerformanceChartGroup = {}));
export var energySavingColor = "#59AC26";
export var partSecuredColor = "#2F80ED";
export var atmColor = "#888888";
