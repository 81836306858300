var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback, useEffect, useState } from "react";
import { selectors, useAppSelector } from "store";
import { getDistinctColor } from "hooks/graph-panel/get-distinct-color";
import { suctionCupIdPrefix, vacuumPumpIdPrefix, } from "ui/components/panels/graphs/constants";
import { upperCaseAlphabeticalCharacterGenerator } from "hooks/graph-panel/alphabetical-character-generator";
import { getCupComponent, getVacuumPumpComponent, } from "store/system-data/helpers";
export var useTimeDataMinMaxDomain = function () {
    var graphData = useAppSelector(selectors.selectGraphData);
    var timeData = graphData.systemProperty.timeData;
    return [timeData[0], timeData[timeData.length - 1]];
};
export var AlphabeticalNodeType;
(function (AlphabeticalNodeType) {
    AlphabeticalNodeType["CUP"] = "cup";
    AlphabeticalNodeType["PUMP"] = "pump";
})(AlphabeticalNodeType || (AlphabeticalNodeType = {}));
export var useGraphData = function () {
    var systemData = useAppSelector(selectors.selectSystemDataDefineParameters);
    var graphData = useAppSelector(selectors.selectGraphData);
    var pumpSelectionCentralMap = useAppSelector(selectors.selectPumpSelectionCentralMap);
    var pumpSelectionDecentralMap = useAppSelector(selectors.selectPumpSelectionDecentralMap);
    var cupSelectionMap = useAppSelector(selectors.selectCupSelectionMap);
    var _a = __read(useState(), 2), pumpsAndCupsNodeTree = _a[0], setPumpsAndCupsNodeTree = _a[1];
    var _b = __read(useState({}), 2), alphabeticalIdObj = _b[0], setAlphabeticalIdObj = _b[1];
    var updateAlphabeticalIdObject = useCallback(function (nodeId, alphabeticalId, alphabeticalNodeType) {
        setAlphabeticalIdObj(function (prev) {
            var _a, _b;
            if (prev[nodeId] &&
                prev[nodeId][alphabeticalNodeType] === alphabeticalId)
                return prev;
            return __assign(__assign({}, prev), (_a = {}, _a[nodeId] = __assign(__assign({}, prev[nodeId]), (_b = {}, _b[alphabeticalNodeType] = alphabeticalId, _b)), _a));
        });
    }, []);
    // Generates an object with pumps and connected cups in a node-tree structure.
    useEffect(function () {
        var vacuumPumps = graphData.vacuumPumps, suctionCups = graphData.suctionCups, governingPump = graphData.governingPump;
        var vacuumPumpAlphabeticalCharacters = upperCaseAlphabeticalCharacterGenerator();
        var pumpsWithConnectedCups = vacuumPumps.reduce(function (acc, pump) {
            var _a;
            var pumpId = pump.performanceData.id;
            var color = getDistinctColor(pump.id);
            var alphabeticalPumpId = vacuumPumpAlphabeticalCharacters
                .next()
                .value.toLocaleUpperCase();
            updateAlphabeticalIdObject(pump.id, alphabeticalPumpId, AlphabeticalNodeType.PUMP);
            var connectedSuctionCups = suctionCups
                .filter(function (cup) { return cup.performanceData.connectedPumpId === pumpId; })
                .reduce(function (acc, cup, index) {
                var _a;
                var suctionCupComponent = getCupComponent(cup.id, cupSelectionMap, systemData);
                var suctionCupId = suctionCupIdPrefix + cup.id;
                var suctionCupColor = getDistinctColor(suctionCupId);
                var suctionCupName = (_a = suctionCupComponent === null || suctionCupComponent === void 0 ? void 0 : suctionCupComponent.label) !== null && _a !== void 0 ? _a : "";
                var alphabeticalCupId = "".concat(alphabeticalPumpId.toLocaleLowerCase() + (index + 1));
                updateAlphabeticalIdObject(cup.id, alphabeticalCupId, AlphabeticalNodeType.CUP);
                var connectedSuctionCup = {
                    nodeId: cup.id,
                    yValueKey: suctionCupId,
                    component: suctionCupComponent,
                    name: suctionCupName,
                    color: suctionCupColor,
                    alphabeticalId: alphabeticalCupId,
                };
                acc.push(connectedSuctionCup);
                return acc;
            }, []);
            if (!connectedSuctionCups.length)
                return acc;
            var isGoverningPump = governingPump.id === pump.id;
            var vacuumPumpComponent = getVacuumPumpComponent(pump.id, pumpSelectionCentralMap, pumpSelectionDecentralMap, systemData);
            var name = (_a = vacuumPumpComponent === null || vacuumPumpComponent === void 0 ? void 0 : vacuumPumpComponent.label) !== null && _a !== void 0 ? _a : "";
            var vacuumPumpGraphData = {
                nodeId: pump.id,
                yValueKey: vacuumPumpIdPrefix + pump.id,
                connectedSuctionCups: connectedSuctionCups,
                component: vacuumPumpComponent,
                name: name,
                isGoverningPump: isGoverningPump,
                color: color,
                alphabeticalId: alphabeticalPumpId,
            };
            acc.push(vacuumPumpGraphData);
            return acc;
        }, []);
        setPumpsAndCupsNodeTree(pumpsWithConnectedCups);
    }, [
        graphData,
        setPumpsAndCupsNodeTree,
        updateAlphabeticalIdObject,
        cupSelectionMap,
        pumpSelectionCentralMap,
        pumpSelectionDecentralMap,
        systemData,
    ]);
    return { pumpsAndCupsNodeTree: pumpsAndCupsNodeTree, alphabeticalIdObj: alphabeticalIdObj };
};
