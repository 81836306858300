import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { H6 } from "@animech/ui";
import { useMemo } from "react";
import * as Styled from "./lifting-performance-box.style";
import { SimpleBox } from "./simple-box";
import * as StyledShared from "./shared.style";
import { systemDataConstants } from "api/system-data";
import { Unit, getAprxPixelWidthOfNumber } from "utility/number";
import { useNumberConversion } from "hooks/number-conversion";
import { CupIcon } from "ui/components/elements/icons/cup";
import { ArrowUpIcon } from "ui/components/elements/icons/arrow-up";
import { ArrowRightIcon } from "ui/components/elements/icons/arrow-right";
import { useAppSelector, selectors } from "store";
import { TextBox } from "ui/components/elements/chart/text-box";
import { partSecuredColor } from "store/system-performance/types";
import { InfoButton } from "ui/components/elements/info-button";
export var LiftingPerformanceBox = function () {
    var _a;
    var convertNumber = useNumberConversion();
    var systemDataDefineParameters = useAppSelector(selectors.selectSystemDataDefineParameters);
    var output = systemDataDefineParameters === null || systemDataDefineParameters === void 0 ? void 0 : systemDataDefineParameters.output;
    var isSystemDataLoading = useAppSelector(selectors.selectIsSystemDataLoading);
    var isSystemPerformanceLoading = useAppSelector(selectors.selectIsPerformanceLoading);
    var t = useTranslation().t;
    var isLoading = isSystemDataLoading || isSystemPerformanceLoading;
    var isPerformanceDataValid = useAppSelector(selectors.selectIsPerformanceDataValid) && !isLoading;
    var performanceData = useAppSelector(selectors.selectActivePerformanceData);
    var partSecuredLevel = useAppSelector(selectors.selectPartSecuredLevel);
    var convertedPartSecuredLevel = convertNumber(partSecuredLevel, Unit.KPA);
    var partSecuredTextboxWidth = getAprxPixelWidthOfNumber(convertedPartSecuredLevel.value);
    var safetyFactor = (_a = systemDataDefineParameters === null || systemDataDefineParameters === void 0 ? void 0 : systemDataDefineParameters.parameters[systemDataConstants.parameterNames.safetyFactor]) === null || _a === void 0 ? void 0 : _a.value;
    var convertForce = function (value) {
        return convertNumber(value ? Math.round(value) : 0, Unit.N);
    };
    var totalHorizontal = convertForce(isPerformanceDataValid
        ? performanceData === null || performanceData === void 0 ? void 0 : performanceData.totalHorizontalLiftForceAtPS
        : output === null || output === void 0 ? void 0 : output.totalLiftingForceHorizontal);
    var totalVertical = convertForce(isPerformanceDataValid
        ? performanceData === null || performanceData === void 0 ? void 0 : performanceData.totalVerticalLiftForceAtPS
        : output === null || output === void 0 ? void 0 : output.totalLiftingForceVertical);
    var requiredHorizontal = convertForce(output === null || output === void 0 ? void 0 : output.requiredLiftingForceHorizontal);
    var requiredVertical = convertForce(output === null || output === void 0 ? void 0 : output.requiredLiftingForceVertical);
    var horizontalForceOk = totalHorizontal.value >= requiredHorizontal.value;
    var verticalForceOk = totalVertical.value >= requiredVertical.value;
    var partSecured = horizontalForceOk && verticalForceOk;
    var calculationStatus = useMemo(function () {
        if (!partSecured)
            return "bad";
        if (!isPerformanceDataValid)
            return "ok";
        return "good";
    }, [partSecured, isPerformanceDataValid]);
    var horizontalForceColorStyle = useMemo(function () {
        if (!horizontalForceOk)
            return "".concat(Styled.prefix, "-red");
        if (!isPerformanceDataValid)
            return "".concat(Styled.prefix, "-yellow");
        return "".concat(Styled.prefix, "-green");
    }, [horizontalForceOk, isPerformanceDataValid]);
    var VerticalForceColorStyle = useMemo(function () {
        if (!verticalForceOk)
            return "".concat(Styled.prefix, "-red");
        if (!isPerformanceDataValid)
            return "".concat(Styled.prefix, "-yellow");
        return "".concat(Styled.prefix, "-green");
    }, [verticalForceOk, isPerformanceDataValid]);
    return (_jsx(Styled.LiftingPerformanceBox, { children: _jsx(SimpleBox, { isLoading: isLoading, children: _jsxs("div", { className: "".concat(Styled.prefix, "-container"), children: [_jsxs("div", { children: [_jsxs(StyledShared.TitleContainer, { children: [_jsxs(H6, { children: [" ", t("dashboard.lifting_forces"), " "] }), _jsx(InfoButton, { title: t("dashboard.lifting_forces"), description: {
                                            long: t("descriptions.dashboard.lifting_forces"),
                                        } })] }), _jsxs("div", { className: "".concat(Styled.prefix, "-safety-factor-text"), children: [t("dashboard.lifting_performance_safety_factor"), ": ", safetyFactor] })] }), _jsxs("div", { className: "".concat(Styled.prefix, "-content"), children: [_jsx(Styled.Status, { status: calculationStatus, children: isPerformanceDataValid
                                    ? t("dashboard.full_calculation")
                                    : t("dashboard.preliminary_values") }), _jsxs("div", { className: "".concat(Styled.prefix, "-row-1"), children: [_jsx(ArrowUpIcon, { className: VerticalForceColorStyle, size: "18" }), _jsx(CupIcon, {})] }), _jsxs("div", { className: "".concat(Styled.prefix, "-row-1"), children: [_jsx(ArrowRightIcon, { className: horizontalForceColorStyle, size: "18" }), _jsx(CupIcon, {})] }), _jsx("div", { className: "".concat(Styled.prefix, "-row-1"), children: t("dashboard.lifting_performance_vacuum_level") }), _jsx("span", {}), _jsx("div", { className: "".concat(Styled.prefix, "-row-2"), children: requiredVertical.unit }), _jsx("div", { className: "".concat(Styled.prefix, "-row-2"), children: requiredHorizontal.unit }), _jsx("div", { className: "".concat(Styled.prefix, "-row-2"), children: convertedPartSecuredLevel.unit }), _jsx("div", { className: "".concat(Styled.prefix, "-left-align"), children: t("dashboard.required_lifting_force") }), _jsx("div", { children: requiredVertical.value }), _jsx("div", { children: requiredHorizontal.value }), _jsx("span", {}), _jsx("div", { className: "".concat(Styled.prefix, "-left-align"), children: t("dashboard.total_lifting_force_part_secured") }), _jsx("div", { className: VerticalForceColorStyle, children: totalVertical.value }), _jsx("div", { className: horizontalForceColorStyle, children: totalHorizontal.value }), _jsx(Styled.SVGContainer, { children: _jsx("svg", { width: partSecuredTextboxWidth, height: "20", children: _jsx(TextBox, { x: 0, y: 0, width: partSecuredTextboxWidth, height: 20, text: convertedPartSecuredLevel.value.toString(), tooltipText: t("dashboard.part_secured_level"), color: partSecuredColor, description: {
                                            long: t("descriptions.dashboard.time_to_part_secured"),
                                        } }) }) }), _jsx("span", {})] })] }) }) }));
};
