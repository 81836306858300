var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { StyledSlider } from "@animech/ui";
import { css, useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import * as Styled from "ui/components/elements/custom-slider/custom-slider.style";
import { InfoButton, } from "ui/components/elements/info-button";
export var CustomSlider = function (props) {
    var t = useTranslation().t;
    var theme = useTheme();
    var header = props.header, labelLeft = props.labelLeft, labelRight = props.labelRight, defaultValue = props.defaultValue, resetValue = props.resetValue, description = props.description, stepSize = props.stepSize, value = props.value, sliderProps = __rest(props, ["header", "labelLeft", "labelRight", "defaultValue", "resetValue", "description", "stepSize", "value"]);
    /* Since the large green dot will snap to certain increments of values, and the yellow default value indicator dot
     * is able to position itself without snapping, we need to hide the yellow dot when the values are externally equal.
     * This is to avoid it appearing as if the values are different even after resetting. */
    var shouldShowDefaultValue = defaultValue !== undefined && defaultValue !== value;
    var shouldShowResetButton = shouldShowDefaultValue && resetValue !== undefined;
    var stepNumber = 1 / (stepSize !== null && stepSize !== void 0 ? stepSize : 0);
    return (_jsxs(Styled.CustomSliderRoot
    // This solution is to make the steps in the
    // slider turn green to the left of the handle
    , { 
        // This solution is to make the steps in the
        // slider turn green to the left of the handle
        css: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t\t\t\t", " {\n\t\t\t\t\t", " {\n\t\t\t\t\t\t:nth-child(-n + ", ") {\n\t\t\t\t\t\t\tbackground: ", ";\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t"], ["\n\t\t\t\t", " {\n\t\t\t\t\t", " {\n\t\t\t\t\t\t:nth-child(-n + ", ") {\n\t\t\t\t\t\t\tbackground: ", ";\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t"])), StyledSlider.Root, StyledSlider.Step, Math.ceil((value !== null && value !== void 0 ? value : 0) * stepNumber), theme.colors.palette.green[400]), dotPosition: defaultValue && defaultValue * 100, hasTwoLabels: labelLeft !== undefined && labelRight !== undefined, children: [_jsxs(Styled.HeaderAndInfoButton, { children: [_jsx(Styled.Header, { children: header }), description && _jsx(InfoButton, { title: header, description: description })] }), _jsxs(Styled.DotAndSlider, { children: [shouldShowDefaultValue && _jsx(Styled.DefaultValueDot, {}), _jsx(Styled.Slider, __assign({ stepSize: stepSize, value: value }, sliderProps))] }), _jsxs(Styled.LabelsAndButtonContainer, { children: [labelLeft && (_jsx(Styled.LeftSliderLabel, { children: labelLeft })), shouldShowResetButton && (_jsx(Styled.ResetButton, { onClick: resetValue, color: "inverted", children: t("custom_slider.reset_button") })), labelRight && (_jsx(Styled.RightSliderLabel, { children: labelRight }))] })] }));
};
var templateObject_1;
