var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { getValueIndex, Icon, useTooltipRoot, } from "@animech/ui";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import * as Styled from "./dropdown.style";
import { dropdownHeight } from "./dropdown.style";
import { InputContainer, } from "ui/components/elements/input/input-container";
var isLabelObject = function (label) {
    return label !== null && typeof label === "object" && "label" in label;
};
export var Dropdown = function (_a) {
    var itemImages = _a.itemImages, valueFromProps = _a.value, onValueChangeFromProps = _a.onValueChange, rootAttributes = _a.rootAttributes, disabled = _a.disabled, lock = _a.lock, description = _a.description, error = _a.error, label = _a.label, props = __rest(_a, ["itemImages", "value", "onValueChange", "rootAttributes", "disabled", "lock", "description", "error", "label"]);
    var items = props.items;
    var valueIndex = getValueIndex(items, valueFromProps, 0);
    var _b = __read(useState(false), 2), isOpen = _b[0], setIsOpen = _b[1];
    var _c = __read(useState({ top: 0, left: 0, width: 0 }), 2), optionListPositions = _c[0], setOptionListPositions = _c[1];
    var dropdownRoot = useTooltipRoot();
    var dropdownRef = useRef(null);
    var optionListRef = useRef(null);
    // Calculate the top, left and width of the optionlist so that it is centered on the dropdown element.
    // This also ensures that the dropdown list is adjusted in it's top position so that it doesn't overflow the bot/top edge of the viewport.
    useEffect(function () {
        var _a, _b;
        // This check is important so that we don't try to access the ref of the option list before it is actually rendered.
        if (!isOpen)
            return;
        var dropdownBoundingRect = (_a = dropdownRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        var optionListBoundingRect = (_b = optionListRef.current) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect();
        if (!dropdownBoundingRect || !optionListBoundingRect)
            return;
        var viewportHeight = window.innerHeight;
        var optionListHeight = optionListBoundingRect.height;
        // If the option list spans outside the top/bottom-edge of the viewport, we add a bit of padding so it doesn't exactly align with the edge.
        var edgePadding = 10;
        // Calculate initial center position of the options list.
        var top = dropdownBoundingRect.y + dropdownHeight;
        // Check if the option list overflows the bottom edge of the viewport
        if (top + optionListHeight > viewportHeight) {
            // Adjust to position the option list above the dropdown if there isn't enough space below
            top = dropdownBoundingRect.y - optionListHeight;
        }
        // Check for top overflow after moving above the dropdown
        if (top < 0) {
            // If it still overflows, position it at the top edge with some padding
            top = edgePadding;
        }
        setOptionListPositions({
            top: top,
            left: dropdownBoundingRect.x,
            width: dropdownBoundingRect.width,
        });
    }, [isOpen, dropdownRef, optionListRef]);
    var isDisabled = disabled || items.length < 2;
    var clickEventListener = function (event) {
        event.stopPropagation();
        setIsOpen(false);
    };
    useEffect(function () {
        if (!isOpen)
            return;
        window.addEventListener("click", clickEventListener);
        var selectedElement = document.getElementById(String(items[valueIndex]));
        var scrollOptions = {
            behavior: "smooth",
            block: "center",
        };
        selectedElement === null || selectedElement === void 0 ? void 0 : selectedElement.scrollIntoView(scrollOptions);
        return function () { return window.removeEventListener("click", clickEventListener); };
    });
    var onValueChange = function (newValue) {
        if (!onValueChangeFromProps || newValue === valueFromProps)
            return;
        onValueChangeFromProps(newValue);
    };
    var toggleOptions = function () {
        if (isDisabled)
            return;
        setIsOpen(!isOpen);
    };
    var getLabel = function (index) {
        var itemLabel = props.itemLabels[index];
        if (isLabelObject(itemLabel)) {
            return itemLabel.label;
        }
        return itemLabel;
    };
    var getLabelIcon = function (index) {
        if (index === valueIndex)
            return "checkbox";
        var itemLabel = props.itemLabels[index];
        if (isLabelObject(itemLabel)) {
            return itemLabel.icon;
        }
        return undefined;
    };
    return (_jsxs(_Fragment, { children: [_jsx(InputContainer, { label: label, error: error, description: description, lock: lock, children: _jsx(Styled.Dropdown, __assign({}, rootAttributes, props, { onClick: toggleOptions, "aria-haspopup": "listbox", "aria-expanded": isOpen, "aria-disabled": isDisabled, isDisabled: isDisabled, ref: dropdownRef, children: _jsxs("div", { className: "".concat(Styled.prefix, "-container"), children: [_jsx(Icon, { color: "primary", className: "".concat(Styled.prefix, "-icon"), name: "chevron-down" }), _jsx(Styled.DropdownLabel, { children: getLabel(valueIndex) })] }) })) }), isOpen &&
                createPortal(_jsx(Styled.OptionList, { ref: optionListRef, className: "".concat(Styled.prefix, "-options"), role: "listbox", "aria-activedescendant": String(items[valueIndex]), tabIndex: -1, optionListPositions: optionListPositions, children: items.map(function (item, index) { return (_jsxs("li", { id: String(item), role: "option", "aria-selected": valueIndex == index, tabIndex: index, onClick: function () {
                            onValueChange(item);
                        }, className: "".concat(Styled.prefix, "-item"), children: [itemImages && itemImages[index] && (_jsx("img", { className: "".concat(Styled.prefix, "-item_image"), src: itemImages[index] })), _jsx("span", { className: "".concat(Styled.prefix, "-item_label"), children: getLabel(index) }), getLabelIcon(index) && (_jsx(Icon, { className: "".concat(Styled.prefix, "-item_icon"), name: getLabelIcon(index) }))] }, String(item))); }) }), dropdownRoot)] }));
};
