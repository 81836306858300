import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTheme } from "@emotion/react";
import { getPickerArea } from "hooks/playcanvas/picker";
export var SelectionRectangle = function (_a) {
    var selectionRectangle = _a.selectionRectangle;
    var theme = useTheme();
    if (!selectionRectangle)
        return null;
    var piabGreen = theme.colors.palette.green[500];
    var backgroundColor = "".concat(piabGreen, "33"); // 20% opacity in hex
    var border = "0.5px solid ".concat(piabGreen);
    var _b = getPickerArea(selectionRectangle), x = _b.x, y = _b.y, width = _b.width, height = _b.height;
    // we could use emotion for this styling,
    // but rapidly changing emotion styles can cause performance issues.
    // so we use inline styles instead
    var style = {
        display: "block",
        position: "absolute",
        backgroundColor: backgroundColor,
        border: border,
        left: x,
        top: y,
        width: width,
        height: height,
    };
    return _jsx("div", { style: style });
};
