var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Line, LineChart, YAxis, CartesianGrid, XAxis, Legend, ReferenceLine, Tooltip, } from "recharts";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { graphMargins, defaultGraphStrokeWidth, graphWidth, defaultYAxisKey, yAxisTickColor, defaultXAxisProps, robotMotionGraphHeight, robotMotionColor, yAxisPadding, robotMotionYAxisUnit, defaultYAxisProps, } from "./constants";
import { VerticalReferenceLines } from "./common/reference-lines";
import { CustomLegend, useCustomLegendWrapperStyle, } from "./common/custom-legend";
import { CustomTooltip } from "./common/custom-tooltip";
import { ZoomHighlightedArea } from "./common/custom-reference-area";
import { useTimeDataMinMaxDomain } from "hooks/graph-panel/graph";
import { selectors, useAppSelector } from "store";
import { useGraphDataContext } from "providers/graph-panel";
export var RobotMotionGraph = function () {
    var t = useTranslation().t;
    var xAxisDomain = useTimeDataMinMaxDomain();
    var parsedGraphData = useAppSelector(selectors.selectParsedGraphData);
    var _a = useGraphDataContext(), onMouseDown = _a.onMouseDown, onMouseMove = _a.onMouseMove, onMouseUp = _a.onMouseUp, zoomedRobotMotionDomain = _a.zoomedRobotMotionDomain, zoomedTimeDomain = _a.zoomedTimeDomain;
    var customLegendWrapperStyle = useCustomLegendWrapperStyle(robotMotionGraphHeight);
    var zoomTicksY = useMemo(function () {
        if (!zoomedRobotMotionDomain)
            return;
        var min = zoomedRobotMotionDomain[0];
        var max = zoomedRobotMotionDomain[1];
        var midPoint = (min + max) / 2;
        return [min, midPoint, max].map(function (tick) { return Math.round(tick); });
    }, [zoomedRobotMotionDomain]);
    if (!parsedGraphData)
        return null;
    var robotMotionData = parsedGraphData.robotMotionData, robotMotionDomain = parsedGraphData.robotMotionDomain;
    var yDomain = zoomedRobotMotionDomain !== null && zoomedRobotMotionDomain !== void 0 ? zoomedRobotMotionDomain : robotMotionDomain;
    var xDomain = zoomedTimeDomain !== null && zoomedTimeDomain !== void 0 ? zoomedTimeDomain : xAxisDomain;
    var ticks = zoomTicksY !== null && zoomTicksY !== void 0 ? zoomTicksY : robotMotionDomain;
    return (_jsxs(LineChart, { width: graphWidth, height: robotMotionGraphHeight, data: robotMotionData, margin: graphMargins, onMouseDown: onMouseDown, onMouseMove: onMouseMove, onMouseUp: onMouseUp, children: [_jsx(CartesianGrid, { vertical: false, horizontal: false }), _jsx(VerticalReferenceLines, {}), _jsx(ReferenceLine, { y: yDomain[0] }), _jsx(ReferenceLine, { y: yDomain[1] }), _jsx(XAxis, __assign({}, defaultXAxisProps, { domain: xDomain, hide: true })), _jsx(YAxis, __assign({}, defaultYAxisProps, { ticks: ticks, interval: 0, tick: yAxisTickColor, tickMargin: 10, tickLine: false, padding: yAxisPadding, domain: yDomain })), _jsx(Tooltip, { wrapperStyle: { zIndex: 100 }, content: _jsx(CustomTooltip, { xLabel: t("graph_panel.pump_x_label_time"), yLabel: t("graph_panel.robot_motion_tooltip_y_label"), yUnit: robotMotionYAxisUnit }) }), _jsx(Line, { type: "linear", dataKey: defaultYAxisKey, stroke: robotMotionColor, strokeWidth: defaultGraphStrokeWidth, dot: false }), _jsx(Legend, { align: "left", wrapperStyle: customLegendWrapperStyle, content: function () { return (_jsx(CustomLegend, { label: "".concat(t("graph_panel.robot_motion_legend"), " (").concat(robotMotionYAxisUnit, ")") })); } }), _jsx(ZoomHighlightedArea, { domain: yDomain })] }));
};
