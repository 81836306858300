var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useEffect, useState } from "react";
import * as Styled from "./sidebar.style";
import { InfoButton, } from "ui/components/elements/info-button";
var useDelayedState = function (condition, delayMs) {
    var _a = __read(useState(!condition), 2), _condition = _a[0], setCondition = _a[1];
    useEffect(function () {
        var timeoutId;
        if (condition) {
            timeoutId = setTimeout(function () {
                setCondition(true);
            }, delayMs);
        }
        else {
            if (timeoutId)
                clearTimeout(timeoutId);
            setCondition(false);
        }
        return function () { return (timeoutId ? clearTimeout(timeoutId) : undefined); };
    }, [condition, delayMs]);
    return _condition;
};
export var TitleWithInfoButton = function (_a) {
    var title = _a.title, description = _a.description, _b = _a.isVisible, isVisibleProps = _b === void 0 ? true : _b;
    // Why is useDelayedState needed? Why can't we just show the infobutton
    // immediately? The reason is that we need to work around the visual
    // transition of the tree nav when a treenavitem is selected. If we make the
    // infobutton visible immediately, it will be visible for a short period of
    // time in the wrong place.
    var isVisible = useDelayedState(isVisibleProps, 100);
    return (_jsxs(Styled.TitleContainer, { children: [title, isVisible && title && description && (_jsx(InfoButton, { title: title, description: description, iconButtonProps: { icon: "document-list" } }))] }));
};
