var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { createContext, useCallback, useContext, useEffect, useRef, useState, } from "react";
import html2canvas from "html2canvas";
import { useGraphData } from "hooks/graph-panel/graph";
import { useZoom } from "hooks/graph-panel/zoom";
var defaultGraphDataContext = {
    responsiveContainerWidth: 900,
    onSelectedNodeChange: function () {
        //
    },
    zoomOut: function () {
        //
    },
    onMouseDown: function () {
        //
    },
    onMouseMove: function () {
        //
    },
    onMouseUp: function () {
        //
    },
    setPrintModeActive: function () {
        //
    },
    generateScreenshot: function () { return Promise.resolve(""); },
    getAlphabeticalId: function () { return ""; },
    horizontalZoom: function () {
        //
    },
    setResponsiveContainerWidth: function () {
        //
    },
    setAirConsumptionGraphRef: function () {
        //
    },
};
var GraphDataContext = createContext(defaultGraphDataContext);
var generateSelectedNodes = function (pumpsAndCupsNodeTree, printMode) {
    if (!pumpsAndCupsNodeTree)
        return {};
    var selectedNodes = pumpsAndCupsNodeTree.reduce(function (acc, pump) {
        var isSelectedByDefault = printMode ? true : pump.isGoverningPump;
        acc[pump.yValueKey] = isSelectedByDefault;
        pump.connectedSuctionCups.forEach(function (connectedCup) {
            acc[connectedCup.yValueKey] = isSelectedByDefault;
        });
        return acc;
    }, {});
    return selectedNodes;
};
export var GraphDataProvider = function (_a) {
    var children = _a.children;
    var graphPanelRef = useRef(null);
    var _b = useGraphData(), pumpsAndCupsNodeTree = _b.pumpsAndCupsNodeTree, alphabeticalIdObj = _b.alphabeticalIdObj;
    var _c = useZoom(), onMouseDown = _c.onMouseDown, onMouseMove = _c.onMouseMove, onMouseUp = _c.onMouseUp, zoomOut = _c.zoomOut, zoomAreaLeftX = _c.zoomAreaLeftX, zoomAreaRightX = _c.zoomAreaRightX, zoomedVacuumLevelDomain = _c.zoomedVacuumLevelDomain, zoomedRobotMotionDomain = _c.zoomedRobotMotionDomain, zoomedAirConsumptionDomain = _c.zoomedAirConsumptionDomain, zoomedLiftForceDomain = _c.zoomedLiftForceDomain, zoomedTimeDomain = _c.zoomedTimeDomain, dataIndexLeft = _c.dataIndexLeft, dataIndexRight = _c.dataIndexRight, horizontalZoom = _c.horizontalZoom;
    var _d = __read(useState({}), 2), selectedNodes = _d[0], setSelectedNodes = _d[1];
    var _e = __read(useState(false), 2), printModeActive = _e[0], setPrintModeActive = _e[1];
    var _f = __read(useState(defaultGraphDataContext.responsiveContainerWidth), 2), responsiveContainerWidth = _f[0], setResponsiveContainerWidth = _f[1];
    var _g = __read(useState(), 2), airConsumptionGraphRef = _g[0], setAirConsumptionGraphRef = _g[1];
    var generateScreenshot = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var graphPanelAsCanvasElement, screenShotSrc;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(graphPanelRef === null || graphPanelRef === void 0 ? void 0 : graphPanelRef.current))
                        return [2 /*return*/, ""];
                    return [4 /*yield*/, html2canvas(graphPanelRef.current)];
                case 1:
                    graphPanelAsCanvasElement = _a.sent();
                    screenShotSrc = graphPanelAsCanvasElement.toDataURL();
                    return [2 /*return*/, screenShotSrc];
            }
        });
    }); }, [graphPanelRef]);
    var getAlphabeticalId = useCallback(function (nodeId, alphabeticalNodeType) {
        var _a;
        if (!alphabeticalIdObj[nodeId])
            return "";
        return (_a = alphabeticalIdObj[nodeId][alphabeticalNodeType]) !== null && _a !== void 0 ? _a : "";
    }, [alphabeticalIdObj]);
    var onSelectedNodeChange = useCallback(function (id, isPump) {
        if (!pumpsAndCupsNodeTree)
            return;
        setSelectedNodes(function (prevState) {
            var _a;
            var isSelected = !prevState[id];
            // When a user selects/deselects a pump, all connected suction cups should be updated to match the selected state of the pump
            var generateUpdatedSelectedCups = function () {
                if (!isPump)
                    return;
                var connectedSuctionCupIds;
                pumpsAndCupsNodeTree.forEach(function (pump) {
                    if (pump.yValueKey !== id)
                        return;
                    connectedSuctionCupIds = pump.connectedSuctionCups.map(function (connectedCup) { return connectedCup.yValueKey; });
                });
                return connectedSuctionCupIds === null || connectedSuctionCupIds === void 0 ? void 0 : connectedSuctionCupIds.reduce(function (acc, suctionCupId) {
                    acc[suctionCupId] = isSelected;
                    return acc;
                }, {});
            };
            var updatedSelectedCups = generateUpdatedSelectedCups();
            return __assign(__assign(__assign({}, prevState), (_a = {}, _a[id] = isSelected, _a)), (updatedSelectedCups && updatedSelectedCups));
        });
    }, [pumpsAndCupsNodeTree]);
    useEffect(function () {
        setSelectedNodes(__assign({}, generateSelectedNodes(pumpsAndCupsNodeTree, printModeActive)));
    }, [pumpsAndCupsNodeTree, printModeActive]);
    return (_jsx(GraphDataContext.Provider, { value: {
            graphPanelRef: graphPanelRef,
            printModeActive: printModeActive,
            selectedNodes: selectedNodes,
            pumpsAndCupsNodeTree: pumpsAndCupsNodeTree,
            onSelectedNodeChange: onSelectedNodeChange,
            onMouseDown: onMouseDown,
            onMouseMove: onMouseMove,
            onMouseUp: onMouseUp,
            zoomOut: zoomOut,
            generateScreenshot: generateScreenshot,
            setPrintModeActive: setPrintModeActive,
            getAlphabeticalId: getAlphabeticalId,
            zoomAreaLeftX: zoomAreaLeftX,
            zoomAreaRightX: zoomAreaRightX,
            zoomedVacuumLevelDomain: zoomedVacuumLevelDomain,
            zoomedRobotMotionDomain: zoomedRobotMotionDomain,
            zoomedAirConsumptionDomain: zoomedAirConsumptionDomain,
            zoomedLiftForceDomain: zoomedLiftForceDomain,
            zoomedTimeDomain: zoomedTimeDomain,
            dataIndexLeft: dataIndexLeft,
            dataIndexRight: dataIndexRight,
            horizontalZoom: horizontalZoom,
            responsiveContainerWidth: responsiveContainerWidth,
            setResponsiveContainerWidth: setResponsiveContainerWidth,
            airConsumptionGraphRef: airConsumptionGraphRef,
            setAirConsumptionGraphRef: setAirConsumptionGraphRef,
        }, children: children }));
};
export var useGraphDataContext = function () { return useContext(GraphDataContext); };
