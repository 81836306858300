var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from "@emotion/react";
import styled from "@emotion/styled";
export var ChartBackgroundContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tposition: relative;\n"], ["\n\tposition: relative;\n"])));
export var ChartBackgroundColor = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var leftXPixelCoordinate = _a.leftXPixelCoordinate, rightXPixelCoordinate = _a.rightXPixelCoordinate;
    var borderWidth = 1;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t\t\tbackground-color: #363839;\n\t\t\tborder: ", "px solid #4d5051;\n\t\t\tposition: absolute;\n\t\t\tbottom: 0;\n\t\t\ttop: 0;\n\t\t\tleft: ", "px;\n\t\t\twidth: ", "px;\n\t\t"], ["\n\t\t\tbackground-color: #363839;\n\t\t\tborder: ", "px solid #4d5051;\n\t\t\tposition: absolute;\n\t\t\tbottom: 0;\n\t\t\ttop: 0;\n\t\t\tleft: ", "px;\n\t\t\twidth: ", "px;\n\t\t"])), borderWidth, leftXPixelCoordinate, rightXPixelCoordinate + borderWidth);
});
var templateObject_1, templateObject_2, templateObject_3;
