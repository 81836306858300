export var Material;
(function (Material) {
    Material["MA_TESTOBJECT"] = "MA_TestObj";
    Material["METAL"] = "MetalPaintedGreyDark";
    Material["GLASS"] = "GlassClearGlossyWhite";
    Material["PLASTIC"] = "PlasticSemiglossGreyDark";
    Material["PLASTIC_TEXTURED"] = "PlasticSemiglossGreyDarkTextured";
    Material["CARDBOARD_KRAFT"] = "CardboardKraft";
    Material["CARDBOARD_COLORED"] = "CardboardColored";
    Material["CARDBOARD_KRAFT_PAINTED"] = "CardboardKraftPainted";
    Material["CARDBOARD_KRAFT_WAXED"] = "CardboardKraftWaxed";
    Material["PLASTIC_GLOSSY_BLUE"] = "PlasticGlossyBlue";
    Material["PLASTIC_GLOSSY_DARK_BLUE"] = "PlasticGlossyDarkBlue";
    Material["PLASTIC_GLOSSY_RED"] = "PlasticGlossyRed";
    Material["PLASTIC_GLOSSY_DARK_RED"] = "PlasticGlossyDarkRed";
    Material["PLASTIC_GLOSSY_YELLOW"] = "PlasticGlossyYellow";
    Material["PLASTIC_GLOSSY_DARK_YELLOW"] = "PlasticGlossyDarkYellow";
    Material["PLASTIC_GLOSSY_BLACK"] = "PlasticGlossyBlack";
    Material["PLASTIC_GLOSSY_BROWN"] = "PlasticBrownGlossy";
    Material["PLASTIC_MATTE_GREY"] = "PlasticMatteGreyDark";
    Material["PLASTIC_MATTE_BLUE"] = "PlasticMatteBlue";
    Material["PLASTIC_MATTE_RED"] = "PlasticMatteRed";
    Material["TRANSPARENT_BLUE"] = "TransparentBlue";
    Material["TRANSPARENT_GREY"] = "TransparentDarkGrey";
    Material["TRANSPARENT_RED"] = "TransparentRed";
    Material["DURAFLEX_GREEN"] = "DuraflexGreen";
    Material["DURAFLEX_BLUE"] = "DuraflexBlue";
    Material["DURAFLEX_WHITE"] = "DuraflexWhite";
    Material["MA_MAIN"] = "MA_SuctionCupMain";
    Material["MA_MAIN2"] = "MA_SuctionCupMain2";
    Material["MA_MAIN3"] = "MA_SuctionCupMain3";
    Material["MA_SECONDARY"] = "MA_SuctionCupSecondary";
    Material["PU30"] = "PU30";
    Material["PU50"] = "PU50";
    Material["PU55"] = "PU55";
    Material["PU60"] = "PU60";
    Material["PU90"] = "PU90";
    Material["S"] = "S";
    Material["SF"] = "SF";
    Material["HN"] = "HN";
    Material["NP"] = "NP";
    Material["T30"] = "T30";
    Material["T50"] = "T50";
    Material["T18"] = "T18";
})(Material || (Material = {}));
export var defaultMetalCupMaterialTransform = {
    PU55: {
        materialTransforms: [
            { sourceMaterial: Material.MA_MAIN, targetMaterial: Material.PU55 },
        ],
    },
    NP: {
        materialTransforms: [
            { sourceMaterial: Material.MA_MAIN, targetMaterial: Material.NP },
        ],
    },
};
export var CameraViewId;
(function (CameraViewId) {
    CameraViewId["INITIAL_3D_VIEW"] = "intitial_3d_view";
    CameraViewId["EDIT_VIEW"] = "edit_view";
    CameraViewId["NODE_VIEW"] = "node_view";
    CameraViewId["NODE_OVERLAY_VIEW"] = "node_overlay_view";
})(CameraViewId || (CameraViewId = {}));
export var AdjustableObjectType;
(function (AdjustableObjectType) {
    AdjustableObjectType["BOX"] = "box";
    AdjustableObjectType["CYLINDER"] = "cylinder";
})(AdjustableObjectType || (AdjustableObjectType = {}));
