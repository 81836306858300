import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useMemo, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Interval } from "./interval";
import { Line } from "./line";
import { TextBox } from "./text-box";
import { Unit, getAprxPixelWidthOfNumber, round } from "utility/number";
import { useNumberConversion } from "hooks/number-conversion";
import { useAppSelector, selectors } from "store";
import { atmColor } from "store/types";
export var getScreenPosXFromRef = function (ref) {
    if (!ref.current)
        return 0;
    var elementBoundingBox = ref.current.getBoundingClientRect();
    return elementBoundingBox.left;
};
export var Chart = function (props) {
    var t = useTranslation().t;
    var convertNumber = useNumberConversion();
    var dashboardData = useAppSelector(selectors.selectDashboardData);
    var transferValueElementPosX = props.transferValueElementPosX, returnValueElementPosX = props.returnValueElementPosX;
    var svgRef = useRef(null);
    var svgLeftPosition = getScreenPosXFromRef(svgRef);
    var transferLeftX = transferValueElementPosX - svgLeftPosition;
    var returnLeftX = returnValueElementPosX - svgLeftPosition;
    var totalWidthInPixels = 500;
    var valueBoxesY = 1;
    var airIntervalsY = 26;
    var pickBoxesY = 31;
    var movementIntervalsY = 47;
    var intervalHeight = 8;
    var minIntervalWidth = 7;
    var pickBoxWidth = 35;
    var placeBoxWidth = 42;
    var boxMargin = 8;
    var boxHeight = 20;
    /* The interval width is calculated as the fraction of the total time that
     * the interval accounts for, times the available interval width in pixels. The available
     * with is the total width of the SVG minus pick/place boxes widths */
    var getIntervalWidth = useCallback(function (widthInSeconds, totalWidthInSeconds) {
        if (widthInSeconds === 0)
            return 0;
        var intervalWidth = (widthInSeconds / totalWidthInSeconds) *
            (totalWidthInPixels -
                (pickBoxWidth + 2 * boxMargin) * 2 -
                (placeBoxWidth + 2 * boxMargin));
        return Math.max(intervalWidth, minIntervalWidth);
    }, [totalWidthInPixels, pickBoxWidth, placeBoxWidth, boxMargin]);
    var getIntervalTooltip = useCallback(function (field) { return "".concat(t(field.translationKey), " - ").concat(round(field.value, 3), "s"); }, [t]);
    var svgElements = useMemo(function () {
        var _a, _b;
        var emptyChartElements = [
            _jsx(Line, { x: 0, y: pickBoxesY + boxHeight / 2, length: totalWidthInPixels }, "line-to-pick-1"),
            _jsx(TextBox, { x: boxMargin, y: pickBoxesY, width: pickBoxWidth, height: boxHeight, text: t("dashboard.pick") }, "pick-1"),
            _jsx(TextBox, { x: totalWidthInPixels - boxMargin - pickBoxWidth, y: pickBoxesY, width: pickBoxWidth, height: boxHeight, text: t("dashboard.pick") }, "pick-2"),
            _jsx(TextBox, { x: totalWidthInPixels / 2 - placeBoxWidth / 2, y: pickBoxesY, width: placeBoxWidth, height: boxHeight, text: t("dashboard.place") }, "place"),
        ];
        if (!dashboardData) {
            return emptyChartElements;
        }
        var speedData = dashboardData.applicationSpeed;
        var desiredVacuumLevels = dashboardData.desiredVacuumLevels;
        var totalWidthInSeconds = speedData.vacuumOnBeforePick.value +
            speedData.blowOffOnBeforePick.value +
            speedData.timeToPartSecured.value +
            speedData.transferToPlace.value +
            speedData.totalReleaseTime.value +
            speedData.returnToPick.value;
        if (totalWidthInSeconds === 0) {
            return emptyChartElements;
        }
        var elements = [];
        /* Put vertical lines in separate array to render them last */
        var verticalLines = [];
        /* Add all elements from left to right */
        var currentX = 0;
        var currentMidLineX = 0;
        /* Vacuum On Before pick */
        var width = getIntervalWidth(speedData.vacuumOnBeforePick.value, totalWidthInSeconds);
        elements.push(_jsx(Interval, { x: currentX, y: airIntervalsY, width: width, height: intervalHeight, color: speedData.vacuumOnBeforePick.color, tooltipText: getIntervalTooltip(speedData.vacuumOnBeforePick), roundedLeft: true, roundedRight: true }, speedData.vacuumOnBeforePick.id));
        currentX += width;
        /* BO on Before pick */
        width = getIntervalWidth(speedData.blowOffOnBeforePick.value, totalWidthInSeconds);
        elements.push(_jsx(Interval, { x: currentX, y: airIntervalsY, width: width, height: intervalHeight, color: speedData.blowOffOnBeforePick.color, tooltipText: getIntervalTooltip(speedData.blowOffOnBeforePick), roundedLeft: true, roundedRight: true }, speedData.blowOffOnBeforePick.id));
        currentX += width;
        /* First "Pick" box */
        currentMidLineX = currentX;
        elements.push(_jsx(Line, { x: currentMidLineX, y: pickBoxesY + boxHeight / 2, length: boxMargin }, "line-to-pick-1"));
        currentX += boxMargin;
        elements.push(_jsx(TextBox, { x: currentX, y: pickBoxesY, width: pickBoxWidth, height: boxHeight, text: t("dashboard.pick") }, "pick-1"));
        currentMidLineX = currentX + pickBoxWidth;
        currentX += pickBoxWidth + boxMargin;
        /* Time to part secured + corresponding dotted line below*/
        width = getIntervalWidth(speedData.timeToPartSecured.value, totalWidthInSeconds);
        elements.push(_jsx(Interval, { x: currentX, y: airIntervalsY, width: width, height: intervalHeight, color: speedData.timeToPartSecured.color, tooltipText: getIntervalTooltip(speedData.timeToPartSecured), roundedLeft: true }, speedData.timeToPartSecured.id));
        elements.push(_jsx(Line, { x: currentX, y: movementIntervalsY + intervalHeight / 2, length: width, isDotted: true }, "line-to-movement-1"));
        currentX += width;
        /* Vertical line + part secured vacuum level */
        var convertedPSLevel = convertNumber(desiredVacuumLevels.partSecured.value, Unit.KPA);
        width = getAprxPixelWidthOfNumber(convertedPSLevel.value);
        elements.push(_jsx(TextBox, { x: currentX - width, y: valueBoxesY, width: width, height: boxHeight, text: convertedPSLevel.value.toString(), tooltipText: t(desiredVacuumLevels.partSecured.translationKey), color: desiredVacuumLevels.partSecured.color, description: {
                long: t((_a = desiredVacuumLevels.partSecured.descriptionKey) !== null && _a !== void 0 ? _a : ""),
            } }, desiredVacuumLevels.partSecured.id));
        verticalLines.push(_jsx(Line, { x: currentX, y: valueBoxesY + 3, length: movementIntervalsY + intervalHeight - valueBoxesY - 3, direction: "vertical" }, "line-to-part-secured"));
        /* Save current X to use for transfer to place box*/
        var transferToPlaceX = currentX;
        if (speedData.delayToEnergySave &&
            desiredVacuumLevels.energySaving &&
            speedData.timeWithEnergySavingOn) {
            /* Delay to ES */
            width = getIntervalWidth(speedData.delayToEnergySave.value, totalWidthInSeconds);
            elements.push(_jsx(Interval, { x: currentX, y: airIntervalsY, width: width, height: intervalHeight, tooltipText: getIntervalTooltip(speedData.delayToEnergySave), color: speedData.delayToEnergySave.color }, speedData.delayToEnergySave.id));
            currentX += width;
            /* Vertical line + final vacuum level */
            var convertedESLevel = convertNumber(desiredVacuumLevels.energySaving.value, Unit.KPA);
            width = getAprxPixelWidthOfNumber(convertedESLevel.value);
            elements.push(_jsx(TextBox, { x: currentX, y: valueBoxesY, width: width, height: boxHeight, text: convertedESLevel.value.toString(), tooltipText: t(desiredVacuumLevels.energySaving.translationKey), color: desiredVacuumLevels.energySaving.color, description: {
                    long: t((_b = desiredVacuumLevels.energySaving.descriptionKey) !== null && _b !== void 0 ? _b : ""),
                } }, desiredVacuumLevels.energySaving.id));
            verticalLines.push(_jsx(Line, { x: currentX, y: valueBoxesY + 3, length: airIntervalsY + intervalHeight - valueBoxesY, direction: "vertical" }, "line-to-final-vacuum"));
            /* ES On */
            width = getIntervalWidth(speedData.timeWithEnergySavingOn.value, totalWidthInSeconds);
            elements.push(_jsx(Interval, { x: currentX, y: airIntervalsY, width: width, height: intervalHeight, color: speedData.timeWithEnergySavingOn.color, tooltipText: getIntervalTooltip(speedData.timeWithEnergySavingOn), roundedRight: true }, speedData.timeWithEnergySavingOn.id));
            currentX += width;
        }
        else if (speedData.timeWithEnergySavingOff) {
            /* ES Off */
            width = getIntervalWidth(speedData.timeWithEnergySavingOff.value, totalWidthInSeconds);
            elements.push(_jsx(Interval, { x: currentX, y: airIntervalsY, width: width, height: intervalHeight, color: speedData.timeWithEnergySavingOff.color, tooltipText: getIntervalTooltip(speedData.timeWithEnergySavingOff), roundedRight: true }, speedData.timeWithEnergySavingOff.id));
            currentX += width;
        }
        /* Transfer to place */
        elements.push(_jsx(Interval, { x: transferToPlaceX, y: movementIntervalsY, width: currentX - transferToPlaceX, height: intervalHeight, color: speedData.transferToPlace.color, tooltipText: getIntervalTooltip(speedData.transferToPlace), roundedRight: true }, speedData.transferToPlace.id));
        /* The line from the transfer interval to the transfer dashboard value */
        var distanceToValueDiv = 30;
        var yPosOfTransferAndReturnIntervals = movementIntervalsY + intervalHeight / 2;
        var distanceToTransfer = transferLeftX - currentX;
        verticalLines.push(_jsx(Line, { x: currentX, y: yPosOfTransferAndReturnIntervals, length: distanceToValueDiv, direction: "vertical" }, "vertical-line-to-transfer"));
        verticalLines.push(_jsx(Line, { x: currentX, y: distanceToValueDiv + yPosOfTransferAndReturnIntervals, length: distanceToTransfer + 8, direction: "horizontal" }, "horizontal-line-to-transfer"));
        /* "Place" box */
        currentX += boxMargin;
        elements.push(_jsx(Line, { x: currentMidLineX, y: pickBoxesY + boxHeight / 2, length: currentX - currentMidLineX }, "line-to-place"));
        elements.push(_jsx(TextBox, { x: currentX, y: pickBoxesY, width: placeBoxWidth, height: boxHeight, text: t("dashboard.place") }, "place"));
        currentMidLineX = currentX + placeBoxWidth;
        currentX += placeBoxWidth + boxMargin;
        /* Release time to place part + corresponding dotted line below */
        width = getIntervalWidth(speedData.releaseTimeToPlacePart.value, totalWidthInSeconds);
        elements.push(_jsx(Line, { x: currentX, y: movementIntervalsY + intervalHeight / 2, length: width, isDotted: true }, "line-to-movement-2"));
        width = getIntervalWidth(speedData.releaseTimeToPlacePart.value, totalWidthInSeconds);
        elements.push(_jsx(Interval, { x: currentX, y: airIntervalsY, width: width, height: intervalHeight, color: speedData.releaseTimeToPlacePart.color, tooltipText: getIntervalTooltip(speedData.releaseTimeToPlacePart), roundedLeft: true }, speedData.releaseTimeToPlacePart.id));
        currentX += width;
        /* Vertical line + ATM box */
        elements.push(_jsx(TextBox, { x: currentX, y: valueBoxesY, width: 37, height: boxHeight, tooltipText: t("dashboard.atm"), text: "ATM", color: atmColor, description: {
                long: t("descriptions.dashboard.release_time_to_place_part"),
            } }, "atm"));
        verticalLines.push(_jsx(Line, { x: currentX, y: valueBoxesY + 3, length: movementIntervalsY + intervalHeight - valueBoxesY - 3, direction: "vertical" }, "line-to-atm"));
        var returnTimeX = currentX;
        /* Aditional release time*/
        width = getIntervalWidth(speedData.additionalReleaseTime.value, totalWidthInSeconds);
        elements.push(_jsx(Interval, { x: currentX, y: airIntervalsY, width: width, height: intervalHeight, color: speedData.additionalReleaseTime.color, tooltipText: getIntervalTooltip(speedData.additionalReleaseTime), roundedRight: true }, speedData.additionalReleaseTime.id));
        currentX += width;
        /* Show the return time for the remainder of the chart */
        var returnTimeWidth = totalWidthInPixels - 2 * boxMargin - pickBoxWidth - returnTimeX;
        elements.push(_jsx(Interval, { x: returnTimeX, y: movementIntervalsY, width: returnTimeWidth, height: intervalHeight, color: speedData.returnToPick.color, tooltipText: getIntervalTooltip(speedData.returnToPick), roundedRight: true }, speedData.returnToPick.id));
        currentX = returnTimeX + returnTimeWidth;
        /* The line from the return interval to the return dashboard value */
        var distanceToReturn = returnLeftX - currentX;
        verticalLines.push(_jsx(Line, { x: currentX, y: yPosOfTransferAndReturnIntervals, length: distanceToValueDiv, direction: "vertical" }, "vertical-line-to-return"));
        verticalLines.push(_jsx(Line, { x: currentX, y: distanceToValueDiv + yPosOfTransferAndReturnIntervals, length: distanceToReturn + 8, direction: "horizontal" }, "horizontal-line-to-return"));
        /* Second "Pick" box */
        currentX += boxMargin;
        elements.push(_jsx(Line, { x: currentMidLineX, y: pickBoxesY + boxHeight / 2, length: currentX - currentMidLineX }, "line-to-pick-2"));
        elements.push(_jsx(TextBox, { x: currentX, y: pickBoxesY, width: pickBoxWidth, height: boxHeight, text: t("dashboard.pick") }, "pick-2"));
        currentX += pickBoxWidth;
        elements.push(_jsx(Line, { x: currentX, y: pickBoxesY + boxHeight / 2, length: boxMargin }, "line-from-pick-2"));
        return elements.concat(verticalLines);
    }, [
        t,
        dashboardData,
        getIntervalWidth,
        getIntervalTooltip,
        convertNumber,
        transferLeftX,
        returnLeftX,
    ]);
    return (_jsx("svg", { ref: svgRef, width: totalWidthInPixels, height: movementIntervalsY + intervalHeight, style: { overflow: "visible" }, children: svgElements }));
};
