var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { StyledButton } from "@animech/ui";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { IconButton } from "ui/components/elements/icons/icon-button.style";
export var Root = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: row;\n\n\t\t\t", " {\n\t\t\t\t&:first-child {\n\t\t\t\t\t", " {\n\t\t\t\t\t\tmargin-right: 2px;\n\t\t\t\t\t\tborder-radius: ", " 0 0\n\t\t\t\t\t\t\t", ";\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\t&:last-child {\n\t\t\t\t\t", " {\n\t\t\t\t\t\tmargin-left: 2px;\n\t\t\t\t\t\tborder-radius: 0 ", "\n\t\t\t\t\t\t\t", " 0;\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t"], ["\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: row;\n\n\t\t\t", " {\n\t\t\t\t&:first-child {\n\t\t\t\t\t", " {\n\t\t\t\t\t\tmargin-right: 2px;\n\t\t\t\t\t\tborder-radius: ", " 0 0\n\t\t\t\t\t\t\t", ";\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\t&:last-child {\n\t\t\t\t\t", " {\n\t\t\t\t\t\tmargin-left: 2px;\n\t\t\t\t\t\tborder-radius: 0 ", "\n\t\t\t\t\t\t\t", " 0;\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t"])), IconButton, StyledButton.Root, theme.dimensions.borderRadius, theme.dimensions.borderRadius, StyledButton.Root, theme.dimensions.borderRadius, theme.dimensions.borderRadius);
});
var templateObject_1, templateObject_2;
