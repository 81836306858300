var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable camelcase */
import { Material } from "3d/types";
/** ---------------   **/
/**     BL Family     **/
/** ---------------   **/
var cupModelTemplate = {
    BL30_2: {
        cupModelName: "BL30-2",
        cupWidth: 0.03,
        cupHeight: 0.0385,
        fittingModelName: "G38M01-S5",
    },
};
var materialTransformTemplate = {
    RED_SIL: {
        materialTransforms: [
            { sourceMaterial: Material.MA_MAIN, targetMaterial: Material.S },
            {
                sourceMaterial: Material.MA_SECONDARY,
                targetMaterial: Material.S,
            },
        ],
    },
    HNBR: {
        materialTransforms: [
            { sourceMaterial: Material.MA_MAIN, targetMaterial: Material.HN },
            {
                sourceMaterial: Material.MA_SECONDARY,
                targetMaterial: Material.HN,
            },
        ],
    },
};
export var suctionCupsFamilyBL = {
    // BL20-2 Red Sil
    suction_cup_0101205: __assign(__assign({}, cupModelTemplate.BL30_2), materialTransformTemplate.RED_SIL),
    // BL20-2 HNBR
    suction_cup_9914278: __assign(__assign({}, cupModelTemplate.BL30_2), materialTransformTemplate.HNBR),
    // BL30-2 Red SIL
    suction_cup_0101511: __assign(__assign({}, cupModelTemplate.BL30_2), materialTransformTemplate.RED_SIL),
    // BL40-2 Red SIL
    suction_cup_0101537: __assign(__assign({}, cupModelTemplate.BL30_2), materialTransformTemplate.RED_SIL),
    // BL50-2 Red SIL
    suction_cup_0101711: __assign(__assign({}, cupModelTemplate.BL30_2), materialTransformTemplate.RED_SIL),
};
