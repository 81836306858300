var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@emotion/styled";
import { CustomTooltip } from "ui/components/panels/graphs/graph.style";
export var SystemMinMaxContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tmargin-bottom: 1em;\n"], ["\n\tmargin-bottom: 1em;\n"])));
export var ZeroMarginParagraph = styled.p(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tmargin: 0;\n"], ["\n\tmargin: 0;\n"])));
export var SystemMinMaxParagraph = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\ttext-indent: 1em;\n\tmargin: 0;\n"], ["\n\ttext-indent: 1em;\n\tmargin: 0;\n"])));
export var CustomLiftForceTooltip = CustomTooltip;
var templateObject_1, templateObject_2, templateObject_3;
