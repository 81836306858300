var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b;
/* eslint-disable camelcase */
import { defaultObjectCameraViews } from "./camera-views";
import { adjustableBox, adjustableCylinder } from "./adjustable-shapes";
import { CameraViewId, Material, } from "3d/types";
import { PlasticObjectShapeId } from "store/types";
import { suctionCupsFamilyBX_P, suctionCupsFamilyB, suctionCupsFamilyB_XP, suctionCupsFamilyOB, suctionCupsFamilyF, suctionCupsFamilyU, suctionCupsFamilyBL, } from "3d/constants/cup-families";
export var suctionCupModelsPlastic = __assign(__assign(__assign(__assign(__assign(__assign(__assign({}, suctionCupsFamilyBX_P), suctionCupsFamilyB_XP), suctionCupsFamilyOB), suctionCupsFamilyB), suctionCupsFamilyF), suctionCupsFamilyU), suctionCupsFamilyBL);
var defaultPlasticObjectShapeData = {
    cameraViews: __assign(__assign({}, defaultObjectCameraViews), (_a = {}, _a[CameraViewId.EDIT_VIEW] = __assign(__assign({}, defaultObjectCameraViews.edit_view), { z: 0 }), _a)),
    objectDefaultSize: { x: 1, y: 1, z: 1 },
    materialTransforms: [
        {
            sourceMaterial: Material.MA_TESTOBJECT,
            targetMaterial: Material.PLASTIC,
        },
    ],
    texturedMaterialTransforms: [
        {
            sourceMaterial: Material.MA_TESTOBJECT,
            targetMaterial: Material.PLASTIC_TEXTURED,
        },
    ],
};
export var plasticObjectShapes = (_b = {},
    _b[PlasticObjectShapeId.ADJUSTABLE_PLASTIC_BOX] = __assign(__assign(__assign({}, defaultPlasticObjectShapeData), adjustableBox), { image: "img/app/object-shapes/plastic/PlasticCube100x100x100cm_Arrows.webp" }),
    _b[PlasticObjectShapeId.ADJUSTABLE_PLASTIC_CYLINDER] = __assign(__assign(__assign({}, defaultPlasticObjectShapeData), adjustableCylinder), { image: "img/app/object-shapes/plastic/PlasticCylinderHorizontal100x100cm_Arrows.webp" }),
    _b[PlasticObjectShapeId.BUMPER_FRONT] = __assign(__assign({}, defaultPlasticObjectShapeData), { translationKey: "sidebar.object_shape.objects.plastic.bumper_front", image: "img/app/object-shapes/plastic/PlasticBumperFront01.webp", modelName: "TestObj-PlasticBumperFront01", gripPositionY: 5.01, tubeNetworkY: 5.4, texturedMaterialTransforms: undefined }),
    _b[PlasticObjectShapeId.BUMPER_REAR] = __assign(__assign({}, defaultPlasticObjectShapeData), { translationKey: "sidebar.object_shape.objects.plastic.bumper_rear", image: "img/app/object-shapes/plastic/PlasticBumperRear01.webp", modelName: "TestObj-PlasticBumperRear01", gripPositionY: 5.01, tubeNetworkY: 5.4, texturedMaterialTransforms: undefined }),
    _b[PlasticObjectShapeId.CYLINDER_10X10] = __assign(__assign({}, defaultPlasticObjectShapeData), { translationKey: "sidebar.object_shape.objects.plastic.cylinder_10x10", image: "img/app/object-shapes/plastic/PlasticCylinder10x10cmB.webp", modelName: "TestObj-PlasticCylinder10x10cm", gripPositionY: 5.01, tubeNetworkY: 5.4 }),
    _b[PlasticObjectShapeId.CYLINDER_HALF_10X10_A] = __assign(__assign({}, defaultPlasticObjectShapeData), { translationKey: "sidebar.object_shape.objects.plastic.cylinder_half_10x10_a", image: "img/app/object-shapes/plastic/PlasticCylinderHalf10x10cm01.webp", modelName: "TestObj-PlasticCylinderHalf10x10cm01", gripPositionY: 5.01, tubeNetworkY: 5.4 }),
    _b[PlasticObjectShapeId.CYLINDER_HALF_10X10_B] = __assign(__assign({}, defaultPlasticObjectShapeData), { translationKey: "sidebar.object_shape.objects.plastic.cylinder_half_10x10_b", image: "img/app/object-shapes/plastic/PlasticCylinderHalf10x10cm02.webp", modelName: "TestObj-PlasticCylinderHalf10x10cm02", gripPositionY: 5.01, tubeNetworkY: 5.4 }),
    _b[PlasticObjectShapeId.DASHBOARD] = __assign(__assign({}, defaultPlasticObjectShapeData), { translationKey: "sidebar.object_shape.objects.plastic.dashboard", image: "img/app/object-shapes/plastic/PlasticDashboard01.webp", modelName: "TestObj-PlasticDashboard01", gripPositionY: 5.01, tubeNetworkY: 5.4 }),
    _b[PlasticObjectShapeId.DOOR_PANEL_INNER] = __assign(__assign({}, defaultPlasticObjectShapeData), { translationKey: "sidebar.object_shape.objects.plastic.door_panel_inner", image: "img/app/object-shapes/plastic/PlasticDoorpanelInner01.webp", modelName: "TestObj-PlasticDoorpanelInner01", gripPositionY: 5.01, tubeNetworkY: 5.4 }),
    _b[PlasticObjectShapeId.SHEET_20X20] = __assign(__assign({}, defaultPlasticObjectShapeData), { translationKey: "sidebar.object_shape.objects.plastic.sheet_20x20", image: "img/app/object-shapes/plastic/PlasticSheet20x20cm.webp", modelName: "TestObj-PlasticSheet20x20cm", gripPositionY: 5.01, tubeNetworkY: 5.4 }),
    _b[PlasticObjectShapeId.TOTE_1] = __assign(__assign({}, defaultPlasticObjectShapeData), { translationKey: "sidebar.object_shape.objects.plastic.tote_1", image: "img/app/object-shapes/plastic/PlasticTote01.webp", modelName: "TestObj-PlasticTote01", gripPositionY: 5.01, tubeNetworkY: 5.4 }),
    _b[PlasticObjectShapeId.TOTE_2] = __assign(__assign({}, defaultPlasticObjectShapeData), { translationKey: "sidebar.object_shape.objects.plastic.tote_2", image: "img/app/object-shapes/plastic/PlasticTote02.webp", modelName: "TestObj-PlasticTote02", gripPositionY: 4.8, tubeNetworkY: 5.4 }),
    _b[PlasticObjectShapeId.TOTE_3] = __assign(__assign({}, defaultPlasticObjectShapeData), { translationKey: "sidebar.object_shape.objects.plastic.tote_3", image: "img/app/object-shapes/plastic/PlasticTote03.webp", modelName: "TestObj-PlasticTote03", gripPositionY: 5.01, tubeNetworkY: 5.4 }),
    _b);
