var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { Container, H6 } from "@animech/ui";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import * as Styled from "./how-to-use.style";
export var HowToUseModal = function (_a) {
    var props = __rest(_a, []);
    var t = useTranslation().t;
    return (_jsx(Styled.Root, __assign({}, props, { title: t("how_to_use.title"), color: "inverted", children: _jsx(Container, { children: _jsxs(Styled.Content, { children: [_jsx(Styled.Introduction, { children: t("how_to_use.introduction") }), _jsxs(Styled.Steps, { children: [_jsx(Step, { number: 1, withVerticalLine: true }), _jsx(Step, { number: 2, withVerticalLine: true }), _jsx(Step, { number: 3 })] })] }) }) })));
};
var Step = function (_a) {
    var number = _a.number, withVerticalLine = _a.withVerticalLine;
    var t = useTranslation().t;
    var title = t("how_to_use.step_".concat(number, ".title"));
    var text = t("how_to_use.step_".concat(number, ".text"));
    return (_jsxs(Styled.Step, { children: [_jsx(StepSvg, { number: number, withVerticalLine: withVerticalLine }), _jsx(H6, { children: title }), _jsx(Styled.Paragraph, { children: text })] }));
};
var VerticalLineSvg = function (_a) {
    var color = _a.color;
    return (_jsx("svg", { width: "100%", height: "100%", viewBox: "0 0 100 100", preserveAspectRatio: "none", children: _jsx("path", { d: "M 0 0 L 0 100", stroke: color, strokeWidth: 10, vectorEffect: "non-scaling-stroke" }) }));
};
var BubbleSvg = function (_a) {
    var color = _a.color;
    return (_jsx("path", { d: "M35 17.5C35 27.165 27.165 35 17.5 35L4.52995e-06 35L7.58975e-06 5.28103e-06L17.5 6.81093e-06C27.165 7.65587e-06 35 7.83502 35 17.5V17.5Z", fill: color }));
};
var NumberSvg = function (_a) {
    var number = _a.number, color = _a.color;
    var numbers = [
        // 1
        "M15.6905 22.9982H22.2947V21.1472H20.1624V12.6328H17.7339L15.6757 14.0099V16.1274L17.7339 14.7651H18.0301V21.1472H15.6905V22.9982Z",
        // 2
        "M15.0879 15.7575H17.2202C17.309 14.7506 17.9606 14.3212 18.8194 14.3212C19.7079 14.3212 20.3298 14.8098 20.3298 15.8019C20.3298 16.7644 19.7375 17.3715 18.1087 18.6006L15.1471 20.8662V22.9985H22.699V21.1475H18.4196V20.8514L20.0189 19.5631C21.8254 18.1564 22.4769 17.2087 22.4769 15.7575C22.4769 13.7585 21.1887 12.4258 18.8787 12.4258C16.6575 12.4258 15.1915 13.5956 15.0879 15.7575Z",
        // 3
        "M15.4588 12.6328V14.4245H19.7827V14.7207L17.7836 16.3644V18.0376C18.0502 17.9636 18.3463 17.934 18.7313 17.934C19.9159 17.934 20.5527 18.6151 20.5527 19.6369C20.5527 20.629 19.8567 21.2953 18.7313 21.2953C17.754 21.2953 17.0432 20.7919 16.9248 19.8442H14.7925C14.8517 21.7395 16.3917 23.1907 18.7313 23.1907C21.0413 23.1907 22.685 21.8284 22.685 19.6517C22.685 17.8007 21.5152 16.8086 20.1232 16.6753V16.3792L22.1223 14.7207V12.6328H15.4588Z",
    ];
    return _jsx("path", { d: numbers[number - 1], fill: color });
};
var StepSvg = function (_a) {
    var number = _a.number, withVerticalLine = _a.withVerticalLine;
    var theme = useTheme();
    var green = theme.colors.palette.green[500];
    var white = theme.colors.palette.white;
    return (_jsxs(_Fragment, { children: [withVerticalLine && _jsx(VerticalLineSvg, { color: green }), _jsxs("svg", { width: "100%", height: "100%", children: [_jsx(BubbleSvg, { color: green }), _jsx(NumberSvg, { number: number, color: white })] })] }));
};
