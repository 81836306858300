var _a, _b;
export var round = function (value, decimals) {
    if (decimals === void 0) { decimals = 2; }
    if (value !== undefined && decimals < 0)
        return value;
    return value !== undefined
        ? +(Math.round(+(value + "e+" + decimals)) + "e-" + decimals)
        : NaN;
};
export var clamp = function (value, min, max) {
    return Math.min(Math.max(value, min), max);
};
/* Rough estimation of width.
 * Each digit is ~7px, the "." in decimals is ~2px.
 * 10px added for padding */
export var getAprxPixelWidthOfNumber = function (value) {
    var stringValue = value.toString();
    if (stringValue.includes(".")) {
        return (stringValue.length - 1) * 7 + 12;
    }
    else {
        return stringValue.length * 7 + 10;
    }
};
export var Unit;
(function (Unit) {
    Unit["KG"] = "kg";
    Unit["MPA"] = "MPa";
    Unit["KPA"] = "-kPa";
    Unit["C"] = "\u02DAC";
    Unit["NL_MIN"] = "Nl/min";
    Unit["NL_CYCLE"] = "Nl/cycle";
    Unit["N"] = "N";
    Unit["L"] = "L";
    Unit["M"] = "m";
    Unit["M_S2"] = "m/s\u00B2";
    Unit["IN"] = "in";
    Unit["LB"] = "lb";
    Unit["PSI"] = "Psi";
    Unit["INHG"] = "-inHg";
    Unit["F"] = "\u02DAF";
    Unit["SCFM"] = "SCFM";
    Unit["SCF_CYCLE"] = "SCF/cycle";
    Unit["LBF"] = "lbf";
    Unit["IN3"] = "in\u00B3";
    Unit["FT_S2"] = "ft/s\u00B2";
    Unit["MM"] = "mm";
    Unit["S"] = "s";
    Unit["MS"] = "ms";
    Unit["PERCENTAGE"] = "%";
    Unit["KWH"] = "kWh/day";
    Unit["W"] = "W";
    Unit["WH"] = "Wh/cycle";
})(Unit || (Unit = {}));
export var conversions = (_a = {},
    _a[Unit.KG] = {
        convert: function (value) { return value * 2.20462262; },
        unit: Unit.LB,
    },
    _a[Unit.MPA] = {
        convert: function (value) { return value * 145; },
        unit: Unit.PSI,
    },
    _a[Unit.KPA] = {
        convert: function (value) { return value * 0.29536; },
        unit: Unit.INHG,
    },
    _a[Unit.C] = {
        convert: function (value) { return value * (9 / 5) + 32; },
        unit: Unit.F,
    },
    _a[Unit.NL_MIN] = {
        convert: function (value) { return value * 0.035314666; },
        unit: Unit.SCFM,
    },
    _a[Unit.NL_CYCLE] = {
        convert: function (value) { return value * 0.035314666; },
        unit: Unit.SCF_CYCLE,
    },
    _a[Unit.N] = {
        convert: function (value) { return value * 0.2248089431; },
        unit: Unit.LBF,
    },
    _a[Unit.L] = {
        convert: function (value) { return value * 61.023744095; },
        unit: Unit.IN3,
    },
    _a[Unit.M] = {
        convert: function (value) { return value * 39.3700787; },
        unit: Unit.IN,
    },
    _a[Unit.MM] = {
        convert: function (value) { return (value / 1000) * 39.3700787; },
        unit: Unit.IN,
    },
    _a[Unit.M_S2] = {
        convert: function (value) { return value * 29.5275590055118; },
        unit: Unit.FT_S2,
    },
    _a[Unit.LB] = {
        convert: function (value) { return value / 2.20462262; },
        unit: Unit.KG,
    },
    _a[Unit.PSI] = {
        convert: function (value) { return value / 145; },
        unit: Unit.MPA,
    },
    _a[Unit.INHG] = {
        convert: function (value) { return value / 0.29536; },
        unit: Unit.KPA,
    },
    _a[Unit.F] = {
        convert: function (value) { return (value - 32) * (5 / 9); },
        unit: Unit.C,
    },
    _a[Unit.SCFM] = {
        convert: function (value) { return value / 0.035314666; },
        unit: Unit.NL_MIN,
    },
    _a[Unit.SCF_CYCLE] = {
        convert: function (value) { return value / 0.035314666; },
        unit: Unit.NL_CYCLE,
    },
    _a[Unit.LBF] = {
        convert: function (value) { return value / 0.2248089431; },
        unit: Unit.N,
    },
    _a[Unit.IN3] = {
        convert: function (value) { return value / 61.023744095; },
        unit: Unit.L,
    },
    _a[Unit.IN] = {
        convert: function (value) { return value / 39.3700787; },
        unit: Unit.M,
    },
    _a[Unit.FT_S2] = {
        convert: function (value) { return value / 29.5275590055118; },
        unit: Unit.M_S2,
    },
    _a[Unit.S] = {
        convert: function (value) { return value; },
        unit: Unit.S,
    },
    _a[Unit.MS] = {
        convert: function (value) { return value; },
        unit: Unit.MS,
    },
    _a[Unit.PERCENTAGE] = {
        convert: function (value) { return value; },
        unit: Unit.PERCENTAGE,
    },
    _a[Unit.KWH] = {
        convert: function (value) { return value; },
        unit: Unit.KWH,
    },
    _a[Unit.W] = {
        convert: function (value) { return value; },
        unit: Unit.W,
    },
    _a[Unit.WH] = {
        convert: function (value) { return value; },
        unit: Unit.WH,
    },
    _a);
export var precision = (_b = {},
    _b[Unit.KG] = 3,
    _b[Unit.MPA] = 3,
    _b[Unit.KPA] = 0,
    _b[Unit.C] = 0,
    _b[Unit.NL_MIN] = 2,
    _b[Unit.NL_CYCLE] = 2,
    _b[Unit.N] = 0,
    _b[Unit.L] = 2,
    _b[Unit.M] = 2,
    _b[Unit.M_S2] = 2,
    _b[Unit.IN] = 3,
    _b[Unit.LB] = 2,
    _b[Unit.PSI] = 0,
    _b[Unit.INHG] = 1,
    _b[Unit.F] = 0,
    _b[Unit.SCFM] = 3,
    _b[Unit.SCF_CYCLE] = 3,
    _b[Unit.LBF] = 0,
    _b[Unit.IN3] = 0,
    _b[Unit.FT_S2] = 0,
    _b[Unit.MM] = 1,
    _b[Unit.S] = 2,
    _b[Unit.MS] = 2,
    _b[Unit.PERCENTAGE] = 0,
    _b[Unit.KWH] = 2,
    _b[Unit.W] = 0,
    _b[Unit.WH] = 2,
    _b);
