import storage from "redux-persist/lib/storage/session";
import { transform } from "./persist-transform";
import { currentSystemDataVersion } from "store/types";
export var persistKey = "systemDataSelectApplication";
export var persistConfig = {
    version: currentSystemDataVersion,
    storage: storage,
    key: persistKey,
    transforms: [transform],
};
