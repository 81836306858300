var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useState } from "react";
import { Label, SupportText } from "@animech/ui";
import { useTheme } from "@emotion/react";
import * as Styled from "./input-container.style";
import { LabelWithDescription } from "ui/components/elements/label-with-description";
import { LockIcon } from "ui/components/elements/icons/lock";
import { UnlockIcon } from "ui/components/elements/icons/unlock";
import { IconButton } from "ui/components/elements/icons/icon-button";
export var InputContainer = function (_a) {
    var label = _a.label, children = _a.children, _b = _a.error, error = _b === void 0 ? "" : _b, description = _a.description, lock = _a.lock;
    var _c = __read(useState(false), 2), childrenHasFocus = _c[0], setChildrenHasFocus = _c[1];
    var theme = useTheme();
    var renderLock = function () {
        if (!lock)
            return null;
        var icon = lock.isLocked ? (_jsx(LockIcon, { size: "16" })) : (_jsx(UnlockIcon, { size: "16", color: theme.colors.palette.green[500] }));
        var onClick = function () {
            lock.isLocked ? lock.onUnlock() : lock.onLock();
        };
        return (_jsx(IconButton, { rootAttributes: {
                "data-testid": "input-button",
                onMouseDown: onClick,
            }, color: lock.isLocked ? "primary" : "neutral", focused: childrenHasFocus, disabled: lock.isLockDisabled || childrenHasFocus, children: icon }));
    };
    return (_jsxs(Styled.InputContainer, { "data-testid": "input-container", locked: lock === null || lock === void 0 ? void 0 : lock.isLocked, children: [label && (_jsx(LabelWithDescription, { label: label, description: description })), _jsxs("div", { className: "".concat(Styled.prefix, "-content-container"), children: [description && _jsxs(Label, { size: "small", children: [" ", description.short, " "] }), error && (_jsx(SupportText, { className: "".concat(Styled.prefix, "-error"), color: "alert", children: error })), _jsxs("div", { className: "".concat(Styled.prefix, "-input-container"), children: [_jsx("span", { onBlur: function () { return setChildrenHasFocus(false); }, onFocus: function () { return setChildrenHasFocus(true); }, children: children }), _jsx("div", { className: "".concat(Styled.prefix, "-button-container"), children: renderLock() })] })] })] }));
};
