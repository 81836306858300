var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { usePlaycanvasApplicationContext } from "providers/playcanvas-application";
export var StyledCanvasOverlay = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var x = _a.x, y = _a.y;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t\t\tposition: absolute;\n\t\t\ttransform: translate(", "px, ", "px);\n\t\t\twidth: fit-content;\n\t\t\ttransition: 0.1s ease-out;\n\n\t\t\t/* Since the canvas overlay position isn't always at the same location as its children, we set pointer events to none to avoid\n\t\t\t * unpredictable hover events to trigger in incorrect locations */\n\t\t\tpointer-events: none;\n\n\t\t\tz-index: 1;\n\t\t"], ["\n\t\t\tposition: absolute;\n\t\t\ttransform: translate(", "px, ", "px);\n\t\t\twidth: fit-content;\n\t\t\ttransition: 0.1s ease-out;\n\n\t\t\t/* Since the canvas overlay position isn't always at the same location as its children, we set pointer events to none to avoid\n\t\t\t * unpredictable hover events to trigger in incorrect locations */\n\t\t\tpointer-events: none;\n\n\t\t\tz-index: 1;\n\t\t"])), x, y);
});
export var CanvasOverlay = function (_a) {
    var props = __rest(_a, []);
    var isCameraMoving = usePlaycanvasApplicationContext().isCameraMoving;
    if (isCameraMoving)
        return null;
    return _jsx(StyledCanvasOverlay, __assign({}, props));
};
var templateObject_1, templateObject_2;
