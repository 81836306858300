var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@emotion/styled";
import { css } from "@emotion/react";
export var prefix = "error-panel";
export var ErrorPanel = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tmargin: auto;\n\t\theight: 100%;\n\t\twidth: 90%;\n\t\tmax-width: 800px;\n\t\tbackground: ", ";\n\t\ttext-align: center;\n\n\t\t.", "-content {\n\t\t\tpadding: ", ";\n\t\t\tborder-radius: ", ";\n\t\t\tbackground: ", ";\n\t\t}\n\n\t\t.", "-message {\n\t\t\tmargin-bottom: ", ";\n\t\t\tfont-size: ", ";\n\t\t}\n\t"], ["\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tmargin: auto;\n\t\theight: 100%;\n\t\twidth: 90%;\n\t\tmax-width: 800px;\n\t\tbackground: ", ";\n\t\ttext-align: center;\n\n\t\t.", "-content {\n\t\t\tpadding: ", ";\n\t\t\tborder-radius: ", ";\n\t\t\tbackground: ", ";\n\t\t}\n\n\t\t.", "-message {\n\t\t\tmargin-bottom: ", ";\n\t\t\tfont-size: ", ";\n\t\t}\n\t"])), theme.colors.palette.black, prefix, theme.dimensions.margin.xLarge, theme.dimensions.borderRadius, theme.colors.palette.grey[800], prefix, theme.dimensions.margin.xLarge, theme.typography.fontSize.large);
});
var templateObject_1, templateObject_2;
