import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNumberConversion } from "./number-conversion";
import { useAppSelector, selectors } from "store";
import { round, Unit } from "utility/number";
export var SystemErrorKey;
(function (SystemErrorKey) {
    SystemErrorKey["CYCLE_TIME_NOT_REACHABLE"] = "system_errors.cycle_time_not_reachable";
    SystemErrorKey["PART_SECURED_NOT_REACHABLE"] = "system_errors.part_secured_level_not_reachable";
    SystemErrorKey["ENERGY_SAVING_NOT_REACHABLE"] = "system_errors.energy_saving_level_not_reachable";
    SystemErrorKey["FEED_PRESSURE_TOO_LOW"] = "system_errors.feed_pressure_too_low";
    SystemErrorKey["FEED_PRESSURE_TOO_HIGH"] = "system_errors.feed_pressure_too_high";
    SystemErrorKey["TUBING_MUCH_TOO_SMALL"] = "system_errors.tubing_much_too_small";
})(SystemErrorKey || (SystemErrorKey = {}));
// Provides the details that we send into a few of the messages
var feedPressureDetails = function (sanityCheckData, convertNumber) {
    var roundedFeedPressureActual = round(sanityCheckData.feedPressureActual, 2);
    var roundedFeedPressureRangeMin = round(sanityCheckData.feedPressureRangeMin, 2);
    var roundedFeedPressureRangeMax = round(sanityCheckData.feedPressureRangeMax, 2);
    return {
        feedPressureActual: convertNumber(roundedFeedPressureActual, Unit.MPA)
            .formatted,
        feedPressureRangeMin: convertNumber(roundedFeedPressureRangeMin, Unit.MPA)
            .formatted,
        feedPressureRangeMax: convertNumber(roundedFeedPressureRangeMax, Unit.MPA)
            .formatted,
    };
};
export var useSystemErrorMessages = function () {
    var sanityCheckData = useAppSelector(selectors.selectSanityCheckData);
    var isLoading = useAppSelector(selectors.selectIsSanityCheckLoading);
    var t = useTranslation().t;
    var convertNumber = useNumberConversion();
    var messages = useMemo(function () {
        var errors = [];
        if (sanityCheckData) {
            if (!sanityCheckData.cycleTimeReachable) {
                errors.push(t(SystemErrorKey.CYCLE_TIME_NOT_REACHABLE));
            }
            if (!sanityCheckData.partSecuredReachable) {
                errors.push(t(SystemErrorKey.PART_SECURED_NOT_REACHABLE));
            }
            if (sanityCheckData.energySaveAvailable &&
                !sanityCheckData.energySaveReachable) {
                errors.push(t(SystemErrorKey.ENERGY_SAVING_NOT_REACHABLE));
            }
            if (sanityCheckData.feedPressureMessage === "feedpressure_too_low") {
                errors.push(t(SystemErrorKey.FEED_PRESSURE_TOO_LOW, feedPressureDetails(sanityCheckData, convertNumber)));
            }
            if (sanityCheckData.feedPressureMessage === "feedpressure_too_high") {
                errors.push(t(SystemErrorKey.FEED_PRESSURE_TOO_HIGH, feedPressureDetails(sanityCheckData, convertNumber)));
            }
            if (sanityCheckData.freeRunningVacuumLevelMessage ===
                "tubing_much_too_small") {
                errors.push(t(SystemErrorKey.TUBING_MUCH_TOO_SMALL));
            }
        }
        return errors;
    }, [convertNumber, sanityCheckData, t]);
    return { messages: messages, isLoading: isLoading };
};
export var SystemWarningKey;
(function (SystemWarningKey) {
    SystemWarningKey["CALCULATIONS_CHANGED"] = "system_warnings.calculations_changed";
    SystemWarningKey["LIFTING_FORCE_NOT_ACHIEVED"] = "system_warnings.required_lifting_force_not_achieved";
})(SystemWarningKey || (SystemWarningKey = {}));
// TODO: All warnings should have a pre and post calculation message in the future.
var PrePostSystemWarningKeys = {
    preCalculation: {
        tubingTooLarge: "system_info.tubing_too_large.precalculate",
        tubingTooSmall: "system_info.tubing_too_small.precalculate",
        feedPressureBelowNormal: "system_warnings.feedpressure_below_normal_operating_range.precalculate",
        feedPressureAboveNormal: "system_warnings.feedpressure_above_normal_operating_range.precalculate",
        feedPressureDropTubingTooSmall: "system_warnings.feed_pressure_drop_tubing_too_small.precalculate",
        feedPressureDropTubingTooLarge: "system_warnings.feed_pressure_drop_tubing_too_large.precalculate",
    },
    postCalculation: {
        tubingTooLarge: "system_info.tubing_too_large.postcalculate",
        tubingTooSmall: "system_info.tubing_too_small.postcalculate",
        feedPressureBelowNormal: "system_warnings.feedpressure_below_normal_operating_range.postcalculate",
        feedPressureAboveNormal: "system_warnings.feedpressure_above_normal_operating_range.postcalculate",
        feedPressureDropTubingTooSmall: "system_warnings.feed_pressure_drop_tubing_too_small.postcalculate",
        feedPressureDropTubingTooLarge: "system_warnings.feed_pressure_drop_tubing_too_large.postcalculate",
    },
};
export var useSystemPerformanceValidityMessage = function () {
    var performanceData = useAppSelector(selectors.selectPerformanceData);
    var isValid = useAppSelector(selectors.selectIsPerformanceDataValid);
    var t = useTranslation().t;
    return performanceData && !isValid
        ? t(SystemWarningKey.CALCULATIONS_CHANGED)
        : null;
};
export var useSystemWarningMessages = function () {
    var performanceValidityMessage = useSystemPerformanceValidityMessage();
    var sanityCheckData = useAppSelector(selectors.selectSanityCheckData);
    var outputDefineParameters = useAppSelector(selectors.selectOutputDefineParameters);
    var isLoadingSanityCheck = useAppSelector(selectors.selectIsSanityCheckLoading);
    var isLoadingSystemData = useAppSelector(selectors.selectIsSystemDataLoading);
    var isPostCalculation = useAppSelector(selectors.selectIsPostCalculation);
    var t = useTranslation().t;
    var convertNumber = useNumberConversion();
    var messages = useMemo(function () {
        var warnings = [];
        if (performanceValidityMessage) {
            warnings.push(performanceValidityMessage);
        }
        if (sanityCheckData) {
            var keys = isPostCalculation
                ? PrePostSystemWarningKeys.postCalculation
                : PrePostSystemWarningKeys.preCalculation;
            if (sanityCheckData.freeRunningVacuumLevelMessage === "tubing_too_large") {
                warnings.push(t(keys.tubingTooLarge));
            }
            if (sanityCheckData.freeRunningVacuumLevelMessage === "tubing_too_small") {
                warnings.push(t(keys.tubingTooSmall));
            }
            if (sanityCheckData.feedPressureMessage ===
                "feedpressure_below_normal_operating_range") {
                warnings.push(t(keys.feedPressureBelowNormal, feedPressureDetails(sanityCheckData, convertNumber)));
            }
            if (sanityCheckData.feedPressureMessage ===
                "feedpressure_above_normal_operating_range") {
                warnings.push(t(keys.feedPressureAboveNormal, feedPressureDetails(sanityCheckData, convertNumber)));
            }
            if (sanityCheckData.feedPressureDropQuality === "tubing_too_small") {
                warnings.push(t(keys.feedPressureDropTubingTooSmall, {
                    feedPressureDrop: convertNumber(round(sanityCheckData.feedPressureDrop, 2), Unit.MPA).formatted,
                }));
            }
            if (sanityCheckData.feedPressureDropQuality === "tubing_too_large") {
                warnings.push(t(keys.feedPressureDropTubingTooLarge, {
                    feedPressureDrop: convertNumber(round(sanityCheckData.feedPressureDrop, 2), Unit.MPA).formatted,
                }));
            }
        }
        if (outputDefineParameters) {
            var horizontalForceOk = outputDefineParameters.totalLiftingForceHorizontal >=
                outputDefineParameters.requiredLiftingForceHorizontal;
            var verticalForceOk = outputDefineParameters.totalLiftingForceVertical >=
                outputDefineParameters.requiredLiftingForceVertical;
            if (!horizontalForceOk || !verticalForceOk) {
                warnings.push(t(SystemWarningKey.LIFTING_FORCE_NOT_ACHIEVED));
            }
        }
        return warnings;
    }, [
        performanceValidityMessage,
        sanityCheckData,
        outputDefineParameters,
        isPostCalculation,
        t,
        convertNumber,
    ]);
    return { messages: messages, isLoading: isLoadingSystemData || isLoadingSanityCheck };
};
export var SystemInfoKey;
(function (SystemInfoKey) {
    SystemInfoKey["FEED_PRESSURE_DROP_TUBING_CORRECTLY_SIZED"] = "system_info.feed_pressure_drop_tubing_correctly_sized";
})(SystemInfoKey || (SystemInfoKey = {}));
var SystemInfoKeys = {
    preCalculation: {
        tubingCorrectlySized: "system_info.tubing_correctly_sized.precalculate",
        feedPressureWithingNormalOperatingRange: "system_info.feed_pressure_within_normal_operating_range.precalculate",
    },
    postCalculation: {
        tubingCorrectlySized: "system_info.tubing_correctly_sized.postcalculate",
        feedPressureWithingNormalOperatingRange: "system_info.feed_pressure_within_normal_operating_range.postcalculate",
    },
};
export var useSystemInfoMessages = function () {
    var sanityCheckData = useAppSelector(selectors.selectSanityCheckData);
    var isLoading = useAppSelector(selectors.selectIsSanityCheckLoading);
    var isPostCalculation = useAppSelector(selectors.selectIsPostCalculation);
    var t = useTranslation().t;
    var convertNumber = useNumberConversion();
    var messages = useMemo(function () {
        var messages = [];
        if (sanityCheckData) {
            var infoKeys = isPostCalculation
                ? SystemInfoKeys.postCalculation
                : SystemInfoKeys.preCalculation;
            if (sanityCheckData.freeRunningVacuumLevelMessage ===
                "tubing_correctly_sized") {
                messages.push(t(infoKeys.tubingCorrectlySized));
            }
            if (sanityCheckData.feedPressureMessage ===
                "feedpressure_within_normal_operating_range") {
                messages.push(t(infoKeys.feedPressureWithingNormalOperatingRange, feedPressureDetails(sanityCheckData, convertNumber)));
            }
            if (sanityCheckData.feedPressureDropQuality === "tubing_correctly_sized") {
                messages.push(t(SystemInfoKey.FEED_PRESSURE_DROP_TUBING_CORRECTLY_SIZED, {
                    feedPressureDrop: convertNumber(round(sanityCheckData.feedPressureDrop, 2), Unit.MPA).formatted,
                }));
            }
        }
        return messages;
    }, [convertNumber, isPostCalculation, sanityCheckData, t]);
    return { messages: messages, isLoading: isLoading };
};
