var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { useAppDispatch, actions } from "store";
export var useAssetLoader = function (app) {
    var _a = __read(useState(false), 2), isLoading = _a[0], setIsLoading = _a[1];
    var dispatch = useAppDispatch();
    var loadAssets = useCallback(function (assets, callback) {
        setIsLoading(true);
        dispatch(actions.setIs3DLoading(true));
        Promise.all(assets.map(function (asset) {
            if (!asset)
                return;
            if (asset.loaded)
                return;
            return new Promise(function (resolve, reject) {
                asset.ready(resolve);
                asset.once("error", reject);
                app.assets.load(asset);
            });
        }))
            .then(function () {
            callback();
        })
            .catch(function (err) {
            Sentry.captureException(err);
        })
            .finally(function () {
            setIsLoading(false);
            dispatch(actions.setIs3DLoading(false));
        });
    }, [app.assets, dispatch]);
    return { loadAssets: loadAssets, isLoadingAssets: isLoading };
};
