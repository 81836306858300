import * as pc from "playcanvas";
var defaultScaleY = 0.01;
export var collisionComponentDefinitionsFromTapeModelID = {
    "TestObj-CardboardBox01TapeStart": function (scale) {
        var scaleX = scale.x, scaleZ = scale.z;
        var offsetZ = 0.005;
        if (scaleZ === -1) {
            offsetZ = -0.01;
        }
        return {
            halfExtents: new pc.Vec3(0.05 * scaleX, defaultScaleY, 0.005),
            linearOffset: new pc.Vec3(0, 0, offsetZ),
        };
    },
    "TestObj-CardboardBox01TapeMiddle": function (scale) { return ({
        halfExtents: new pc.Vec3(0.05 * scale.x, defaultScaleY, 0.5 * scale.z),
        linearOffset: new pc.Vec3(0, 0, -0.5 * scale.z),
    }); },
    "TestObj-CardboardBox01TapeEdgeCorner": function (scale) {
        var scaleX = scale.x, scaleZ = scale.z;
        var offsetX = -0.005;
        var offsetZ = 0.005;
        if (scaleX === -1 && scaleZ === 1) {
            offsetX = 0.01;
            offsetZ = 0.01;
        }
        else if (scaleX === 1 && scaleZ === -1) {
            offsetX = -0.01;
            offsetZ = -0.01;
        }
        return {
            halfExtents: new pc.Vec3(0.005, defaultScaleY, 0.005),
            linearOffset: new pc.Vec3(offsetX, 0, offsetZ),
        };
    },
    "TestObj-CardboardBox01TapeEdgeBottom": function (scale) {
        var scaleX = scale.x, scaleZ = scale.z;
        var offsetX = 0.5 * scaleX;
        var offsetZ = 0.005;
        if (scaleZ < 0) {
            offsetX *= 2;
            offsetZ *= -2;
        }
        return {
            halfExtents: new pc.Vec3(0.5 * scaleX, defaultScaleY, 0.005),
            linearOffset: new pc.Vec3(offsetX, 0, offsetZ),
        };
    },
    "TestObj-CardboardBox01TapeEdgeSide": function (scale) {
        var scaleX = scale.x, scaleZ = scale.z;
        if (scaleX < 0) {
            return {
                halfExtents: new pc.Vec3(0.005, defaultScaleY, 0.5 * scaleZ),
                linearOffset: new pc.Vec3(0.01, 0, -scaleZ),
            };
        }
        var q = new pc.Quat();
        q.setFromAxisAngle(new pc.Vec3(0, 1, 0), 90);
        return {
            halfExtents: new pc.Vec3(0.5 * scaleZ, defaultScaleY, 0.005),
            linearOffset: new pc.Vec3(-0.005, 0, -0.5 * scaleZ),
            angularOffset: q,
        };
    },
    "TestObj-CardboardBox01SeamMiddle": function (scale) { return ({
        halfExtents: new pc.Vec3(0.004, defaultScaleY, 0.5 * scale.z),
        linearOffset: new pc.Vec3(0.003, 0, -0.5 * scale.z),
    }); },
    "TestObj-CardboardBox01SeamTop": function (_scale) { return ({
        halfExtents: new pc.Vec3(0.004, defaultScaleY, 0.005),
        linearOffset: new pc.Vec3(0.003, 0, -0.005),
    }); },
    "TestObj-CardboardBox01SeamBottom": function (_scale) { return ({
        halfExtents: new pc.Vec3(0.004, defaultScaleY, 0.005),
        linearOffset: new pc.Vec3(0.003, 0, 0.005),
    }); },
};
export var addTapeCollisionComponent = function (_a) {
    var dimensions = _a.dimensions, part = _a.part;
    var entity = part.entity, scaleVec3 = part.scaleVec3, collisionComponentDefinition = part.collisionComponentDefinition;
    if (!collisionComponentDefinition)
        return;
    var scaleVector = scaleVec3 && scaleVec3(dimensions);
    var collisionComponentDefinitions = collisionComponentDefinition(scaleVector !== null && scaleVector !== void 0 ? scaleVector : new pc.Vec3(1, 1, 1));
    var halfExtents = collisionComponentDefinitions.halfExtents, linearOffset = collisionComponentDefinitions.linearOffset, angularOffset = collisionComponentDefinitions.angularOffset;
    entity.addComponent("collision", {
        type: "box",
        halfExtents: halfExtents,
        linearOffset: linearOffset,
        angularOffset: angularOffset !== null && angularOffset !== void 0 ? angularOffset : pc.Quat.IDENTITY,
    });
    entity.addComponent("rigidbody", {
        type: pc.RIGIDBODY_TYPE_STATIC,
        friction: 0,
        restitution: 0,
    });
};
