var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@emotion/styled";
import { css } from "@emotion/react";
export var ItalicText = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var Grid = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
export var GridMain = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
export var GridSide = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
export var EnergyUsageRoot = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme, isOpen = _a.isOpen;
    return css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\t\t\ttransition: all 0.3s ease-in-out;\n\t\t\tz-index: 0;\n\n\t\t\t", "\n\n\t\t\t", " {\n\t\t\t\tfont-style: italic;\n\t\t\t\tfont-weight: 200;\n\t\t\t\tfont-size: ", ";\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tdisplay: grid;\n\t\t\t\talign-items: stretch;\n\t\t\t\theight: 100%;\n\t\t\t\tgrid-template-columns: 85% auto;\n\t\t\t\tgrid-template-areas: \"main side\";\n\t\t\t}\n\t\t\t", ", ", " {\n\t\t\t\tdisplay: grid;\n\t\t\t\talign-items: center;\n\t\t\t}\n\t\t\t", " {\n\t\t\t\tgrid-area: main;\n\t\t\t\tgrid-template-rows: 20% 10% 25% 20% 25%;\n\t\t\t}\n\t\t\t", " {\n\t\t\t\tgrid-area: side;\n\t\t\t\tgrid-template-rows: 20% 20% auto;\n\t\t\t}\n\t\t"], ["\n\t\t\ttransition: all 0.3s ease-in-out;\n\t\t\tz-index: 0;\n\n\t\t\t", "\n\n\t\t\t", " {\n\t\t\t\tfont-style: italic;\n\t\t\t\tfont-weight: 200;\n\t\t\t\tfont-size: ", ";\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tdisplay: grid;\n\t\t\t\talign-items: stretch;\n\t\t\t\theight: 100%;\n\t\t\t\tgrid-template-columns: 85% auto;\n\t\t\t\tgrid-template-areas: \"main side\";\n\t\t\t}\n\t\t\t", ", ", " {\n\t\t\t\tdisplay: grid;\n\t\t\t\talign-items: center;\n\t\t\t}\n\t\t\t", " {\n\t\t\t\tgrid-area: main;\n\t\t\t\tgrid-template-rows: 20% 10% 25% 20% 25%;\n\t\t\t}\n\t\t\t", " {\n\t\t\t\tgrid-area: side;\n\t\t\t\tgrid-template-rows: 20% 20% auto;\n\t\t\t}\n\t\t"])), isOpen
        ? "\n\t\t\t\ttransform: translateY(0);\n\t\t\t\topacity: 1;\n\t\t\t"
        : "\n\t\t\t\ttransform: translateY(100%);\n\t\t\t\topacity: 0;\n\t\t\t", ItalicText, theme.typography.fontSize.xSmall, Grid, GridMain, GridSide, GridMain, GridSide);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
