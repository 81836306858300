var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b;
import { CameraViewId } from "3d/types";
export var defaultObjectCameraViews = (_a = {},
    _a[CameraViewId.INITIAL_3D_VIEW] = {
        x: 0,
        y: 4.8,
        z: 0,
        rotx: 40,
        roty: 19,
        distance: 3,
        minDistance: 0.75,
        maxDistance: 5,
        locked: false,
    },
    _a[CameraViewId.EDIT_VIEW] = {
        x: 0,
        y: 4.4,
        z: 0.15,
        rotx: 0.1,
        roty: 90,
        distance: 3,
        minDistance: 0.75,
        maxDistance: 5,
        locked: true,
    },
    _a[CameraViewId.NODE_VIEW] = {
        rotx: 0,
        roty: 70,
        distance: 0.25,
        minDistance: 0.25,
        maxDistance: 1,
        locked: false,
        useNodePosition: true,
    },
    _a[CameraViewId.NODE_OVERLAY_VIEW] = {
        rotx: 0.1,
        roty: 90,
        distance: 2,
        minDistance: 0.75,
        maxDistance: 5,
        locked: true,
        useNodePosition: true,
    },
    _a);
export var defaultAdjustableObjectCameraViews = __assign(__assign({}, defaultObjectCameraViews), (_b = {}, _b[CameraViewId.INITIAL_3D_VIEW] = __assign(__assign({}, defaultObjectCameraViews[CameraViewId.INITIAL_3D_VIEW]), { maxDistance: 10 }), _b[CameraViewId.EDIT_VIEW] = __assign(__assign({}, defaultObjectCameraViews[CameraViewId.EDIT_VIEW]), { z: 0.1, rotx: 0, maxDistance: 10 }), _b));
