import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { StyleProvider, createTheme } from "@animech/ui";
import { HashRouter } from "react-router-dom";
import { GlobalStyle } from "./global.style";
import { theme as piabTheme } from "theme";
import { ConfigIdProvider } from "providers/config-id";
import Routes from "ui/routes";
import { Auth0ProviderWithNavigate } from "auth0/auth0-provider-with-navigate";
var theme = createTheme(piabTheme);
export var App = function () {
    return (_jsxs(StyleProvider, { theme: theme, children: [_jsx(GlobalStyle, {}), _jsx(HashRouter, { children: _jsx(Auth0ProviderWithNavigate, { children: _jsx(ConfigIdProvider, { children: _jsx(Routes, {}) }) }) })] }));
};
