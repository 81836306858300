import { useCallback } from "react";
import { conversions, round, Unit, precision } from "utility/number";
import { UnitSystem } from "store/types";
import { useAppSelector, selectors } from "store";
var getReturnValue = function (value, unit, applyRounding) {
    var returnValue = applyRounding ? round(value, precision[unit]) : value;
    return {
        value: returnValue,
        formatted: "".concat(returnValue, " ").concat(unit),
        unit: unit,
    };
};
export var useNumberConversion = function () {
    var unitSystem = useAppSelector(selectors.selectUnitSystem);
    var convertNumber = useCallback(function (fromValue, fromUnit, applyRounding) {
        if (applyRounding === void 0) { applyRounding = true; }
        if (unitSystem === UnitSystem.METRIC) {
            return getReturnValue(fromValue, fromUnit, applyRounding);
        }
        var conversion = conversions[fromUnit];
        if (!conversion) {
            return getReturnValue(fromValue, fromUnit, applyRounding);
        }
        else {
            var convertedValue = conversion.convert(fromValue);
            return getReturnValue(convertedValue, conversion.unit, applyRounding);
        }
    }, [unitSystem]);
    return convertNumber;
};
export var useNumberConversionWithMillimeters = function () {
    var unitSystem = useAppSelector(selectors.selectUnitSystem);
    var defaultConversion = useNumberConversion();
    var convertNumber = useCallback(function (fromValue, fromUnit, applyRounding) {
        if (applyRounding === void 0) { applyRounding = true; }
        if (unitSystem === UnitSystem.METRIC) {
            if (fromUnit === Unit.M) {
                var convertedValue = fromValue * 1000;
                return getReturnValue(convertedValue, Unit.MM, applyRounding);
            }
            else if (fromUnit === Unit.MM) {
                var convertedValue = fromValue / 1000;
                return getReturnValue(convertedValue, Unit.M, applyRounding);
            }
            else {
                return getReturnValue(fromValue, fromUnit, applyRounding);
            }
        }
        else {
            return defaultConversion(fromValue, fromUnit, applyRounding);
        }
    }, [unitSystem, defaultConversion]);
    return convertNumber;
};
