import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { forwardRef } from "react";
import { TreeNavItem } from "@animech/ui";
import { ObjectParameterMenu } from "./object-parameter-menu";
import { SpecialGroupLayout } from "api/types";
import { SidebarDrawerButton } from "ui/components/elements/custom-drawer";
import { TitleWithInfoButton } from "ui/components/panels/sidebar/title-with-info-button";
import { DefineCardboardContainer } from "ui/components/modals/define-cardboard";
export var DefaultGroupMenu = forwardRef(function (props, ref) {
    var _a;
    var groups = props.parameterGroups || [];
    var sideBarWidth = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.clientWidth;
    return (_jsx(_Fragment, { children: groups.map(function (group) { return (_jsx(SidebarDrawerButton, { sidebarWidth: sideBarWidth ? sideBarWidth : 0, title: group.title, id: group.id, button: _jsx(TreeNavItem, { label: _jsx(TitleWithInfoButton, { title: group.title, description: group.helpText ? { long: group.helpText } : undefined, isVisible: props.activeMenuItem === group.id }), id: group.id }, 2), additionalRenderElement: group.id === "object_group" ? _jsx(ObjectParameterMenu, {}) : null, customRenderElement: group.specialGroupLayout === SpecialGroupLayout.CARDBOARD ? (_jsx(DefineCardboardContainer, {})) : null }, group.id)); }) }));
});
DefaultGroupMenu.displayName = "DefaultGroupMenu";
