var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { createSelector } from "@reduxjs/toolkit";
import { CameraViewId } from "3d/types";
export var selectUIRoot = function (state) { return state.ui; };
export var selectSelectedIds = function (state) {
    return selectUIRoot(state).selectedIds;
};
export var selectSelectedId = function (state) {
    return selectUIRoot(state).selectedIds[0];
};
export var selectCameraViewId = function (state) {
    return selectUIRoot(state).cameraViewId;
};
export var selectOverlayData = function (state) {
    return selectUIRoot(state).overlayData;
};
export var selectActiveMenuItem = function (state) {
    return selectUIRoot(state).activeMenuItem;
};
export var selectIs3DLoading = function (state) {
    return selectUIRoot(state).is3DLoading;
};
export var selectUnitSystem = function (state) {
    return selectUIRoot(state).unitSystem;
};
export var selectIsGraphPanelOpen = function (state) {
    return selectUIRoot(state).isGraphPanelOpen;
};
export var selectChecklist = function (state) {
    return selectUIRoot(state).checklist;
};
export var selectActiveSidebarItemID = function (state) {
    return selectUIRoot(state).activeSidebarItemID;
};
export var selectActiveContextMenuNodeId = function (state) {
    return selectUIRoot(state).activeContextMenuNodeId;
};
export var selectSortedChecklist = createSelector(selectChecklist, function (checklist) {
    return Object.entries(checklist)
        .filter(function (_a) {
        var _b = __read(_a, 2), _itemName = _b[0], item = _b[1];
        return !item.hidden;
    })
        .sort(function (a, b) {
        var checklistItemA = a[1];
        var checklistItemB = b[1];
        if (!checklistItemA.completed && checklistItemB.completed) {
            return 1;
        }
        if (checklistItemA.completed && !checklistItemB.completed) {
            return -1;
        }
        return 0;
    });
});
export var selectNextChecklistStepIndex = createSelector(selectSortedChecklist, function (sortedChecklist) {
    return sortedChecklist.findIndex(function (_a) {
        var _b = __read(_a, 2), _id = _b[0], item = _b[1];
        return !item.completed;
    });
});
export var selectIsZoomedIn = function (nodeId) {
    return createSelector(selectSelectedIds, selectCameraViewId, function (selectedIds, cameraViewId) {
        return selectedIds.includes(nodeId) && cameraViewId === CameraViewId.NODE_VIEW;
    });
};
export var selectIsMultiSelecting = createSelector(selectSelectedIds, function (selectedIds) { return selectedIds.length > 1; });
