var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useEffect, useMemo } from "react";
import * as pc from "playcanvas";
import { useAssetLoader } from "hooks/playcanvas/asset-loader";
import { useAppSelector, selectors } from "store";
import { objectPosition, objectShapes } from "3d/constants/common";
import { applyMaterialTransforms, getMaterialAssets, getMaterialTransformsForObjectShape, } from "3d/helpers";
var OBJECT_ENTITY_NAME = "Object";
export var ApplicationObject = function (_a) {
    var app = _a.app, modelAssets = _a.modelAssets, materialAssets = _a.materialAssets;
    var objectShapeId = useAppSelector(selectors.selectObjectShape);
    var objectSize = useAppSelector(selectors.selectObjectSize);
    var activeApplication = useAppSelector(selectors.selectActiveApplication);
    var useTexturedSurfaces = useAppSelector(selectors.selectUseTexturedSurfaces);
    var objectShape = objectShapes[objectShapeId];
    var loadAssets = useAssetLoader(app).loadAssets;
    var objectEntity = useMemo(function () { return new pc.Entity(OBJECT_ENTITY_NAME); }, []);
    useEffect(function () {
        objectEntity.setPosition(objectPosition.x, objectPosition.y, objectPosition.z);
        objectEntity.addComponent("model", {
            type: "asset",
        });
        app.root.addChild(objectEntity);
        return function () {
            objectEntity.destroy();
        };
    }, [app.root, materialAssets, objectEntity]);
    //Switch object when objectShapeId changes
    useEffect(function () {
        if (!activeApplication)
            return;
        var assetsToLoad = [];
        var objectAsset = modelAssets[objectShape.modelName];
        assetsToLoad.push(objectAsset);
        var materialTransforms = getMaterialTransformsForObjectShape(objectShape, useTexturedSurfaces);
        var assetMaterialTransforms = getMaterialAssets(materialAssets, materialTransforms);
        if (assetMaterialTransforms) {
            assetsToLoad.push.apply(assetsToLoad, __spreadArray([], __read(Object.values(assetMaterialTransforms)), false));
        }
        var updateObject = function () {
            if (objectEntity.model) {
                // TODO: Figure out why we need to set the model asset to 0 for some inexplicable reason.
                // Currently the materials on the object won't change when applying transforms if we don't do this first.
                objectEntity.model.asset = 0;
                objectEntity.model.asset = objectAsset;
                applyMaterialTransforms(objectEntity, assetMaterialTransforms);
            }
        };
        loadAssets(assetsToLoad, updateObject);
    }, [
        activeApplication,
        loadAssets,
        materialAssets,
        modelAssets,
        objectEntity,
        objectShape,
        objectShape.materialTransforms,
        objectShape.modelName,
        useTexturedSurfaces,
    ]);
    // Currently the object scale is (1,1,1) for all object models except for the adjustable box/cylinder.
    // So we always need to reset/update the scale on our object entity incase the previous/next model is one of the adjustable.
    useEffect(function () {
        if (objectEntity) {
            objectEntity.setLocalScale(objectSize.x, objectSize.y, objectSize.z);
        }
    }, [app.root, objectEntity, objectSize, objectShape]);
    return null;
};
