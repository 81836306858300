var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSelector } from "@reduxjs/toolkit";
import { PerformanceChartGroup, partSecuredColor, energySavingColor, atmColor, } from "./types";
import { systemDataConstants } from "api/system-data";
import { airConsumptionYAxisKey, liftingForcesYAxisHorizontalKey, liftingForcesYAxisVerticalKey, robotMotionYAxisKey, suctionCupIdPrefix, USE_LOCAL_GRAPH_DATA, vacuumPumpIdPrefix, xAxisKey, } from "ui/components/panels/graphs/constants";
import { getGoverningPump, getSuctionCups, getVacuumPumps, } from "ui/components/panels/graphs/common/helper-functions";
import { SystemPerformanceNodeType } from "api/types";
import { mockPerformanceGraphData } from "ui/components/panels/graphs/TEST-DATA/local-test-data";
import { Unit } from "utility/number";
export var selectPerformanceRoot = function (state) {
    return state.systemPerformance;
};
export var selectPerformanceData = function (state) {
    return selectPerformanceRoot(state).data;
};
export var selectPreloadedPerformance = function (state) {
    return selectPerformanceRoot(state).preloadedData;
};
export var selectPerformanceSystemDataSnapshot = function (state) {
    return selectPerformanceRoot(state).systemDataSnapshot;
};
export var selectIsPerformanceLoading = function (state) {
    return selectPerformanceRoot(state).isLoading;
};
export var selectPerformanceError = function (state) {
    return selectPerformanceRoot(state).error;
};
export var selectIsPerformanceDataValid = function (state) {
    return selectPerformanceRoot(state).isPerformanceDataValid;
};
export var selectShowEnergySave = function (state) {
    return selectPerformanceRoot(state).showEnergySavePerformance;
};
export var selectSanityCheckData = function (state) {
    return selectPerformanceRoot(state).sanityCheckData;
};
export var selectSanityCheckValid = function (state) {
    return selectPerformanceRoot(state).isSanityCheckValid;
};
export var selectIsSanityCheckLoading = function (state) {
    return selectPerformanceRoot(state).isSanityCheckLoading;
};
export var selectIsPerformanceDataPending = function (state) {
    return selectPerformanceRoot(state).isPerformanceDataPending;
};
export var selectIsEnergySaveAvailableForSystem = createSelector(selectPerformanceData, function (performanceData) {
    return (performanceData === null || performanceData === void 0 ? void 0 : performanceData.energySavingOn) !== undefined;
});
export var selectHasReachedEnergySave = createSelector(selectPerformanceData, function (performanceData) {
    return ((performanceData === null || performanceData === void 0 ? void 0 : performanceData.energySavingOn) &&
        performanceData.energySavingOn.systemTimeToEnergySave > 0);
});
export var selectActivePerformanceData = createSelector(selectPerformanceData, selectShowEnergySave, function (performanceData, showEnergySave) {
    if (!performanceData)
        return;
    if (showEnergySave && performanceData.energySavingOn) {
        return performanceData.energySavingOn;
    }
    else if (!showEnergySave) {
        return performanceData.energySavingOff;
    }
    else {
        return undefined;
    }
});
export var selectIsPostCalculation = createSelector(selectIsPerformanceDataValid, selectSanityCheckValid, function (isPerformanceDataValid, isSanityCheckValid) {
    if (isPerformanceDataValid && isSanityCheckValid) {
        return true;
    }
    else {
        return false;
    }
});
export var selectHasReachedPartSecured = createSelector(selectActivePerformanceData, function (activePerformanceData) {
    return activePerformanceData
        ? activePerformanceData.systemTimeToPartSecured > 0
        : false;
});
export var selectVacuumPumpPerformance = function (nodeId) {
    return createSelector(selectActivePerformanceData, function (activePerformanceData) { var _a; return (_a = activePerformanceData === null || activePerformanceData === void 0 ? void 0 : activePerformanceData.nodeData[nodeId]) === null || _a === void 0 ? void 0 : _a.vacuumPump; });
};
export var selectSuctionCupPerformance = function (nodeId) {
    return createSelector(selectActivePerformanceData, function (activePerformanceData) { var _a; return (_a = activePerformanceData === null || activePerformanceData === void 0 ? void 0 : activePerformanceData.nodeData[nodeId]) === null || _a === void 0 ? void 0 : _a.suctionCup; });
};
export var selectDashboardData = createSelector(selectActivePerformanceData, selectPerformanceSystemDataSnapshot, selectHasReachedPartSecured, function (activePerformanceData, systemDataSnapshot, hasReachedPartSecured) {
    if (!activePerformanceData || !systemDataSnapshot)
        return;
    if (!hasReachedPartSecured)
        return;
    var parameterNames = systemDataConstants.parameterNames;
    var getParameterValue = function (parameterName) { var _a; return Number((_a = systemDataSnapshot.parameters[parameterName]) === null || _a === void 0 ? void 0 : _a.value) || 0; };
    var timeToPartSecured = activePerformanceData.systemTimeToPartSecured;
    var timeToEnergySave = activePerformanceData.systemTimeToEnergySave > 0
        ? activePerformanceData.systemTimeToEnergySave
        : undefined;
    var targetPartsPerMinute = {
        value: getParameterValue(parameterNames.targetPartsPerMinute) || 1,
        translationKey: "dashboard.parts_per_minute",
    };
    return {
        applicationSpeed: __assign(__assign(__assign(__assign({ vacuumOnBeforePick: {
                id: "vacuum_on_before_pick",
                value: getParameterValue(parameterNames.vacuumOnBeforePick),
                translationKey: "dashboard.vacuum_on_before_pick",
                color: "#56CCF2",
                borderColor: "#56CCF2",
                parameterId: parameterNames.vacuumOnBeforePick,
                group: PerformanceChartGroup.BEFORE_PICK,
            }, blowOffOnBeforePick: {
                id: "blow_off_on_before_pick",
                value: getParameterValue(parameterNames.blowOffOnBeforePick),
                translationKey: "dashboard.blow_off_on_before_pick",
                color: "#F04E35",
                borderColor: "#F04E35",
                parameterId: parameterNames.blowOffOnBeforePick,
                group: PerformanceChartGroup.BEFORE_PICK,
            }, 
            /* PICK */
            timeToPartSecured: {
                id: "time_to_part_secured",
                value: timeToPartSecured,
                translationKey: "dashboard.time_to_part_secured",
                descriptionKey: "descriptions.dashboard.time_to_part_secured",
                color: "#2F80ED",
                borderColor: "#2F80ED",
                group: PerformanceChartGroup.BEFORE_PLACE,
            } }, (timeToEnergySave && {
            delayToEnergySave: {
                id: "delay_to_es",
                value: timeToEnergySave - timeToPartSecured,
                translationKey: "dashboard.delay_to_es",
                descriptionKey: "descriptions.dashboard.delay_to_es",
                color: "#C4C4C4",
                borderColor: "#2F80ED",
                group: PerformanceChartGroup.BEFORE_PLACE,
            },
        })), (timeToEnergySave && {
            timeWithEnergySavingOn: {
                id: "es_on_tooltip",
                value: activePerformanceData.transferTimeWithPart -
                    timeToEnergySave +
                    timeToPartSecured,
                translationKey: "dashboard.es_on_tooltip",
                descriptionKey: "descriptions.dashboard.es_on_tooltip",
                color: energySavingColor,
                borderColor: energySavingColor,
                group: PerformanceChartGroup.BEFORE_PLACE,
            },
        })), (!timeToEnergySave && {
            timeWithEnergySavingOff: {
                id: "es_off_tooltip",
                value: activePerformanceData.transferTimeWithPart,
                translationKey: "dashboard.es_off_tooltip",
                descriptionKey: "descriptions.dashboard.es_off_tooltip",
                color: "#F2C94C",
                borderColor: "#F2C94C",
                group: PerformanceChartGroup.BEFORE_PLACE,
            },
        })), { transferToPlace: {
                id: "transfer_to_place",
                value: activePerformanceData.transferTimeWithPart,
                translationKey: "dashboard.transfer_to_place",
                descriptionKey: "descriptions.dashboard.transfer_to_place",
                color: "#C4C4C4",
                borderColor: "#C4C4C4",
                group: PerformanceChartGroup.BEFORE_PLACE,
            }, 
            /* PLACE */
            releaseTimeToPlacePart: {
                id: "release_time_to_place_part",
                value: activePerformanceData.blowOffTimeToAtm,
                translationKey: "dashboard.release_time_to_place_part",
                descriptionKey: "descriptions.dashboard.release_time_to_place_part",
                color: "#FB7F7F",
                borderColor: "#FB7F7F",
                group: PerformanceChartGroup.AFTER_PLACE,
            }, additionalReleaseTime: {
                id: "additional_release_time",
                value: getParameterValue(parameterNames.blowOffClearanceTime),
                translationKey: "dashboard.additional_release_time",
                color: "#C71515",
                borderColor: "#C71515",
                parameterId: parameterNames.blowOffClearanceTime,
                group: PerformanceChartGroup.AFTER_PLACE,
            }, totalReleaseTime: {
                id: "total_release_time",
                value: activePerformanceData.blowOffTimeToAtm +
                    getParameterValue(parameterNames.blowOffClearanceTime),
                translationKey: "dashboard.total_release_time",
                descriptionKey: "descriptions.dashboard.total_release_time",
                color: "#FB7F7F",
                borderColor: "#C71515",
                group: PerformanceChartGroup.AFTER_PLACE,
            }, returnToPick: {
                id: "return_to_pick",
                value: activePerformanceData.transferTimeReturn,
                translationKey: "dashboard.return_to_pick",
                descriptionKey: "descriptions.dashboard.return_to_pick",
                color: "#848484",
                borderColor: "#848484",
                group: PerformanceChartGroup.AFTER_PLACE,
            } }),
        targetPartsPerMinute: {
            value: getParameterValue(parameterNames.targetPartsPerMinute) || 1,
            translationKey: "dashboard.parts_per_minute",
        },
        targetSecsPerCycle: {
            value: 60 / targetPartsPerMinute.value,
            translationKey: "dashboard.sec_per_cycle",
        },
        totalTransferTime: {
            value: activePerformanceData.transferTimeReturn +
                activePerformanceData.transferTimeWithPart,
            translationKey: "dashboard.total_time",
        },
        airConsumption: {
            perCycle: {
                translationKey: "dashboard.nl_per_cycle",
                value: activePerformanceData.airConsumptionTotal,
            },
            perMinute: {
                translationKey: "dashboard.nl_per_minute",
                value: activePerformanceData.airConsumptionTotal *
                    targetPartsPerMinute.value,
            },
        },
        energyUsage: {
            total: {
                translationKey: "dashboard.energy_usage_total",
                value: activePerformanceData.energyUsageTotal,
                unit: Unit.WH,
            },
            BO: {
                translationKey: "dashboard.energy_usage_bo",
                value: activePerformanceData.energyUsageBO,
                unit: Unit.WH,
            },
            vacuumGeneration: {
                translationKey: "dashboard.energy_usage_vacuum_generation",
                value: activePerformanceData.energyUsageVacuumGeneration,
                unit: Unit.WH,
            },
            perDay: {
                translationKey: "dashboard.energy_usage_per_day",
                value: activePerformanceData.energyUsage8hr,
                unit: Unit.KWH,
            },
            compressorPower: {
                translationKey: "dashboard.energy_usage_compressor_usage",
                value: activePerformanceData.averageCompressorPower,
                unit: Unit.W,
            },
        },
        desiredVacuumLevels: __assign({ partSecured: {
                id: "part_secured_level",
                value: getParameterValue(parameterNames.partSecuredLevel),
                translationKey: "dashboard.part_secured_level",
                descriptionKey: "descriptions.dashboard.time_to_part_secured",
                color: partSecuredColor,
                borderColor: "#FFFFFF",
                group: PerformanceChartGroup.VACUUM_LEVELS,
                parameterId: parameterNames.partSecuredLevel,
            } }, (timeToEnergySave && {
            energySaving: {
                id: "final_vacuum_level",
                value: getParameterValue(parameterNames.energySavingLevel),
                translationKey: "dashboard.final_vacuum_level",
                descriptionKey: "descriptions.dashboard.final_vacuum_level",
                color: energySavingColor,
                borderColor: "#FFFFFF",
                group: PerformanceChartGroup.VACUUM_LEVELS,
                parameterId: parameterNames.energySavingLevel,
            },
        })),
    };
});
export var selectGraphData = createSelector(selectActivePerformanceData, selectSanityCheckData, selectShowEnergySave, function (performanceData, sanityCheckData, showEnergySave) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var nodeData = (_a = performanceData === null || performanceData === void 0 ? void 0 : performanceData.nodeData) !== null && _a !== void 0 ? _a : {};
    var psIndex = (_b = performanceData === null || performanceData === void 0 ? void 0 : performanceData.psIndex) !== null && _b !== void 0 ? _b : 0;
    var esIndex = (_c = performanceData === null || performanceData === void 0 ? void 0 : performanceData.esIndex) !== null && _c !== void 0 ? _c : 0;
    var atmIndex = (_d = performanceData === null || performanceData === void 0 ? void 0 : performanceData.atmIndex) !== null && _d !== void 0 ? _d : 0;
    var timeToEnergySave = performanceData && performanceData.systemTimeToEnergySave > 0
        ? performanceData.systemTimeToEnergySave
        : undefined;
    var segmentLines = [
        {
            index: 0,
            translationKey: "graph_panel.pump_x_label_ATM",
            color: atmColor,
            placement: "center",
            invertedValue: true,
            value: 0,
            tooltipText: "dashboard.atm",
            descriptionKey: "descriptions.dashboard.release_time_to_place_part",
        },
        {
            index: psIndex,
            color: partSecuredColor,
            placement: "top",
            invertedValue: true,
            tooltipText: "dashboard.part_secured_level",
            descriptionKey: "descriptions.dashboard.time_to_part_secured",
        },
        {
            index: esIndex,
            placement: "bottom",
            color: energySavingColor,
            disabled: !showEnergySave || !timeToEnergySave,
            invertedValue: true,
            tooltipText: "dashboard.final_vacuum_level",
            descriptionKey: "descriptions.dashboard.final_vacuum_level",
        },
    ];
    return USE_LOCAL_GRAPH_DATA
        ? mockPerformanceGraphData
        : {
            systemProperty: {
                timeData: (_e = performanceData === null || performanceData === void 0 ? void 0 : performanceData.vacuumLevelTimeData) !== null && _e !== void 0 ? _e : [],
                airConsumptionVsTime: (_f = performanceData === null || performanceData === void 0 ? void 0 : performanceData.airConsumptionVsTime) !== null && _f !== void 0 ? _f : [],
                robotMotionVsTime: (_g = performanceData === null || performanceData === void 0 ? void 0 : performanceData.robotMotionVsTime) !== null && _g !== void 0 ? _g : [],
                psIndex: psIndex,
                esIndex: esIndex,
                atmIndex: atmIndex,
                placeStartIndex: (_h = performanceData === null || performanceData === void 0 ? void 0 : performanceData.placeStartIndex) !== null && _h !== void 0 ? _h : 0,
                airConsumptionTotal: (_j = performanceData === null || performanceData === void 0 ? void 0 : performanceData.airConsumptionTotal) !== null && _j !== void 0 ? _j : 0,
                liftingForceHorizontalVsTime: (_k = performanceData === null || performanceData === void 0 ? void 0 : performanceData.liftingForceHorizontalVsTime) !== null && _k !== void 0 ? _k : [],
                liftingForceVerticalVsTime: (_l = performanceData === null || performanceData === void 0 ? void 0 : performanceData.liftingForceVerticalVsTime) !== null && _l !== void 0 ? _l : [],
            },
            nodeData: nodeData,
            governingPump: getGoverningPump(nodeData, (_m = sanityCheckData === null || sanityCheckData === void 0 ? void 0 : sanityCheckData.governingPumpId) !== null && _m !== void 0 ? _m : 0),
            vacuumPumps: getVacuumPumps(nodeData),
            suctionCups: getSuctionCups(nodeData),
            segmentLines: segmentLines,
        };
});
// We need to parse the pure graph data from the calculation API to fit the expected data structure used by Recharts.
export var selectParsedGraphData = createSelector(selectGraphData, function (graphData) {
    var _a, _b, _c, _d;
    var vacuumPumps = graphData.vacuumPumps, suctionCups = graphData.suctionCups;
    var systemProperty = graphData.systemProperty;
    var robotMotionVsTime = systemProperty.robotMotionVsTime, airConsumptionVsTime = systemProperty.airConsumptionVsTime, timeData = systemProperty.timeData, psIndex = systemProperty.psIndex;
    if (!vacuumPumps.length)
        return;
    var robotMotionData = [];
    var airConsumptionData = [];
    var nodeData = [];
    var liftingForcesData = [];
    if (robotMotionVsTime.length !== airConsumptionVsTime.length ||
        airConsumptionVsTime.length !== timeData.length) {
        console.error("Arrays from API not the same length");
        return;
    }
    var initialDomain = [Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER];
    var vacuumLevelDomain = __spreadArray([], __read(initialDomain), false);
    var airConsumptionDomain = __spreadArray([], __read(initialDomain), false);
    var robotMotionDomain = __spreadArray([], __read(initialDomain), false);
    var liftingForcesDomain = __spreadArray([], __read(initialDomain), false);
    // Some of the very small values from the api contains (-e) notation, so we need to parse them as float first using toFixed(4).
    // Otherwise the values will be undefined when converted to Number
    var convertVacuumLevelToFixedNumber = function (vacuumLevel) {
        return Number(vacuumLevel.toFixed(4)) * -1;
    };
    var convertRobotMotionToFixedNumber = function (robotMotionValue) {
        return Number(robotMotionValue.toFixed(4));
    };
    // The unit we get from the API is NL/s, we convert it to minutes by multiplying by 60.
    var convertAirConsumptionToFixedNumber = function (airConsumptionValue) {
        return Number(airConsumptionValue.toFixed(4)) * 60;
    };
    var convertLiftingForceToFixedNumber = function (liftingForceValue) {
        return Number(liftingForceValue.toFixed(4));
    };
    var generateVacuumLevelsWithUniqueIds = function (nodes, i, nodeType, idPrefix) {
        return nodes.reduce(function (acc, node) {
            var prefixedNodeId = idPrefix + node.id;
            var vacuumLevels = node.performanceData.vacuumLevels;
            if (!vacuumLevels) {
                console.error("Missing vacuumlevels for ".concat(nodeType, "-ID: "), node.id);
                return acc;
            }
            var vacuumLevel = convertVacuumLevelToFixedNumber(vacuumLevels[i]);
            vacuumLevelDomain = [
                Math.min(vacuumLevelDomain[0], vacuumLevel),
                Math.max(vacuumLevelDomain[1], vacuumLevel),
            ];
            acc[prefixedNodeId] = vacuumLevel;
            return acc;
        }, {});
    };
    var generateLiftingForcesWithUniqueIds = function (nodes, i) {
        return nodes.reduce(function (acc, node) {
            var verticalForcePrefixedId = liftingForcesYAxisVerticalKey + "-" + node.id;
            var verticalForces = node.performanceData.liftingForceVertical;
            var horizontalForcePrefixedId = liftingForcesYAxisHorizontalKey + "-" + node.id;
            var horizontalForces = node.performanceData.liftingForceHorizontal;
            if (!verticalForces || !horizontalForces) {
                console.error("Missing lifting forces for: ", node.id);
                return acc;
            }
            var verticalForce = convertLiftingForceToFixedNumber(verticalForces[i]);
            var horizontalForce = convertLiftingForceToFixedNumber(horizontalForces[i]);
            liftingForcesDomain = [
                Math.min(liftingForcesDomain[0], Math.min(verticalForce, horizontalForce)),
                Math.max(liftingForcesDomain[1], Math.max(verticalForce, horizontalForce)),
            ];
            acc[verticalForcePrefixedId] = verticalForce;
            acc[horizontalForcePrefixedId] = horizontalForce;
            return acc;
        }, {});
    };
    for (var i = 0; i < timeData.length; i++) {
        var vacuumPumpsData = generateVacuumLevelsWithUniqueIds(vacuumPumps, i, SystemPerformanceNodeType.VACUUM_PUMP, vacuumPumpIdPrefix);
        var suctionCupsData = generateVacuumLevelsWithUniqueIds(suctionCups, i, SystemPerformanceNodeType.SUCTION_CUP, suctionCupIdPrefix);
        var robotMotion = convertRobotMotionToFixedNumber(robotMotionVsTime[i]);
        robotMotionDomain = [
            Math.min(robotMotionDomain[0], robotMotion),
            Math.max(robotMotionDomain[1], robotMotion),
        ];
        var airConsumption = convertAirConsumptionToFixedNumber(airConsumptionVsTime[i]);
        /* The first few milliseconds, air consumption will reach very high values.
         * we need to make sure that we don't calculate the values of the domain until after part secured index to avoid that.
         */
        if (i >= psIndex) {
            airConsumptionDomain = [
                Math.min(airConsumptionDomain[0], airConsumption),
                Math.max(airConsumptionDomain[1], airConsumption),
            ];
        }
        var liftForceDataWithUniqueIds = generateLiftingForcesWithUniqueIds(suctionCups, i);
        robotMotionData.push((_a = {},
            _a[xAxisKey] = timeData[i],
            _a[robotMotionYAxisKey] = robotMotion,
            _a));
        airConsumptionData.push((_b = {},
            _b[xAxisKey] = timeData[i],
            _b[airConsumptionYAxisKey] = airConsumption,
            _b));
        nodeData.push(__assign(__assign((_c = {}, _c[xAxisKey] = timeData[i], _c), vacuumPumpsData), suctionCupsData));
        liftingForcesData.push(__assign((_d = {}, _d[xAxisKey] = timeData[i], _d), liftForceDataWithUniqueIds));
    }
    return {
        nodeData: nodeData,
        airConsumptionData: airConsumptionData,
        robotMotionData: robotMotionData,
        vacuumLevelDomain: vacuumLevelDomain,
        airConsumptionDomain: airConsumptionDomain,
        robotMotionDomain: robotMotionDomain,
        liftingForcesDomain: liftingForcesDomain,
        liftingForcesData: liftingForcesData,
    };
});
