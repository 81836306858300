var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { ToolChangerTooltip } from "./tool-changer-tooltip";
import { FeedPressureTooltip } from "./feed-pressure-tooltip";
import { VacuumPumpTooltip } from "./pump-tooltip";
import { CupTooltip } from "./cup-tooltip";
import { NodeComponentType } from "store/types";
import { useAppSelector, selectors } from "store";
export var NodeTooltip = function (_a) {
    var node = _a.node, nodeCenterY = _a.nodeCenterY, nodeRightX = _a.nodeRightX, nodeLeftX = _a.nodeLeftX, isSelected = _a.isSelected, isMoving = _a.isMoving, hoveredNodeTooltipId = _a.hoveredNodeTooltipId, onTooltipHover = _a.onTooltipHover;
    var systemDataDefineParameters = useAppSelector(selectors.selectSystemDataDefineParameters);
    var isMultiSelecting = useAppSelector(selectors.selectIsMultiSelecting);
    var setHoveredTooltip = useCallback(function (isHovered) {
        if (!isHovered) {
            onTooltipHover(undefined);
            return;
        }
        onTooltipHover(node.id);
    }, [node.id, onTooltipHover]);
    // If any tooltip of another node is hovered, we fade the current tooltip.
    var shouldFadeTooltip = useMemo(function () {
        return (hoveredNodeTooltipId !== undefined && hoveredNodeTooltipId !== node.id);
    }, [hoveredNodeTooltipId, node.id]);
    if (!systemDataDefineParameters ||
        !isSelected ||
        isMoving ||
        isMultiSelecting)
        return null;
    var tooltips = __spreadArray(__spreadArray(__spreadArray([], [
        _jsx(CupTooltip, { node: node, x: nodeLeftX, y: nodeCenterY, onTooltipHover: setHoveredTooltip, shouldFadeTooltip: shouldFadeTooltip, align: "left" }, "cup"),
        _jsx(VacuumPumpTooltip, { node: node, x: nodeRightX, y: nodeCenterY, onTooltipHover: setHoveredTooltip, shouldFadeTooltip: shouldFadeTooltip, align: "right" }, "pump"),
    ], false), __read((node.type === NodeComponentType.FEED_PRESSURE_POINT
        ? [
            _jsx(FeedPressureTooltip, { x: nodeLeftX, y: nodeCenterY }, "feed-pressure"),
        ]
        : [])), false), __read((node.type === NodeComponentType.TUBE_CONNECTION
        ? [
            _jsx(ToolChangerTooltip, { node: node, x: nodeLeftX, y: nodeCenterY }, "tool-changer"),
        ]
        : [])), false).filter(Boolean);
    if (tooltips.length === 0)
        return null;
    return _jsx(_Fragment, { children: tooltips });
};
