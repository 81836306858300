var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import * as Styled from "./define-cardboard.style";
import { selectors, useAppSelector } from "store";
import { paperCoatingParameterValues, tapeEndsParameterValues, tapeParameterValues, } from "api/tacton/constants";
var paperCoatingFolderMap = (_a = {},
    _a[paperCoatingParameterValues.rawNatural] = "raw_natural",
    _a[paperCoatingParameterValues.rawColored] = "raw_colored",
    _a[paperCoatingParameterValues.coated] = "coated",
    _a[paperCoatingParameterValues.painted] = "painted",
    _a);
var withEnds = tapeEndsParameterValues.withTape;
var withoutEnds = tapeEndsParameterValues.withoutTape;
var tapeImageMap = (_b = {},
    _b[tapeParameterValues.noTape] = (_c = {},
        _c[withEnds] = "edge_no",
        _c[withoutEnds] = "no",
        _c),
    _b[tapeParameterValues.singleCenter] = (_d = {},
        _d[withEnds] = "edge_single_center",
        _d[withoutEnds] = "single_center",
        _d),
    _b[tapeParameterValues.doubleCenter] = (_e = {},
        _e[withEnds] = "edge_double_center",
        _e[withoutEnds] = "double_center",
        _e),
    _b[tapeParameterValues.left] = (_f = {},
        _f[withEnds] = "edge_left",
        _f[withoutEnds] = "left",
        _f),
    _b[tapeParameterValues.right] = (_g = {},
        _g[withEnds] = "edge_right",
        _g[withoutEnds] = "right",
        _g),
    _b[tapeParameterValues.doubleLeft] = (_h = {},
        _h[withEnds] = "edge_double_left",
        _h[withoutEnds] = "double_left",
        _h),
    _b[tapeParameterValues.doubleRight] = (_j = {},
        _j[withEnds] = "edge_double_right",
        _j[withoutEnds] = "double_right",
        _j),
    _b[tapeParameterValues.centerCenter] = (_k = {},
        _k[withEnds] = "edge_center_center",
        _k[withoutEnds] = "center_center",
        _k),
    _b);
export var CardboardWizardImage = function () {
    var paperCoating = useAppSelector(selectors.selectPaperCoatingValue);
    var tapeValue = useAppSelector(selectors.selectTapeParameter);
    var tapeEndsParameter = useAppSelector(selectors.selectTapeEndsParameter);
    if (!paperCoating || !tapeValue || !tapeEndsParameter)
        throw new Error("Paper coating, tape or tapeEnds value is undefined");
    var tapeEnds = tapeEndsParameter.value;
    var folderName = paperCoatingFolderMap[paperCoating];
    var imageName = tapeImageMap[tapeValue][tapeEnds];
    var imgSrc = "img/app/object-shapes/cardboard/".concat(folderName, "/").concat(imageName, ".webp");
    return (_jsx(Styled.ImageContainer, { children: _jsx(Styled.Image, { src: imgSrc }) }));
};
