var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@emotion/styled";
export var Logo = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
export var GroupContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
export var ParametersPage = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\tdisplay: flex;\n\tflex-direction: column;\n\theight: 100%;\n\twidth: 100%;\n\toverflow: scroll;\n\tbackground: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),\n\t\turl(\"img/app/intro-background.jpg\");\n\tbackground-size: cover;\n\n\t", " {\n\t\tposition: relative;\n\t\ttop: 6px;\n\t\tleft: 63px;\n\t\tmargin-left: -16px;\n\t\talign-self: flex-start;\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\t\tgap: 15px;\n\t}\n\n\t", " {\n\t\tposition: relative;\n\t\tmargin: auto;\n\t\tmin-width: 900px;\n\t\twidth: fit-content;\n\n\t\t", " {\n\t\t\tposition: relative;\n\t\t}\n\t}\n"], ["\n\tdisplay: flex;\n\tflex-direction: column;\n\theight: 100%;\n\twidth: 100%;\n\toverflow: scroll;\n\tbackground: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),\n\t\turl(\"img/app/intro-background.jpg\");\n\tbackground-size: cover;\n\n\t", " {\n\t\tposition: relative;\n\t\ttop: 6px;\n\t\tleft: 63px;\n\t\tmargin-left: -16px;\n\t\talign-self: flex-start;\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\t\tgap: 15px;\n\t}\n\n\t", " {\n\t\tposition: relative;\n\t\tmargin: auto;\n\t\tmin-width: 900px;\n\t\twidth: fit-content;\n\n\t\t", " {\n\t\t\tposition: relative;\n\t\t}\n\t}\n"])), Logo, Content, GroupContainer);
export var SelectApplicationPage = styled(ParametersPage)(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
