var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useEffect } from "react";
/**  If you use a container with flex-direction: column-reverse.
 * The scroll will automatically initiate at the bottom of the container.
 * With this hook we can set the scroll to the top of the container on mount.
 * @param containerRef a React.RefObject referencing the container.
 */
export var useScrollToTopOfFlexReversedContainer = function (containerRef, deps) {
    if (deps === void 0) { deps = []; }
    useEffect(function () {
        var container = containerRef.current;
        if (!container)
            return;
        container.scrollTop = -container.scrollHeight;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, __spreadArray([containerRef], __read(deps), false));
};
