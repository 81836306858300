//eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useDispatch, useSelector } from "react-redux";
import { configureStore, combineReducers, } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import { persistConfig as persistConfigDefineParameters, persistKey as persistKeyDefineParameters, } from "./system-data/define-parameters/persist-config";
import { persistConfig as persistConfigSelectApplication, persistKey as persistKeySelectApplication, } from "./system-data/select-application/persist-config";
import { listenerMiddleware } from "./listener-middleware/listener-middleware";
import systemDataReducerDefineParameters from "store/system-data/define-parameters/reducer";
import { getURLParameter, clearURLParameter } from "utility/url";
import sceneReducer from "store/scene/reducer";
import systemDataReducerSelectApplication from "store/system-data/select-application/reducer";
import systemPerformanceReducer from "store/system-performance/reducer";
import { persistConfig as scenePersistConfig, persistKey as scenePersistKey, } from "store/scene/persist-config";
import { persistConfig as uiPersistConfig, persistKey as uiPersistKey, } from "store/ui/persist-config";
import uiReducer from "store/ui/reducer";
export var rootReducer = combineReducers({
    scene: sceneReducer,
    systemDataDefineParameters: systemDataReducerDefineParameters,
    systemDataSelectApplication: systemDataReducerSelectApplication,
    ui: uiReducer,
    systemPerformance: systemPerformanceReducer,
});
export var persistedRootReducer = combineReducers({
    scene: persistReducer(scenePersistConfig, sceneReducer),
    systemDataDefineParameters: persistReducer(persistConfigDefineParameters, systemDataReducerDefineParameters),
    systemDataSelectApplication: persistReducer(persistConfigSelectApplication, systemDataReducerSelectApplication),
    ui: persistReducer(uiPersistConfig, uiReducer),
    systemPerformance: systemPerformanceReducer,
});
var storeURLParameter = function (persistKey) {
    var value = getURLParameter(persistKey);
    if (value) {
        window.sessionStorage.setItem("persist:".concat(persistKey), value);
        clearURLParameter(persistKey);
    }
};
export var getStore = function (configId, ticket, computationApiBaseUrl, preloadedState) {
    storeURLParameter(scenePersistKey);
    storeURLParameter(persistKeyDefineParameters);
    storeURLParameter(persistKeySelectApplication);
    storeURLParameter(uiPersistKey);
    var store = configureStore({
        reducer: persistReducer({ key: "root", storage: storage, whitelist: [] }, persistedRootReducer),
        middleware: function (getDefaultMiddleware) {
            return getDefaultMiddleware({
                serializableCheck: false,
                thunk: { extraArgument: { configId: configId, ticket: ticket, computationApiBaseUrl: computationApiBaseUrl } },
            }).prepend(listenerMiddleware.middleware);
        },
        preloadedState: preloadedState,
    });
    var persistor = persistStore(store);
    return { store: store, persistor: persistor };
};
var store = getStore("tmp").store;
export var useAppDispatch = useDispatch;
export var useAppSelector = useSelector;
