var _a, _b;
import { Unit } from "utility/number";
export var MenuItem;
(function (MenuItem) {
    MenuItem["SELECTED_ENTITY"] = "selected_entity";
    MenuItem["APPLICATION_SPEED"] = "application_speed";
})(MenuItem || (MenuItem = {}));
export var OverlayData;
(function (OverlayData) {
    OverlayData["PERFORMANCE_DATA"] = "performance_data";
    OverlayData["VACUUM_LEVEL_PS"] = "vacuum_level_ps";
    OverlayData["VACUUM_LEVEL_ES"] = "vacuum_level_es";
})(OverlayData || (OverlayData = {}));
export var UnitSystem;
(function (UnitSystem) {
    UnitSystem["METRIC"] = "metric";
    UnitSystem["IMPERIAL"] = "imperial";
})(UnitSystem || (UnitSystem = {}));
export var tubeDiameterOptionInitialIndex = (_a = {},
    _a[UnitSystem.METRIC] = {
        centralized: 14,
        decentralized: 14,
    },
    _a[UnitSystem.IMPERIAL] = {
        centralized: 8,
        decentralized: 10,
    },
    _a);
export var tubeDiameterOptions = (_b = {},
    _b[UnitSystem.METRIC] = [
        {
            outerLabel: "16",
            innerLabel: "13",
            value: 0.013,
            outerValue: 0.016,
            unit: Unit.MM,
        },
        {
            outerLabel: "16",
            innerLabel: "11",
            value: 0.011,
            outerValue: 0.016,
            unit: Unit.MM,
        },
        {
            outerLabel: "14",
            innerLabel: "12",
            value: 0.012,
            outerValue: 0.014,
            unit: Unit.MM,
        },
        {
            outerLabel: "14",
            innerLabel: "11",
            value: 0.011,
            outerValue: 0.014,
            unit: Unit.MM,
        },
        {
            outerLabel: "14",
            innerLabel: "9.5",
            value: 0.0095,
            outerValue: 0.014,
            unit: Unit.MM,
        },
        {
            outerLabel: "12",
            innerLabel: "10",
            value: 0.01,
            outerValue: 0.012,
            unit: Unit.MM,
        },
        {
            outerLabel: "12",
            innerLabel: "9.5",
            value: 0.0095,
            outerValue: 0.012,
            unit: Unit.MM,
        },
        {
            outerLabel: "12",
            innerLabel: "9",
            value: 0.009,
            outerValue: 0.012,
            unit: Unit.MM,
        },
        {
            outerLabel: "12",
            innerLabel: "8",
            value: 0.008,
            outerValue: 0.012,
            unit: Unit.MM,
        },
        {
            outerLabel: "10",
            innerLabel: "8",
            value: 0.008,
            outerValue: 0.01,
            unit: Unit.MM,
        },
        {
            outerLabel: "10",
            innerLabel: "7.5",
            value: 0.0075,
            outerValue: 0.01,
            unit: Unit.MM,
        },
        {
            outerLabel: "10",
            innerLabel: "7",
            value: 0.007,
            outerValue: 0.01,
            unit: Unit.MM,
        },
        {
            outerLabel: "10",
            innerLabel: "6.5",
            value: 0.0065,
            outerValue: 0.01,
            unit: Unit.MM,
        },
        {
            outerLabel: "8",
            innerLabel: "6",
            value: 0.006,
            outerValue: 0.008,
            unit: Unit.MM,
        },
        {
            outerLabel: "8",
            innerLabel: "5.5",
            value: 0.0055,
            outerValue: 0.008,
            unit: Unit.MM,
        }, // Default Central
        {
            outerLabel: "6",
            innerLabel: "4",
            value: 0.004,
            outerValue: 0.006,
            unit: Unit.MM,
        }, // Default Decentral
        {
            outerLabel: "4",
            innerLabel: "2.7",
            value: 0.0027,
            outerValue: 0.004,
            unit: Unit.MM,
        },
        {
            outerLabel: "4",
            innerLabel: "2.5",
            value: 0.0025,
            outerValue: 0.004,
            unit: Unit.MM,
        },
        {
            outerLabel: "4",
            innerLabel: "2",
            value: 0.002,
            outerValue: 0.004,
            unit: Unit.MM,
        },
    ],
    _b[UnitSystem.IMPERIAL] = [
        {
            outerLabel: '3/4"',
            innerLabel: "0.566",
            value: 0.0143764,
            outerValue: 0.0191,
            unit: Unit.IN,
        },
        {
            outerLabel: '3/4"',
            innerLabel: "0.500",
            value: 0.0127,
            outerValue: 0.0191,
            unit: Unit.IN,
        },
        {
            outerLabel: '1/2"',
            innerLabel: "0.375",
            value: 0.009525,
            outerValue: 0.0127,
            unit: Unit.IN,
        },
        {
            outerLabel: '1/2"',
            innerLabel: "0.320",
            value: 0.008128,
            outerValue: 0.0127,
            unit: Unit.IN,
        },
        {
            outerLabel: '3/8"',
            innerLabel: "0.275",
            value: 0.006985,
            outerValue: 0.009525,
            unit: Unit.IN,
        },
        {
            outerLabel: '3/8"',
            innerLabel: "0.250",
            value: 0.00635,
            outerValue: 0.009525,
            unit: Unit.IN,
        },
        {
            outerLabel: '5/16"',
            innerLabel: "0.236",
            value: 0.0059944,
            outerValue: 0.007938,
            unit: Unit.IN,
        },
        {
            outerLabel: '5/16"',
            innerLabel: "0.233",
            value: 0.0059182,
            outerValue: 0.007938,
            unit: Unit.IN,
        },
        {
            outerLabel: '5/16"',
            innerLabel: "0.217",
            value: 0.0055118,
            outerValue: 0.007938,
            unit: Unit.IN,
        }, // Default Central
        {
            outerLabel: '1/4"',
            innerLabel: "0.180",
            value: 0.004572,
            outerValue: 0.00635,
            unit: Unit.IN,
        },
        {
            outerLabel: '1/4"',
            innerLabel: "0.170",
            value: 0.004318,
            outerValue: 0.00635,
            unit: Unit.IN,
        }, // Default Decentral
        {
            outerLabel: '1/4"',
            innerLabel: "0.127",
            value: 0.0032258,
            outerValue: 0.00635,
            unit: Unit.IN,
        },
        {
            outerLabel: '1/4"',
            innerLabel: "0.125",
            value: 0.003175,
            outerValue: 0.00635,
            unit: Unit.IN,
        },
        {
            outerLabel: '5/32"',
            innerLabel: "0.106",
            value: 0.0026924,
            outerValue: 0.003969,
            unit: Unit.IN,
        },
        {
            outerLabel: '5/32"',
            innerLabel: "0.094",
            value: 0.0023876,
            outerValue: 0.003969,
            unit: Unit.IN,
        },
    ],
    _b);
export var ChecklistItemName;
(function (ChecklistItemName) {
    ChecklistItemName["ADD_GRIP_POSITION"] = "add_grip_position";
    ChecklistItemName["SELECT_CUP"] = "select_cup";
    ChecklistItemName["ADD_PUMP"] = "add_pump";
    ChecklistItemName["ADD_TUBE_CONNECTION"] = "add_tube_connection";
    ChecklistItemName["CONNECT_PARTS"] = "connect_parts";
    ChecklistItemName["CALCULATE"] = "calculate";
})(ChecklistItemName || (ChecklistItemName = {}));
export var MIXED_VALUES_CONSTANT = "MIXED_VALUES_CONSTANT";
