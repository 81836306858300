import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Button } from "@animech/ui";
import * as Styled from "ui/components/panels/sidebar/sidebar.style";
import { Unit } from "utility/number";
import { ConvertedNumberInput } from "ui/components/elements/input/converted-number-input";
import { MIXED_VALUES_CONSTANT, TubeType } from "store/types";
import { useAppDispatch, actions, useAppSelector, selectors } from "store";
import { StepperInput } from "ui/components/elements/input/stepper-input";
import { selectIsMultiSelecting } from "store/ui/selectors";
export var EdgeMenu = function (_a) {
    var _b;
    var edges = _a.edges;
    var t = useTranslation().t;
    var dispatch = useAppDispatch();
    var useDoubleCompressedAirTubes = useAppSelector(selectors.selectUseDoubleCompressedAirTubes);
    var isMultiSelecting = useAppSelector(selectIsMultiSelecting);
    var onDelete = function () {
        var _a;
        if (isMultiSelecting)
            return;
        dispatch(actions.deleteEdge((_a = edges[0]) === null || _a === void 0 ? void 0 : _a.id));
    };
    var onChangeLength = function (length) {
        dispatch(actions.setEdgeLength({
            edgeIds: edges.map(function (edge) { return edge.id; }),
            length: length,
            isLocked: true,
        }));
    };
    var onChangeDefaultDiameter = function (diameter, index) {
        dispatch(actions.setEdgeDiameterDefaultTube({
            edgeIds: edges.map(function (edge) { return edge.id; }),
            diameter: diameter,
            index: index,
        }));
    };
    var onChangeBlowOffDiameter = function (diameter, index) {
        dispatch(actions.setEdgeDiameterBlowOffTube({
            edgeIds: edges.map(function (edge) { return edge.id; }),
            diameter: diameter,
            index: index,
        }));
    };
    var setTubeLengthsLocked = function (isLocked) {
        dispatch(actions.setEdgeLengthLocked({
            edgeIds: edges.map(function (edge) { return edge.id; }),
            isLocked: isLocked,
        }));
    };
    var isDoubleTube = edges.every(function (edge) {
        return edge.tubeType === TubeType.COMPRESSED_AIR && useDoubleCompressedAirTubes;
    });
    var lengthValuesAreMixed = edges.some(function (edge) { return edge.length !== edges[0].length; });
    return (_jsxs(_Fragment, { children: [_jsx(Styled.SidebarSection, { children: _jsx(StepperInput, { label: t(isDoubleTube
                        ? "sidebar.edit_tube.tube_diameter_double_ejector"
                        : "sidebar.edit_tube.tube_diameter"), diameterIndexes: edges.map(function (edge) { return edge.defaultDiameterIndex; }), onValueChange: onChangeDefaultDiameter }) }), isDoubleTube && (_jsx(Styled.SidebarSection, { children: _jsx(StepperInput, { label: t("sidebar.edit_tube.tube_diameter_double_blow_off"), diameterIndexes: edges.map(function (edge) { return edge.blowOffDiameterIndex; }), onValueChange: onChangeBlowOffDiameter }) })), _jsx(Styled.SidebarSection, { children: _jsx(ConvertedNumberInput, { value: (_b = edges[0]) === null || _b === void 0 ? void 0 : _b.length, label: t("sidebar.edit_tube.tube_length"), setValue: onChangeLength, type: "float", min: 0, max: 20, unit: Unit.M, lock: {
                        isLocked: edges.some(function (edge) { return edge.lengthLocked === true; }),
                        onLock: function () { return setTubeLengthsLocked(true); },
                        onUnlock: function () { return setTubeLengthsLocked(false); },
                    }, displayValue: lengthValuesAreMixed ? t(MIXED_VALUES_CONSTANT) : undefined }) }), !isMultiSelecting && (_jsx(_Fragment, { children: _jsx(Styled.SidebarSection, { children: _jsx(Button, { appearance: "ghost", color: "alert", onClick: onDelete, children: t("sidebar.edit_tube.delete_button") }) }) }))] }));
};
