/** ---------------   **/
/**    FXI Family    **/
/** ---------------   **/
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Material } from "3d/types";
var cupModelTemplate = {
    MX25: {
        cupModelName: "MX25",
        cupWidth: 0.027,
        cupHeight: 0.025,
        fittingModelName: "G38M01-S7",
    },
    MX50: {
        cupModelName: "MX50",
        cupWidth: 0.05,
        cupHeight: 0.0297,
        fittingModelName: "G38M01-S7",
    },
    MX35_B3: {
        cupModelName: "MX35-B3",
        cupWidth: 0.035,
        cupHeight: 0.0473,
        fittingModelName: "G38M01-S7",
    },
    MX50_B3: {
        cupModelName: "MX50-B3",
        cupWidth: 0.05,
        cupHeight: 0.0604,
        fittingModelName: "G38M01-S7",
    },
};
var materialTransformTemplate = {
    DURAFLEX_BLUE_PU30: {
        materialTransforms: [
            {
                sourceMaterial: Material.MA_MAIN,
                targetMaterial: Material.DURAFLEX_BLUE,
            },
            {
                sourceMaterial: Material.MA_MAIN2,
                targetMaterial: Material.PU30,
            },
        ],
    },
    DURAFLEX_BLUE_WHITE_PU30: {
        materialTransforms: [
            {
                sourceMaterial: Material.MA_MAIN,
                targetMaterial: Material.DURAFLEX_BLUE,
            },
            {
                sourceMaterial: Material.MA_MAIN2,
                targetMaterial: Material.PU30,
            },
            {
                sourceMaterial: Material.MA_MAIN3,
                targetMaterial: Material.DURAFLEX_WHITE,
            },
        ],
    },
};
export var suctionCupsFamilyMX = {
    // MX25
    "suction_cup_S.MX25P3060.G18M.01": __assign(__assign({}, cupModelTemplate.MX25), materialTransformTemplate.DURAFLEX_BLUE_PU30),
    // MX35
    "suction_cup_S.MX35P3060.G38M.00": __assign(__assign({}, cupModelTemplate.MX50), materialTransformTemplate.DURAFLEX_BLUE_WHITE_PU30),
    // MX42
    "suction_cup_S.MX42P3060.G38M.00": __assign(__assign({}, cupModelTemplate.MX50), materialTransformTemplate.DURAFLEX_BLUE_WHITE_PU30),
    // MX50
    "suction_cup_S.MX50P3060.G38M.00": __assign(__assign({}, cupModelTemplate.MX50), materialTransformTemplate.DURAFLEX_BLUE_WHITE_PU30),
    // MX57
    "suction_cup_S.MX57P3060.G38M.00": __assign(__assign({}, cupModelTemplate.MX50), materialTransformTemplate.DURAFLEX_BLUE_WHITE_PU30),
    // MX65
    "suction_cup_S.MX65P3060.G38M.00": __assign(__assign({}, cupModelTemplate.MX50), materialTransformTemplate.DURAFLEX_BLUE_WHITE_PU30),
    // MX35-B3
    "suction_cup_S.MX35P3060.B3.G38M.00": __assign(__assign({}, cupModelTemplate.MX35_B3), materialTransformTemplate.DURAFLEX_BLUE_WHITE_PU30),
    // MX42-B3
    "suction_cup_S.MX42P3060.B3.G38M.00": __assign(__assign({}, cupModelTemplate.MX50_B3), materialTransformTemplate.DURAFLEX_BLUE_WHITE_PU30),
    // MX50-B3
    "suction_cup_S.MX50P3060.B3.G38M.00": __assign(__assign({}, cupModelTemplate.MX50_B3), materialTransformTemplate.DURAFLEX_BLUE_WHITE_PU30),
    // MX57-B3
    "suction_cup_S.MX57P3060.B3.G38M.00": __assign(__assign({}, cupModelTemplate.MX50_B3), materialTransformTemplate.DURAFLEX_BLUE_WHITE_PU30),
    // MX65-B3
    "suction_cup_S.MX65P3060.B3.G38M.00": __assign(__assign({}, cupModelTemplate.MX50_B3), materialTransformTemplate.DURAFLEX_BLUE_WHITE_PU30),
};
