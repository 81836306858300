var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable camelcase */
import { Material } from "3d/types";
/** ---------------   **/
/**     FC Family     **/
/** ---------------   **/
var cupModelTemplate = {
    FC25P: {
        cupModelName: "FC25P",
        cupWidth: 0.0285,
        cupHeight: 0.016,
        fittingModelName: "G18M-S2",
    },
    FC75P: {
        cupModelName: "FC75P",
        cupWidth: 0.075,
        cupHeight: 0.024,
        fittingModelName: "G38M01-S6",
    },
    FC150P: {
        cupModelName: "FC150P",
        cupWidth: 0.15,
        cupHeight: 0.0565,
        fittingModelName: "G12F01-S1",
    },
};
var materialTransformTemplate = {
    PU60: {
        materialTransforms: [
            { sourceMaterial: Material.MA_MAIN, targetMaterial: Material.PU60 },
        ],
    },
    PU50: {
        materialTransforms: [
            { sourceMaterial: Material.MA_MAIN, targetMaterial: Material.PU50 },
        ],
    },
};
export var suctionCupsFamilyFC = {
    // FC20P
    suction_cup_0106722: __assign(__assign({}, cupModelTemplate.FC25P), materialTransformTemplate.PU50),
    // FC25P
    suction_cup_0106721: __assign(__assign({}, cupModelTemplate.FC25P), materialTransformTemplate.PU50),
    // FC35P
    suction_cup_0103720: __assign(__assign({}, cupModelTemplate.FC25P), materialTransformTemplate.PU60),
    // FC50P
    suction_cup_0103293: __assign(__assign({}, cupModelTemplate.FC75P), materialTransformTemplate.PU60),
    // FC75P
    suction_cup_0107303: __assign(__assign({}, cupModelTemplate.FC75P), materialTransformTemplate.PU60),
    // FC100P
    suction_cup_0107305: __assign(__assign({}, cupModelTemplate.FC75P), materialTransformTemplate.PU60),
    // FC150P
    suction_cup_0103757: __assign(__assign({}, cupModelTemplate.FC150P), materialTransformTemplate.PU60),
};
