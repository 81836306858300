var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@emotion/styled";
import { css } from "@emotion/react";
export var prefix = "application-speed-box";
export var ApplicationSpeedBox = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t\t\tmin-width: 700px;\n\t\t\tflex-grow: 1;\n\t\t\tmargin-left: ", ";\n\t\t\tz-index: 2;\n\n\t\t\t.", "-content {\n\t\t\t\tdisplay: flex;\n\t\t\t}\n\n\t\t\t.", "-values-container {\n\t\t\t\tdisplay: flex;\n\t\t\t\tjustify-content: flex-end;\n\t\t\t\tmargin: 10px auto;\n\t\t\t\tgap: 2em;\n\t\t\t\tmax-width: 550px;\n\n\t\t\t\t.", "-values-section, .", "-values-section-with-gap {\n\t\t\t\t\tdisplay: flex;\n\t\t\t\t}\n\t\t\t\t.", "-values-section-with-gap {\n\t\t\t\t\tgap: 1em;\n\t\t\t\t}\n\t\t\t}\n\t\t"], ["\n\t\t\tmin-width: 700px;\n\t\t\tflex-grow: 1;\n\t\t\tmargin-left: ", ";\n\t\t\tz-index: 2;\n\n\t\t\t.", "-content {\n\t\t\t\tdisplay: flex;\n\t\t\t}\n\n\t\t\t.", "-values-container {\n\t\t\t\tdisplay: flex;\n\t\t\t\tjustify-content: flex-end;\n\t\t\t\tmargin: 10px auto;\n\t\t\t\tgap: 2em;\n\t\t\t\tmax-width: 550px;\n\n\t\t\t\t.", "-values-section, .", "-values-section-with-gap {\n\t\t\t\t\tdisplay: flex;\n\t\t\t\t}\n\t\t\t\t.", "-values-section-with-gap {\n\t\t\t\t\tgap: 1em;\n\t\t\t\t}\n\t\t\t}\n\t\t"])), theme.dimensions.margin.xSmall, prefix, prefix, prefix, prefix, prefix);
});
var templateObject_1, templateObject_2;
