var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@emotion/styled";
import { css } from "@emotion/react";
export var Root = styled.table(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\twidth: 100%;\n\tborder-collapse: collapse;\n\ttext-align: left;\n"], ["\n\twidth: 100%;\n\tborder-collapse: collapse;\n\ttext-align: left;\n"])));
export var HeaderRow = styled.tr(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t\t\tborder-bottom: 1px solid ", ";\n\t\t"], ["\n\t\t\tborder-bottom: 1px solid ", ";\n\t\t"])), theme.colors.palette.grey[200]);
});
export var HeaderCol = styled.td(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\t\t\tfont-weight: 500;\n\t\t\tpadding: ", "\n\t\t\t\t", ";\n\t\t\tfont-size: ", ";\n\t\t"], ["\n\t\t\tfont-weight: 500;\n\t\t\tpadding: ", "\n\t\t\t\t", ";\n\t\t\tfont-size: ", ";\n\t\t"])), theme.dimensions.margin.small, theme.dimensions.margin.xSmall, theme.typography.fontSize.small);
});
export var Row = styled.tr(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n\t\t\tborder-radius: ", ";\n\t\t"], ["\n\t\t\tborder-radius: ", ";\n\t\t"])), theme.dimensions.borderRadius);
});
export var Col = styled.td(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n\t\t\tcolor: ", ";\n\t\t\tfont-size: ", ";\n\t\t\tpadding: ", "\n\t\t\t\t", ";\n\t\t"], ["\n\t\t\tcolor: ", ";\n\t\t\tfont-size: ", ";\n\t\t\tpadding: ", "\n\t\t\t\t", ";\n\t\t"])), theme.colors.palette.grey[500], theme.typography.fontSize.small, theme.dimensions.margin.small, theme.dimensions.margin.xSmall);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
