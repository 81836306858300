var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@emotion/styled";
import { css } from "@emotion/react";
export var prefix = "loading-overlay";
export var LoadingOverlay = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t\t\tdisplay: flex;\n\t\t\tposition: absolute;\n\t\t\ttop: 0;\n\t\t\tleft: 0;\n\t\t\tflex-direction: column;\n\t\t\tjustify-content: center;\n\t\t\theight: 100%;\n\t\t\twidth: 100%;\n\t\t\tbackground: ", ";\n\t\t\topacity: 80%;\n\t\t\tz-index: 3;\n\n\t\t\t.", "-loading {\n\t\t\t\tmargin: auto;\n\t\t\t\theight: fit-content;\n\t\t\t\twidth: 80%;\n\t\t\t\tmax-width: 300px;\n\t\t\t}\n\t\t"], ["\n\t\t\tdisplay: flex;\n\t\t\tposition: absolute;\n\t\t\ttop: 0;\n\t\t\tleft: 0;\n\t\t\tflex-direction: column;\n\t\t\tjustify-content: center;\n\t\t\theight: 100%;\n\t\t\twidth: 100%;\n\t\t\tbackground: ", ";\n\t\t\topacity: 80%;\n\t\t\tz-index: 3;\n\n\t\t\t.", "-loading {\n\t\t\t\tmargin: auto;\n\t\t\t\theight: fit-content;\n\t\t\t\twidth: 80%;\n\t\t\t\tmax-width: 300px;\n\t\t\t}\n\t\t"])), theme.colors.palette.grey[800], prefix);
});
var templateObject_1, templateObject_2;
