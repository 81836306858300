var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { persistKey as scenePersistKey } from "store/scene/persist-config";
import { persistKey as uiPersistKey } from "store/ui/persist-config";
import { persistKey as persistKeyDefineParameters } from "store/system-data/define-parameters/persist-config";
import { persistKey as persistKeySelectApplication } from "store/system-data/select-application/persist-config";
var persistIfExists = function (key, map) {
    var value = map.get(key);
    if (value) {
        window.sessionStorage.setItem("persist:".concat(key), value);
    }
};
export var persistTemplate = function (template) {
    var parts = template.split("&").reduce(function (acc, param) {
        var _a = __read(param.split("="), 2), key = _a[0], value = _a[1];
        acc.set(key, decodeURIComponent(value));
        return acc;
    }, new Map());
    persistIfExists(scenePersistKey, parts);
    persistIfExists(persistKeyDefineParameters, parts);
    persistIfExists(persistKeySelectApplication, parts);
    persistIfExists(uiPersistKey, parts);
};
