var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
// list adapted from https://stackoverflow.com/questions/1168260/algorithm-for-generating-unique-colors
var distinctColors = [
    "#2F80ED",
    "#E85EBE",
    "#00FF00",
    "#0000FF",
    "#FF0000",
    "#01FFFE",
    "#FFA6FE",
    "#FFDB66",
    "#006401",
    "#010067",
    "#95003A",
    "#007DB5",
    "#FF00F6",
    "#FFEEE8",
    "#774D00",
    "#90FB92",
    "#0076FF",
    "#D5FF00",
    "#FF937E",
    "#6A826C",
    "#FF029D",
    "#FE8900",
    "#7A4782",
    "#7E2DD2",
    "#85A900",
    "#FF0056",
    "#A42400",
    "#00AE7E",
    "#683D3B",
    "#BDC6FF",
    "#263400",
    "#BDD393",
    "#00B917",
    "#9E008E",
    "#001544",
    "#C28C9F",
    "#FF74A3",
    "#01D0FF",
    "#004754",
    "#E56FFE",
    "#788231",
    "#0E4CA1",
    "#91D0CB",
    "#BE9970",
    "#968AE8",
    "#BB8800",
    "#43002C",
    "#DEFF74",
    "#00FFC6",
    "#FFE502",
    "#620E00",
    "#008F9C",
    "#98FF52",
    "#7544B1",
    "#B500FF",
    "#00FF78",
    "#FF6E41",
    "#005F39",
    "#6B6882",
    "#5FAD4E",
    "#A75740",
    "#A5FFD2",
    "#FFB167",
];
/**
 * Generates colors, where each generated color is visually distinct from the previous color.
 * Will loop around when the list is exhausted.
 *
 * @example
 * const colors = colorGenerator();
 * colors.next().value // #2F80ED
 * colors.next().value // #E85EBE
 * colors.next().value // #00FF00
 * ...
 * colors.next().value // #FFB167
 * colors.next().value // #2F80ED
 */
function ColorGenerator() {
    var distinctColors_1, distinctColors_1_1, color, e_1_1;
    var e_1, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!true) return [3 /*break*/, 9];
                _b.label = 1;
            case 1:
                _b.trys.push([1, 6, 7, 8]);
                distinctColors_1 = (e_1 = void 0, __values(distinctColors)), distinctColors_1_1 = distinctColors_1.next();
                _b.label = 2;
            case 2:
                if (!!distinctColors_1_1.done) return [3 /*break*/, 5];
                color = distinctColors_1_1.value;
                return [4 /*yield*/, color];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4:
                distinctColors_1_1 = distinctColors_1.next();
                return [3 /*break*/, 2];
            case 5: return [3 /*break*/, 8];
            case 6:
                e_1_1 = _b.sent();
                e_1 = { error: e_1_1 };
                return [3 /*break*/, 8];
            case 7:
                try {
                    if (distinctColors_1_1 && !distinctColors_1_1.done && (_a = distinctColors_1.return)) _a.call(distinctColors_1);
                }
                finally { if (e_1) throw e_1.error; }
                return [7 /*endfinally*/];
            case 8: return [3 /*break*/, 0];
            case 9: return [2 /*return*/];
        }
    });
}
var colors = ColorGenerator();
var idColorMap = new Map();
/**
 * Retrieves a visually distinct color for the given ID. If the ID already has
 * an associated color, the same color will be returned. If the ID is new, the
 * next distinct color in the sequence will be associated with the ID and
 * returned.
 */
export function getDistinctColor(id) {
    if (!idColorMap.has(id)) {
        var color = colors.next().value;
        idColorMap.set(id, color);
    }
    // One beautiful day, TypeScript will understand that the above if statement
    // guarantees that the ID is in the map... dear future reader, if that
    // beautiful day has come, please remove the following eslint-disable line.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return idColorMap.get(id);
}
