import { jsx as _jsx } from "@emotion/react/jsx-runtime";
/* eslint-disable camelcase */
import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { Auth0LoadingFallback } from "./auth0-loading-fallback";
import { ErrorPanel } from "ui/components/panels/error";
import { clearURLParameter } from "utility/url";
var domain = process.env.AUTH0_DOMAIN;
var clientId = process.env.AUTH0_CLIENT_ID;
var redirectUri = process.env.AUTH0_REDIRECT_URI;
var isDev = process.env.ENVIRONMENT === "development";
/**
 * When Auth0 redirects back to our application we get code and state as query
 * parameters in the URL. For some reason using React Router methods doesn't
 * work for clearing these. So we use the custom function clearURLParameter().
 */
function clearAuth0URLParameters() {
    clearURLParameter("code");
    clearURLParameter("state");
}
/**
 * A wrapper around Auth0Provider that uses React Router's navigate() method to
 * redirect the user after authentication. If domain, client ID, or redirect URI
 * are not set in the environment variables, an error panel will be rendered.
 * @returns The Auth0Provider component, with children.
 */
export var Auth0ProviderWithNavigate = function (_a) {
    var children = _a.children;
    var navigate = useNavigate();
    var onRedirectCallback = function (appState) {
        clearAuth0URLParameters();
        var navigatePath = (appState === null || appState === void 0 ? void 0 : appState.returnTo) || window.location.pathname;
        if (isDev) {
            // NOTE: Ugly hack to manually set URL when we're redirected to DEV.
            // If we only use navigate(), we'll lose any subpaths (/piab) after dev.animech.com in the URL
            window.location.href = window.location.origin + "/piab/#" + navigatePath;
        }
        else {
            navigate(navigatePath);
        }
    };
    if (!(domain && clientId && redirectUri)) {
        console.error("Auth0 environment variables not set.");
        return _jsx(ErrorPanel, {});
    }
    return (_jsx(Auth0Provider, { domain: domain, clientId: clientId, authorizationParams: {
            redirect_uri: redirectUri,
        }, onRedirectCallback: onRedirectCallback, children: _jsx(Auth0LoadingFallback, { children: children }) }));
};
