var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/**
 * This component is copied from @animech/ui and modified as follows
 * - If no label is provided the header will not be shown when no active item is selected
 * - When root children change, find the active item in the tree again and update the state
 *   this is needed to trigger a rerender of items when the data context changes
 */
import { isValidElement, useState, Children, cloneElement, useEffect, useCallback, useRef, Fragment, } from "react";
import { useTheme } from "@emotion/react";
import { StyledTreeNav as Styled, usePrevious, TreeNavItem, } from "@animech/ui";
import { getTreeNavItemElement, getTreeNavItemDepth, } from "./utility";
import { SidebarDrawerButton, } from "ui/components/elements/custom-drawer";
export var CustomTreeNav = function (_a) {
    var _b, _c, _d;
    var rootChildren = _a.children, className = _a.className, rootAttributes = _a.rootAttributes, rootIcon = _a.icon, rootImage = _a.image, rootLabel = _a.label, activeItemId = _a.activeItem, preventScrollReset = _a.preventScrollReset, onActiveItemChange = _a.onActiveItemChange;
    var _e = __read(useState(), 2), activeItem = _e[0], setActiveItemInternal = _e[1];
    var prevActiveItem = usePrevious(activeItem);
    var rootRef = useRef(null);
    useEffect(function () {
        if (activeItem) {
            setActiveItemInternal(getTreeNavItemElement(activeItem.props.id, rootChildren));
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [rootChildren]);
    useEffect(function () {
        // If state is controlled by parent
        if (activeItemId !== undefined) {
            if (activeItemId === null) {
                setActiveItemInternal(undefined);
            }
            else {
                setActiveItemInternal(getTreeNavItemElement(activeItemId, rootChildren));
            }
        }
    }, [activeItemId, rootChildren, setActiveItemInternal]);
    // Scroll to top when activeItem changes
    useEffect(function () {
        if (rootRef.current && !preventScrollReset) {
            rootRef.current.scrollTop = 0;
        }
    }, [activeItem, preventScrollReset]);
    var setActiveItem = useCallback(function (item) {
        var _a;
        // If state is controlled by parent
        if (onActiveItemChange) {
            onActiveItemChange((_a = item === null || item === void 0 ? void 0 : item.props.id) !== null && _a !== void 0 ? _a : null);
        }
        else {
            setActiveItemInternal(item);
        }
    }, [onActiveItemChange, setActiveItemInternal]);
    var children = activeItem ? activeItem.props.children : rootChildren;
    var icon = activeItem ? activeItem.props.icon : rootIcon;
    var image = activeItem ? activeItem.props.image : rootImage;
    var label = activeItem ? activeItem.props.label : "";
    var key = (_b = activeItem === null || activeItem === void 0 ? void 0 : activeItem.props.id) !== null && _b !== void 0 ? _b : "root";
    var newDepth = activeItem
        ? (_c = getTreeNavItemDepth(activeItem.props.id, rootChildren)) !== null && _c !== void 0 ? _c : 0
        : 0;
    var prevDepth = prevActiveItem
        ? (_d = getTreeNavItemDepth(prevActiveItem.props.id, rootChildren)) !== null && _d !== void 0 ? _d : 0
        : 0;
    var direction = newDepth === prevDepth ? undefined : newDepth > prevDepth ? "down" : "up";
    var backButtonIcon = useTheme().components.treeNav.backButtonIcon;
    return (_jsxs(Styled.Root, __assign({}, rootAttributes, { className: className, "data-direction": direction, ref: rootRef, children: [activeItem && (_jsxs(Styled.Header, { children: [_jsx(Styled.BackButton, { icon: backButtonIcon, onClick: function () { return setActiveItem(activeItem.parent); } }, "back-" + key), image ? (_jsx(Styled.Image, { style: { backgroundImage: "url(".concat(image, ")") } }, "image-" + key)) : icon ? (_jsx(Styled.Icon, { name: icon }, "icon-" + key)) : null, _jsx(Styled.Label, { children: label }, "label-" + key)] })), !activeItem && rootLabel && (_jsxs(Styled.Header, { children: [image ? (_jsx(Styled.Image, { style: { backgroundImage: "url(".concat(image, ")") } }, "image-" + key)) : icon ? (_jsx(Styled.Icon, { name: icon }, "icon-" + key)) : null, _jsx(Styled.Label, { children: rootLabel }, "label-" + key)] })), _jsx(Styled.Body, { children: Children.toArray(children).map(function (child, i) {
                    var _a;
                    if (isValidElement(child) &&
                        child.type === TreeNavItem) {
                        return cloneElement(child, {
                            key: child.props.id,
                            onClick: (_a = child.props.onClick) !== null && _a !== void 0 ? _a : (function () { return setActiveItem(__assign(__assign({}, child), { parent: activeItem })); }),
                        });
                    }
                    else if (isValidElement(child) &&
                        child.type === SidebarDrawerButton) {
                        return cloneElement(child, {
                            key: child.key,
                        });
                    }
                    else {
                        return _jsx(Fragment, { children: child }, i);
                    }
                }) }, "body-" + key)] })));
};
