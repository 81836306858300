import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useNumberConversion } from "hooks/number-conversion";
import * as Styled from "ui/components/panels/graphs/graph.style";
import { xAxisUnit } from "ui/components/panels/graphs/constants";
export var CustomTooltip = function (_a) {
    var active = _a.active, payload = _a.payload, label = _a.label, xLabel = _a.xLabel, yLabel = _a.yLabel, yUnit = _a.yUnit;
    var convertNumber = useNumberConversion();
    if (active && payload && payload.length && payload[0].value !== undefined) {
        var value = Number(payload[0].value);
        return (_jsxs(Styled.CustomTooltip, { children: [_jsx("p", { children: "".concat(xLabel, ": ").concat(convertNumber(label, xAxisUnit).formatted) }), _jsx("p", { children: "".concat(yLabel, ": ").concat(convertNumber(value, yUnit).formatted) })] }));
    }
    return null;
};
