import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { useAuth0 } from "@auth0/auth0-react";
import { LoadingOverlay } from "ui/components/elements/loading-overlay";
import { DelayedFallback } from "ui/utility/delayed-fallback";
/**
 * A component that will render a loading overlay if Auth0 is not yet loaded.
 * @returns Either the children or a loading overlay.
 */
export var Auth0LoadingFallback = function (_a) {
    var children = _a.children;
    var isLoading = useAuth0().isLoading;
    if (isLoading) {
        return (_jsx(DelayedFallback, { isLoading: isLoading, fallback: _jsx(LoadingOverlay, {}) }));
    }
    return _jsx(_Fragment, { children: children });
};
