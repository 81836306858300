import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { t } from "i18next";
import { PerformanceTooltip } from "./performance-tooltip";
import { systemDataConstants } from "api/system-data";
import { TooltipInput, TooltipInputType, } from "ui/components/elements/input/tooltip-input";
import { selectors, useAppSelector } from "store";
export var FeedPressureTooltip = function (_a) {
    var x = _a.x, y = _a.y, _b = _a.align, align = _b === void 0 ? "left" : _b;
    var systemDataDefineParameters = useAppSelector(selectors.selectSystemDataDefineParameters);
    if (!systemDataDefineParameters)
        return null;
    return (_jsx(PerformanceTooltip, { x: x, y: y, align: align, title: t("canvas.regulator_title"), showValidityMessage: false, children: _jsx(TooltipInput, { parameterType: TooltipInputType.PARAMETER, parameter: systemDataDefineParameters.parameters[systemDataConstants.parameterNames.feedPressure], label: "".concat(t("canvas.feed_pressure_regulator"), ":") }) }, "feed-pressure"));
};
