import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import * as Styled from "./custom-legend.style";
import { graphWidth, graphMargins, } from "ui/components/panels/graphs/constants";
import { useGraphDataContext } from "providers/graph-panel";
export var CustomLegend = function (props) {
    var label = props.label, extraContent = props.extraContent;
    return (_jsxs(Styled.LegendContainer, { children: [_jsx(Styled.LegendLabel, { children: label }), extraContent ? extraContent : null] }));
};
export var useCustomLegendWrapperStyle = function (height) {
    var responsiveContainerWidth = useGraphDataContext().responsiveContainerWidth;
    return {
        width: responsiveContainerWidth - graphWidth,
        left: graphWidth - graphMargins.right / 2,
        bottom: "".concat(height / 2 - 24, "px"),
        height: 24,
    };
};
