import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useMemo } from "react";
import * as Styled from "./node-vacuum-level.style";
import { CanvasOverlay } from "./canvas-overlay";
import { useSystemPerformanceValidityMessage } from "hooks/messages";
import { useAppSelector, selectors } from "store";
import { OverlayData } from "store/types";
import { MessagesPopover, MessageLevel, } from "ui/components/elements/messages-popover";
import { useNumberConversion } from "hooks/number-conversion";
import { Unit } from "utility/number";
import { ArrowUpIcon } from "ui/components/elements/icons/arrow-up";
import { ArrowRightIcon } from "ui/components/elements/icons/arrow-right";
import { CustomUITooltip } from "ui/components/elements/custom-ui-tooltip";
export var NodeVacuumLevel = function (_a) {
    var nodeId = _a.nodeId, nodeCenterX = _a.nodeCenterX, nodeCenterY = _a.nodeCenterY;
    var hasReachedPartSecured = useAppSelector(selectors.selectHasReachedPartSecured);
    var cupPerformance = useAppSelector(selectors.selectSuctionCupPerformance(nodeId));
    var convertNumber = useNumberConversion();
    var systemPerformanceMessage = useSystemPerformanceValidityMessage();
    var overlayData = useAppSelector(selectors.selectOverlayData);
    var vacuumLevel = useMemo(function () {
        if (!cupPerformance)
            return null;
        if (overlayData === OverlayData.VACUUM_LEVEL_ES) {
            return cupPerformance.vacuumLevelAtSystemES;
        }
        else if (overlayData === OverlayData.VACUUM_LEVEL_PS) {
            if (!hasReachedPartSecured)
                return null;
            return cupPerformance.vacuumLevelAtSystemPS;
        }
        else {
            return null;
        }
    }, [overlayData, cupPerformance, hasReachedPartSecured]);
    var liftForce = useMemo(function () {
        if (!cupPerformance)
            return null;
        return {
            vertical: cupPerformance.liftingForceVerticalAtPS,
            horizontal: cupPerformance.liftingForceHorizontalAtPS,
        };
    }, [cupPerformance]);
    if (!vacuumLevel || !liftForce)
        return null;
    var VacuumAndLiftForceContainer = function () {
        return (_jsxs(_Fragment, { children: [_jsx("span", { children: convertNumber(vacuumLevel, Unit.KPA).formatted }), overlayData !== OverlayData.VACUUM_LEVEL_ES && (_jsxs(_Fragment, { children: [", ", _jsx(ArrowUpIcon, { size: "18" }), _jsx("span", { children: convertNumber(liftForce.vertical, Unit.N).formatted }), " ", _jsx(ArrowRightIcon, { size: "18" }), _jsx("span", { children: convertNumber(liftForce.horizontal, Unit.N).formatted })] }))] }));
    };
    if (vacuumLevel) {
        return (_jsx(CanvasOverlay, { x: nodeCenterX, y: nodeCenterY, children: _jsx(Styled.NodeVacuumLevel, { children: _jsx(CustomUITooltip, { hideArrow: true, children: _jsxs("div", { className: "".concat(Styled.prefix, "-content"), children: [_jsx(VacuumAndLiftForceContainer, {}), systemPerformanceMessage && (_jsx("div", { className: "".concat(Styled.prefix, "-warning"), children: _jsx(MessagesPopover, { messages: [systemPerformanceMessage], level: MessageLevel.WARNING, toggleEvent: "hover", size: "small" }) }))] }) }) }) }));
    }
    else {
        return null;
    }
};
