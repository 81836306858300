var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b;
/* eslint-disable camelcase */
import { adjustableBox, adjustableCylinder } from "./adjustable-shapes";
import { CameraViewId, Material, } from "3d/types";
import { GlassObjectShapeId } from "store/types";
import { defaultObjectCameraViews } from "3d/constants/camera-views";
import { suctionCupsFamilyBX_P, suctionCupsFamilyXLF, suctionCupsFamilyB, suctionCupsFamilyB_XP, suctionCupsFamilyOB, suctionCupsFamilyF, suctionCupsFamilyFC, suctionCupsFamilyU, suctionCupsFamilyBL, } from "3d/constants/cup-families";
export var suctionCupModelsGlass = __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, suctionCupsFamilyXLF), suctionCupsFamilyBX_P), suctionCupsFamilyB_XP), suctionCupsFamilyB), suctionCupsFamilyOB), suctionCupsFamilyF), suctionCupsFamilyU), suctionCupsFamilyBL), suctionCupsFamilyFC);
var defaultGlassObjectShapeData = {
    cameraViews: __assign({}, defaultObjectCameraViews),
    objectDefaultSize: { x: 1, y: 1, z: 1 },
    materialTransforms: [
        {
            sourceMaterial: Material.MA_TESTOBJECT,
            targetMaterial: Material.GLASS,
        },
    ],
};
export var glassObjectShapes = (_a = {},
    _a[GlassObjectShapeId.ADJUSTABLE_GLASS_BOX] = __assign(__assign(__assign({}, defaultGlassObjectShapeData), adjustableBox), { image: "img/app/object-shapes/glass/GlassCube100x100x100cm_Arrows.webp" }),
    _a[GlassObjectShapeId.ADJUSTABLE_GLASS_CYLINDER] = __assign(__assign(__assign({}, defaultGlassObjectShapeData), adjustableCylinder), { image: "img/app/object-shapes/glass/GlassCylinderHorizontal100x100cm_Arrows.webp" }),
    _a[GlassObjectShapeId.SCREEN_PHONE] = __assign(__assign({}, defaultGlassObjectShapeData), { translationKey: "sidebar.object_shape.objects.glass.phone_screen", cameraViews: __assign(__assign({}, defaultObjectCameraViews), (_b = {}, _b[CameraViewId.EDIT_VIEW] = __assign(__assign({}, defaultObjectCameraViews.edit_view), { z: 0 }), _b)), image: "img/app/object-shapes/glass/GlassScreenPhone01.webp", modelName: "TestObj-GlassScreenPhone01", gripPositionY: 5.01, tubeNetworkY: 5.4 }),
    _a[GlassObjectShapeId.SHEET_63X120] = __assign(__assign({}, defaultGlassObjectShapeData), { translationKey: "sidebar.object_shape.objects.glass.sheet_63x120", image: "img/app/object-shapes/glass/GlassSheet63x120cm.webp", modelName: "TestObj-GlassSheet63x120cm", gripPositionY: 5.01, tubeNetworkY: 5.4 }),
    _a[GlassObjectShapeId.SHEET_100X10] = __assign(__assign({}, defaultGlassObjectShapeData), { translationKey: "sidebar.object_shape.objects.glass.sheet_100x10", image: "img/app/object-shapes/glass/GlassSheet100x100cm.webp", modelName: "TestObj-GlassSheet100x100cm", gripPositionY: 5.01, tubeNetworkY: 5.4 }),
    _a[GlassObjectShapeId.WINDSHIELD] = __assign(__assign({}, defaultGlassObjectShapeData), { translationKey: "sidebar.object_shape.objects.glass.windshield", image: "img/app/object-shapes/glass/GlassWindshield01.webp", modelName: "TestObj-GlassWindshield01", gripPositionY: 5.01, tubeNetworkY: 5.4 }),
    _a);
