var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useCallback, useEffect, useMemo, useState } from "react";
import * as pc from "playcanvas";
import { cardboardBoxMap, cardboardMaterialMap, tapeCenterPointMap, tapeEndsParts, tapeModelsMap, } from "./constants";
import { addTapeCollisionComponent, collisionComponentDefinitionsFromTapeModelID, } from "./collision-helper";
import { useAssetLoader } from "hooks/playcanvas/asset-loader";
import { useAppSelector, selectors } from "store";
import { objectPosition } from "3d/constants/common";
import { applyMaterialTransforms, getMaterialAssets } from "3d/helpers";
import { Material } from "3d/types";
import { tapeEndsParameterValues } from "api/tacton/constants";
import { useCollisionsContext } from "providers/collisions";
var ROOT_ENTITY_NAME = "Cardboard Box";
export var CardboardBuilder = function (_a) {
    var app = _a.app, modelAssets = _a.modelAssets, materialAssets = _a.materialAssets;
    var setCollisionTriggers = useCollisionsContext().setCollisionTriggers;
    var objectSize = useAppSelector(selectors.selectObjectSize);
    var objectShapeId = useAppSelector(selectors.selectObjectShape);
    var loadAssets = useAssetLoader(app).loadAssets;
    var paperCoating = useAppSelector(selectors.selectPaperCoatingValue);
    var tape = useAppSelector(selectors.selectTapeParameter);
    var tapeEnds = useAppSelector(selectors.selectTapeEndsParameter);
    var _b = __read(useState(), 2), cardboardRootEntity = _b[0], setCardboardRootEntity = _b[1];
    var dimensions = useMemo(function () { return ({
        width: objectSize.z,
        length: objectSize.x,
        height: 1,
    }); }, [objectSize]);
    // Create cardboard root entity.
    useEffect(function () {
        var entity = app.root.findByName(ROOT_ENTITY_NAME) || null;
        if (!entity) {
            entity = new pc.Entity(ROOT_ENTITY_NAME);
            app.root.addChild(entity);
            setCardboardRootEntity(entity);
        }
        return function () {
            entity.destroy();
        };
    }, [app.root]);
    // Update position of root entity based on dimensions .
    useEffect(function () {
        var width = dimensions.width, length = dimensions.length;
        if (!cardboardRootEntity)
            return;
        cardboardRootEntity.setPosition(-width * 0.5, objectPosition.y, length * 0.5);
    }, [cardboardRootEntity, dimensions]);
    // Load all model/material assets and add them to each cardboard entity.
    // Also sets the scale and positions each object invidiually.
    var addModelsAndMaterialsToParts = useCallback(function (rootEntity, parts) {
        if (!paperCoating || !cardboardRootEntity)
            return;
        var assetsToLoad = [];
        // Remove all previous parts before generating new ones.
        for (var i = cardboardRootEntity.children.length - 1; i >= 0; i--) {
            cardboardRootEntity.children[i].destroy();
        }
        parts.forEach(function (part) {
            var modelAsset = part.modelAsset, entity = part.entity;
            entity.addComponent("model", {
                type: "asset",
            });
            rootEntity.addChild(part.entity);
            transformModel({ part: part, dimensions: dimensions });
            assetsToLoad.push(modelAsset);
        });
        var assetMaterialTransforms = getAssetMaterialTransforms(assetsToLoad, materialAssets, cardboardMaterialMap[paperCoating]);
        var updateModelAssets = function () {
            parts.forEach(function (part) {
                var entity = part.entity, modelAsset = part.modelAsset, scaleUVVec2 = part.scaleUVVec2;
                var uvScale = scaleUVVec2 && scaleUVVec2(dimensions);
                var model = entity.model;
                if (model) {
                    model.asset = 0;
                    model.asset = modelAsset;
                    applyMaterialTransforms(entity, assetMaterialTransforms, uvScale);
                }
                addTapeCollisionComponent({ part: part, dimensions: dimensions });
            });
        };
        loadAssets(assetsToLoad, updateModelAssets);
    }, [cardboardRootEntity, dimensions, loadAssets, materialAssets, paperCoating]);
    useEffect(function () {
        if (!paperCoating ||
            !tape ||
            !objectShapeId ||
            !tapeEnds ||
            !cardboardRootEntity)
            return;
        /* ----- Reset and create root entity and underlying parts ------*/
        var cardboardBox = cardboardBoxMap(objectShapeId, tapeCenterPointMap[tape]);
        var cardboardParts = generateParts(modelAssets, cardboardBox);
        var tapeModels = tapeModelsMap[tape];
        var shouldShowTapeEnds = tapeEnds.value === tapeEndsParameterValues.withTape;
        var tapeEndsModels = shouldShowTapeEnds ? tapeEndsParts : [];
        var tapeParts = generateParts(modelAssets, __spreadArray(__spreadArray([], __read(tapeModels), false), __read(tapeEndsModels), false));
        var partsWithCollisions = __spreadArray(__spreadArray([], __read(cardboardParts), false), __read(tapeParts), false).filter(function (part) { return part.collisionComponentDefinition; });
        setCollisionTriggers(partsWithCollisions);
        /* ----- Apply all models and materials ------*/
        addModelsAndMaterialsToParts(cardboardRootEntity, __spreadArray(__spreadArray([], __read(cardboardParts), false), __read(tapeParts), false));
    }, [
        cardboardRootEntity,
        addModelsAndMaterialsToParts,
        paperCoating,
        tape,
        objectShapeId,
        modelAssets,
        tapeEnds,
        setCollisionTriggers,
    ]);
    return null;
};
var getAssetMaterialTransforms = function (assetsToLoad, materialAssets, targetMaterial) {
    var materialTransforms = [
        {
            sourceMaterial: Material.MA_TESTOBJECT,
            targetMaterial: targetMaterial,
        },
    ];
    var assetMaterialTransforms = getMaterialAssets(materialAssets, materialTransforms);
    if (assetMaterialTransforms) {
        assetsToLoad.push.apply(assetsToLoad, __spreadArray([], __read(Object.values(assetMaterialTransforms)), false));
    }
    return assetMaterialTransforms;
};
var transformModel = function (_a) {
    var dimensions = _a.dimensions, part = _a.part;
    var entity = part.entity, scaleVec3 = part.scaleVec3, translateVec3 = part.translateVec3, rotateVec3 = part.rotateVec3;
    if (scaleVec3) {
        var scale = scaleVec3(dimensions);
        entity.setLocalScale(scale);
    }
    if (translateVec3) {
        var translation = translateVec3(dimensions);
        entity.translateLocal(translation);
    }
    if (rotateVec3) {
        var rotation = rotateVec3(dimensions);
        entity.setLocalEulerAngles(rotation);
    }
};
// Generate entities and assets for each individual part used to build the cardboard box.
var generateParts = function (modelAssets, rawCardboardParts) {
    return rawCardboardParts.map(function (part, index) {
        var modelId = part.modelId, transforms = __rest(part, ["modelId"]);
        var entityName = "".concat("Cardboard-Part" + modelId, "-").concat(index);
        return __assign({ modelAsset: modelAssets[modelId], entity: new pc.Entity(entityName), modelId: modelId, collisionComponentDefinition: collisionComponentDefinitionsFromTapeModelID[modelId] }, transforms);
    });
};
