var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
/*
 * Each migration step will take one version as input and return the next version as output.
 * (The key `2` means that it is the step which migrates from V1 to V2.)
 */
var migrations = (_a = {},
    _a[0] = function (state) {
        return __assign(__assign({}, state), { 
            /* Example: add a new (required) key to an old state*/
            cupMountingSelectionMap: {} });
    },
    _a);
export { migrations };
