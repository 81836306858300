var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable camelcase */
import { FeedPressureType, SystemModel, } from "./types";
import { systemDataConstants } from "api/system-data";
import { NodeComponentType, TubeConnectionId, } from "store/types";
import { tubeConnectionModels, toolChangerDefaultLength, feedPressurePointNodeId, } from "3d/constants/common";
import { getCylinderVolumeDm3 } from "3d/helpers";
import { selectors } from "store/selectors";
import { getGoverningPump } from "ui/components/panels/graphs/common/helper-functions";
export var getPumpProperties = function (pump) { return ({
    model: pump.model,
    volume: pump.volume,
    blow_off_capacity_flow: pump.blowOffCapacityFlow,
    blow_off_capacity_pressure: pump.blowOffCapacityPressure,
}); };
export var getCupProperties = function (cup) { return ({
    volume: cup.volume,
    collapse_pressure: cup.collapsePressure,
    model_code: cup.modelCode,
    collapse_volume: cup.collapseVolume,
    lifting_force_60_kpa_vertical: cup.verticalLiftForce60,
    lifting_force_90_kpa_vertical: cup.verticalLiftForce90,
    lifting_force_60_kpa_horizontal: cup.horizontalLiftForce60,
    lifting_force_90_kpa_horizontal: cup.horizontalLiftForce90,
}); };
export var getCupMountingProperties = function (cupMounting) { return ({
    model: cupMounting.model,
    volume: cupMounting.volume,
    diameter: cupMounting.diameter,
}); };
var Network;
(function (Network) {
    Network["DEFAULT"] = "default";
    Network["BLOW_OFF"] = "blow_off";
})(Network || (Network = {}));
var getNetworkId = function (sceneId, network) {
    return "".concat(network, "-").concat(sceneId);
};
export var serializeStateToRequest = function (state, energySavingOn, isValidation) {
    var edges = selectors.selectEdges(state);
    var nodes = selectors.selectNodes(state);
    var attachments = selectors.selectAttachments(state);
    var hasDoubleCompressedAirConnections = selectors.selectUseDoubleCompressedAirTubes(state);
    /* API IDs are number 0-255*/
    var availableApiIds = __spreadArray([], __read(Array(255).keys()), false).reverse();
    var getId = function () {
        var id = availableApiIds.pop();
        if (id === undefined) {
            throw Error("Not enough IDs");
        }
        return id;
    };
    /*
     * Create a simplified network representation of nodes/edges, where
     * each node/edge has an API Id and a list of edges/nodes
     * it is connected to.
     *
     * For double tube networks, each node/edge positioned between the
     * feed pressure point and a pump will exist twice. Once in the default
     * network and once in the blow off network.
     */
    var networkIdsPartial = {};
    var addToNetwork = function (sceneId, network, networkConnections) {
        var networkId = getNetworkId(sceneId, network);
        var ids = {
            networkId: networkId,
            network: network,
            sceneId: sceneId,
            apiId: getId(),
            networkConnections: networkConnections,
        };
        networkIdsPartial[networkId] = ids;
    };
    var populatePartialNetwork = function (node, addToBlowOffNetwork, additionalNetworkConnections) {
        if (addToBlowOffNetwork === void 0) { addToBlowOffNetwork = false; }
        if (additionalNetworkConnections === void 0) { additionalNetworkConnections = []; }
        var defaultNetworkId = getNetworkId(node.id, Network.DEFAULT);
        if (networkIdsPartial[defaultNetworkId])
            return;
        var edgeIds = node.attachmentIds
            .map(function (attachmentId) { return attachments[attachmentId].edgeId; })
            .filter(Boolean);
        addToNetwork(node.id, Network.DEFAULT, edgeIds
            .map(function (id) { return getNetworkId(id, Network.DEFAULT); })
            .concat(additionalNetworkConnections));
        if (addToBlowOffNetwork) {
            addToNetwork(node.id, Network.BLOW_OFF, edgeIds.map(function (id) { return getNetworkId(id, Network.BLOW_OFF); }));
        }
        edgeIds.forEach(function (edgeId) {
            var fromAttachment = attachments[edges[edgeId].fromAttachmentId];
            var toAttachment = attachments[edges[edgeId].toAttachmentId];
            var connectedNode = fromAttachment.nodeId === node.id
                ? nodes[toAttachment.nodeId]
                : nodes[fromAttachment.nodeId];
            /*
             * When we encounter an edge that is connected to a central pump
             * or grip position, the blow off tube network ends and the
             * blow off tube will be connected to the pump/grip position on
             * the default network.
             * From that point forward, we will only have the default network.
             */
            var continueBlowOffNetwork = addToBlowOffNetwork &&
                connectedNode.type !== NodeComponentType.CENTRALIZED_VACUUM_PUMP &&
                connectedNode.type !== NodeComponentType.GRIP_POSITION;
            var connectBlowOffEdgeToDefaultNode = addToBlowOffNetwork && !continueBlowOffNetwork;
            var edgeDefaultNetworkId = getNetworkId(edgeId, Network.DEFAULT);
            var edgeBlowOffNetworkId = getNetworkId(edgeId, Network.BLOW_OFF);
            if (!networkIdsPartial[edgeDefaultNetworkId]) {
                addToNetwork(edgeId, Network.DEFAULT, [
                    getNetworkId(node.id, Network.DEFAULT),
                    getNetworkId(connectedNode.id, Network.DEFAULT),
                ]);
                if (addToBlowOffNetwork) {
                    addToNetwork(edgeId, Network.BLOW_OFF, [
                        getNetworkId(node.id, Network.BLOW_OFF),
                        getNetworkId(connectedNode.id, continueBlowOffNetwork ? Network.BLOW_OFF : Network.DEFAULT),
                    ]);
                }
            }
            populatePartialNetwork(connectedNode, continueBlowOffNetwork, connectBlowOffEdgeToDefaultNode ? [edgeBlowOffNetworkId] : []);
        });
    };
    var feedPressurePointNode = nodes[feedPressurePointNodeId];
    populatePartialNetwork(feedPressurePointNode, hasDoubleCompressedAirConnections);
    /*
     * Complete the simplified representation by adding the list
     * of API Ids the node/edge is connected to
     */
    var networkIds = Object.values(networkIdsPartial).reduce(function (allIds, currentIds) {
        allIds[currentIds.networkId] = __assign(__assign({}, currentIds), { apiConnections: currentIds.networkConnections.map(function (conn) { return networkIdsPartial[conn].apiId; }) });
        return allIds;
    }, {});
    var systemDataDefineParameters = selectors.selectSystemDataDefineParameters(state);
    if (!systemDataDefineParameters) {
        throw Error("System data undefined");
    }
    var parameters = systemDataDefineParameters.parameters;
    var calculationInput = systemDataDefineParameters.calculationInput;
    var parameterNames = systemDataConstants.parameterNames;
    var isCentralizedSystem = selectors.selectIsCentralizedSystem(state);
    var isMultiCircuitSystem = selectors.selectIsMultiCircuitSystem(state);
    var suctionCups = [];
    var tubeConnectors = [];
    var vacuumPumps = [];
    var feedPressurePoints = [];
    var vacuumComponents = [];
    var tubes = [];
    /* Populate the request data based on the id network and the node data */
    Object.values(networkIds).forEach(function (idMap) {
        var sceneId = idMap.sceneId, apiId = idMap.apiId, networkConnections = idMap.networkConnections, apiConnections = idMap.apiConnections, network = idMap.network;
        var node = nodes[sceneId];
        if (node && node.type === NodeComponentType.FEED_PRESSURE_POINT) {
            feedPressurePoints.push({
                id: apiId,
                connections: apiConnections,
                feed_pressure: Number(parameters[parameterNames.feedPressure].value),
                type: network === Network.DEFAULT
                    ? FeedPressureType.EJECTOR
                    : FeedPressureType.BLOW_OFF,
            });
        }
        else if (node &&
            node.type === NodeComponentType.CENTRALIZED_VACUUM_PUMP &&
            isCentralizedSystem) {
            var selectedPump = selectors.selectSelectedCentralPump(node.id)(state);
            if (selectedPump) {
                vacuumPumps.push(__assign({ id: apiId, connections: apiConnections }, getPumpProperties(selectedPump)));
            }
        }
        else if (node && node.type === NodeComponentType.GRIP_POSITION) {
            var cup_1 = selectors.selectSelectedCup(node.id)(state);
            var pump_1 = selectors.selectSelectedDecentralPump(node.id)(state);
            var cupMounting_1 = selectors.selectSelectedCupMounting(node.id)(state);
            var gripPosition_1 = node.component;
            /* Grip position nodes are split into three potential API nodes; one for the pump,
             * one for the cup mounting and one for the cup.
             */
            var pushStack_1 = [
                !isCentralizedSystem && pump_1
                    ? function (id, connections) {
                        return vacuumPumps.push(__assign({ id: id, connections: connections }, getPumpProperties(pump_1)));
                    }
                    : undefined,
                !isCentralizedSystem && pump_1 && pump_1.isInlineDecentralPump
                    ? function (id, connections) {
                        return tubes.push({
                            id: id,
                            connections: connections,
                            length: gripPosition_1.inlineTubeLength,
                            diameter: gripPosition_1.inlineTubeDiameter,
                        });
                    }
                    : undefined,
                cupMounting_1
                    ? function (id, connections) {
                        return vacuumComponents.push(__assign({ id: id, connections: connections }, getCupMountingProperties(cupMounting_1)));
                    }
                    : undefined,
                cup_1
                    ? function (id, connections) {
                        return suctionCups.push(__assign({ id: id, connections: connections }, getCupProperties(cup_1)));
                    }
                    : undefined,
            ].filter(Boolean);
            var additionalApiIds_1 = [];
            var currentApiId_1 = apiId;
            var currentConnections_1 = apiConnections;
            pushStack_1.forEach(function (pushFn, ix) {
                var nextApiId = ix < pushStack_1.length - 1 ? getId() : undefined;
                if (nextApiId) {
                    currentConnections_1.push(nextApiId);
                    additionalApiIds_1.push(nextApiId);
                }
                pushFn(currentApiId_1, currentConnections_1);
                if (nextApiId) {
                    currentConnections_1 = [currentApiId_1];
                    currentApiId_1 = nextApiId;
                }
            });
            idMap.additionalApiIds = additionalApiIds_1;
        }
        else if (node && node.type === NodeComponentType.TUBE_CONNECTION) {
            var component = node.component;
            var tubeConnectionModel = tubeConnectionModels[component.type];
            // Piab expects only one diameter and that is the largest diameter that the tube
            // connection is connected to
            var largestDiameter = Math.max.apply(Math, __spreadArray([], __read(component.diameters), false));
            /* All tube connections are represented as one node in this application, but the API
             * does not handle double-T connections so when this is selected we need to split
             * it into two single-T nodes with different IDs. The two nodes will be connected
             * to each other and the edges will be split so that one node gets the first two
             * edges and the other get the last two edges.
             */
            if (tubeConnectionModel.isDouble) {
                var apiId1 = apiId;
                var apiId2_1 = getId();
                tubeConnectors.push({
                    id: apiId1,
                    connections: __spreadArray(__spreadArray([], __read(apiConnections.slice(0, 2)), false), [apiId2_1], false),
                    model: TubeConnectionId.THREE_WAY_T,
                    diameter: largestDiameter,
                });
                tubeConnectors.push({
                    id: apiId2_1,
                    connections: __spreadArray(__spreadArray([], __read(apiConnections.slice(2, 4)), false), [apiId1], false),
                    model: TubeConnectionId.THREE_WAY_T,
                    diameter: largestDiameter,
                });
                networkConnections.slice(2, 4).forEach(function (networkId) {
                    var edgeIds = networkIds[networkId];
                    var indexOfTubeConn = edgeIds.apiConnections.indexOf(apiId);
                    if (indexOfTubeConn > -1)
                        edgeIds.apiConnections[indexOfTubeConn] = apiId2_1;
                });
                idMap.additionalApiIds = [apiId2_1];
            }
            else if (component.type === TubeConnectionId.TOOL_CHANGER) {
                vacuumComponents.push({
                    id: apiId,
                    connections: apiConnections,
                    model: component.type,
                    diameter: largestDiameter,
                    volume: getCylinderVolumeDm3(toolChangerDefaultLength, largestDiameter),
                });
            }
            else {
                tubeConnectors.push({
                    id: apiId,
                    connections: apiConnections,
                    model: component.type,
                    diameter: largestDiameter,
                });
            }
        }
    });
    /* Populate the request data tubes based on the id network and the edge data */
    Object.values(networkIds).forEach(function (idMap) {
        var sceneId = idMap.sceneId, apiId = idMap.apiId, apiConnections = idMap.apiConnections;
        var edge = edges[sceneId];
        if (edge) {
            tubes.push({
                id: apiId,
                connections: apiConnections,
                length: edge.length,
                diameter: edge.diameterDefaultTube,
            });
        }
    });
    /* Map all created API IDs to scene IDs */
    var apiIdToNodeId = Object.values(networkIds).reduce(function (apiToNodeMap, currentIdMap) {
        var _a;
        apiToNodeMap[currentIdMap.apiId] = currentIdMap.sceneId;
        (_a = currentIdMap.additionalApiIds) === null || _a === void 0 ? void 0 : _a.forEach(function (apiId) { return (apiToNodeMap[apiId] = currentIdMap.sceneId); });
        return apiToNodeMap;
    }, {});
    var getSystemModel = function () {
        if (isMultiCircuitSystem)
            return SystemModel.MULTI_CIRCUIT;
        if (isCentralizedSystem)
            return SystemModel.CENTRALIZED;
        return SystemModel.DECENTRALIZED;
    };
    var surfaceRoughness = calculationInput.surfaceRoughness, throughLeak = calculationInput.throughLeak, handledObjectType = calculationInput.handledObjectType, surfaceStructure = calculationInput.surfaceStructure, surfaceCoating = calculationInput.surfaceCoating;
    var manualCalculationInput = selectors.selectManualCalculationInput(state);
    var manualSurfaceRoughness = manualCalculationInput && manualCalculationInput.manualSurfaceRoughness;
    var manualThroughLeak = manualCalculationInput && manualCalculationInput.manualThroughLeak;
    return {
        request: {
            suction_cups: suctionCups,
            vacuum_pumps: vacuumPumps,
            tubes: tubes,
            tube_connectors: tubeConnectors,
            feed_pressure_points: feedPressurePoints,
            vacuum_components: vacuumComponents,
            system_property: {
                part_secured_level: Number(parameters[parameterNames.partSecuredLevel].value),
                energy_saving_level: energySavingOn
                    ? Number(parameters[parameterNames.energySavingLevel].value)
                    : 101.3,
                energy_saving_restart_level: energySavingOn
                    ? Number(parameters[parameterNames.energySavingRestartLevel].value)
                    : 101.3,
                blow_off_clearance_time: Number(parameters[parameterNames.blowOffClearanceTime].value),
                target_parts_per_minute: Number(parameters[parameterNames.targetPartsPerMinute].value),
                vacuum_on_before_pick: Number(parameters[parameterNames.vacuumOnBeforePick].value),
                blow_off_on_before_pick: Number(parameters[parameterNames.blowOffOnBeforePick].value),
                system_model: getSystemModel(),
            },
            handled_object: {
                object_type: handledObjectType,
                surface_roughness: manualSurfaceRoughness !== undefined
                    ? manualSurfaceRoughness
                    : surfaceRoughness,
                surface_structure: surfaceStructure,
                through_leak: manualThroughLeak !== undefined ? manualThroughLeak : throughLeak,
                surface_coating: surfaceCoating,
            },
            sanity_check: {
                make_sanity_check: isValidation,
            },
        },
        apiIdToNodeId: apiIdToNodeId,
    };
};
export var errors;
(function (errors) {
    errors["NO_CUP_DATA"] = "no suction cup data available";
    errors["NO_PUMP_DATA"] = "no vacuum pump data available";
})(errors || (errors = {}));
export var deserializeResponseToSystemPerformance = function (response, apiIdToNodeId) {
    var system_property = response.system_property, suction_cups = response.suction_cups, vacuum_pumps = response.vacuum_pumps, sanity_check = response.sanity_check;
    var nodeData = {};
    if (vacuum_pumps.length === 0) {
        throw Error(errors.NO_PUMP_DATA);
    }
    suction_cups.forEach(function (cup) {
        var nodeId = apiIdToNodeId[cup.id];
        var data = {
            timeToPartSecured: cup.time_2_ps,
            timeToEnergySave: cup.time_2_es,
            finalVacuumLevel: cup.final_vac_level,
            connectedPumpId: cup.pump_connection_id,
            vacuumLevels: cup.vacuum_level,
            vacuumLevelAtSystemPS: cup.vacuum_level_at_system_ps,
            vacuumLevelAtSystemES: cup.vacuum_level_at_system_es,
            liftingForceHorizontalAtPS: cup.lifting_force_horizontal_at_system_ps,
            liftingForceVerticalAtPS: cup.lifting_force_vertical_at_system_ps,
            liftingForceHorizontal: cup.lifting_force_horizontal,
            liftingForceVertical: cup.lifting_force_vertical,
        };
        if (nodeData[nodeId]) {
            nodeData[nodeId].suctionCup = data;
        }
        else {
            nodeData[nodeId] = { suctionCup: data };
        }
    });
    vacuum_pumps.forEach(function (pump) {
        var nodeId = apiIdToNodeId[pump.id];
        var data = {
            id: pump.id,
            timeToPartSecured: pump.time_2_ps,
            timeToEnergySave: pump.time_2_es,
            finalVacuumLevel: pump.final_vac_level,
            vacuumLevels: pump.vacuum_level,
            compressedAirPressure: pump.compressed_air_pressure,
            freeRunningVacuumLevel: pump.free_running_vacuum_level,
            feedPressureRecommended: pump.feed_pressure_recommended,
            airConsumptionTotal: pump.airconsumption_total,
        };
        if (nodeData[nodeId]) {
            nodeData[nodeId].vacuumPump = data;
        }
        else {
            nodeData[nodeId] = { vacuumPump: data };
        }
    });
    var systemTimeToPs = Math.max.apply(Math, __spreadArray([], __read(vacuum_pumps.map(function (pump) { return pump.time_2_ps; })), false));
    var systemTimeToEs = Math.max.apply(Math, __spreadArray([], __read(vacuum_pumps.map(function (pump) { return pump.time_2_es; })), false));
    return {
        nodeData: nodeData,
        vacuumLevelTimeData: system_property.time_data,
        systemTimeToPartSecured: systemTimeToPs,
        systemTimeToEnergySave: systemTimeToEs,
        compressedAirFlowrateVac: system_property.compressed_air_flowrate_vac,
        compressedAirFlowrateBlowOff: system_property.compressed_air_flowrate_BO,
        airConsumptionTotal: system_property.airconsumption_total,
        airConsumptionVacuumGeneration: system_property.airconsumption_vacuum_generation,
        airConsumptionBlowOff: system_property.airconsumption_blow_off,
        transferTimeWithPart: system_property.transfer_time_with_part,
        blowOffTimeToAtm: system_property.blow_off_time_to_atm,
        transferTimeReturn: system_property.transfer_time_return,
        totalHorizontalLiftForceAtPS: system_property === null || system_property === void 0 ? void 0 : system_property.lifting_force_horizontal_at_system_ps,
        totalVerticalLiftForceAtPS: system_property === null || system_property === void 0 ? void 0 : system_property.lifting_force_vertical_at_system_ps,
        systemModel: system_property.system_model,
        energyUsageTotal: system_property.energy_use_total,
        energyUsageVacuumGeneration: system_property.energy_use_vacuum_generation,
        energyUsageBO: system_property.energy_use_BO,
        energyUsage8hr: system_property.energy_use_8hr,
        averageCompressorPower: system_property.compressor_average_power,
        // Graph data
        airConsumptionVsTime: system_property.air_cons_vs_time,
        robotMotionVsTime: system_property.robot_motion_vs_time,
        psIndex: system_property.ps_index,
        esIndex: system_property.es_index,
        atmIndex: system_property.place_stop_index,
        placeStartIndex: system_property.place_start_index,
        governingPump: getGoverningPump(nodeData, sanity_check.governing_pump_id),
        liftingForceHorizontalVsTime: system_property === null || system_property === void 0 ? void 0 : system_property.lifting_force_horizontal,
        liftingForceVerticalVsTime: system_property === null || system_property === void 0 ? void 0 : system_property.lifting_force_vertical,
    };
};
export var deserializeResponseToSystemSanityCheck = function (response, includeEnergySave) {
    var sanity_check = response.sanity_check;
    return {
        estimatedCalculationTime: sanity_check.calculation_time_estimate,
        freeRunningVacuumLevel: sanity_check.free_running_vacuum_level,
        freeRunningVacuumLevelMessage: sanity_check.free_running_vacuum_level_quality,
        feedPressureMessage: sanity_check.feed_pressure_quality,
        energySaveAvailable: includeEnergySave,
        energySaveReachable: includeEnergySave
            ? sanity_check.energy_saving_reachable
            : undefined,
        partSecuredReachable: sanity_check.part_secured_reachable,
        estimatedTimeToEnergySave: includeEnergySave
            ? sanity_check.time_to_energy_saving
            : undefined,
        estimatedTimeToPartSecured: sanity_check.time_to_part_secured,
        cycleTimeReachable: sanity_check.cycle_time_reachable,
        validationPassed: sanity_check.sanity_check_passed,
        governingPumpId: sanity_check.governing_pump_id,
        feedPressureActual: sanity_check.feed_pressure_actual,
        feedPressureRecommended: sanity_check.feed_pressure_recommended,
        feedPressureDrop: sanity_check.feed_pressure_drop,
        feedPressureDropQuality: sanity_check.feed_pressure_drop_quality,
        feedPressureRangeMax: sanity_check.feed_pressure_range_max,
        feedPressureRangeMin: sanity_check.feed_pressure_range_min,
    };
};
