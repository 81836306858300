var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { SystemPerformanceNodeType, } from "api/types";
import { liftingForcesYAxisVerticalKey } from "ui/components/panels/graphs/constants";
export var getGoverningPump = function (nodeData, governingPumpId) {
    var e_1, _a;
    var governingPump = { id: "", node: {} };
    try {
        for (var _b = __values(Object.entries(nodeData)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var _d = __read(_c.value, 2), nodeId = _d[0], node = _d[1];
            if (!node.vacuumPump)
                continue;
            // We give the pump a value before checking if it's the governing pump, if the API
            // has an incorrect value for governing pump ID, we atleast need to return something.
            governingPump = {
                id: nodeId,
                node: node,
            };
            var isGoverningPump = node.vacuumPump.id === governingPumpId;
            if (isGoverningPump) {
                break;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return governingPump;
};
var getNodesByType = function (nodeData, nodeType) {
    var nodes = Object.entries(nodeData)
        .filter(function (_a) {
        var _b = __read(_a, 2), _nodeId = _b[0], node = _b[1];
        return node[nodeType];
    })
        .map(function (_a) {
        var _b = __read(_a, 2), nodeId = _b[0], node = _b[1];
        return {
            id: nodeId,
            performanceData: node[nodeType],
        };
    });
    return nodes;
};
export var getVacuumPumps = function (nodeData) {
    return getNodesByType(nodeData, SystemPerformanceNodeType.VACUUM_PUMP);
};
export var getSuctionCups = function (nodeData) {
    return getNodesByType(nodeData, SystemPerformanceNodeType.SUCTION_CUP);
};
export var isVerticalForce = function (dataKey) {
    return dataKey.startsWith(liftingForcesYAxisVerticalKey);
};
