var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback, useState } from "react";
/**
 * Hook to manage the state of the selection rectangle.
 * @returns The selection rectangle state and functions to start, update and stop the selection
 */
export var useSelectionRectangleState = function () {
    var _a = __read(useState(null), 2), selectionRectangle = _a[0], setSelectionRectangle = _a[1];
    var startSelection = useCallback(function (_a) {
        var x = _a.x, y = _a.y;
        setSelectionRectangle({
            to: { x: x, y: y },
            from: { x: x, y: y },
        });
    }, []);
    var updateSelection = useCallback(function (_a) {
        var x = _a.x, y = _a.y;
        setSelectionRectangle(function (currentSelection) {
            // If there is no selection, do nothing
            if (!currentSelection) {
                return null;
            }
            return __assign(__assign({}, currentSelection), { to: { x: x, y: y } });
        });
    }, []);
    var stopSelection = useCallback(function () {
        setSelectionRectangle(null);
    }, []);
    return {
        selectionRectangle: selectionRectangle,
        startSelection: startSelection,
        updateSelection: updateSelection,
        stopSelection: stopSelection,
    };
};
