import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import * as Styled from "./cardboard-code.style";
import { selectors, useAppSelector } from "store";
export var CardboardCode = function () {
    var cardboardCodeParameter = useAppSelector(selectors.selectCardboardCodeParameter);
    if (!cardboardCodeParameter)
        return null;
    var label = cardboardCodeParameter.label, value = cardboardCodeParameter.value;
    return (_jsxs(Styled.CardboardCodeRoot, { children: [_jsx(Styled.Label, { children: _jsx("span", { children: label }) }), _jsx(Styled.Code, { children: value })] }));
};
