import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTranslation } from "react-i18next";
import * as Styled from "./summary-table.style";
import { getSelectionTableRows, getTubeConnectionTableRows, getTubeTableRows, TableCellType, } from "./helpers";
import * as Table from "ui/components/elements/table";
import { useNumberConversionWithMillimeters } from "hooks/number-conversion";
import { useAppSelector, selectors } from "store";
export var SummaryTable = function () {
    var t = useTranslation().t;
    var convertNumberWithMillimeters = useNumberConversionWithMillimeters();
    var systemDataDefineParameters = useAppSelector(selectors.selectSystemDataDefineParameters);
    var isCentralizedSystem = useAppSelector(selectors.selectIsCentralizedSystem);
    var useDoubleCompressedAirTubes = useAppSelector(selectors.selectUseDoubleCompressedAirTubes);
    var nodes = useAppSelector(selectors.selectNodes);
    var edges = useAppSelector(selectors.selectEdges);
    var tubeConnections = useAppSelector(selectors.selectTubeConnections);
    if (!systemDataDefineParameters)
        return null;
    var suctionCupHeaders = [
        t("summary_modal.table.suction_cup"),
        t("summary_modal.table.quantity"),
        t("summary_modal.table.prel_code"),
        t("summary_modal.table.link_header"),
    ];
    var suctionCupRows = getSelectionTableRows(systemDataDefineParameters.suctionCupSelections, systemDataDefineParameters.suctionCupComponents);
    var pumpHeaders = [
        t("summary_modal.table.vacuum_pump"),
        t("summary_modal.table.quantity"),
        t("summary_modal.table.prel_code"),
        t("summary_modal.table.link_header"),
    ];
    var pumpSelections = isCentralizedSystem
        ? systemDataDefineParameters.vacuumPumpSelectionsCentralized
        : systemDataDefineParameters.vacuumPumpSelectionsDecentralized;
    var pumpRows = getSelectionTableRows(pumpSelections, systemDataDefineParameters.vacuumPumpComponents);
    var tubeConnectionHeaders = [];
    var tubeConnectionRows = [];
    if (tubeConnections[0]) {
        var tubeConnectionComponent = tubeConnections[0]
            .component;
        tubeConnectionHeaders = [
            t("summary_modal.table.tube_connection"),
            t("summary_modal.table.quantity"),
            tubeConnectionComponent.diameters.length > 1
                ? t("summary_modal.table.tube_connection_diameters")
                : t("summary_modal.table.tube_connection_diameter"),
            "",
        ];
        tubeConnectionRows = getTubeConnectionTableRows(nodes, convertNumberWithMillimeters);
    }
    var edgeHeaders = [
        t("summary_modal.table.tube"),
        t("summary_modal.table.quantity"),
        t("summary_modal.table.tube_length"),
        t("summary_modal.table.tube_diameter"),
    ];
    var edgeRows = getTubeTableRows(edges, useDoubleCompressedAirTubes, convertNumberWithMillimeters);
    var cupMountingHeaders = [
        t("summary_modal.table.cup_mounting"),
        t("summary_modal.table.quantity"),
        t("summary_modal.table.prel_code"),
        t("summary_modal.table.link_header"),
    ];
    var cupMountingRows = getSelectionTableRows(systemDataDefineParameters.cupMountingSelections, systemDataDefineParameters.cupMountingComponents);
    return (_jsx(Styled.SummaryTable, { children: _jsxs(Table.Root, { children: [suctionCupRows.length > 0 && (_jsx(TableGroup, { headers: suctionCupHeaders, rows: suctionCupRows })), pumpRows.length > 0 && (_jsx(TableGroup, { headers: pumpHeaders, rows: pumpRows })), cupMountingRows.length > 0 && (_jsx(TableGroup, { headers: cupMountingHeaders, rows: cupMountingRows })), tubeConnectionRows.length > 0 && (_jsx(TableGroup, { headers: tubeConnectionHeaders, rows: tubeConnectionRows })), edgeRows.length > 0 && (_jsx(TableGroup, { headers: edgeHeaders, rows: edgeRows }))] }) }));
};
var TableGroup = function (_a) {
    var headers = _a.headers, rows = _a.rows;
    var t = useTranslation().t;
    return (_jsxs(Table.Body, { children: [_jsx(Table.HeaderRow, { children: headers.map(function (header) { return (_jsxs(Table.HeaderCol, { children: [" ", header, " "] }, header)); }) }), Object.values(rows).map(function (row, ix) {
                return (_jsx(Table.Row, { children: row.map(function (cell, rowIndex) {
                        if (cell.type === TableCellType.LINK) {
                            return (_jsx(Table.Col, { children: _jsx("a", { href: cell.value, target: "_blank", rel: "noreferrer", children: t("summary_modal.table.link_row") }) }, rowIndex));
                        }
                        else if (cell.type === TableCellType.TRANSLATION_KEY) {
                            return (_jsx(Table.Col, { children: t(cell.value) }, rowIndex));
                        }
                        else {
                            return _jsxs(Table.Col, { children: [" ", cell.value, " "] }, rowIndex);
                        }
                    }) }, ix));
            })] }));
};
