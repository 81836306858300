var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Container, Grid, GridItem, Button, } from "@animech/ui";
import { CustomBaseModal } from "ui/components/modals/custom-base-modal/custom-base-modal";
/**
 * The implementation of this confirm modal follows the original implementation in @animech/ui,
 * with some project-specific customizations
 */
export var CustomConfirmModal = function (props) {
    var color = props.color, _a = props.cancelActionLabel, cancelActionLabel = _a === void 0 ? "Cancel" : _a, _b = props.okActionLabel, okActionLabel = _b === void 0 ? "OK" : _b, footerPadding = props.footerPadding, _c = props.hideCloseButton, hideCloseButton = _c === void 0 ? true : _c, _d = props.preventCloseOnDefocus, preventCloseOnDefocus = _d === void 0 ? true : _d, onShouldClose = props.onShouldClose, children = props.children, cancelButtonProps = props.cancelButtonProps, confirmButtonProps = props.confirmButtonProps, otherProps = __rest(props, ["color", "cancelActionLabel", "okActionLabel", "footerPadding", "hideCloseButton", "preventCloseOnDefocus", "onShouldClose", "children", "cancelButtonProps", "confirmButtonProps"]);
    return (_jsx(CustomBaseModal, __assign({}, otherProps, { color: color, hideCloseButton: hideCloseButton, preventCloseOnDefocus: preventCloseOnDefocus, onShouldClose: function () { return onShouldClose === null || onShouldClose === void 0 ? void 0 : onShouldClose(false); }, footer: _jsx(Container, { padding: footerPadding, children: _jsxs(Grid, { spacing: "medium", children: [_jsx(GridItem, { rootAttributes: { style: { textAlign: "left" } }, children: _jsx(Button, __assign({ type: "auto", appearance: "ghost", color: "neutral", onClick: function () { return onShouldClose === null || onShouldClose === void 0 ? void 0 : onShouldClose(false); } }, cancelButtonProps, { children: cancelActionLabel })) }), _jsx(GridItem, { rootAttributes: { style: { textAlign: "right" } }, children: _jsx(Button, __assign({ type: "auto", color: color, onClick: function () { return onShouldClose === null || onShouldClose === void 0 ? void 0 : onShouldClose(true); } }, confirmButtonProps, { children: okActionLabel })) })] }) }), children: children })));
};
