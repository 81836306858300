var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useState } from "react";
import { Notification, NotificationPortal } from "@animech/ui";
export var TimedNotification = function (_a) {
    var timeout = _a.timeout, text = _a.text, onClose = _a.onClose, _b = _a.color, color = _b === void 0 ? "alert" : _b;
    var _c = __read(useState(true), 2), shouldShow = _c[0], setShouldShow = _c[1];
    return (_jsx(NotificationPortal, { open: shouldShow, onShouldClose: function () {
            setShouldShow(false);
            onClose === null || onClose === void 0 ? void 0 : onClose();
        }, children: _jsx(Notification, { color: color, timeout: timeout, className: "globally-styled-notification", children: text }) }));
};
