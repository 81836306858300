import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var RotateIcon = function (_a) {
    var size = _a.size, flip = _a.flip;
    return (_jsxs("svg", { width: size, height: size, viewBox: "0 0 29 27", fill: "none", xmlns: "http://www.w3.org/2000/svg", style: { transform: flip ? "scaleX(-1)" : undefined }, children: [_jsx("path", { d: "M27.3206 3.26562V10.8631H19.7231", stroke: "#212426", strokeWidth: "2.53248", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M24.1415 17.1924C23.3184 19.5222 21.7604 21.5214 19.7022 22.8886C17.6441 24.2559 15.1973 24.9172 12.7306 24.7729C10.2639 24.6286 7.91098 23.6865 6.0263 22.0886C4.14163 20.4907 2.82736 18.3235 2.28153 15.9136C1.73571 13.5037 1.98791 10.9818 3.00012 8.7277C4.01234 6.47364 5.72973 4.60963 7.8935 3.41654C10.0573 2.22346 12.5502 1.76595 14.9966 2.11295C17.443 2.45996 19.7104 3.59268 21.457 5.34042L27.3197 10.8612", stroke: "#212426", strokeWidth: "2.53248", strokeLinecap: "round", strokeLinejoin: "round" })] }));
};
export var RotateClockwiseIcon = function (_a) {
    var size = _a.size;
    return (_jsx(RotateIcon, { size: size }));
};
export var RotateCounterClockwiseIcon = function (_a) {
    var size = _a.size;
    return (_jsx(RotateIcon, { size: size, flip: true }));
};
