var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { H4, Button, Notification } from "@animech/ui";
import { useTranslation } from "react-i18next";
import * as Styled from "./summary.style";
import { SummaryTable } from "./summary-table";
import { SummaryPDF } from "./summary-pdf";
import { DownloadIcon } from "ui/components/elements/icons/download";
import { useSystemPerformanceValidityMessage } from "hooks/messages";
import { useAppSelector, selectors } from "store";
import { CustomBaseModal } from "ui/components/modals/custom-base-modal/custom-base-modal";
import { DelayedFallback } from "ui/utility/delayed-fallback";
import { LoadingOverlay } from "ui/components/elements/loading-overlay";
export var SummaryModal = function (_a) {
    var generateSceneScreenshot = _a.generateSceneScreenshot, generateGraphScreenshot = _a.generateGraphScreenshot, onCloseModal = _a.onCloseModal, props = __rest(_a, ["generateSceneScreenshot", "generateGraphScreenshot", "onCloseModal"]);
    var onShouldClose = props.onShouldClose;
    // Since we have a custom X-button for closing the modal, we need to write a custom
    // method that hijacks the default method and also resets the graph-panel in the background.
    var closeModal = function () {
        onCloseModal();
        onShouldClose && onShouldClose();
    };
    return (_jsx(CustomBaseModal, __assign({}, props, { hideCloseButton: true, onShouldClose: closeModal, children: _jsx(SummaryModalContent, { generateSceneScreenshot: generateSceneScreenshot, generateGraphScreenshot: generateGraphScreenshot, onShouldClose: closeModal }) })));
};
export var SummaryModalContent = function (_a) {
    var onShouldClose = _a.onShouldClose, generateSceneScreenshot = _a.generateSceneScreenshot, generateGraphScreenshot = _a.generateGraphScreenshot;
    var dashboardData = useAppSelector(selectors.selectDashboardData);
    var systemPerformanceMessage = useSystemPerformanceValidityMessage();
    var isDownloadAvailable = dashboardData && !systemPerformanceMessage;
    var _b = __read(useState(null), 2), graphScreenshotSrc = _b[0], setGraphScreenshotSrc = _b[1];
    var _c = __read(useState(false), 2), shouldPrint = _c[0], setShouldPrint = _c[1];
    var t = useTranslation().t;
    var pdfComponentRef = useRef(null);
    var handlePrint = useReactToPrint({
        content: function () { return pdfComponentRef.current; },
    });
    var sceneScreenshotSrc = generateSceneScreenshot(200, 150);
    useEffect(function () {
        if (!shouldPrint || graphScreenshotSrc)
            return;
        var getGraphScreenshotSource = function () { return __awaiter(void 0, void 0, void 0, function () {
            var src;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, generateGraphScreenshot()];
                    case 1:
                        src = _a.sent();
                        setGraphScreenshotSrc(src);
                        return [2 /*return*/];
                }
            });
        }); };
        getGraphScreenshotSource();
    }, [generateGraphScreenshot, graphScreenshotSrc, shouldPrint]);
    useEffect(function () {
        if (!shouldPrint)
            return;
        if (!graphScreenshotSrc)
            return;
        handlePrint();
        setShouldPrint(false);
    }, [graphScreenshotSrc, handlePrint, shouldPrint]);
    var summaryTable = _jsx(SummaryTable, {});
    var DownloadButton = function () {
        if (!isDownloadAvailable) {
            return _jsxs("div", { children: [" ", t("summary_modal.download_button"), " "] });
        }
        return (_jsx("a", { onClick: function () { return setShouldPrint(true); }, children: t("summary_modal.download_button") }));
    };
    var isPDFLoading = shouldPrint && !graphScreenshotSrc;
    return (_jsxs(Styled.SummaryModal, { children: [_jsxs("div", { className: "".concat(Styled.prefix, "-left-section"), children: [_jsx("div", { className: "".concat(Styled.prefix, "-title"), children: _jsx(H4, { children: t("summary_modal.title") }) }), _jsx("div", { className: "".concat(Styled.prefix, "-left-content"), children: summaryTable })] }), _jsxs("div", { className: "".concat(Styled.prefix, "-right-section"), children: [_jsx("div", { className: "".concat(Styled.prefix, "-close-icon"), children: _jsx(Button, { color: "neutral", appearance: "text", icon: "close", onClick: function (e) {
                                e.stopPropagation();
                                onShouldClose === null || onShouldClose === void 0 ? void 0 : onShouldClose();
                            }, rounded: true }) }), _jsxs("div", { className: "".concat(Styled.prefix, "-right-content"), children: [_jsx("img", { src: sceneScreenshotSrc }), _jsxs("div", { className: "".concat(Styled.prefix, "-link ").concat(!isDownloadAvailable && "disabled"), children: [_jsx(DownloadIcon, {}), _jsx(DownloadButton, {})] }), !isDownloadAvailable && (_jsx(Notification, { color: "tertiary", children: t("summary_modal.download_unavailable_warning") }))] }), _jsx(SummaryPDF, { ref: pdfComponentRef, screenshotSrc: sceneScreenshotSrc, graphScreenshotSrc: graphScreenshotSrc !== null && graphScreenshotSrc !== void 0 ? graphScreenshotSrc : "", summaryTable: summaryTable })] }), _jsx(DelayedFallback, { isLoading: isPDFLoading, fallback: _jsx(LoadingOverlay, {}) })] }));
};
