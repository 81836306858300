var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
/* eslint-disable camelcase */
import { defaultAdjustableObjectCameraViews } from "./camera-views";
import { suctionCupsFamilyFX, suctionCupsFamilyFXI, suctionCupsFamilyMX, suctionCupsFamilyB, suctionCupsFamilyB_XP, suctionCupsFamilyBX_P, suctionCupsFamilyOB, } from "./cup-families";
import { CardboardObjectShapeId } from "store/types";
import { AdjustableObjectType, } from "3d/types";
export var suctionCupModelsCardboard = __assign(__assign(__assign(__assign(__assign(__assign(__assign({}, suctionCupsFamilyBX_P), suctionCupsFamilyB_XP), suctionCupsFamilyOB), suctionCupsFamilyB), suctionCupsFamilyMX), suctionCupsFamilyFX), suctionCupsFamilyFXI);
var defaultCardboardObjectShapeData = {
    cameraViews: __assign({}, defaultAdjustableObjectCameraViews),
    materialTransforms: [],
    modelName: "",
    sizeRange: {
        min: {
            x: 0.1,
            y: 0.1,
        },
        max: {
            x: 5,
            y: 5,
        },
    },
    gripPositionY: 5.003,
    tubeNetworkY: 5.43,
    adjustableObject: { type: AdjustableObjectType.BOX },
};
export var cardboardObjectShapes = (_a = {},
    _a[CardboardObjectShapeId.WITH_SEAM] = __assign(__assign({}, defaultCardboardObjectShapeData), { translationKey: "sidebar.object_shape.objects.cardboard_with_seam", image: "img/app/object-shapes/cardboard/with_seam.webp" }),
    _a[CardboardObjectShapeId.WITHOUT_SEAM] = __assign(__assign({}, defaultCardboardObjectShapeData), { translationKey: "sidebar.object_shape.objects.cardboard_without_seam", image: "img/app/object-shapes/cardboard/without_seam.webp" }),
    _a);
